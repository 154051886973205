import FormDynamicArrayInput from "@/components/formDynamic/components/nonPrimitiveType/formDynamicArrayInput";
import FormDynamicGroupInput from "@/components/formDynamic/components/nonPrimitiveType/formDynamicGroupInput";
import FormDynamicInput from "@/components/formDynamic/components/nonPrimitiveType/formDynamicInput";
import { Grid } from "@mantine/core";
import jsonata from "jsonata";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

function FormDynamicMainInput(props) {
  const [fieldTemplateMain, setFieldTemplateMain] = useState(null);
  const [inputProps, setInputProps] = useState(null);

  const getFormInputProps = (currentNameArray = []) => {
    return props.formDynamic.getInputProps(currentNameArray.join("."));
  };

  const getInitialValue = (currentNameArray = []) => {
    let currentValue = null;

    currentNameArray.forEach((name) => {
      const convertedName = name.toString();

      const keyArray = convertedName.includes(".") ? convertedName.split(".") : [convertedName];

      if (!currentValue) {
        currentValue = props.finalFormConfig.initialValues;
      }

      keyArray.forEach((key) => {
        currentValue = currentValue[key];
      });
    });

    return currentValue;
  };

  useEffect(() => {
    let queryId = router.query.id;
    if (queryId === "home") {
      queryId = "recruitments";
    }

    if (
      props.fieldTemplateMain &&
      (!Array.isArray(props.fieldTemplateMain.hide) || !props.fieldTemplateMain.hide.includes(queryId))
    ) {
      setFieldTemplateMain(props.fieldTemplateMain);
    }
  }, [props.fieldTemplateMain]);

  useEffect(() => {
    if (props.formDynamic) {
      const inputProps = getFormInputProps(props.currentNameArray);
      const promiseArray = [];

      let selfJsonataString = "$.values.";
      props.currentNameArray.forEach((name, index) => {
        if (typeof name === "number") {
          if (selfJsonataString.endsWith(".")) {
            selfJsonataString = selfJsonataString.slice(0, -1);
          }
          selfJsonataString += `[${name}]`;
        } else {
          if (name.includes("-")) {
            selfJsonataString += `'${name}'`;
          } else {
            selfJsonataString += name;
          }
        }
        if (index < props.currentNameArray.length - 1) {
          selfJsonataString += ".";
        }
      });
      const fieldTemplateMainKeys = Object.keys(props.fieldTemplateMain);
      fieldTemplateMainKeys.forEach((key) => {
        if (key[0] === "_" && props.fieldTemplateMain[key] && key !== "_id") {
          const inputPropsKey = key.slice(1);

          let jsonataKey;
          if (props.fieldTemplateMain[key]["code"] && props.fieldTemplateMain.name) {
            if (typeof props.fieldTemplateMain[key]["code"] === "string") {
              jsonataKey = selfJsonataString.replace(
                props.fieldTemplateMain.name,
                props.fieldTemplateMain[key]["code"]
              );
            } else if (props.fieldTemplateMain[key]["parsed"]) {
              jsonataKey = props.fieldTemplateMain[key]["parsed"];
              const codeIds = Object.keys(props.fieldTemplateMain[key]["code"]);

              codeIds.forEach((codeId) => {
                const codeNames = Object.keys(props.fieldTemplateMain[key]["code"][codeId]);
                codeNames.forEach((codeName) => {
                  const idToReplace = codeId + ":" + codeName;
                  if (!props.fieldTemplateMain[key]["code"][codeId][codeName]) {
                    jsonataKey = jsonataKey.replace(
                      idToReplace,
                      selfJsonataString.replace(props.fieldTemplateMain.name, codeName)
                    );
                  } else {
                    jsonataKey = jsonataKey.replace(
                      idToReplace,
                      selfJsonataString.replace(codeName, props.fieldTemplateMain[key]["code"][codeId][codeName])
                    );
                  }
                });
              });
              jsonataKey = jsonataKey.replace("self", selfJsonataString);
            }
          } else {
            jsonataKey = props.fieldTemplateMain[key];
          }
          let expression = jsonata(jsonataKey);
          const evaluate = async () => {
            return await expression.evaluate(props.formDynamic);
          };

          promiseArray.push(
            evaluate().then((value) => {
              if (value !== inputProps[inputPropsKey]) {
                if (inputPropsKey === "value") {
                  inputProps.onChange(value);
                }
                // if (inputProps.dataRef !== value && inputPropsKey === 'dataRef') {
                //     inputProps.onChange(inputProps.value)
                // }
                inputProps[inputPropsKey] = value;
                props.fieldTemplateMain[inputPropsKey] = value;
              }
            })
          );
        }
      });

      Promise.all(promiseArray).then(() => {
        setInputProps((prev) => ({
          ...prev,
          ...inputProps,
        }));
      });
    }
  }, [props.formDynamic]);

  const router = useRouter();

  const maxSpan = 12;

  const generateOneFieldInputJSX = (fieldTemplate) => {
    if (!inputProps) {
      return <></>;
    }
    return (
      <FormDynamicInput
        options={{
          inputTemplate: fieldTemplate,
          initialValue: getInitialValue(props.currentNameArray),
          formInputProps: inputProps,
          requiredInput:
            fieldTemplate.validators &&
            fieldTemplate.validators.find((validator) => validator.type === "required") !== undefined,
          primaryColor: props.primaryColor,
          disabledInput: props.disabledInput,
          handleDataInSelect: props.handleDataInSelect,
          dataInSelect: props.dataInSelect,
          setHasCustomError: props.setHasCustomError,
          fileToUploadArray: props.fileToUploadArray,
          setFileToUploadArray: props.setFileToUploadArray,
          inputName: props.currentNameArray.join("."),
          setDisabledSubmit: props.setDisabledSubmit,
          handleCheckBoxMasterIndex: props.handleCheckBoxMasterIndex,
          setDisabledInput: props.setDisabledInput,
          setDisabledButton: props.setDisabledButton,
          disabledButton: props.disabledButton,
          sectionStatus: props.sectionStatus,
          requireUpdateInfo: props.requireUpdateInfo,
          isFormSubmitted: props.isFormSubmitted,
          hostNameUrl: props.hostNameUrl,
        }}
      />
    );
  };

  if (!fieldTemplateMain) {
    return <></>;
  }

  if (fieldTemplateMain.type === "group") {
    return (
      <Grid.Col span={fieldTemplateMain.nCols || maxSpan}>
        {fieldTemplateMain.typeGroup ? <FormDynamicGroupInput {...props} /> : <FormDynamicArrayInput {...props} />}
      </Grid.Col>
    );
  }

  let columnCount = maxSpan / props.numInputPerRow;
  if (fieldTemplateMain.priority) {
    columnCount = 12;
  } else if (fieldTemplateMain.nCols) {
    columnCount = props.numInputPerRow > 1 ? fieldTemplateMain.nCols : columnCount;
  }

  return (
    <Grid.Col span={fieldTemplateMain.priority ? maxSpan : columnCount}>
      {generateOneFieldInputJSX(fieldTemplateMain)}
    </Grid.Col>
  );
}

export default FormDynamicMainInput;

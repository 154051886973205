
import { Box } from '@mantine/core';
import dynamic from 'next/dynamic';



function IconComponent({ name, color, size, stroke }) {
    const IconJSX = dynamic(() => import(`@/public/icons/${name}.svg`), { ssr: false })

    const strokeCSS = stroke ? { 'stroke': stroke || 'var(--primaryColor)' } : {}

    return <Box style={{ width: size, height: size, display: 'flex', alignItems: 'center', justifyContent: 'center' }} sx={(theme) => ({
        'path': {
            fill: color || 'var(--primaryColor)',
            ...strokeCSS

        },
        'svg': {
            width: '100%',
            height: '100%'
        }
    })}>

        <IconJSX />

    </Box>
}

export default IconComponent
import { useState } from "react";
import Feature from "@/components/feature";
import Switcher1 from "@/components/switcher/switcher1";

export default function Switcher(props) {
  let eleHTML = <></>;
  switch (props.display) {
    case 'switcher1':
      eleHTML = <Switcher1 {...props}/>;
      break;
    default:
      break;
  }
  return eleHTML;
}




import ImagePlaceholder from "@/components/imagePlaceholder/imagePlaceholder";
import { Badge, Button, Group, Image, Stack, Text, Tooltip } from "@mantine/core";
import { CurrencyDollar } from "tabler-icons-react";
import styles from "../../styles/feature.module.scss";
import variables from "../../styles/variables.module.scss";
import useTrans from "@/hooks/useTrans";

function Carousel5Card(props) {
    const trans = useTrans();
    const handleButtonClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.handleButtonClick) {
            props.handleButtonClick();
        }
    }

    return (
        <>
            <Stack justify={'flex-start'} className={styles.card} spacing={24}>
                <Stack style={{ position: "relative" }}>
                    <Image
                        className={styles.cardImage}
                        src={props.image}
                        height={344}
                        radius={"32px 32px 0px 0px"}
                        alt={props.image}
                        withPlaceholder
                        placeholder={<ImagePlaceholder display={"imagePlaceholder1"} radius={32}
                            image={props.defaultImage ? props.defaultImage : props.imageLogo} />}
                    />
                    {props.tags && Array.isArray(props.tags)
                        ? <Group style={{ position: "absolute", bottom: 0, padding: 15 }} spacing={5}>
                            {
                                props.tags.map((tag, index) => {
                                    return <Badge variant="gradient"
                                        gradient={{
                                            from: variables.primaryColor,
                                            to: variables.primaryColor,
                                            deg: 0
                                        }}
                                        key={`badge-${index}`}>{tag}
                                    </Badge>
                                })
                            }
                        </Group>
                        : <></>
                    }
                </Stack>
                <Stack spacing={28} className={styles.cardInfo}>
                    <Stack spacing={16}>
                        <Group position={props.hot_job ? "apart" : "right"}>
                            {props.hot_job
                                ? <Group spacing={8}>
                                    <Image src="/images/urgentIcon.svg" width={16} alt="" />
                                    <Text fw={400} fz={14} color="red">{trans.hotJob}</Text>

                                </Group>
                                : <></>}
                            <Group spacing={8}>
                                <Image src="/images/clockIcon.svg" width={16} alt="" />
                                <Text fw={400} fz={14} color="black">{props.expiration_date}</Text>
                            </Group>
                        </Group>
                        <Group position="apart" style={{ position: "relative", margin: 0, height: "60px" }}>
                            <Tooltip label={props.name} withArrow={true}>
                                <Text lineClamp={2} fz={20} fw={700}
                                    color={variables.primaryColor}>{props.name}</Text>
                            </Tooltip>
                        </Group>

                        <Stack spacing={12}>
                            <Group spacing={8}>
                                <Image src="/images/marker.svg" width={20} alt="" />
                                <Text fz={16} fw={400}>{props.location}</Text>
                            </Group>
                            <Group spacing={8}>
                                <CurrencyDollar size={20} strokeWidth={1.5} />
                                <Text fz={16} fw={400}>{props.salary}</Text>
                            </Group>
                        </Stack>
                    </Stack>

                    <Button color="white" className={styles.applyButton} fullWidth
                        onClick={(e) => handleButtonClick(e)}>
                        {trans.apply}
                    </Button>
                </Stack>
            </Stack>
        </>
    );
}

export default Carousel5Card

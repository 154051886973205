import { Image } from "@mantine/core";

export default function ImagePlaceholder(props) {
    let eleHTML = <></>;

    switch (props.display) {
        case "imagePlaceholder1":
            eleHTML = <Image src={props.image} alt={`imagePlaceholder`} height={props.height || '100%'} width={props.width || '100%'} radius={props.allRadius} styles={{
                image: props.radius && {
                    borderTopLeftRadius: props.radius,
                    borderTopRightRadius: props.radius
                }
            }} />;
            break;
        default:
            break;
    }

    return eleHTML
}


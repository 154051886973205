/* eslint-disable react-hooks/exhaustive-deps */
import { Carousel1 } from "@/components/carousel/carousel1/carousel1";
import { Carousel2 } from "@/components/carousel/carousel2/carousel2";
import { Carousel3 } from "@/components/carousel/carousel3/carousel3";
import { Carousel4 } from "@/components/carousel/carousel4/carousel4";
import Carousel6 from "@/components/carousel/carousel6/carousel6";
import Carousel7 from "@/components/carousel/carousel7/carousel7";
import Carousel8 from "@/components/carousel/carousel8/carousel8";
import Carousel9 from "@/components/carousel/carousel9/carousel9";
import Carousel10 from "@/components/carousel/carousel10/carousel10";
import variables from "@/styles/variables.module.scss";
import { getDataFromCollection } from "services/services";

export default function _Carousel(props) {
  let eleHTML = <> </>;
  switch (props.display) {
    case "carousel1":
      eleHTML = (
        <Carousel1 {...props.options} url={props.url} imageLogo={props.image} defaultImage={props.defaultImage} />
      );
      break;

    case "carousel2":
      eleHTML = (
        <Carousel2
          {...props.options}
          url={props.url}
          background={props.background}
          windowDimension={props.windowDimension}
          primaryColor={props.primaryColor}
          secondaryColor={props.secondaryColor}
          imageLogo={props.image}
          defaultImage={props.defaultImage}
        />
      );

      break;

    case "carousel3":
      eleHTML = (
        <Carousel3 {...props.options} url={props.url} imageLogo={props.image} defaultImage={props.defaultImage} />
      );
      break;

    case "carousel4":
      eleHTML = (
        <Carousel4 {...props.options} url={props.url} imageLogo={props.image} defaultImage={props.defaultImage} />
      );
      break;

    case "carousel6":
      eleHTML = (
        <Carousel6
          {...props.options}
          url={props.url}
          windowDimension={props.windowDimension}
          primaryColor={props.primaryColor}
          imageLogo={props.image}
          secondaryColor={props.secondaryColor}
          defaultImage={props.defaultImage}
          noDataText={props.noDataText}
        />
      );
      break;
    case "carousel7":
      eleHTML = (
        <Carousel7
          primaryColor={props.primaryColor}
          options={props.options}
          defaultImage={props.defaultImage || props.image || "/images/no_image.png"}
          windowDimension={props.windowDimension}
          url={props.url}
          display={props.display}
          backgroundColor={props.backgroundColor}
        />
      );
      break;

    case "carousel8":
      eleHTML = (
        <Carousel8
          backgroundColor={props.backgroundColor}
          options={props.options}
          windowDimension={props.windowDimension}
        />
      );
      break;

    case "carousel9":
      eleHTML = (
        <Carousel9
          options={props.options}
          variables={variables}
          defaultImage={props.defaultImage || props.image || "/images/no_image.png"}
          display={props.display}
          url={props.url}
        />
      );
      break;
    case "carousel10":
      eleHTML = <Carousel10 options={props.options} variables={variables}/>;
      break;
    default:
      break;
  }

  return eleHTML;
}

export function loadData(loadDataOptions, setDataFunc) {
  const loadDataFunc = async () =>
    await getDataFromCollection(
      loadDataOptions.url,
      loadDataOptions.data,
      loadDataOptions.query || "",
      loadDataOptions.jsonata,
      loadDataOptions.selectedIds
    );
  loadDataFunc().then((res) => {
    if (res) {
      console.log('huyvn res', res)
      let resUsed = [res];
      if (Array.isArray(res)) resUsed = res;

      if (loadDataOptions.display === "carousel4") {
        const resSorted = resUsed.sort((a, b) => {
          const date1 = new Date(a.date);
          const date2 = new Date(b.date);
          return date2 - date1;
        });
        setDataFunc(resSorted);
      } else {
        setDataFunc(resUsed);
      }
    }
  });
}

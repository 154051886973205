import NoData from "@/components/helpers/noData";
import List8CardWithModal from "@/components/modal/list8CardWithModal";
import { getDataFromCollection } from "@/services/services";
import styles from "@/styles/feature.module.scss";
import variables from "@/styles/variables.module.scss";
import { Divider, Group, Pagination, SimpleGrid, Stack, Text } from "@mantine/core";
import { useEffect, useState } from 'react';

function List8R(props) {

    const [data, setData] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [activeLimit, setActiveLimit] = useState(9);

    useEffect(() => {
        const getDataApi = async () => await getDataFromCollection(props.url, props.options.data, "", props.options.jsonata);

        getDataApi().then(res => {
            if (res) {
                let resUsed = [res];
                if (Array.isArray(res)) {
                    resUsed = res;
                }
                setData(resUsed);

            }
        }).catch(err => console.log(err));

        return () => {
            setData([])
        }
    }, [props.options.data, props.url, props.options.jsonata, activeLimit]);

    useEffect(() => {
        setActivePage(1);
    }, [props.options.filterTagStringArray]);

    useEffect(() => {
        if (props.options.limitPage)
            setActiveLimit(props.options.limitPage)
    }, [props.options.limitPage])

    let dataFiltered = data;
    if (props.options.filterTagStringArray) {
        dataFiltered = dataFiltered.filter(recruitmentPlan => {

            let filterAnd = true;
            props.options.filterTagStringArray.forEach(filterTagString => {
                let filterOr = false;
                recruitmentPlan.tags.forEach(tagName => {
                    if (!filterTagString || filterTagString === tagName)
                        filterOr = true;
                });
                if (!filterOr) {
                    filterAnd = false;
                }
            })

            return filterAnd;
        });
    }

    if (props.options.textSearch) {
        dataFiltered = dataFiltered.filter(recruitmentPlan => {
            return recruitmentPlan.name.toLowerCase().includes(props.options.textSearch.toLowerCase());
        })
    }

    const pagedData = dataFiltered.slice(activeLimit * (activePage - 1), activeLimit * (activePage));


    const cardContentJSX = pagedData.map((dataSingleton, index) => {
        return (
            <List8CardWithModal key={`carousel5Card-${index}`}
                dataSingleton={dataSingleton}
                typeChild={props.options.child}
                imageLogo={props.imageLogo}
                defaultImage={props.defaultImage}
                url={props.url}
                primaryColor={props.primaryColor}
                secondaryColor={props.secondaryColor} />
        )
    })

    const contentJSX = data.length >= 3
        ? (
            <SimpleGrid cols={3} breakpoints={[
                { maxWidth: 1440, cols: 3, spacing: "sm" },
                { maxWidth: 1300, cols: 2, spacing: "sm" },
                { maxWidth: 800, cols: 1, spacing: "sm" },
            ]}>
                {cardContentJSX}
            </SimpleGrid>
        )
        : (
            <Group position={"center"} spacing={20}>
                {cardContentJSX}
            </Group>
        )

    let eleHTML = <NoData />;
    if (dataFiltered.length > 0) {
        eleHTML = (
            <>
                <Stack spacing={30} className={styles.list8}>
                    {props.options.title && <Stack spacing={10}>
                        <Text ta={"left"} fw={700} fz={25} c={variables.primaryColor}>{props.options.title}</Text>
                        <div style={{ width: "30%" }}>
                            <Divider color={variables.secondColor} />
                        </div>
                    </Stack>}

                    {contentJSX}

                    <Group position={"right"}>
                        <Pagination value={activePage} onChange={setActivePage}
                            total={Math.ceil(dataFiltered.length / activeLimit)} radius={"xl"} />
                    </Group>
                </Stack>

            </>
        )
    }
    return eleHTML;
}

export default List8R

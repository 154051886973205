import ImageComponent from "@/components/helpers/imageComponent/imageComponent";
import { Carousel } from "@mantine/carousel";
import { Group, Image, Stack, Text, Tooltip } from "@mantine/core";
import convert from "color-convert";
import Link from "next/link";
import { useEffect, useState, useRef } from "react";
import { ArrowRight } from "tabler-icons-react";
import { useMediaQuery } from "usehooks-ts";
import { loadData } from "../carouel";
import carouselStyles from "./carousel7.module.scss";
import Autoplay from 'embla-carousel-autoplay';

function Carousel7({
  options,
  windowDimension,
  url,
  display,
  backgroundColor,
  defaultImage,
  primaryColor,
}) {
  const [data, setData] = useState([]);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const autoplay = useRef(Autoplay({ delay: 5000 }));
  useEffect(() => {
    if (options.useApi) {
      loadData(
        {
          url: url,
          data: options.data,
          query: options.query,
          jsonata: options.jsonata,
          display: display,
          selectedIds: options.selectedIds || options.hotJobIds || options.hotNewsIds
        },
        setData
      );
    } else {
      setData(options.dataSource || []);
    }

    return () => {
      setData([]);
    };
  }, []);

  const maxSlideVisible =
    windowDimension.width >= 1400 ? 3 : windowDimension.width > 768 ? 2 : 1;

  const contentJSX = [];
  let slideCardGroup = [];

  const useCarousel =
    data.length / (options.cardType === "imageGroup" ? 2 : 1) > maxSlideVisible;

  data.forEach((dataSingleton, index) => {
    let slideCard = <></>;

    const imageSrc = !dataSingleton.image || dataSingleton.image.includes("null")
      ? defaultImage
      : dataSingleton.image
    switch (options.cardType) {
      case "news": {
        const tag = dataSingleton.tags?.[0];
        const tagColor =
          tag?.color || options.defaultTag?.color || primaryColor;
        const convertColor = convert.hex.hsl(tagColor);
        const backgroundColor = `hsla(${convertColor[0]}, ${convertColor[1]}%, ${convertColor[2]}%, 0.15)`;
        const slideCardJSX = (
          <Stack
            className={carouselStyles.carouselNewsCard}
            style={{ cursor: "pointer" }}
          >
            {/* {(tag || options.defaultTag) && (
              <Text
                bg={backgroundColor}
                c={tagColor}
                className={carouselStyles.carouselNewsCardTag}
              >
                {tag?.label || options.defaultTag?.label}
              </Text>
            )} */}

            <Stack spacing={16}>
              {options.showPreviewImage
                ? <ImageComponent
                  radius={8}
                  alt="image"
                  src={imageSrc}
                  imageFit={options.imageFit}
                  height={180} />
                : <Image
                  radius={8}
                  alt={"image"}
                  src={imageSrc}
                  fit={options.imageFit}
                  height={180}
                />}

              <Tooltip label={dataSingleton.title} withArrow multiline w={300}>
                <Text
                  lineClamp={3}
                  className={carouselStyles.carouselNewsCardTitle}
                >
                  {dataSingleton.title || options.defaultTitle || "Tiêu đề mẫu"}
                </Text>
              </Tooltip>
            </Stack>

            <Group position="right" spacing={8} align="center">
              <Text className={carouselStyles.text}>Xem thêm</Text>
              <ArrowRight size={20} color="black" />
            </Group>
          </Stack>
        );

        if (options.data && dataSingleton.id && options.child) {
          slideCard = (
            <Link
              href={`${options.data}/${dataSingleton.id}?type_child=${options.child}`}
            >
              {slideCardJSX}
            </Link>
          );
        } else {
          slideCard = slideCardJSX;
        }

        break;
      }

      case "imageGroup":
        slideCardGroup.push(
          options.showPreviewImage
            ? <ImageComponent
              radius={24}
              alt="image"
              src={imageSrc}
              imageFit={options.imageFit}
              height={320} />
            : <Image
              radius={24}
              alt={"image"}
              src={imageSrc}
              fit={options.imageFit}
              height={320}
            />
        );
        if ((index + 1) % 2 === 0) {
          slideCard = (
            <>
              <Stack spacing={32}>{slideCardGroup}</Stack>
            </>
          );
          slideCardGroup = [];
        }

        break;

      default:
        slideCard = (
          options.showPreviewImage
            ? <ImageComponent
              radius={24}
              alt="image"
              src={imageSrc}
              imageFit={options.imageFit}
              height={320} />
            : <Image
              radius={24}
              alt={"image"}
              src={imageSrc}
              fit={options.imageFit}
              height={320}
            />
        );
        break;
    }

    if (options.cardType === "imageGroup" && (index + 1) % 2 !== 0) {
      return;
    }

    if (useCarousel) {
      contentJSX.push(
        <Carousel.Slide key={`slide ${index}`}>{slideCard}</Carousel.Slide>
      );
    } else {
      contentJSX.push(<div key={`slide ${index}`} style={{ width: '33%' }}>{slideCard}</div>);
    }
  });

  if (contentJSX.length === 0) {
    return <></>;
  }
  return (
    <Stack
      className={carouselStyles.carousel}
    >
      {options.title && (
        <Text lineClamp={2} className={carouselStyles.title}>
          {options.title}
        </Text>
      )}
      {useCarousel ? (
        <Carousel
          loop={options.hasLoop || true}
          slideSize={`${100 / maxSlideVisible}%`}
          slideGap={32}
          align="start"
          slidesToScroll={maxSlideVisible === 1 ? maxSlideVisible : 2}
          controlSize={48}
          controlsOffset={0}
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
          sx={{
            button: {
              background: "#FFFFFF",
              border: '2px solid #EBEAED',
              opacity: 1,
              width: 48,
              height: 48
            },
            svg: { color: primaryColor, width: "24px", height: "24px" },
          }}
          styles={{
            controls: {
              top: isMobile ? -65 : -90,
              justifyContent: "center",
              gap: 32,
              right: isMobile ? "-70%" : "-90%",
            },
          }}
        >
          {contentJSX}
        </Carousel>
      ) : (
        <Group position="center" spacing={32} noWrap>
          {contentJSX}
        </Group>
      )}
    </Stack>
  );
}

export default Carousel7;

import Feature from "@/components/feature";
import { useWindowSize } from "@/hooks";
import { LocalStorageKey, setRootFunctions } from "@/services/constants";
import { getLayout } from "@/services/services";
import { Stack } from "@mantine/core";
import Head from "next/head";
import { useEffect, useState } from "react";
import _Footer from "./footer/footer";
import _Menu from "./menu/menu";

export default function MainComposition(props) {
  const windowDimension = useWindowSize();

  let eleHTML = <></>;

  if (!props.config) {
    return eleHTML;
  }

  switch (props.display) {
    case "main-composition1":
      eleHTML = (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 0,
              alignItems: "center",
              width: "100%",
              minHeight: "100dvh",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                background: "white",
                maxHeight: props.config.headerHeight || "100px",
                marginBottom: windowDimension.width >= 750 ? 0 : "71px",
              }}
            >
              <_Menu
                display="basic"
                logo={props.config.logo}
                data={props.headers}
                isHeaderSticky={props.config.isHeaderSticky}
                logoSize={props.config.logoSize}
                logoHref={props.config.logoHref}
                windowDimension={windowDimension}
              ></_Menu>
            </div>

            <Stack
              spacing={56}
              style={{
                background: "#F2F2F2",
                width: "100%",
                display: "flex",
                flex: "1 1 0",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {props.mainContent}
            </Stack>

            {props.config.fabButton !== undefined ? (
              <Feature
                component="fab-button"
                display={props.config.fabButton.display}
                options={props.config.fabButton.options}
                primaryColor={props.config.primaryColor}
              />
            ) : (
              <></>
            )}

            <div
              style={{
                display: "flex",
                width: "100%",
                maxHeight:
                  windowDimension.width < (props.footers.options?.height?.threshold || 800)
                    ? props.footers.options?.height?.afterThreshold || "100%"
                    : props.footers.options?.height?.default || "100%",
              }}
            >
              {props.footers && (
                <_Footer
                  display={props.footers.display}
                  logo={props.footers.logo ? props.footers.logo : props.config.logo}
                  data={props.footers.data}
                  description={props.footers.description}
                  options={props.footers.options}
                  primaryColor={props.config.primaryColor}
                  logoHref={props.footers.logoHref ? props.footers.logoHref : props.config.logoHref}
                  windowDimension={windowDimension}
                  {...props.footers}
                />
              )}
            </div>
          </div>
        </>
      );
      break;

    default:
      break;
  }
  return eleHTML;
}

export function MainErrorComposition(props) {
  const [layout, setLayout] = useState(null);

  useEffect(() => {
    const oldTenant = localStorage.getItem(LocalStorageKey.TENANT);
    let hostNameUrl = "";

    if (oldTenant) {
      const oldUrl = localStorage.getItem(oldTenant);
      hostNameUrl = oldUrl ? oldUrl : window.location.host;
    } else {
      hostNameUrl = window.location.host;
    }
    getLayout(hostNameUrl)
      .then((resLayout) => {
        if (resLayout) {
          const layoutMain = resLayout.layout;
          setLayout(layoutMain);
          setRootFunctions(layoutMain.config);
        }
      })
      .catch((error) => setLayout(null));
    return () => {
      setLayout(null);
    };
  }, []);

  return layout ? (
    <>
      <Head>
        <title>{props.errorNum} Error Page</title>
        <meta name="description" content="Build your web" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={layout.config.favicon} />
      </Head>
      <MainComposition display={"main-composition1"} {...layout} mainContent={props.mainContentJSX} />
    </>
  ) : (
    props.mainContentJSX
  );
}

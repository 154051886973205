import { Group, Image, Stack, Text, Tooltip } from "@mantine/core";
import { IoLocationOutline } from "react-icons/io5";
import { Clock, CurrencyDollar } from "tabler-icons-react";
import { Tag } from "../../helpers/tag/tag";
import JobCard2Styles from "./jobCard2.module.scss";
import useTrans from "@/hooks/useTrans";

function JobCard2({ title, image, salary, location, tags, expirationDate, onBtnClick, description, variables }) {
  const trans = useTrans();
  const isHotJob = Array.isArray(tags) && tags.includes("Hot Job");

  return (
    <div className={JobCard2Styles.jobCard}>
      <Image className={JobCard2Styles.image} src={image} alt="test" height={250}></Image>

      <div className={JobCard2Styles.info}>
        <div className={JobCard2Styles.infoMain} style={{ position: "relative" }}>
          <div className={JobCard2Styles.top}>
            <Group className={JobCard2Styles.defaultText} style={{ color: "var(--primary-4)!important" }}>
              <Clock size={20} />
              <Text>{expirationDate}</Text>
            </Group>

            {isHotJob && <Tag type="hotJob" />}
          </div>
          <Tooltip withArrow label={title}>
            <Text className={JobCard2Styles.title} lineClamp={1}>
              {title}
            </Text>
          </Tooltip>

          <Stack spacing={12}>
            <Group className={JobCard2Styles.defaultText}>
              <CurrencyDollar size={20} />
              <Text>{salary ?? trans.salary}</Text>
            </Group>
            <Group className={JobCard2Styles.defaultText}>
              <IoLocationOutline size={20} />
              <Text>{location}</Text>
            </Group>
          </Stack>
        </div>

        <div onClick={onBtnClick} className={JobCard2Styles.btn}>
          {trans.apply}
        </div>
      </div>
    </div>
  );
}

export default JobCard2;

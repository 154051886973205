import IconComponent from "@/components/helpers/iconComponent/iconComponent";
import { TextInput } from "@mantine/core";
import { useEffect, useRef } from "react";
import styles from "./inputText.module.scss";
export interface InputTextProps {
  name: string;
  label: string;
  placeholder: string;
  value: string;
  icon?: { name: string; stroke: string; color: string };
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  onChange: (value: string) => void;
}

export function InputText(props: InputTextProps) {
  const ref = useRef<HTMLInputElement>(null);
  const icon = props.icon ? (
    <IconComponent
      name={props.icon.name}
      stroke={props.icon.stroke}
      size={16}
      color={props.icon.color ?? "currentColor"}
    />
  ) : undefined;
  useEffect(() => {
    if (ref.current) {
      ref.current.value = props.value || "";
    }
  }, [props.value]);
  return (
    <>
      <TextInput
        ref={ref}
        label={props.label}
        placeholder={props.placeholder}
        icon={icon}
        defaultValue={props.value}
        onChange={(e) => {
          props.onChange(e.currentTarget.value);
        }}
        size={props.size ?? "md"}
        radius={100}
        classNames={{
          input: styles.input,
          icon: styles.icon,
        }}
      />
    </>
  );
}

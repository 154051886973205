/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Button, Center, Collapse, Divider, Group, Image, Stack, Text,} from "@mantine/core";
import variables from "@/styles/variables.module.scss";
import {useForm} from "@mantine/form";
import FormDynamicInput from "@/components/formDynamic/components/nonPrimitiveType/formDynamicInput";
import {getLocationData, postFormQuickApply, postFormTalentAdvancedInfo,} from "@/services/formDynamicApis";
import {openContextModal} from "@mantine/modals";
import {useRouter} from "next/router";
import convert from "color-convert";
import {showFailureNotification} from "@/services/constants";
import {getCustomValidation} from "@/components/formDynamic/services/services";
import useTrans from "@/hooks/useTrans";

export default function FormDynamic1(props) {
    const trans = useTrans();
    const router = useRouter();
    const [formTemplate, setFormTemplate] = useState(null);
    const [basicTalentInfo, setBasicTalentInfo] = useState(null);
    const [advancedTalentInfo, setAdvancedTalentInfo] = useState(null);
    const [hasCustomError, setHasCustomError] = useState(false);
    const [validateForm, setValidateForm] = useState({});
    const [formDynamicFieldArray, setFormDynamicFieldArray] = useState([]);
    const [finalFormConfig, setFinalFormConfig] = useState(null);

    const convertColor = convert.hex.rgb(props.primaryColor);
    const backgroundColor = `rgba(${convertColor[0]}, ${convertColor[1]}, ${convertColor[2]},0.1)`;


    const formDynamic = useForm({
        initialValues: "",
        validate: validateForm,
    });

    useEffect(() => {
        if (props.getFormTemplateApi) {
            props.getFormTemplateApi(props.hostNameUrl)
                .then((resTemplate) => {
                    if (resTemplate.isError) {
                        showFailureNotification(resTemplate.message, resTemplate.errorCode);
                    } else {
                        setFormTemplate(resTemplate.data);
                    }
                });
        }

        if (props.getDefaultValueApi) {
            props.getDefaultValueApi(props.hostNameUrl, router.query.child)
                .then((resTemplate) => {
                    if (resTemplate.isError) {
                        showFailureNotification(resTemplate.message, resTemplate.errorCode);
                    } else {
                        const defaultValueInfo = resTemplate.data;
                        const basicTalentInfoData = defaultValueInfo.application ? defaultValueInfo.application : defaultValueInfo;
                        const advancedTalentInfoData = defaultValueInfo.advancedInfo ? defaultValueInfo.advancedInfo : null;
                        setBasicTalentInfo(basicTalentInfoData);
                        setAdvancedTalentInfo(advancedTalentInfoData)
                    }
                });
        }
        return () => {
            setFormTemplate(null);
            setBasicTalentInfo(null);
            setAdvancedTalentInfo(null);
        }
    }, []);

    useEffect(() => {
        if (formTemplate && (!props.useDefaultValue || basicTalentInfo)) {
            const formConfig = {
                initialValues: {},
                validate: {},
            };
            let initialValueConstant = "";
            let customValidation = undefined;

            if (basicTalentInfo && router.query.id === "collect-form") {
                formConfig.initialValues['recruitmentPlan'] = basicTalentInfo.recruitmentPlan
            }

            if (props.recruitmentPlanId) {
                formConfig.initialValues['recruitmentPlan'] = props.recruitmentPlanId;
            }


            const handleInitialValueAndCustomValidation = (fieldTemplate) => {
                customValidation = getCustomValidation(fieldTemplate.validators, fieldTemplate.label, fieldTemplate.type);
                if (fieldTemplate.type === "file-input") {
                    initialValueConstant = null;
                }
            }

            formTemplate.fields.forEach((fieldTemplate) => {
                if (fieldTemplate.type === "group" && !fieldTemplate.typeGroup) {
                    const fieldTemplateName = fieldTemplate.name;
                    const initialValueInGroupObject = {};
                    formConfig.validate[fieldTemplateName] = {};

                    fieldTemplate.fields.forEach((fieldTemplateInGroup) => {
                        handleInitialValueAndCustomValidation(fieldTemplateInGroup);
                        initialValueInGroupObject[fieldTemplateInGroup.name] = initialValueConstant;
                        formConfig.validate[fieldTemplateName][fieldTemplateInGroup.name] = customValidation;
                        initialValueConstant = ""
                        customValidation = undefined;
                    });
                    formConfig.initialValues[fieldTemplateName] = [initialValueInGroupObject];
                } else {
                    const handleFieldTemplateSingle = (fieldTemplateSingle) => {
                        const fieldTemplateName = fieldTemplateSingle.name;
                        handleInitialValueAndCustomValidation(fieldTemplateSingle);
                        if (fieldTemplateName.includes(".")) {
                            const fieldTemplateNameSplit = fieldTemplateName.split(".");
                            if (!formConfig.initialValues[fieldTemplateNameSplit[0]]) {
                                formConfig.initialValues[fieldTemplateNameSplit[0]] = {};
                                formConfig.validate[fieldTemplateNameSplit[0]] = {};
                            }
                            formConfig.initialValues[fieldTemplateNameSplit[0]][fieldTemplateNameSplit[1]] = initialValueConstant;
                            formConfig.validate[fieldTemplateNameSplit[0]][fieldTemplateNameSplit[1]] = customValidation;
                        } else {
                            formConfig.initialValues[fieldTemplateName] = initialValueConstant;
                            formConfig.validate[fieldTemplateName] = customValidation;
                        }
                        initialValueConstant = "";
                        customValidation = undefined;
                    }
                    if (fieldTemplate.type === "group" && fieldTemplate.typeGroup === "partial") {
                        fieldTemplate.fields.forEach(oneFieldTemplate => handleFieldTemplateSingle(oneFieldTemplate));
                    } else {
                        handleFieldTemplateSingle(fieldTemplate);
                    }
                }
            });

            if (props.useDefaultValue) {
                const initialValueFromApi = advancedTalentInfo ? advancedTalentInfo : {};
                if (basicTalentInfo && basicTalentInfo.talent && basicTalentInfo.talent.basics) {
                    initialValueFromApi.basics = {...basicTalentInfo.talent.basics}
                }
                Object.keys(formConfig.initialValues).forEach(key => {
                    if (initialValueFromApi[key] !== undefined)
                        formConfig.initialValues[key] = initialValueFromApi[key];
                });
            }

            formDynamic.setValues(formConfig.initialValues);
            setValidateForm(formConfig.validate);
            setFormDynamicFieldArray(formTemplate.fields);
            setFinalFormConfig(formConfig);
        }
    }, [formTemplate, basicTalentInfo, advancedTalentInfo]);


    const currentWidth = props.windowDimension.width;
    let padding = "44px 64px";

    if (currentWidth <= 1000) {
        padding = "15px 30px";
    }

    if (currentWidth <= 800) {
        padding = "5px 10px";
    }
    const fontDivider = currentWidth >= 800 ? 1 : 1.5;
    let numInputPerRow = 3;
    let inputRowSpacing = 24;
    if (currentWidth <= 1000) {
        numInputPerRow = 2;
        inputRowSpacing = 22;
    }
    if (currentWidth <= 800) {
        numInputPerRow = 1;
        inputRowSpacing = 20;
    }

    let oneInputSectionJSX = [];
    let sectionNum = 0;

    let inputSectionJSXs = [];
    const disabledInput = basicTalentInfo && basicTalentInfo.requireUpdateInfo !== undefined && !(basicTalentInfo.requireUpdateInfo);
    if (finalFormConfig) {
        // const formDynamicFieldArraySorted = formDynamicFieldArray.sort((x, y) => {
        //     if (x.priority !== undefined && y.priority !== undefined) {
        //         return x.priority - y.priority;
        //     }
        //
        //     if (x.priority === undefined && y.priority !== undefined) {
        //         return 1;
        //     }
        //     if (
        //         (x.priority !== undefined && y.priority === undefined) ||
        //         (x.priority === undefined && y.priority === undefined)
        //     ) {
        //         return -1;
        //     }
        // });

        const formDynamicFieldPriorityFields = formDynamicFieldArray.filter((e) => e.priority !== undefined);
        const formDynamicFieldNonPriorityFields = formDynamicFieldArray.filter((e) => e.priority === undefined);
        const formDynamicFieldArraySorted = [...formDynamicFieldPriorityFields, ...formDynamicFieldNonPriorityFields]


        inputSectionJSXs = formDynamicFieldArraySorted.map(
            (fieldTemplate, index) => {
                if (fieldTemplate.type === "group") {
                    return (
                        <GroupInputSection
                            key={`section-${sectionNum++}`}
                            hostNameUrl={props.hostNameUrl}
                            formDynamic={formDynamic}
                            sectionNum={sectionNum}
                            finalFormConfig={finalFormConfig}
                            numInputPerRow={numInputPerRow}
                            groupFieldTemplate={fieldTemplate}
                            currentWidth={currentWidth}
                            backgroundColor={backgroundColor}
                            primaryColor={props.primaryColor}
                            inputRowSpacing={inputRowSpacing}
                            disabledInput={disabledInput}
                            setHasCustomError={setHasCustomError}
                        />
                    );
                } else {
                    const fieldTemplateName = fieldTemplate.name;
                    let finalFormConfigInitialValue =
                        finalFormConfig.initialValues[fieldTemplateName];
                    if (fieldTemplateName.includes(".")) {
                        const fieldTemplateNameSplit = fieldTemplateName.split(".");
                        finalFormConfigInitialValue = finalFormConfig.initialValues[fieldTemplateNameSplit[0]][fieldTemplateNameSplit[1]];
                    }
                    oneInputSectionJSX.push(
                        <div key={`section-${sectionNum}-input-${oneInputSectionJSX.length}`} style={{
                            width: (index === 0 && props.useDefaultValue) ? 168 : `${100 / numInputPerRow - 2}%`,
                        }}>
                            <FormDynamicInput
                                options={{
                                    inputTemplate: fieldTemplate,
                                    initialValue: finalFormConfigInitialValue,
                                    formInputProps: {
                                        ...formDynamic.getInputProps(fieldTemplateName),
                                    },
                                    requiredInput:
                                        fieldTemplate.validators
                                        && fieldTemplate.validators.filter(
                                            (validator) => validator.type === "required"
                                        ).length > 0,
                                    primaryColor: props.primaryColor,
                                    disabledInput: disabledInput,
                                    setHasCustomError: setHasCustomError
                                }}
                            />
                        </div>
                    );

                    if (oneInputSectionJSX.length === numInputPerRow
                        || index === 0
                        || index === formDynamicFieldArraySorted.length - 1
                        || (index < formDynamicFieldArraySorted.length - 1 && formDynamicFieldArraySorted[index + 1].type.includes("group"))) {
                        if (oneInputSectionJSX.length < numInputPerRow && index !== 0) {
                            while (oneInputSectionJSX.length < numInputPerRow && index !== 0) {
                                oneInputSectionJSX.push(
                                    <div
                                        key={`section-${sectionNum}-fakeInput-${oneInputSectionJSX.length}`}
                                        style={{width: `${100 / numInputPerRow - 2}%`}}
                                    ></div>
                                );
                            }
                        }

                        const returnOneInputSectionJSX = oneInputSectionJSX.slice(0);
                        oneInputSectionJSX = [];
                        return (
                            <Group
                                key={`section-${sectionNum++}`}
                                spacing={24}
                                position={(index === 0 && props.useDefaultValue) ? "center" : "apart"}
                            >
                                {returnOneInputSectionJSX}
                            </Group>
                        );
                    }
                }
            }
        );
    }
    const handleClick = (e) => {
        e.preventDefault();
        const handleFormSuccess = (talentApplyResultCode = null, successMessage = "") => {
            let handleClose = () => {
            }

            if (router.query.id === "collect-form") {
                handleClose = () => {
                    window.location.href = "/home"
                }
            } else if (props.closeModal) {
                handleClose = props.closeModal;
            }

            openContextModal({
                modal: "success",
                centered: true,
                innerProps: {
                    text: trans.applyNow.success,
                    content: successMessage,
                    color: variables.primaryColor,
                    code: talentApplyResultCode
                },
                onClose: handleClose,
            });


        }

        const formValues = formDynamic.values;
        if (!formDynamic.validate().hasErrors && !hasCustomError) {
            if (props.useDefaultValue && basicTalentInfo) {
                const sendData = async () => await postFormTalentAdvancedInfo(formValues, props.hostNameUrl, basicTalentInfo._id);
                sendData().then((responsePostTalent) => {
                    if (responsePostTalent.isError) {
                        showFailureNotification(responsePostTalent.message, responsePostTalent.errorCode);
                    } else {
                        handleFormSuccess(null, responsePostTalent.message);
                    }
                });
            } else if (!props.useDefaultValue) {
                const postForm = async () => await postFormQuickApply(formValues, props.hostNameUrl);
                postForm().then(resultPostFormQuickApply => {
                    if (resultPostFormQuickApply.isError) {
                        showFailureNotification(resultPostFormQuickApply.message, resultPostFormQuickApply.errorCode);
                    } else {
                        handleFormSuccess(resultPostFormQuickApply.data, resultPostFormQuickApply.message);
                    }
                })
            } else {
                showFailureNotification("Đường dẫn đơn này không hợp lệ vui lòng kiểm tra lại", "DF-1");
            }

        } else {
            showFailureNotification("Thông tin điền vào đơn chưa hợp lệ. Vui lòng kiểm tra lại", "DF-2");
        }
    };
    const mainContent = (
        <Stack spacing={15}>
            {props.useDefaultValue
                ? (
                    <Stack
                        spacing={24}
                        style={{
                            backgroundColor: backgroundColor,
                            padding: padding,
                        }}
                    >
                        <Text
                            ta={"center"}
                            fw={600}
                            fz={32 / fontDivider}
                            c={variables.primaryColor}
                        >
                            {formTemplate ? formTemplate.title : "Đang tải..."}
                        </Text>
                        <Text ta={"center"} fz={18 / fontDivider} c={"#232323"}>
                            Cảm ơn bạn đã ứng tuyển tại công ty. Bạn vui lòng hoàn thành đơn bổ
                            sung thông tin dưới đây trước thời hạn quy định.
                        </Text>
                    </Stack>
                )
                : <></>}
            <Stack spacing={inputRowSpacing} style={{padding: padding}}>
                {inputSectionJSXs}
            </Stack>
            {formTemplate && !disabledInput ? (
                <Group position={"center"}>
                    <Button
                        type={"submit"}
                        bg={variables.primaryColor}
                        style={{width: 200, borderRadius: 100}}
                        onClick={e => handleClick(e)}
                    >
                        {trans.apply}
                    </Button>
                </Group>
            ) : (
                <></>
            )}
        </Stack>
    );

    const badContent = (
        <Center>
            <Stack justify={"center"} style={{height: "100vh"}} spacing={20}>
                <Text ta={"center"}>Không Có Ứng Viên Với Mã {props.talentCode}</Text>
                <Image
                    src={"/images/NoDataImage.svg"}
                    width={500}
                    alt={"no-data-image"}
                />
            </Stack>
        </Center>
    );

    return (basicTalentInfo || !props.useDefaultValue) ? mainContent : badContent;
}

function OneGroupInput(props) {
    const [duoArrayData, setDuoArrayData] = useState([[], []]);
    const [singleArrayData, setSingleArrayData] = useState([]);

    const getInputPropsName = (fieldTemplateName) => {
        return props.isGroupNotPartial
            ? `${props.groupFieldTemplateName}.${props.indexGroupField}.${fieldTemplateName}`
            : fieldTemplateName
    }
    const handleDuoArrayData = (inputTemplate, value, secondInitialValue = null) => {
        if (inputTemplate.select) {
            let indexToUpdate = -1;
            let masterdataToUpdate = "";
            let secondMasterdataToUpdate = "";
            let inputNameToUpdate = "";
            let secondInputNameToUpdate = "";
            switch (inputTemplate.select.masterdata) {
                case "provinces":
                    indexToUpdate = 0;
                    masterdataToUpdate = "districts";
                    secondMasterdataToUpdate = "wards";

                    if (inputTemplate.name.includes('temporary')) {
                        inputNameToUpdate = 'temporaryDistrict';
                        secondInputNameToUpdate = 'temporaryWard';
                    }
                    if (inputTemplate.name.includes('residence')) {
                        inputNameToUpdate = 'residenceDistrict';
                        secondInputNameToUpdate = 'residenceWard';
                    }
                    break;
                case "districts":
                    indexToUpdate = 1;
                    if (inputTemplate.name.includes('temporary')) {
                        inputNameToUpdate = 'temporaryWard';
                    }
                    if (inputTemplate.name.includes('residence')) {
                        inputNameToUpdate = 'residenceWard';
                    }
                    masterdataToUpdate = "wards";
                    break;
                default:
                    break;
            }
            if (indexToUpdate > -1) {
                const currentDuoArrayData = [...duoArrayData];
                if (value && value !== "") {
                    switch (inputTemplate.select.masterdata) {
                        case "provinces":
                        case "districts":
                            if (!secondInitialValue) {
                                getLocationData(masterdataToUpdate, value, props.hostNameUrl).then(res => {
                                    if (res.isError) {
                                        showFailureNotification(res.message, res.errorCode)
                                    } else {
                                        currentDuoArrayData[indexToUpdate] = res.data;
                                        props.formDynamic.setFieldValue(getInputPropsName(inputNameToUpdate), "");
                                        if (indexToUpdate === 0) {
                                            currentDuoArrayData[1] = [];
                                            props.formDynamic.setFieldValue(getInputPropsName(secondInputNameToUpdate), "");
                                        }
                                        setDuoArrayData(currentDuoArrayData);
                                    }
                                });
                            } else if (inputTemplate.select.masterdata === "provinces") {
                                getLocationData(masterdataToUpdate, value, props.hostNameUrl).then(firstLocationRes => {
                                    if (!firstLocationRes.isError) {
                                        currentDuoArrayData[0] = firstLocationRes.data;
                                        getLocationData(secondMasterdataToUpdate, secondInitialValue, props.hostNameUrl).then(secondLocationRes => {
                                            if (!secondLocationRes.isError) {
                                                currentDuoArrayData[1] = secondLocationRes.data;
                                                setDuoArrayData(currentDuoArrayData);
                                            }
                                        })
                                    }
                                })
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    }
    const handleSingleArrayData = (inputTemplate, value) => {
        if (inputTemplate.select) {
            if (value && value !== "") {
                let masterdataToUpdate = null;
                switch (inputTemplate.select.masterdata) {
                    case "bank":
                        masterdataToUpdate = "bank-branch"
                        break;
                    default:
                        break;
                }
                if (masterdataToUpdate) {
                    getLocationData(masterdataToUpdate, value, props.hostNameUrl).then(res => {
                        if (res.isError) {
                            showFailureNotification(res.message, res.errorCode)
                        } else {
                            setSingleArrayData(res.data);
                        }
                    })
                }

            }
        }
    }

    const handleSelectLogic = (fieldTemplate) => {
        const getInitialValueFromFieldName = (fieldName) => {
            return props.isGroupNotPartial
                ? (props.finalFormConfig.initialValues[props.groupFieldTemplateName][props.indexGroupField]
                    ? props.finalFormConfig.initialValues[props.groupFieldTemplateName][props.indexGroupField][fieldName]
                    : "")
                : props.finalFormConfig.initialValues[fieldName]
        }

        const initialValue = getInitialValueFromFieldName(fieldTemplate.name);

        let secondInitialValue = null
        if (fieldTemplate.select) {
            if (fieldTemplate.select.masterdata === "provinces") {
                if (fieldTemplate.name.includes('residence')) {
                    secondInitialValue = getInitialValueFromFieldName('residenceDistrict');
                }
                if (fieldTemplate.name.includes('temporary')) {
                    secondInitialValue = getInitialValueFromFieldName('temporaryDistrict');
                }
            }

            if (fieldTemplate.select.masterdata === "districts") {
                if (fieldTemplate.name.includes('residence')) {
                    secondInitialValue = getInitialValueFromFieldName('residenceWard');
                }
                if (fieldTemplate.name.includes('temporary')) {
                    secondInitialValue = getInitialValueFromFieldName('temporaryWard');
                }
            }
        }


        let handleDataInSelect = undefined;
        let dataInSelect = null;
        if (fieldTemplate.select) {
            switch (fieldTemplate.select.masterdata) {
                case "provinces":
                    handleDataInSelect = handleDuoArrayData;
                    break;
                case "districts":
                    dataInSelect = duoArrayData[0];
                    handleDataInSelect = handleDuoArrayData;
                    break;
                case "wards":
                    dataInSelect = duoArrayData[1];
                    break;
                case "bank":
                    handleDataInSelect = handleSingleArrayData;
                    break;
                case "bank-branch":
                    dataInSelect = singleArrayData;
                    break;
                default:
                    break;
            }
        }
        return {initialValue, dataInSelect, handleDataInSelect, secondInitialValue}
    }


    useEffect(() => {
        props.oneGroupField.forEach(fieldTemplate => {
            const {
                initialValue,
                dataInSelect,
                handleDataInSelect,
                secondInitialValue
            } = handleSelectLogic(fieldTemplate);
            if (handleDataInSelect && initialValue && initialValue !== "") {
                handleDataInSelect(fieldTemplate, initialValue, secondInitialValue)

            }

        })
    }, []);


    let oneInputRowJSX = [];
    let row = 0;
    const returnJSX = props.oneGroupField.map((fieldTemplate, indexFieldTemplate) => {

        const inputPropsName = getInputPropsName(fieldTemplate.name)

        const {initialValue, dataInSelect, handleDataInSelect} = handleSelectLogic(fieldTemplate);

        oneInputRowJSX.push(
            <div
                key={`section-${props.sectionNum}-group-${props.indexGroupField}-row-${row}-input-${oneInputRowJSX.length}`}
                style={{width: `${100 / props.numInputPerRow - 2}%`}}
            >
                <FormDynamicInput
                    options={{
                        inputTemplate: fieldTemplate,
                        initialValue: initialValue,
                        formInputProps: {
                            ...props.formDynamic.getInputProps(inputPropsName),
                        },
                        requiredInput:
                            fieldTemplate.validators &&
                            fieldTemplate.validators.filter((validator) => validator.type === "required").length > 0,
                        handleDataInSelect: handleDataInSelect,
                        dataInSelect: dataInSelect,
                        primaryColor: props.primaryColor,
                        formDynamic: props.formDynamic,
                        disabledInput: props.disabledInput,
                        setHasCustomError: props.setHasCustomError
                    }}
                />
            </div>
        );
        if (oneInputRowJSX.length === props.numInputPerRow || indexFieldTemplate === props.oneGroupField.length - 1) {
            const returnOneInputRowJSX = oneInputRowJSX.slice(0);
            oneInputRowJSX = [];
            return (
                <Group
                    spacing={24}
                    key={`section-${props.sectionNum}-group-${props.indexGroupField}-row-${row++}`}>
                    {returnOneInputRowJSX}
                </Group>
            );
        }
    });

    return (
        <Stack
            spacing={10}
            key={`section-${props.sectionNum}-group-${props.indexGroupField}`}
        >
            {props.isGroupNotPartial
                ? <Text color={"dimmed"} ta={"right"} fz={12}>
                    {props.groupFieldTemplateLabel} - {props.indexGroupField + 1}
                </Text>
                : <></>
            }

            <Stack spacing={props.inputRowSpacing}>{returnJSX}</Stack>
            <Group style={{justifyContent: "flex-end"}}>
                {props.isGroupNotPartial && !props.disabledInput
                    ? <Image
                        onClick={() => props.handleDelete(props.indexGroupField)}
                        alt={""}
                        src={`${process.env.BASE_PATH ? `${process.env.BASE_PATH}/images/trash-bin-svgrepo-com.svg` : "/images/trash-bin-svgrepo-com.svg"}`}
                        width={30}
                    />
                    : <></>
                }
            </Group>
            {props.groupFieldArrayLength > 1 &&
            props.indexGroupField < props.groupFieldArrayLength - 1 ? (
                <Divider size={1} color={variables.primaryColor}/>
            ) : (
                <></>
            )}
        </Stack>
    );
}

function GroupInputSection(props) {
    const [groupFieldTemplate, setGroupFieldTemplate] = useState(null);
    const [groupFieldArray, setGroupFieldArray] = useState([]);
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        if (props.groupFieldTemplate) {
            const initialValueOfGroupFieldTemplateName = props.finalFormConfig.initialValues[props.groupFieldTemplate.name]
            const initialGroupFieldArrayNum =
                initialValueOfGroupFieldTemplateName && Array.isArray(initialValueOfGroupFieldTemplateName)
                    ? initialValueOfGroupFieldTemplateName.length
                    : 1;

            setGroupFieldTemplate(props.groupFieldTemplate);
            setGroupFieldArray(Array(initialGroupFieldArrayNum).fill(props.groupFieldTemplate.fields))
            setShowContent(initialGroupFieldArrayNum > 0)
        }
    }, [props.groupFieldTemplate, props.numInputPerRow]);


    if (groupFieldTemplate) {

        const isGroupNotPartial = groupFieldTemplate.typeGroup !== "partial"

        const handleAdd = () => {

            setGroupFieldArray([...groupFieldArray, groupFieldTemplate.fields]);
            const initialValueObject = {};
            groupFieldTemplate.fields.forEach(fieldTemplate => {
                initialValueObject[fieldTemplate.name] = ""
            })
            props.formDynamic.insertListItem(groupFieldTemplate.name, initialValueObject);

            if (!showContent)
                setShowContent(true);
        };

        const handleDelete = (index) => {
            const groupFieldArrayCopy = [
                ...groupFieldArray.slice(0, index),
                ...groupFieldArray.slice(index + 1),
            ];
            setGroupFieldArray(groupFieldArrayCopy);
            props.formDynamic.removeListItem(groupFieldTemplate.name, index);
            if (groupFieldArrayCopy.length === 0) {
                setShowContent(false);
            }
        };

        const addIcon = (
            <Image
                onClick={handleAdd}
                alt={""}
                src={`${process.env.BASE_PATH ? `${process.env.BASE_PATH}/images/add-svgrepo-com.svg` : "/images/add-svgrepo-com.svg"}`}
                width={props.currentWidth > 800 ? 24 : 20}
            />
        );

        const contentShown = groupFieldArray.map((oneGroupField, indexGroupField) =>
            <OneGroupInput key={`one-group-input-${indexGroupField}`}
                           oneGroupField={oneGroupField}
                           indexGroupField={indexGroupField}
                           isGroupNotPartial={isGroupNotPartial}
                           handleDelete={handleDelete}
                           groupFieldTemplateName={groupFieldTemplate.name}
                           groupFieldTemplateLabel={groupFieldTemplate.label}
                           groupFieldArrayLength={groupFieldArray.length}
                           setHasCustomError={props.setHasCustomError}
                           {...props}/>
        );

        return (
            <Stack
                p={24}
                spacing={20}
                style={{
                    marginTop: 20,
                    borderRadius: 24,
                    backgroundColor: isGroupNotPartial ? props.backgroundColor : "transparent",
                    border: isGroupNotPartial ? "" : `1px solid ${variables.primaryColor}`,
                    position: "relative",
                }}
            >
                <Group position={"apart"}>
                    <Group spacing={10} onClick={() => {
                        if (isGroupNotPartial && groupFieldArray.length > 0) {
                            setShowContent((value) => !value)
                        }
                    }}>
                        <Text fw={700} fz={props.currentWidth > 800 ? 22 : 18}
                              style={isGroupNotPartial
                                  ? {}
                                  : {
                                      position: "absolute",
                                      top: -20,
                                      zIndex: 3,
                                      backgroundColor: "white",
                                      color: variables.primaryColor,
                                      padding: "0px 20px"
                                  }}>
                            {groupFieldTemplate.label}
                        </Text>
                        {
                            isGroupNotPartial ? <Image
                                alt=""
                                src={`${process.env.BASE_PATH ? `${process.env.BASE_PATH}/images/caret-down.svg` : "/images/caret-down.svg"}`}
                                width={props.currentWidth > 800 ? 24 : 20}
                                style={{
                                    transition: "0.5s ease-in-out",
                                    rotate: showContent ? null : "-180deg",
                                }}
                            /> : <></>
                        }

                    </Group>
                    {isGroupNotPartial && !props.disabledInput ? addIcon : <></>}
                </Group>
                <Collapse in={showContent} transitionDuration={300}>
                    {contentShown}
                </Collapse>
            </Stack>
        );
    } else return <></>
}

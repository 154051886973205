import { Group, Stack, Text, Tooltip } from '@mantine/core';
import parse from "html-react-parser";
import { IoLocationOutline } from 'react-icons/io5';
import { Clock, CurrencyDollar, Tag } from 'tabler-icons-react';
import jobCardStyles from './jobCard.module.scss';
export function JobCard({ title, salary, location, description, expirationDate, tags, business_line }) {

    return <>
        <div className={jobCardStyles.jobCard}>
            <Stack className={jobCardStyles.jobCardMain} style={{ position: "relative" }}>

                <Tooltip position='top' withArrow label={title}  >
                    <Text lineClamp={2} className={jobCardStyles.title} >{title}</Text>
                </Tooltip>


                <Stack spacing={16} justify='center'>

                    <Group className={jobCardStyles.group} style={{ position: "relative", height: 'fit-content' }}>
                        <Group spacing={4} align='center' style={{ maxWidth: "100%", position: "relative" }} noWrap>
                            <CurrencyDollar size={16} />
                            <Tooltip position='top' withArrow label={salary}>
                                <Text style={{ width: "calc(100% - 20px)" }} lineClamp={1}>{salary}</Text>

                            </Tooltip>

                        </Group>

                        <Group spacing={4} align='center' noWrap style={{ maxWidth: "100%", position: "relative" }}>
                            <IoLocationOutline size={16} />
                            <Tooltip position='top' withArrow label={location}>
                                <Text style={{ width: "calc(100% - 20px)" }} lineClamp={1}>{location}</Text>
                            </Tooltip>
                        </Group>

                        <Group spacing={4} align='center' noWrap style={{ width: "40%", position: "relative" }}>
                            <Clock size={16} />
                            <Tooltip position='top' withArrow label={expirationDate}>
                                <Text style={{ width: "calc(100% - 20px)" }} lineClamp={1}>{expirationDate}</Text>
                            </Tooltip>
                        </Group>



                        {
                            business_line && <Group spacing={4} align='center' position='left' style={{ maxWidth: '100%' }}>
                                <Tag size={16} />
                                <Tooltip position='top' withArrow label={business_line}>
                                    <Text>{business_line}</Text>
                                </Tooltip>
                            </Group>
                        }

                    </Group>

                    {/* <Group className={jobCardStyles.group} style={{ position: "relative", height: 'fit-content' }}> */}



                    {/* <div style={{ overflow: 'hidden', display: 'flex', gap: 16, maxWidth: '50%' }}>
                        {tags?.slice(0, 2).map((eTag, index) => {
                            return <Group spacing={4} key={index} align='center' position='left' noWrap>
                                <Tag size={16} />
                                <Tooltip position='top' withArrow label={eTag}>
                                    <Text style={{ width: "calc(100% - 20px)", position: 'relative' }} lineClamp={1}>{eTag}</Text>
                                </Tooltip>
                            </Group>
                        })}
                    </div> */}
                    {/* </Group> */}
                </Stack>
                {Array.isArray(description) && description.length > 0 && <Text lineClamp={business_line ? 2 : 3} className={jobCardStyles.description}>{parse(description[0].content || "")}</Text>}
            </Stack>
        </div >
    </>
}

import SearchHub from "@/components/search/searchHub";
import SearchHub2 from "@/components/search/searchHub2/searchHub2";
import useTrans from "@/hooks/useTrans";
import { getMasterdata } from "@/services/services";
import { Box, Button, Center, Group, NativeSelect, Stack, Text, TextInput } from "@mantine/core";
import convert from "color-convert";
import { useEffect, useRef, useState } from "react";
import { Briefcase, CurrencyDollar, Location } from "tabler-icons-react";
import { Search3 } from '../search/search3/search3';
import styles from "/styles/feature.module.scss";
import variables from "/styles/variables.module.scss";


export default function Search(props) {
    const trans = useTrans();
    let eleHTML = <></>;
    switch (props.display) {
        case "search1":
            eleHTML = <Search1 defaultImage={props.defaultImage} />;
            break;
        case "search2":
            return <Search2 hostNameUrl={props.url}
                options={props.options} windowDimension={props.windowDimension}
                primaryColor={props.primaryColor}
                secondaryColor={props.secondaryColor}
                defaultImage={props.defaultImage} />

        case "search3":
            return <Search3 windowDimension={props.windowDimension} url={props.url} options={props.options} primaryColor={props.primaryColor} variables={variables} />
        case "searchHub":
            return <SearchHub {...props} />

        case "searchHub2":
            return <SearchHub2 {...props} />
        default:
            break;
    }

    return eleHTML;
}

function Search1(props) {
    return (
        <Box
            className={styles.search}
            sx={(theme) => ({
                height: 248,
                background: "#F2F2F2",
                padding: "56px 64px",
                display: "flex",
                justifyContent: "center",
            })}
        >
            <Group
                spacing={44}
                noWrap
                style={{ background: "#fff", borderRadius: 100, width: "100%" }}
                position="center"
                align='center'
                px={44}
            >
                <Stack spacing={16}>
                    <Text className={styles.label}>Jobs</Text>
                    <TextInput
                        rightSection={
                            <Briefcase
                                size={24}
                                stoke={1.5}
                                color={variables.primaryColor}
                            />
                        }
                        className={styles.input}
                        variant="unstyled"
                        placeholder="Fronend Dev, Full stack,..."
                    ></TextInput>
                </Stack>
                <Stack spacing={16}>
                    <Text className={styles.label}>Where</Text>
                    <TextInput
                        className={styles.input}
                        variant="unstyled"
                        placeholder="Where 're you now?"
                        rightSection={
                            <Location
                                size={24}
                                stoke={1.5}
                                color={variables.primaryColor}
                            />
                        }
                    ></TextInput>
                </Stack>
                <Stack spacing={16}>
                    <Text className={styles.label}>Salary range?</Text>
                    <TextInput
                        className={styles.input}
                        variant="unstyled"
                        placeholder="Salary range"
                        rightSection={
                            <CurrencyDollar
                                size={24}
                                stoke={1.5}
                                color={variables.primaryColor}
                            />
                        }
                    ></TextInput>
                </Stack>
                <Button
                    radius={40}
                    sx={{
                        "&:hover": { backgroundColor: variables.hoverColor },
                        width: 336,
                        height: "auto",
                        background: variables.primaryColor,
                        fontWeight: 400,
                        fontSize: 24,
                        padding: "19px 0px",
                        margin: 0,
                    }}
                >
                    Search
                </Button>
            </Group>
        </Box>
    );
}

function Search2(props) {
    const trans = useTrans();
    const [data1, setData1] = useState("");
    const [data2, setData2] = useState("");

    const [dataSelect1, setDataSelect1] = useState([]);
    const [dataSelect2, setDataSelect2] = useState([]);

    const textSearchValue = useRef("");

    useEffect(() => {
        const fetchMasterdata = async () => await getMasterdata(props.hostNameUrl, props.options.masterdata)
        fetchMasterdata().then(resMasterdata => {
            const tempDataSelect1 = resMasterdata.filter(e => {
                const metaDataType = e.metadata.find(metadataSingle => metadataSingle.type === props.options.metadataType1);
                return metaDataType !== undefined;
            });
            const mappedTempDataSelect1 = tempDataSelect1.map(dataSingle => dataSingle.label);
            setDataSelect1([{ value: "", label: trans.all }, ...mappedTempDataSelect1]);

            const tempDataSelect2 = resMasterdata.filter(e => {
                const metaDataType = e.metadata.find(metadataSingle => metadataSingle.type === props.options.metadataType2);
                return metaDataType !== undefined;
            });
            const mappedTempDataSelect2 = tempDataSelect2.map(dataSingle => dataSingle.label);
            setDataSelect2([{ value: "", label: trans.all }, ...mappedTempDataSelect2]);
        })
    }, [])


    const currentWidth = props.windowDimension.width;
    let eleHTML = <></>;

    const textSearch = (
        <TextInput placeholder={"Nhập tên công việc"}
            onChange={(e) => textSearchValue.current = e.target.value} />
    )


    const selectData1 = (
        <NativeSelect
            value={data1}
            onChange={e => setData1(e.target.value)}
            data={dataSelect1}
            rightSection={<Location
                size={24}
                stoke={1.5}
                color={variables.primaryColor}
            />
            }
            placeholder="Chọn khối ngành..."
        />
    );

    const selectData2 = (
        <NativeSelect
            value={data2}
            onChange={e => setData2(e.target.value)}
            data={dataSelect2}
            rightSection={<Briefcase
                size={24}
                stoke={1.5}
                color={variables.primaryColor}
            />
            }
            placeholder="Chọn khối ngành..."
        />
    );
    if (currentWidth >= 1000) {
        const convertColor = convert.hex.rgb(props.primaryColor);
        eleHTML = (
            <Box
                className={styles.search2}
                sx={(theme) => ({
                    padding: `${44}px ${48}px 0px ${44}px`
                })}
            >
                <Group className={styles.searchField}
                    spacing={56}
                    noWrap
                    position="apart"
                    align='center'
                    style={{
                        height: 136,
                        padding: `${32}px ${48}px`,
                        boxShadow: `0px 0px 30px 5px rgba(${convertColor[0]}, ${convertColor[1]}, ${convertColor[2]},0.2)`
                    }}

                >

                    <Stack spacing={10} style={{ width: "25%" }}>
                        <Text fz={20} className={styles.label}>Tên công việc</Text>

                        {textSearch}

                    </Stack>

                    <Stack spacing={10} style={{ width: "25%" }}>
                        <Text fz={20} className={styles.label}>Khu Vực</Text>

                        {selectData1}

                    </Stack>
                    <Stack spacing={10} style={{ width: "25%" }}>
                        <Text fz={20} className={styles.label}>Khối ngành</Text>

                        {selectData2}

                    </Stack>

                    <Stack style={{ width: "15%" }}>
                        <Button
                            radius={40}
                            onClick={() => {
                                props.options.handleSearch(data1, data2, textSearchValue.current);
                            }}
                            sx={{
                                "&:hover": { backgroundColor: variables.hoverColor },
                                width: "100%",
                                height: "auto",
                                background: variables.primaryColor,
                                fontWeight: 500,
                                fontSize: 20,
                                padding: "18px 0px",
                            }}
                        >
                            Tìm kiếm
                        </Button>
                    </Stack>
                </Group>
            </Box>
        );
    } else {
        eleHTML = (
            <Box
                className={styles.search2}
                sx={(theme) => ({
                    padding: `20px 24px 0px 20px`,

                })}>
                <Center style={{ background: 'white', borderRadius: 20, padding: 40 }}>
                    <Stack spacing={40} style={{ width: '100%' }}>
                        <Stack spacing={10} style={{ width: "100%" }}>
                            <Text fz={20} c={variables.primaryColor} className={styles.label}>Tên công việc</Text>
                            {textSearch}
                        </Stack>

                        <Stack spacing={10} style={{ width: "100%" }}>
                            <Text fz={20} c={variables.primaryColor} className={styles.label}>Khu Vực</Text>
                            {selectData1}
                        </Stack>
                        <Stack spacing={10} style={{ width: "100%" }}>
                            <Text fz={20} c={variables.primaryColor} className={styles.label}>Khối ngành</Text>

                            {selectData2}

                        </Stack>

                        <Center>
                            <Button
                                radius={40}
                                onClick={() => props.options.handleSearch(data1, data2)}
                                sx={{
                                    "&:hover": { backgroundColor: variables.hoverColor },
                                    width: "70%",
                                    height: "auto",
                                    background: variables.primaryColor,
                                    fontWeight: 500,
                                    fontSize: 20,
                                    padding: "12px 0px",
                                }}
                            >
                                Tìm kiếm
                            </Button>
                        </Center>
                    </Stack>
                </Center>
            </Box>
        );

    }
    return eleHTML;
}

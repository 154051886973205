import variables from "@/styles/variables.module.scss";
import { Slider, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import styles from "../../styles/dynamic-form.module.scss";

export default function FormDynamicInputSlider(props) {
    let eleHTML = <></>;
    switch (props.inputTemplate.type) {
        case "slider":
            eleHTML = <SliderInput {...props} />
            break

        default:
            break;
    }

    return eleHTML;
}

const SliderInput = (props) => {
    const inputProps = { ...props.formInputProps }
    const inputTemplate = { ...props.inputTemplate }
    const [oldInvisible, setOldInvisible] = useState(false);


    useEffect(() => {
        if (inputTemplate.invisible) {
            setOldInvisible(true);
            inputProps.onChange(undefined);

        } else if (oldInvisible) {
            setOldInvisible(false);
            inputProps.onChange(props.initialValue)
        }
    }, [inputTemplate.invisible]);

    return (
        <Stack spacing={10}>
            <Text fz={variables.dynamicFormLabelSize} color={variables.primaryColor}>{inputTemplate.label}</Text>
            <Slider
                className={styles.formDynamicTextDateSelectInput}
                min={0}
                max={5}
                style={{ width: "100%" }}
                styles={(theme) => ({
                    track: {
                        backgroundColor: variables.primaryColor
                    },
                    bar: {
                        backgroundColor: variables.primaryColor
                    },
                    markFilled: {
                        borderColor: variables.primaryColor,
                    },
                    thumb: {
                        borderColor: variables.primaryColor
                    }
                })}
                defaultValue={0}
                {...inputProps}
                step={1}
                marks={[
                    { value: 0, label: "0" },
                    { value: 1, label: "1" },
                    { value: 2, label: "2" },
                    { value: 3, label: "3" },
                    { value: 4, label: "4" },
                    { value: 5, label: "5" },
                ]}
            />
            {inputProps.error && <Text fz={12} color={"red"} mt={20}>{inputProps.error}</Text>}
        </Stack>
    )

}


import { Anchor, Group, Image, Stack, Text } from "@mantine/core";
import { BrandFacebook, BrandInstagram, BrandTwitter } from "tabler-icons-react";
import variables from "../../../styles/variables.module.scss";

export function Footer1R(props) {
    const currentWidth = props.windowDimension.width;
    const divider = Math.min((currentWidth < 1440) ? 1440 / currentWidth : 1, 3.2);

    const dataFooter = props.data.map((dataSingleton, index) => {
        const dataChildFooter = dataSingleton.childData.map((childTextDataSingleton, indexChild) =>
            <Text fz={16 / divider} key={`text-data-${indexChild}`}>{childTextDataSingleton}</Text>
        );
        return (
            <Stack key={`stack-data-${index}`} spacing={8 / divider}>
                <Text fz={16 / divider} fw={700}>{dataSingleton.childName}</Text>
                {dataChildFooter}
            </Stack>
        );
    });

    return (
        <Group position='apart' align='center'
            style={{ padding: `${20 / divider}px ${32 / divider}px` }}>
            <Anchor href={props.logoHref} target={"_blank"}>
                <Image src={props.logo} alt='logo' width={150 / divider} />
            </Anchor>
            <Group spacing={44 / divider}>
                {dataFooter}
            </Group>

            <Group align='center' spacing={16 / divider}>
                {
                    props.facebook?.length > 0
                        ? <a target={"_blank"} rel="noreferrer"
                            href={props.facebook}><BrandFacebook size={24 / divider} strokeWidth={1}
                                color={variables.primaryColor} /></a>
                        : <></>
                }

                {
                    props.instagram?.length > 0
                        ? <a target={"_blank"} rel="noreferrer"
                            href={props.instagram}><BrandInstagram size={24 / divider} strokeWidth={1}
                                color={variables.primaryColor} /></a>
                        : <></>
                }

                {
                    props.twitter?.length > 0
                        ? <a target={"_blank"} rel="noreferrer"
                            href={props.twitter}><BrandTwitter size={24 / divider} strokeWidth={1}
                                color={variables.primaryColor} /></a>
                        : <></>
                }
                {
                    props.zalo?.length > 0
                        ? <a target={"_blank"} rel="noreferrer"
                            href={props.zalo}><Image size={24 / divider} src={"/images/zalo-icon.svg"} alt={"zalo"} /></a>
                        : <></>
                }

            </Group>
        </Group>
    );
}

import { getDescriptionStyleJSX } from "@/components/formDynamic/components/nonPrimitiveType/formDynamicInput";
import { Anchor, Checkbox, Grid, Group, Modal, Radio, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import dynamic from "next/dynamic";
import { useEffect, useRef, useState } from "react";
import dynamicStyles from "../../styles/dynamic-form.module.scss";

const ModalContent = dynamic(() => import("@/components/modal/modalContent"), {
  ssr: false,
});

function FormDynamicCheckbox(props) {
  const [dataToLoop, setDataToLoop] = useState([]);
  const [oldInvisible, setOldInvisible] = useState(false);
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const inputTemplate = props.inputTemplate;
  const inputProps = { ...props.formInputProps };

  const pdfScrolled = useRef(
    inputTemplate.pdfFileUrl === undefined || (Array.isArray(props.initialValue) && props.initialValue.length > 0)
  );
  const setPdfScrolled = (value) => {
    pdfScrolled.current = value;
  };

  const isCheckBox = inputTemplate.type === "checkbox";
  const maxSpan = inputTemplate.maxSpan ?? 3;
  const canBeDisabled =
  (inputTemplate.validators?.find((e) => e.type === "disabledButton") === undefined &&
    inputTemplate.validators?.find((e) => e.type === "disabledForm") === undefined )||
    !props.requireUpdateInfo ||
    props.isFormSubmitted;

  if (inputProps.value === null || (inputProps.value === "" && isCheckBox)) {
    if (isCheckBox) {
      inputProps.value = [];
    } else {
      inputProps.value = "";
    }
  }

  useEffect(() => {
    if (inputTemplate.select && inputTemplate.select.options && inputTemplate.select.options.length > 0) {
      let dataToBeLooped = inputTemplate.select.options;

      const valueField = inputTemplate.select.valueField ?? "code";
      const labelField = inputTemplate.select.labelField ?? "name";

      dataToBeLooped = dataToBeLooped.map((e) => {
        const returnData = { ...e };
        returnData["code"] = e[valueField];
        returnData["name"] = e[labelField];
        return returnData;
      });

      if (inputTemplate.select.masterdata === "nationality") {
        const vietnamOptionIndex = dataToBeLooped.findIndex((e) => e.code === "001");
        if (vietnamOptionIndex >= 0) {
          dataToBeLooped = [
            dataToBeLooped[vietnamOptionIndex],
            ...dataToBeLooped.slice(0, vietnamOptionIndex),
            ...dataToBeLooped.slice(vietnamOptionIndex + 1),
          ];
        }
      }

      setDataToLoop(dataToBeLooped);
    }
  }, [inputTemplate.select]);

  const descriptionJSX = getDescriptionStyleJSX(inputTemplate);

  const inputOnChange = (value) => {
    if (!pdfScrolled.current) {
      return;
    }

    if (inputTemplate.validators?.find((e) => e.type === "disabledSubmit") && isCheckBox && Array.isArray(value)) {
      props.setDisabledSubmit(value.length === 0 || (inputTemplate.pdfFileUrl && !pdfScrolled.current));
    }
    if (
      inputTemplate.validators?.find((e) => e.type === "disabledForm") &&
      isCheckBox &&
      Array.isArray(value) &&
      props.requireUpdateInfo
    ) {
      
      props.setDisabledInput(value.length === 0);
    }
     if (
      inputTemplate.validators?.find((e) => e.type === "disabledButton") &&
      isCheckBox &&
      Array.isArray(value) &&
      props.requireUpdateInfo
    ) {
      props.setDisabledButton(value.length === 0);
    }

    if (props.handleCheckBoxMasterIndex) {
      const filteredCheckboxValue = dataToLoop.filter((e) => {
        return value.includes(e.code);
      });

      props.handleCheckBoxMasterIndex(filteredCheckboxValue);
    }

    inputProps.onChange(value);
  };

  useEffect(() => {
    if (inputTemplate.invisible) {
      setOldInvisible(true);
      inputProps.onChange(undefined);
    } else if (oldInvisible) {
      setOldInvisible(false);
      inputProps.onChange(props.initialValue);
    }
  }, [inputTemplate.invisible]);

  useEffect(() => {
    if (inputProps.value) {
      inputOnChange(inputProps.value);
    } else if (props.initialValue) {
      inputOnChange(props.initialValue);
    }
  }, [dataToLoop]);

  const modalJSX = inputTemplate.pdfFileUrl ? (
    <Modal
      opened={modalOpened}
      overflow={"inside"}
      onClose={closeModal}
      size={1000}
      title={
        <div
          style={{
            fontWeight: "bold",
            fontSize: 18,
            color: props.primaryColor,
          }}
        >
          {inputTemplate.pdfFileTitle || "Thông báo và đồng ý về việc xử lý dữ liệu"}
        </div>
      }
    >
      <ModalContent
        display={"modal-content2"}
        pdfFileUrl={inputTemplate.pdfFileUrl.link}
        setPdfScrolled={setPdfScrolled}
      ></ModalContent>
    </Modal>
  ) : (
    <></>
  );

  const displayItems = dataToLoop.map((item, index) => {
    const labelJSX = (
      <Group spacing={10} style={{ width: "100%" }} align={"center"}>
        {item.name}
        {item.hasLink && item.linkUrl ? (
          <Anchor href={item.linkUrl} target={"_blank"}>
            {item.linkLabel || "Xem file tại đây"}
          </Anchor>
        ) : (
          <></>
        )}
        {inputTemplate.pdfFileUrl && (
          <div
            style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              openModal();
            }}
          >
            {inputTemplate.pdfFileUrl.label || "Xem file tại đây"}
          </div>
        )}
      </Group>
    );
    const itemJSX = isCheckBox ? (
      <Checkbox
        disabled={(props.disabledInput || inputProps.disabled) && canBeDisabled}
        className={dynamicStyles.checkboxRadioItem}
        value={item.code}
        label={labelJSX}
      />
    ) : (
      <Radio
        disabled={(props.disabledInput || inputProps.disabled) && canBeDisabled}
        className={dynamicStyles.checkboxRadioItem}
        style={{ width: "85%" }}
        value={item.code}
        label={labelJSX}
      />
    );

    return (
      <Grid.Col
        span={inputTemplate.isHorizontal ? item.nCols || 1 : maxSpan}
        key={index}
        style={{ position: "relative" }}
      >
        {inputTemplate.pdfFileUrl && modalJSX}
        {itemJSX}
      </Grid.Col>
    );
  });

  const fullInputProps = {
    ...inputProps,
    onChange: inputOnChange,

    withAsterisk: props.requiredInput,
    label: inputTemplate.labelNoWrap ? (
      <span style={{ textWrap: "nowrap" }}>{inputTemplate.label}</span>
    ) : (
      inputTemplate.label
    ),
    description: inputTemplate.placeholder,
    className: dynamicStyles.checkboxRadioInput,
  };

  if (!inputTemplate || inputTemplate.invisible) {
    return <></>;
  }

  return (
    <>
      <Stack spacing={5}>
        {isCheckBox ? (
          <Checkbox.Group {...fullInputProps}>
            <Grid columns={maxSpan} m={0} p={0} gutter={12}>
              {displayItems}
            </Grid>
          </Checkbox.Group>
        ) : (
          <Radio.Group {...fullInputProps}>
            <Grid columns={maxSpan} m={0} gutter={12}>
              {displayItems}
            </Grid>
          </Radio.Group>
        )}
        {descriptionJSX}
      </Stack>
    </>
  );
}

export default FormDynamicCheckbox;

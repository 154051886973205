import { dynamicFilterData } from "@/components/feature";
import ImagePlaceholder from "@/components/imagePlaceholder/imagePlaceholder";
import { Carousel } from "@mantine/carousel";
import { Box, Card, Group, Image, Stack, Text, Flex } from "@mantine/core";
import Link from "next/link";
import { useEffect, useState, useRef } from "react";
import styles from "../../../styles/feature.module.scss";
import variables from "../../../styles/variables.module.scss";
import { JobCard } from "../../card/jobCard/jobCard";
import JobCard2 from "../../card/jobCard2/jobCard2";
import JobCard3 from "../../card/jobCard3/jobCard3";
import NoData from "../../helpers/noData";
import { loadData } from "../carouel";
import Autoplay from 'embla-carousel-autoplay';

export function Carousel2(props) {
  const [data, setData] = useState([]);
  const autoplay = useRef(Autoplay({ delay: 5000 }));
  useEffect(() => {
    loadData(
      {
        url: props.url,
        data: props.data,
        query: props.query,
        jsonata: props.jsonata,
        display: props.display,
        selectedIds: props.selectedIds || props.hotJobIds || props.hotNewsIds,
      },
      setData
    );
  }, [props.data, props.display, props.hotJobIds, props.hotNewsIds, props.jsonata, props.query, props.selectedIds, props.url]);

  // if (data.length !== 0) {
  //   console.log('huyvn', data);
  // }

  const filteredData = dynamicFilterData(props.dynamicFilters || [], [...data], props.sortOption);

  const getCardContentJSX = (job) => {
    switch (props.cardType) {
      case "job-card3":
        return <JobCard3 job={job} />;

      case "job-card2":
        return (
          <JobCard2
            title={job.name}
            image={job.image || props.defaultImage || props.imageLogo}
            salary={job.salary}
            location={job.location}
            expirationDate={job.expiration_date ?? job.date}
            tags={job.tags}
            description={job.description}
          />
        );

      case "job-card":
      case "job-card1":
      case "job-alt":
        return (
          <JobCard
            title={job.name}
            salary={job.salary}
            location={job.location}
            expirationDate={job.expiration_date}
            tags={job.tags}
            business_line={job.business_line}
            description={job.description}
            isInCarousel={filteredData.length > 3}
          />
        );

      default:
        // return <JobCardRect title={job.name} salary={job.salary} location={job.location} expirationDate={job.expiration_date} tags={job.tags} />
        return (
          <Card sx={{ borderColor: "#FFFFFF" }} radius={32} p={24} m={0} withBorder>
            <Stack spacing={24} >
              <Image
                radius={32}
                src={job.image}
                withPlaceholder
                placeholder={
                  <ImagePlaceholder
                    display={"imagePlaceholder1"}
                    image={props.defaultImage ? props.defaultImage : props.imageLogo}
                  />
                }
                alt={job.name}
                height={240}
              />

              <Text className={styles.jobName} align="center" lineClamp={1}>
                {job.name}
              </Text>
            </Stack>
          </Card>
        );
    }
  };

  let paddedData = [...filteredData];
  if (filteredData.length === 4) {
    paddedData = [...filteredData, ...filteredData];
  }

  const contentJSX = paddedData.map((ele, index) => {
    const cardJSX = (
      <Link target="_blank" href={`/${props.data}/${ele.id}?type_child=${props.child}`}>
        {getCardContentJSX(ele)}
      </Link>
    );

    if (filteredData.length <= 3)
      return (
        <div
          style={{
            width: props.windowDimension.width <= 750 ? "100%" : props.windowDimension.width <= 1300 ? "46%" : "33%",
          }}
          key={index}
        >
          {cardJSX}
        </div>
      );
    else return <Carousel.Slide key={index} >{cardJSX}</Carousel.Slide>;
  });
  let eleHTML = <NoData />;

  if (contentJSX.length > 0) {
    eleHTML = (
      <Box
        className={styles.carousel1}
        sx={(theme) => ({
          padding: props.windowDimension.width <= 1500 ? "20px" : "56px",
          width: "100%",
        })}
      >
        <Flex
          gap={props.windowDimension.width <= 1500 ? 20 : 56}
          style={{ width: "100%", justifyContent: "center" }}
          direction={"column"}
        >
          <Stack
            justify="center"
            align="center"
            style={{
              height: "100%",
              // width: props.windowDimension.width <= 1500 ? "100%" : props.titleStyle?.width || 240,
            }}
          >
            <Text
              align="left"
              className={styles.title}
              fz={props.windowDimension.width <= 800 ? 32 : props.titleStyle?.fontSize || 48}
            >
              {props.title}
            </Text>
            <Text fz={14} fw={400} color={"rgba(21, 20, 57, 0.8)"}>
              {props.subtitle}
            </Text>
          </Stack>

          <div
            style={{
              display: "flex",
            }}
          >
            {contentJSX.length <= 3 ? (
              <Group
                spacing={props.slide?.gap || 20}
                position={"center"}
                style={{ width: "100%" }}
                noWrap={props.windowDimension.width > 1500}
              >
                {contentJSX}
              </Group>
            ) : (
              <Carousel
                loop={props.hasLoop || true}
                className={styles.carousel}
                plugins={[autoplay.current]}
                onMouseEnter={autoplay.current.stop}
                onMouseLeave={autoplay.current.reset}
                breakpoints={[
                  { maxWidth: "lg", slideSize: "50%", slideGap: 16 },
                  { maxWidth: "sm", slideSize: "100%", slideGap: 16 },
                ]}
                slideSize={props.slide?.size || `${100 / 3}%`}
                slideGap={32}
                align="center"
                draggable={false}
                slidesToScroll={1}
                controlSize={48}
                controlsOffset={0}
                sx={{
                  button: {
                    background: "#FFFFFF",
                    border: "2px solid #EBEAED",
                    boxShadow: "0px 0px 20px 2px #0000001A",

                    opacity: 1,
                    width: 48,
                    height: 48,
                  },
                  svg: {
                    color: variables.primaryColor,
                    width: "24px",
                    height: "24px",
                  },
                }}
                styles={{ controls: { left: -22} }}
              >
                {contentJSX}
              </Carousel>
            )}
          </div>
        </Flex>
      </Box>
    );
  }

  return eleHTML;
}

import React from 'react'

import {Center, Image, Stack, Text} from "@mantine/core";

function NoData(props) {
    return (
        // <Text ta={"center"}>Không có dữ liệu từ API</Text>
        <Center style={{height: "100%"}}>
            <Stack spacing={20} justify={"center"} align={"center"}>
                {props.hasImage
                    ? <Image src={"/images/NoDataImage.svg"} width={500} alt={"no_image"}/>
                    : <></>
                }
                {props.hasText
                    ? <Text style={{color: "var(--primaryColor)"}} ta={"center"} fw={700} fz={32}>
                        {props.noDataText ? props.noDataText : "Không có dữ liệu"}
                    </Text>
                    : <></>
                }

            </Stack>
        </Center>
    )
}

export default NoData

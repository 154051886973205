import { Carousel } from "@mantine/carousel";
import { Box, Card, Group, Image, Text } from "@mantine/core";
import parse from "html-react-parser";
import markdownit from "markdown-it";
import Link from "next/link";
import { useEffect, useState } from "react";
import styles from "../../../styles/feature.module.scss";
import variables from "../../../styles/variables.module.scss";
import ImagePlaceholder from "../../imagePlaceholder/imagePlaceholder";
import { loadData } from "../carouel";

export function Carousel4(props) {
  const [data, setData] = useState([]);
  useEffect(() => {
    loadData(
      {
        url: props.url,
        data: props.data,
        query: props.query,
        jsonata: props.jsonata,
        display: props.display,
        selectedIds: props.selectedIds || props.hotJobIds || props.hotNewsIds,
      },
      setData
    );
  }, []);
  let carouselSlideJSX = <></>;
  carouselSlideJSX = data.map((dataSingleton) => (
    <Carousel.Slide key={dataSingleton.id}>
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Card.Section>
          <Image
            src={dataSingleton.image}
            height={200}
            alt={dataSingleton.image}
            withPlaceholder
            placeholder={
              <ImagePlaceholder
                display={"imagePlaceholder1"}
                image={props.defaultImage ? props.defaultImage : props.imageLogo}
              />
            }
          />
        </Card.Section>

        <Group position="center" mt="md" mb="xs">
          <Text lineClamp={1} weight={700}>
            {dataSingleton.excerpt}
          </Text>
        </Group>

        <Text size="sm" color="dimmed" lineClamp={2}>
          {parse(markdownit().render(dataSingleton.markdown))}
        </Text>

        <Group position="center" mt="md" mb="xs">
          <Link href={`${props.data}/${dataSingleton.id}?type_child=${props.child}`}>
            <Text fz={20} c={variables.primaryColor}>
              Xem chi tiết
            </Text>
          </Link>
        </Group>
      </Card>
    </Carousel.Slide>
  ));

  return (
    <Box
      className={styles.container}
      sx={(theme) => ({
        padding: "0px 100px",
      })}
    >
      <Carousel
        loop={props.hasLoop}
        withIndicators
        height={"auto"}
        slideSize="33.333333%"
        slideGap="md"
        align="start"
        slidesToScroll={3}
        sx={{
          button: {
            background: variables.primaryColor,
            border: "none",
          },
          svg: { color: "#FFFFFF", width: "24px", height: "24px" },
        }}
      >
        {carouselSlideJSX}
      </Carousel>
    </Box>
  );
}

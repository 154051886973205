import { Text, Accordion, Stack } from "@mantine/core";
import { useEffect, useState } from "react";
import variables from "../../styles/variables.module.scss";
import markdownit from "markdown-it";
import parse from "html-react-parser";

export default function Faq(props) {
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  let eleHTML = <></>;
  if (domLoaded) {
    switch (props.display) {
      case "type1":
        eleHTML = (
          <FaqType1 title={props.options.title} data={props.options.data} />
        );
        break;
      default:
        break;
    }
  }

  return eleHTML;
}

function FaqType1(props) {
  const dataList = props.data;
  const md = markdownit();

  const titleStyle = {
    fontSize: "48px",
    fontWeight: 700,
    lineHeight: "66px",
    letterSpacing: 0,
    textAlign: "left",
    color: variables.primaryColor,
  };
  return (
    <Stack p={{ base: 48, md: 64 }} spacing={32}>
      <Text style={titleStyle}>{props.title}</Text>
      <Accordion variant="separated" radius="lg">
        {dataList.map((data, index) => {
          return (
            <Accordion.Item
              key={index}
              value={data.question}
              style={{ backgroundColor: "#fff", borderRadius: "8px" }}
            >
              <Accordion.Control>
                <Text fw={400} fz="18px">
                  {data.question}
                </Text>
              </Accordion.Control>
              <Accordion.Panel>{parse(md.render(data.answer))}</Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Stack>
  );
}

import { Divider, Group, Image, Stack, Text } from '@mantine/core';
import parse from "html-react-parser";
import { Clock, Tag, User } from 'tabler-icons-react';
import CoreValueListStyles from './coreValueList.module.scss';
function CoreValueList({ options: { bannerImage, coreValues }, primaryColor }) {
    return (
        <Stack className={CoreValueListStyles.coreValueList}>
            <Image alt="banner-image" src={bannerImage} width="100%" height={600} radius={32} />
            <Stack spacing={32}>
                <Stack spacing={16}>
                    <Text className={CoreValueListStyles.title}>
                        {coreValues.map((coreValue, index) => {
                            if (!coreValue.title) {
                                return ""
                            }
                            return coreValue.title + (index < coreValues.length - 1 ? ' - ' : '')
                        })}
                    </Text>

                    <Group spacing={16} align='center' className={CoreValueListStyles.tag}>
                        <Group spacing={8}>
                            <Clock size={16} />
                            <Text>2 days ago</Text>
                        </Group>
                        <Group spacing={8}>
                            <User size={16} />
                            <Text>Admin</Text>
                        </Group>
                        <Group spacing={8}>
                            <Tag size={16} />
                            <Text>Fitness</Text>
                        </Group>
                    </Group>

                </Stack>
                <div style={{ width: "100%" }}>
                    <Divider size={1} color={primaryColor} />
                </div>
                {coreValues.map((coreValue, index) => {
                    return (

                        <Stack spacing={16} key={`core-value-${index}`} >
                            <Text className={CoreValueListStyles.coreValueTitle}>{coreValue.title}</Text>
                            {coreValue.description && <Text className={CoreValueListStyles.coreValueDescription}>
                                {parse(coreValue.description)}
                            </Text>}
                            <div className={CoreValueListStyles.coreValueImages}>
                                {coreValue.images?.map((imageUrl, index) => {
                                    return <div key={index} style={{ width: `${100 / coreValue.images.length}%` }} className={CoreValueListStyles.coreValueImage}>
                                        <Image alt={`image-${index}`} src={imageUrl} width="100%" height={420} radius={32} />
                                    </div>


                                })}
                            </div>

                        </Stack>

                    )
                })}
            </Stack>
        </Stack>
    )
}

export default CoreValueList
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Collapse,
  Divider,
  FileInput,
  Flex,
  Group,
  Image,
  LoadingOverlay,
  Select,
  SimpleGrid,
  Slider,
  Stack,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import styles from "../../styles/feature.module.scss";
import {useForm} from "@mantine/form";
import {useState} from "react";
import variables from "../../styles/variables.module.scss";
import {Dropzone, IMAGE_MIME_TYPE} from "@mantine/dropzone";
import {Photo, SquarePlus, Trash} from "tabler-icons-react";
import {DatePicker} from "@mantine/dates";
import "dayjs/locale/vi";
import {showNotification} from "@mantine/notifications";
import {openContextModal} from '@mantine/modals';
import {uploadAnyFile} from "@/services/formDynamicApis";
import {postTalent} from "@/services/services";
import useTrans from "@/hooks/useTrans";

export default function Form(props) {
  const trans = useTrans();
  let eleHTML;
  const [file, setFile] = useState(null); //  hiển thị hình ảnh ở dropzone
  const [visible, setVisible] = useState(false); // biến check loading overlay khi click submit
  const [disabled, setDisabled] = useState(false);


  // các biến opend dùng để toggle các collapse
  const [openedHocvan, setOpenHocVan] = useState([true, false, false]);
  const [openedKNLV, setOpenKNLV] = useState([true, false, false]);
  const [openedChungChi, setOpenChungChi] = useState([true, false, false]);
  const [openedSkills, setOpenSkills] = useState([true, false, false]);

  // các biến model dùng để thêm hoặc bớt item trong form nest field
  const modelEducation = {
    institution: "",
    url: "",
    area: "",
    studyType: "",
    startDate: "",
    endDate: "",
    score: "",
    courses: [],
  };
  const modelWork = {
    name: "",
    location: "",
    description: "",
    position: "",
    startDate: "",
    endDate: "",
    summary: "",
    highlights: [],
  };
  const modelCertificates = {
    name: "",
    date: "",
  };
  const modelSkills = {
    name: "",
    level: 0,
  };

  // biến form dùng để khởi tạo giá trị model cả talent, hiện đang theo format của jsonresume
  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      basics: {
        name: "",
        dob: null,
        cv: null,
        gender: "",
        phone: "",
        label: "",
        image: "", // chưa up dc image
        email: "",
        summary: "",
        location: {
          address: "",
          city: "",
          countryCode: "",
        },
        profiles: [],
      },
      work: [
        {
          name: "",
          location: "",
          description: "",
          position: "",
          startDate: "",
          endDate: "",
          summary: "",
          highlights: [],
        },
      ],
      volunteer: [],
      education: [
        {
          institution: "",
          area: "",
          studyType: "",
          startDate: "",
          endDate: "",
        },
      ],
      awards: [],
      certificates: [
        {
          name: "",
          date: "",
        },
      ],
      publications: [],
      skills: [
        {
          name: "",
          level: 0,
        },
      ],
      languages: [],
      interests: [],
      references: [],
      projects: [],
      meta: {
        source: "portal",
      },
      tags: ['public']
    },
    validate: {
      basics: {
        name: (value) =>
          value.length < 2 ? "Tên của bạn ít nhất phải 2 kí tự" : null,
        dob: (value) =>
          value == null ? "Không được để trống ngày sinh" : null,
        gender: (value) =>
          value.length < 1 ? "Không được để trống giới tính" : null,
        phone: (value) =>
          /(((\+84)|0[3|5|7|8|9])+([0-9]{9})\b)|((84|0[3|5|7|8|9])+([0-9]{8})\b)|((02)+([0-9]{9})\b)|((02|(\+)84)+([0-9]{10})\b)/.test(
            value
          )
            ? null
            : "Không đúng định dạng",
        email: (value) =>
          value != '' ? ( /^\S+@\S+$/.test(value) ? null : "Không đúng định dạng email" ) : null,
      },
    },
  });

  // biến render các thành phần nâng cao
  const moreInfomations = [
    {
      title: "Trình độ học vấn",
      field: "education",
      model: modelEducation,
      function: () => {
        form.reset("education");
      },
      body: (
        <Stack spacing={8} w={"100%"}>
          {form.values.education.map((item, index) => (
            <div key={`education-${index}`}>
              <Group
                mb={18}
                onClick={() =>
                  setOpenHocVan((o) => {
                    return o.map((e, i) => (i == index ? !e : e));
                  })
                }
                className={styles.collapse}
                p="xs"
              >
                <ActionIcon
                  onClick={() => form.removeListItem("education", index)}
                >
                  <Trash
                    size={30}
                    color={variables.primaryColor}
                    strokeWidth={1.5}
                  />
                </ActionIcon>
                <Text>{item.institution ? item.institution : "- - -"}</Text>
              </Group>
              <Collapse in={openedHocvan[index]} className={styles.childCol}>
                <Stack spacing={8} w={"100%"}>
                  <TextInput
                    radius="lg"
                    label={<Text className={styles.label}>Tên trường</Text>}
                    type="text"
                    disabled={disabled}
                    {...form.getInputProps(`education.${index}.institution`)}
                  />
                  <TextInput
                    radius="lg"
                    label={<Text className={styles.label}>Chuyên nghành</Text>}
                    type="text"
                    disabled={disabled}
                    {...form.getInputProps(`education.${index}.area`)}
                  />
                  <SimpleGrid cols={2} spacing={32}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      labelFormat="DD/MM/YYYY"
                      locale="vi"
                      radius="lg"
                      placeholder="Chọn thời gian"
                      label={<Text className={styles.label}>Từ</Text>}
                      disabled={disabled}
                      {...form.getInputProps(`education.${index}.startDate`)}
                    />
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      labelFormat="DD/MM/YYYY"
                      locale="vi"
                      radius="lg"
                      placeholder="Chọn thời gian"
                      label={<Text className={styles.label}>Đến</Text>}
                      disabled={disabled}
                      {...form.getInputProps(`education.${index}.endDate`)}
                    />
                  </SimpleGrid>
                </Stack>
              </Collapse>
            </div>
          ))}
        </Stack>
      ),
    },
    {
      title: "Kinh nghiệm làm việc",
      field: "work",
      model: modelWork,
      function: () => {
        form.reset("work");
      },
      body: (
        <Stack spacing={8} w={"100%"}>
          {form.values.work.map((item, index) => (
            <div key={`work-${index}`}>
              <Group
                mb={18}
                onClick={() =>
                  setOpenKNLV((o) => {
                    return o.map((e, i) => (i == index ? !e : e));
                  })
                }
                className={styles.collapse}
                p="xs"
              >
                <ActionIcon
                  onClick={() => form.removeListItem("work", index)}
                >
                  <Trash
                    size={30}
                    color={variables.primaryColor}
                    strokeWidth={1.5}
                  />
                </ActionIcon>
                <Text>{item.name ? item.name : "- - -"}</Text>
              </Group>
              <Collapse in={openedKNLV[index]} className={styles.childCol}>
                <Stack spacing={8} w={"100%"}>
                  <TextInput
                    radius="lg"
                    label={<Text className={styles.label}>Tên công ty</Text>}
                    type="text"
                    disabled={disabled}
                    {...form.getInputProps(`work.${index}.name`)}
                  />
                  <TextInput
                    radius="lg"
                    label={<Text className={styles.label}>Chức danh</Text>}
                    type="text"
                    disabled={disabled}
                    {...form.getInputProps(`work.${index}.position`)}
                  />
                  <Textarea
                    radius="lg"
                    disabled={disabled}
                    label={
                      <Text className={styles.label}>Mô tả công việc</Text>
                    }
                    {...form.getInputProps(`work.${index}.summary`)}
                  />
                  <SimpleGrid cols={2} spacing={32}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      labelFormat="DD/MM/YYYY"
                      locale="vi"
                      radius="lg"
                      placeholder="Chọn thời gian"
                      label={<Text className={styles.label}>Từ</Text>}
                        disabled={disabled}
                      {...form.getInputProps(`work.${index}.startDate`)}
                    />
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      labelFormat="DD/MM/YYYY"
                      locale="vi"
                      radius="lg"
                      placeholder="Chọn thời gian"
                      label={<Text className={styles.label}>Đến</Text>}
                        disabled={disabled}
                      {...form.getInputProps(`work.${index}.endDate`)}
                    />
                  </SimpleGrid>
                </Stack>
              </Collapse>
            </div>
          ))}
        </Stack>
      ),
    },
    {
      title: "Chứng chỉ",
      field: "certificates",
      model: modelCertificates,
      function: () => {
        form.reset("certificates");
      },
      body: (
        <Stack spacing={8} w={"100%"}>
          {form.values.certificates.map((item, index) => (
            <div key={`certificates-${index}`}>
              <Group
                mb={18}
                onClick={() =>
                  setOpenChungChi((o) => {
                    return o.map((e, i) => (i == index ? !e : e));
                  })
                }
                className={styles.collapse}
                p="xs"
              >
                <ActionIcon
                  onClick={() => form.removeListItem("certificates", index)}
                >
                  <Trash
                    size={30}
                    color={variables.primaryColor}
                    strokeWidth={1.5}
                  />
                </ActionIcon>
                <Text>{item.name ? item.name : "- - -"}</Text>
              </Group>
              <Collapse in={openedChungChi[index]} className={styles.childCol}>
                <Stack spacing={8} w={"100%"}>
                  <TextInput
                    radius="lg"
                    label={<Text className={styles.label}>Tên chứng chỉ</Text>}
                    type="text"
                    disabled={disabled}
                    {...form.getInputProps(`certificates.${index}.name`)}
                  />
                  <TextInput
                    radius="lg"
                    label={<Text className={styles.label}>Được trao bởi</Text>}
                    type="text"
                    disabled={disabled}
                    {...form.getInputProps(`certificates.${index}.issuer`)}
                  />
                  <SimpleGrid cols={2} spacing={32}>
                    <DatePicker
                      radius="lg"
                      locale="vi"
                      inputFormat="DD/MM/YYYY"
                      labelFormat="DD/MM/YYYY"
                      placeholder="Chọn thời gian"
                      label={
                        <Text className={styles.label}>Thời gian nhận</Text>
                      }
                      disabled={disabled}
                      {...form.getInputProps(`certificates.${index}.date`)}
                    />
                    <TextInput
                      radius="lg"
                      label={<Text className={styles.label}>Url</Text>}
                      type="url"
                      disabled={disabled}
                      {...form.getInputProps(`certificates.${index}.url`)}
                    />
                  </SimpleGrid>
                </Stack>
              </Collapse>
            </div>
          ))}
        </Stack>
      ),
    },
    {
      title: "Kỹ năng",
      field: "skills",
      model: modelSkills,
      function: () => {
        form.reset("skills");
      },
      body: (
        <Stack spacing={8} w={"100%"}>
          {form.values.skills.map((item, index) => (
            <div key={`skills-${index}`}>
              <Group
                mb={18}
                onClick={() =>
                  setOpenSkills((o) => {
                    return o.map((e, i) => (i == index ? !e : e));
                  })
                }
                className={styles.collapse}
                p="xs"
              >
                <ActionIcon
                  onClick={() => form.removeListItem("skills", index)}
                >
                  <Trash
                    size={30}
                    color={variables.primaryColor}
                    strokeWidth={1.5}
                  />
                </ActionIcon>
                <Text>{item.name ? item.name : "- - -"}</Text>
              </Group>
              <Collapse in={openedSkills[index]} className={styles.childCol}>
                <Stack spacing={8} w={"100%"}>
                  <SimpleGrid cols={2} spacing={32}>
                    <TextInput
                      radius="lg"
                      label={<Text className={styles.label}>Tên kỹ năng</Text>}
                      type="text"
                      disabled={disabled}
                      {...form.getInputProps(`skills.${index}.name`)}
                    />
                    <Flex align="center">
                      <Slider
                        min={0}
                        max={5}
                        style={{ width: "100%" }}
                        defaultValue={0}
                        step={1}
                        marks={[
                          { value: 0, label: "0" },
                          { value: 1, label: "1" },
                          { value: 2, label: "2" },
                          { value: 3, label: "3" },
                          { value: 4, label: "4" },
                          { value: 5, label: "5" },
                        ]}
                        onChange={(value) => {
                          form.setFieldValue(`skills.${index}.level`, value);
                        }}
                      />
                    </Flex>
                  </SimpleGrid>
                </Stack>
              </Collapse>
            </div>
          ))}
        </Stack>
      ),
    },
  ];

  const onSubmit = async () => {
    setVisible(true);
    if (form.validate().hasErrors) {
      showNotification({
        id: "noti-fail",
        loading: false,
        color: "red",
        title: "Thất baị!",
        message: "Có lỗi xảy ra trong quá trình ứng tuyển.",
        autoClose: 3000,
      });
      setVisible(false);
    } else {
      let fileUrl = '';
      let cvUrl = ''
      let cvFile = null;
      if (file) {
        const fileUpload = await uploadAnyFile(file, props.url);
        if (fileUpload) {
          fileUrl = fileUpload.url;
        }
      }
      if (form.values.basics.cv) {
        cvFile = await uploadAnyFile(form.values.basics.cv, props.url);
        if (cvFile) {
          cvUrl = cvFile.url;
        }
      }
      if (fileUrl.length > 0) {
        form.values.basics.image = fileUrl;
      }

      if (cvUrl.length > 0) {
        form.values.basics.cv = cvUrl;
      }

      const newTalent = await postTalent(props.url, form.values);
      if (newTalent.status === 200 || newTalent.status === 201) {
        openContextModal({
          modal: 'success',
          centered: true,
          innerProps: {
            text:trans.applyNow.success,
            content: trans.applyNow.successBody,
            color: variables.primaryColor
          },
        })
        form.values.basics.cv = cvFile;
        setDisabled(true);
        setVisible(false);
      } else {
        setVisible(false);
        showNotification({
          id: "noti-fail",
          loading: false,
          color: "red",
          title: trans.applyNow.fail,
          message: trans.applyNow.failBody,
          autoClose: 3000,
        });
      }
    }
  };

  switch (props.display) {
    case "form1":
      eleHTML = (
        <div className={styles.form1}>
          <Box
            sx={(theme) => ({
              background: "#fff",
              boxShadow: theme.shadows.md,
              [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                padding: 10,
              },
              [`@media (min-width: ${theme.breakpoints.md}px)`]: {
                padding: 30,
              },
              borderRadius: 32,
              position: "relative",
              width: 1140,
            })}
          >
            <LoadingOverlay visible={visible} overlayBlur={0.5} />
            <form onSubmit={form.onSubmit(() => onSubmit())}>
              <Stack spacing={32}>
                <Stack align="center" mb={32}>
                  <Text align="center" className={styles.title}>
                    {props.options.title}
                  </Text>
                  <div className={styles.boxDivider}>
                    <Divider size={1} color={variables.primaryColor} />
                  </div>
                </Stack>
                <Group noWrap spacing={32}>
                  <Dropzone
                    onDrop={(files) => {
                      setFile(files[0]);
                    }}
                    onReject={(files) => console.log("rejected files", files)}
                    maxSize={3 * 1024 ** 2}
                    accept={IMAGE_MIME_TYPE}
                    className={styles.dropZone}
                    style={{
                      height: "340px",
                      maxHeight: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {!file ? (
                      <Center>
                        <Photo size="48" strokeWidth={1.5} />
                        <Text px={5}>{trans.applyNow.photoNotice}</Text>
                      </Center>
                    ) : (
                      <Image
                        src={URL.createObjectURL(file)}
                        alt=""
                        fit="cover"
                        height={300}
                      />
                    )}
                  </Dropzone>
                  <Stack spacing={8} w={"100%"}>
                    <TextInput
                      radius="lg"
                      label={<Text className={styles.label}>Họ và tên</Text>}
                      type="text"
                      disabled={disabled}
                      {...form.getInputProps("basics.name")}
                    />
                    <SimpleGrid cols={2} spacing={32}>
                      <TextInput
                        radius="lg"
                        label={
                          <Group spacing={8}>
                            <Text className={styles.label}>Email</Text>
                            {/* <Text
                              fz={12}
                              c={variables.primaryColor}
                            >{`(không bắt buộc)`}</Text> */}
                          </Group>
                        }
                        type="email"
                        disabled={disabled}
                        {...form.getInputProps("basics.email")}
                      />
                      <TextInput
                        radius="lg"
                        label={
                          <Text className={styles.label}>Số điện thoại</Text>
                        }
                        type="tel"
                        disabled={disabled}
                        {...form.getInputProps("basics.phone")}
                      />
                      <Select
                        label={<Text className={styles.label}>Giới tính</Text>}
                        radius="lg"
                        data={[
                          { value: "male", label: "Nam" },
                          { value: "female", label: "Nữ" },
                          { value: "order", label: "Khác" },
                        ]}
                        disabled={disabled}
                        {...form.getInputProps("basics.gender")}
                      />
                      <DatePicker
                          linitialLevel='year'
                        allowFreeInput
                        inputFormat="DD/MM/YYYY"
                        labelFormat="DD/MM/YYYY"
                        radius="lg"
                        locale="vi"
                        placeholder="Chọn thời gian"
                        label={<Text className={styles.label}>Ngày sinh</Text>}
                          disabled={disabled}
                        {...form.getInputProps("basics.dob")}
                      />
                    </SimpleGrid>
                    <FileInput disabled={disabled}
                               clearable={!disabled}
                               radius="md"
                               label={<Group spacing={8}>
                                 <Text className={styles.label}>CV</Text>
                                 {/* <Text
                                     fz={12}
                                     c={variables.primaryColor}
                                 >{`(không bắt buộc)`}</Text> */}
                               </Group>}
                               {...form.getInputProps("basics.cv")}
                    />
                  </Stack>
                </Group>
                {/* // các element  */}
                {/* {education}
                {work}
                {certificates}
                {skills} */}
                {moreInfomations.map((ele, index) => (
                  <Stack
                    key={`moreInfo-${index}`}
                  >
                    <Stack>
                      <div className={styles.boxBetween}>
                        <Group noWrap>
                          <Trash
                            size={30}
                            style={{ cursor: "pointer" }}
                            strokeWidth={1.5}
                            color={variables.primaryColor}
                            onClick={ele.function}
                          />
                          <Text className={styles.subtitle}>{ele.title}</Text>
                        </Group>
                        <Group spacing={16} noWrap>
                          <SquarePlus
                            size={30}
                            strokeWidth={1.5}
                            color={variables.primaryColor}
                            display={
                              form.values[ele.field].length < 3 ? "" : "none"
                            }
                            onClick={() =>
                              form.insertListItem(ele.field, ele.model)
                            }
                          />
                        </Group>
                      </div>
                      <div className={styles.boxDivider}>
                        <Divider size={1} color={variables.primaryColor} />
                      </div>
                    </Stack>
                    {ele.body}
                  </Stack>
                ))}
                <Group position="right">
                  <Button
                    fw={400}
                    fz={20}
                    radius={40}
                    w={200}
                    type="submit"
                    bg={variables.primaryColor}
                    // onClick={onSubmit}
                  >
                    {trans.apply}
                  </Button>
                </Group>
              </Stack>
            </form>
          </Box>
        </div>
      );
      break;

    default:
      break;
  }

  return eleHTML;
}

import React, {useEffect, useState} from "react";
import {Center, Flex, Group, Image, Pagination, Stack, Text} from "@mantine/core";
import convert from "color-convert";
import styles from "../../../styles/feature.module.scss";

export function Gallery3R(props) {
    let imageJSXArray = [];
    let imagePerRow = props.data.length === 4 ? 2 : 3;
    const [activePage, setActivePage] = useState(1);
    const [activeLimit, setActiveLimit] = useState(6);


    useEffect(() => {
        if (props.limitPage) {
            setActiveLimit(props.limitPage)
        }
    }, [props.limitPage]);

    const currentWidth = props.windowDimension.width;
    const divider = Math.min((currentWidth < 1440) ? 1440 / currentWidth : 1, 1.5);

    if (props.windowDimension.width < 1100) {
        imagePerRow = 2;
    }
    if (props.windowDimension.width < 780) {
        imagePerRow = 1;
    }

    const testData = props.data && Array.isArray(props.data) ? props.data : [];
    const pageTotal = Math.ceil(testData.length / activeLimit);

    const pagedData = testData.slice(activeLimit * (activePage - 1), activeLimit * (activePage));
    const galleryImage = pagedData.map((dataSingleton, index) => {
        const image = <Image key={`gallery3-photo-${index}`} radius={20}
                             style={{objectFit: 'contain'}} src={dataSingleton.image} alt={`photo-${index}`}
                             height={223}/>

        imageJSXArray.push(image);
        if ((index + 1) % imagePerRow === 0 || index + 1 === props.optionData?.length) {
            const returnJSXArray = imageJSXArray.slice();
            imageJSXArray = [];
            const max_width = (1440 / imagePerRow) * ((returnJSXArray.length) % (imagePerRow + 1));
            return (
                <Center key={`group-${index}`}>
                    <Flex mih={32}
                          style={{width: max_width}}
                          gap="xl"
                          justify="center"
                          align="center"
                          direction="row">
                        {returnJSXArray}
                    </Flex>
                </Center>

            );
        }

    });
    const convertColor = convert.hex.rgb(props.primaryColor);
    return (
        <Stack spacing={44 / divider}
               style={{
                   height: '100%',
                   padding: `${44 / divider}px ${64 / divider}px`,
                   background: `rgba(${convertColor[0]}, ${convertColor[1]}, ${convertColor[2]},0.1)`,
               }}
               className={styles.gallery3}>
            <Text align="center" fz={32 / divider} fw={700}>{props.title}</Text>
            <Stack spacing={32 / divider}>
                {galleryImage}
            </Stack>

            <Group position={"right"}>
                {pageTotal > 1
                    ? <Pagination value={activePage} onChange={setActivePage}
                                  total={pageTotal} radius={"xl"}/>
                    : <></>
                }

            </Group>
        </Stack>
    );
}

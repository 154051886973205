import { Button, Center, Group, Image, Loader, Modal, Stack, Text } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from "@mantine/dropzone";
import { useDisclosure } from "@mantine/hooks";
import axios from "axios";
import dynamic from "next/dynamic";
import { useEffect, useRef, useState } from "react";
import { BiTrash } from "react-icons/bi";
import variables from "styles/variables.module.scss";

/**
 * Renders a dynamic input dropzone based on the input template type.
 * @param {object} props - The props containing inputTemplate type.
 * @returns {JSX.Element} - The rendered input dropzone.
 */
function FormDynamicInputDropzone(props) {
  // Initialize eleHTML as a fragment
  let eleHTML = <></>;

  // Render different dropzone components based on inputTemplate type
  switch (props.inputTemplate.type) {
    case "drop-zone":
      eleHTML = <ImageDropzone {...props} />;
      break;

    case "file-picker":
      eleHTML = <FileDropzone {...props} />;
      break;

    case "file-picker-many":
      eleHTML = <FileManyDropzone {...props} />;
      break;

    default:
      break;
  }

  return eleHTML;
}

function ImageDropzone(props) {
  const [imagePreviewFile, setImagePreviewFile] = useState(null);
  const [oldInvisible, setOldInvisible] = useState(false);

  const inputProps = { ...props.formInputProps };
  /**
   * Handles the file drop event
   * @param {Array} files - The array of files being dropped
   */
  inputProps.onDrop = (files) => {
    // If no files are dropped, remove the file from the array if it exists
    if (files === undefined || files === null) {
      const index =
        props.fileToUploadArray && props.setFileToUploadArray
          ? props.fileToUploadArray.findIndex((e) => e.inputName === props.inputName)
          : null;

      if (index !== null && index >= 0) {
        const copyFileToUploadArray = [
          ...props.fileToUploadArray.slice(0, index),
          ...props.fileToUploadArray.slice(index + 1),
        ];
        props.setFileToUploadArray(copyFileToUploadArray);
      }
      setImagePreviewFile(files);
      props.formInputProps.onChange(files);
      return;
    }

    // If files are dropped, update the image file and add it to the array
    const imageFile = files[0];
    setImagePreviewFile(imageFile);
    const index =
      props.fileToUploadArray && props.setFileToUploadArray
        ? props.fileToUploadArray.findIndex((e) => e.inputName === props.inputName)
        : null;
    if (index !== null) {
      let copyFileToUploadArray;
      if (index >= 0) {
        copyFileToUploadArray = [
          ...props.fileToUploadArray.slice(0, index),
          ...props.fileToUploadArray.slice(index + 1),
        ];
      } else {
        copyFileToUploadArray = [...props.fileToUploadArray];
      }
      copyFileToUploadArray.push({ inputName: props.inputName, file: imageFile, inputTemplate: props.inputTemplate });
      props.setFileToUploadArray(copyFileToUploadArray);
    }
    props.formInputProps.onChange(imageFile);
  };

  useEffect(() => {
    if (props.inputTemplate.invisible) {
      setOldInvisible(true);
      props.formInputProps.onChange(undefined);
    } else if (oldInvisible) {
      setOldInvisible(false);
      props.formInputProps.onChange(props.initialValue === undefined ? null : props.initialValue);
    }
  }, [props.inputTemplate.invisible]);

  /**
   * Generates a preview of the given image file.
   */
  const imagePreview = () => {
    // Create a URL for the image file
    const imageUrl = URL.createObjectURL(imagePreviewFile);

    // Return an Image component with the generated URL and specified properties
    return (
      <Image
        radius={10}
        alt={""}
        width={180}
        height={180}
        src={imageUrl}
        imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
      />
    );
  };

  const uploadLoadJSX = (
    <Stack spacing={25} pt={25} pl={25} pr={25} pb={34} mt={15}>
      <Center>
        <Image width={32} alt={""} src={"/images/cloud-upload.svg"} />
      </Center>
      <Text ta="center" fz={16} c={"rgba(21, 20, 57, 0.8)"}>
        Kéo thả hình ảnh ở đây
      </Text>
    </Stack>
  );

  const imagePreviewJSX = imagePreviewFile ? (
    imagePreview()
  ) : props.initialValue && props.initialValue !== "" ? (
    <InitialFileUpload
      url={props.initialValue}
      primaryColor={props.primaryColor}
      customSize={{ radius: 10, width: 180, height: 180 }}
    />
  ) : (
    uploadLoadJSX
  );

  return !props.inputTemplate.invisible ? (
    <Center style={{ marginBottom: 20, position: "relative" }}>
      <div style={{ position: "absolute", width: 210, height: 210, zIndex: 3, top: -13 }}>
        <Image
          alt={"border-profile-photo"}
          src={`data:image/svg+xml,
                        %3csvg width='205' height='205' 
                        xmlns='http://www.w3.org/2000/svg'%3e%3crect 
                        width='100%25' height='100%25' fill='none' 
                        stroke='%23${props.primaryColor.slice(1)}' 
                        stroke-width='2' 
                        stroke-dasharray='15' 
                        stroke-dashoffset='0' 
                        stroke-linecap='square'/%3e%3c/svg%3e`}
        />
      </div>
      <div style={{ position: "relative", zIndex: 4 }}>
        <Dropzone
          style={{
            width: 180,
            height: 180,
            padding: 0,
            borderRadius: 16,
            border: "none",
            backgroundColor: "transparent",
          }}
          accept={IMAGE_MIME_TYPE}
          {...inputProps}
          disabled={props.disabledInput || inputProps.disabled}
        >
          <Dropzone.Idle>{imagePreviewJSX}</Dropzone.Idle>
        </Dropzone>
        {!props.disabledInput && ((props.initialValue && props.initialValue !== "") || imagePreviewFile) ? (
          <BiTrash
            color={"red"}
            size={"1.2rem"}
            onClick={() => {
              inputProps.onDrop(null);
            }}
            style={{ position: "absolute", bottom: -10, right: -50, cursor: "pointer" }}
          />
        ) : (
          <></>
        )}
        <div
          style={{
            position: "relative",
            bottom: -20,
            left: "5%",
            textWrap: "nowrap",
            color: "red",
            fontSize: 12,
            textAlign: "center",
          }}
        >
          {inputProps.error}
        </div>
      </div>
    </Center>
  ) : (
    <></>
  );
}

function FileDropzone(props) {
  const openRef = useRef(null);
  const [filePreview, setFilePreview] = useState(null);
  const [oldInvisible, setOldInvisible] = useState(false);

  const inputProps = { ...props.formInputProps };
  inputProps.onDrop = (files) => {
    const file = files[0];
    setFilePreview(file);
    const index =
      props.fileToUploadArray && props.setFileToUploadArray
        ? props.fileToUploadArray.findIndex((e) => e.inputName === props.inputName)
        : null;
    if (index !== null) {
      let copyFileToUploadArray;
      if (index >= 0) {
        copyFileToUploadArray = [
          ...props.fileToUploadArray.slice(0, index),
          ...props.fileToUploadArray.slice(index + 1),
        ];
      } else {
        copyFileToUploadArray = [...props.fileToUploadArray];
      }
      copyFileToUploadArray.push({ inputName: props.inputName, file: file, inputTemplate: props.inputTemplate });
      props.setFileToUploadArray(copyFileToUploadArray);
    }
    props.formInputProps.onChange(file);
  };

  const handleDelete = (invisible = false) => {
    setFilePreview(invisible ? undefined : null);
    const index =
      props.fileToUploadArray && props.setFileToUploadArray
        ? props.fileToUploadArray.findIndex((e) => e.inputName === props.inputName)
        : null;

    if (index !== null && index >= 0) {
      const copyFileToUploadArray = [
        ...props.fileToUploadArray.slice(0, index),
        ...props.fileToUploadArray.slice(index + 1),
      ];
      props.setFileToUploadArray(copyFileToUploadArray);
    }
    props.formInputProps.onChange(invisible ? undefined : null);
  };

  useEffect(() => {
    if (props.inputTemplate.invisible) {
      setOldInvisible(true);
      handleDelete(true);
    } else if (oldInvisible) {
      setOldInvisible(false);
      setFilePreview(null);
      inputProps.onChange(props.initialValue === undefined ? null : props.initialValue);
    }
  }, [props.inputTemplate.invisible]);

  useEffect(() => {
    setFilePreview(props.formInputProps.value);
  }, [props.formInputProps.value]);

  const filePreviewJSX = filePreview ? (
    <Group position={"center"} style={{ justifyContent: "center" }} w={360} spacing={5} noWrap>
      <Text
        style={{
          width: "70%",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        ta={"center"}
        fz={16}
      >
        {filePreview.name}
      </Text>
      <BiTrash color={"red"} size={"1rem"} cursor={"pointer"} onClick={() => handleDelete()} />
    </Group>
  ) : (
    <></>
  );

  return !props.inputTemplate.invisible ? (
    <>
      <Dropzone
        openRef={openRef}
        accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
        activateOnClick={false}
        {...inputProps}
        disabled={props.disabledInput || inputProps.disabled}
        multiple={false}
        style={{ marginTop: 20, cursor: "default" }}
        styles={{ inner: { pointerEvents: "all" } }}
      >
        <Dropzone.Idle>
          <Stack spacing={10}>
            {props.inputTemplate.label ? (
              <Text fw={500} c={variables.primaryColor}>
                {props.inputTemplate.label}
              </Text>
            ) : (
              <></>
            )}

            {inputProps.error ? (
              <div
                style={{
                  marginTop: -5,
                  marginBottom: 20,
                  color: "red",
                  fontSize: 12,
                }}
              >
                {inputProps.error}
              </div>
            ) : (
              <></>
            )}

            <Center>
              <Stack spacing={2}>
                {props.initialValue && props.initialValue !== "" && (
                  <InitialFileUpload
                    customSize={props.inputTemplate.customSize}
                    url={props.initialValue}
                    primaryColor={props.primaryColor}
                    label={props.inputTemplate.label}
                  />
                )}

                {((props.initialValue && props.initialValue === "") || !props.initialValue) && props.disabledInput ? (
                  <div>Không có file</div>
                ) : (
                  filePreviewJSX
                )}
              </Stack>
            </Center>
            {!props.disabledInput ? (
              <Group position="center">
                <Button
                  onClick={() => {
                    openRef.current();
                  }}
                >
                  Tải hình ảnh/tải danh sách
                </Button>
              </Group>
            ) : (
              <></>
            )}
          </Stack>
        </Dropzone.Idle>
      </Dropzone>
    </>
  ) : (
    <></>
  );
}

function FileManyDropzone(props) {
  const openRef = useRef(null);
  const [filePreview, setFilePreview] = useState([]);
  const [oldInvisible, setOldInvisible] = useState(false);

  const inputProps = { ...props.formInputProps };

  inputProps.onDrop = (files) => {
    const filePreviewCopy = [...filePreview, ...files];

    if (props.fileToUploadArray && props.setFileToUploadArray) {
      const copyFileToUploadArray = [...props.fileToUploadArray];

      const masterIndex = props.fileToUploadArray.findIndex((e) => e.inputName === props.inputName);
      if (masterIndex >= 0) {
        copyFileToUploadArray[masterIndex].file.push(...files);
      } else {
        copyFileToUploadArray.push({ inputName: props.inputName, file: files, inputTemplate: props.inputTemplate });
      }

      props.setFileToUploadArray(copyFileToUploadArray);
    }
    props.formInputProps.onChange(filePreviewCopy);
    setFilePreview(filePreviewCopy);
  };

  const handleDeleteFile = (index) => {
    const filePreviewCopy = [...filePreview.slice(0, index), ...filePreview.slice(index + 1)];
    props.formInputProps.onChange(filePreviewCopy);
    setFilePreview(filePreviewCopy);

    if (props.fileToUploadArray && props.setFileToUploadArray) {
      const masterIndex = props.fileToUploadArray.findIndex((e) => e.inputName === props.inputName);
      // const copyFileToUploadArray = [...props.fileToUploadArray.slice(0, masterIndex + index), ...props.fileToUploadArray.slice(masterIndex + index + 1)];

      let copyFileToUploadArray = [...props.fileToUploadArray];
      if (masterIndex >= 0) {
        const copyOfFiles = [...copyFileToUploadArray[masterIndex].file];
        copyFileToUploadArray[masterIndex].file = [...copyOfFiles.slice(0, index), ...copyOfFiles.slice(index + 1)];

        if (copyFileToUploadArray[masterIndex].file.length <= 0) {
          copyFileToUploadArray = [
            ...copyFileToUploadArray.slice(0, masterIndex),
            ...copyFileToUploadArray.slice(masterIndex + 1),
          ];
        }

        props.setFileToUploadArray(copyFileToUploadArray);
      }
    }
    props.formInputProps.onChange(filePreviewCopy);
  };

  const handleDeleteAll = () => {
    props.formInputProps.onChange(undefined);
    setFilePreview([]);
    const masterIndex = props.fileToUploadArray.findIndex((e) => e.inputName === props.inputName);
    let copyFileToUploadArray = [...props.fileToUploadArray];
    copyFileToUploadArray = [
      ...copyFileToUploadArray.slice(0, masterIndex),
      ...copyFileToUploadArray.slice(masterIndex + 1),
    ];
    props.setFileToUploadArray(copyFileToUploadArray);
  };

  useEffect(() => {
    if (props.inputTemplate.invisible) {
      setOldInvisible(true);
      handleDeleteAll();
    } else if (oldInvisible) {
      setOldInvisible(false);
      props.formInputProps.onChange(Array.isArray(props.initialValue) ? props.initialValue : []);
    }
  }, [props.inputTemplate.invisible]);

  const filePreviewJSX =
    filePreview.length > 0 ? (
      <Stack spacing={3}>
        {filePreview.map((filePreviewSingle, i) => {
          return (
            <Group key={i} style={{ width: 360, justifyContent: "center" }} spacing={5} align={"center"} noWrap>
              <Text
                key={i}
                style={{
                  width: "70%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                ta={"center"}
                fz={16}
              >
                {filePreviewSingle.name}
              </Text>
              <BiTrash color={"red"} size={"1rem"} onClick={() => handleDeleteFile(i)} />
            </Group>
          );
        })}
      </Stack>
    ) : (
      <></>
    );

  return !props.inputTemplate.invisible ? (
    <>
      <Dropzone
        openRef={openRef}
        accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
        activateOnClick={false}
        {...inputProps}
        style={{ marginTop: 20 }}
        styles={{ inner: { pointerEvents: "all" } }}
        disabled={props.disabledInput || inputProps.disabled}
      >
        <Dropzone.Idle>
          <Stack spacing={10}>
            {props.inputTemplate.label ? (
              <Text fw={500} c={variables.primaryColor}>
                {props.inputTemplate.label}
              </Text>
            ) : (
              <></>
            )}

            {inputProps.error ? (
              <div
                style={{
                  marginTop: -5,
                  marginBottom: 20,
                  color: "red",
                  fontSize: 12,
                }}
              >
                {inputProps.error}
              </div>
            ) : (
              <></>
            )}

            <Center>
              <Stack spacing={2}>
                {props.initialValue && Array.isArray(props.initialValue) ? (
                  props.initialValue.map((initialValueSingle, index) => {
                    return (
                      <InitialFileUpload
                        key={index}
                        customSize={props.inputTemplate.customSize}
                        index={index}
                        url={props.initialValue}
                        primaryColor={props.primaryColor}
                        label={`${props.inputTemplate.label}.${index + 1}`}
                      />
                    );
                  })
                ) : (
                  <></>
                )}

                {((props.initialValue && Array.isArray(props.initialValue) && props.initialValue.length === 0) ||
                  !props.initialValue) &&
                props.disabledInput ? (
                  <div>Không có file</div>
                ) : (
                  filePreviewJSX
                )}
              </Stack>
            </Center>
            {!props.disabledInput ? (
              <Group position="center">
                <Button
                  onClick={() => {
                    openRef.current();
                  }}
                >
                  Tải hình ảnh/tải danh sách
                </Button>
              </Group>
            ) : (
              <></>
            )}
          </Stack>
        </Dropzone.Idle>
      </Dropzone>
    </>
  ) : (
    <></>
  );
}

/**
 * Component for initial file upload
 * @param {Object} props - Component props
 * @param {string} props.url - The URL of the file
 * @param {string} props.primaryColor - The primary color for UI elements
 * @param {string} props.label - The label for the file
 * @param {number} props.index - The index of the file
 * @param {Object} props.customSize - Custom size for the image
 * @param {number} props.customSize.radius - The radius of the custom-sized image
 * @param {number} props.customSize.width - The width of the custom-sized image
 * @param {string} props.placeholder - The placeholder for the image
 * @returns {JSX.Element} - The rendered component
 */
const InitialFileUpload = (props) => {
  // State for loading indicator
  const [loading, setLoading] = useState(true);
  // State for object URL of the file
  const [objectUrl, setObjectUrl] = useState(null);
  // State for modal open/close
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);

  // Get file extension from the URL
  const fileExtension = props.url.substring(props.url.lastIndexOf(".") + 1);

  const ModalContent = dynamic(() => import("@/components/modal/modalContent"), {
    ssr: false,
  });

  // Fetch the file and set object URL on component mount
  useEffect(() => {
    if (fileExtension === "pdf") {
      setLoading(false);
      return;
    }

    axios
      .get(props.url, { responseType: "blob" })
      .then((response) => {
        const blob = response.data;
        const objectURL = URL.createObjectURL(blob);
        setObjectUrl(objectURL);
        setLoading(false);
      })
      .catch((error) => {
        setObjectUrl(null);
        setLoading(false);
      });
  }, [fileExtension, props.url]);

  // Render loading indicator while fetching the file
  if (loading) {
    return (
      <Center>
        <Stack justify={"center"} align={"center"} style={{ height: "100%" }} spacing={20}>
          <Loader color={props.primaryColor} />
          <Text fw={700} c={props.primaryColor}>
            Đang tải...
          </Text>
        </Stack>
      </Center>
    );
  }

  // Render PDF file content
  if (fileExtension === "pdf") {
    return (
      <Group position={"center"} align={"center"} spacing={5}>
        <Text ta={"center"}>File Cũ:</Text>
        <Text c={props.primaryColor} style={{ textDecoration: "underline", cursor: "pointer" }} onClick={openModal}>
          Link
        </Text>
        <Modal
          opened={modalOpened}
          overflow={"inside"}
          onClose={closeModal}
          size={1000}
          title={
            <div
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: props.primaryColor,
              }}
            >
              {props.label}
            </div>
          }
        >
          <ModalContent display={"modal-content2"} pdfFileUrl={props.url}></ModalContent>
        </Modal>
      </Group>
    );
  }

  // Render image file content
  return (
    <Stack spacing={2} justify={"center"} align={"center"} style={{ height: "100%" }}>
      {(!props.index || props.index === 0) && !props.customSize && <Text ta={"center"}>Hình Cũ</Text>}
      {!props.customSize && (
        <Image
          src={objectUrl}
          alt={"image-preview"}
          imageProps={{
            onLoad: () => {
              URL.revokeObjectURL(objectUrl);
            },
          }}
        />
      )}
      {props.customSize && (
        <Image
          src={objectUrl}
          radius={props.customSize.radius}
          width={props.customSize.width}
          height={props.customSize.width}
          withPlaceholder={props.placeholder !== undefined}
          placeholder={props.placeholder}
          alt={"image-preview"}
          imageProps={{
            onLoad: () => {
              URL.revokeObjectURL(objectUrl);
            },
          }}
        />
      )}
    </Stack>
  );
};
export default FormDynamicInputDropzone;

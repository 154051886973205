import { getDescriptionStyleJSX } from "@/components/formDynamic/components/nonPrimitiveType/formDynamicInput";
import FormDynamicMainInput from "@/components/formDynamic/components/nonPrimitiveType/formDynamicMainInput";
import { FormSectionStatus } from "@/services/constants";
import variables from "@/styles/variables.module.scss";
import { Grid, Group, Loader, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";

function FormDynamicGroupInput(props) {
  const [groupFieldTemplate, setGroupFieldTemplate] = useState(null);

  const getGroupValue = (fieldTemplateGroupInput) => {
    if (!fieldTemplateGroupInput) {
      return null;
    }
    let currentGroupValue = null;
    props.currentNameArray.forEach((name) => {
      if (!currentGroupValue) currentGroupValue = props.finalFormConfig.initialValues[name];
      else {
        currentGroupValue = currentGroupValue[name];
      }
    });
    if (!currentGroupValue) {
      currentGroupValue = {};
      const setGroupValue = (fieldTemplate) => {
        if (fieldTemplate.fields) {
          fieldTemplate.fields.forEach((fieldTemplateChild) => {
            if (fieldTemplateChild.name) {
              currentGroupValue[fieldTemplateChild.name] = props.finalFormConfig.initialValues[fieldTemplateChild.name];
            } else {
              setGroupValue(fieldTemplateChild);
            }
          });
        }
      };
      setGroupValue(fieldTemplateGroupInput);
    }

    return currentGroupValue;
  };

  useEffect(() => {
    if (props.fieldTemplateMain) {
      const fieldTemplateMain = { ...props.fieldTemplateMain };
      const checkboxMasterIndex = fieldTemplateMain.checkboxMasterIndex;
      const checkboxControlIndex = fieldTemplateMain.checkboxControlIndex;

      const currentGroupValue = getGroupValue(fieldTemplateMain);

      if (
        checkboxMasterIndex !== undefined &&
        checkboxControlIndex !== undefined &&
        fieldTemplateMain.fields &&
        fieldTemplateMain.fields[checkboxMasterIndex]
      ) {
        const masterCheckBoxFieldName = fieldTemplateMain.fields[checkboxMasterIndex].name;
        const masterCheckboxValue = currentGroupValue[masterCheckBoxFieldName];
        if (masterCheckBoxFieldName && masterCheckboxValue && Array.isArray(masterCheckboxValue)) {
          checkboxControlIndex.forEach((checkboxIndex) => {
            fieldTemplateMain.fields[checkboxIndex].invisible = currentGroupValue[masterCheckBoxFieldName].length === 0;
          });
        }
      }
      setGroupFieldTemplate(fieldTemplateMain);
    }
  }, [props.fieldTemplateMain, props.fieldTemplateMain.invisible]);

  // useEffect(() => {
  //     if (groupFieldTemplate && !masterSelect && groupFieldTemplate.masterSelectIndex !== undefined && groupFieldTemplate.masterSelectDependents !== undefined) {
  //         const masterSelectDependents = groupFieldTemplate.masterSelectDependents;
  //         const masterSelectIndex = groupFieldTemplate.masterSelectIndex;

  //         setMasterSelect({
  //             arraySelect: Array(masterSelectDependents).fill([]),
  //             numOfDependents: masterSelectDependents,
  //             masterIndex: masterSelectIndex,
  //             isLoading: true,
  //         })
  //     }
  // }, [groupFieldTemplate, props.currentNameArray]);

  // useEffect(() => {
  //     if (groupFieldTemplate && masterSelect && masterSelect.isLoading) {

  //         const currentGroupValue = getGroupValue(groupFieldTemplate);

  //         const masterSelectIndex = masterSelect.masterIndex;
  //         const masterSelectDependents = masterSelect.numOfDependents;

  //         const initialValueArray = [];

  //         for (let i = (masterSelectIndex + (groupFieldTemplate.indexInGroup ?? 0)); i <= (masterSelectIndex + masterSelectDependents + (groupFieldTemplate.indexInGroup ?? 0)); i++) {
  //             const key = groupFieldTemplate.fields && groupFieldTemplate.fields[i] && groupFieldTemplate.fields[i].name ? groupFieldTemplate.fields[i].name : null;
  //             if (key && currentGroupValue[key] && currentGroupValue[key].length > 0) {
  //                 initialValueArray.push(currentGroupValue[key]);
  //             }
  //         }

  //         const promiseArray = [];
  //         initialValueArray.forEach((initialValue, i) => {
  //             if (i < initialValueArray.length - 1) {
  //                 const targetMasterDataName = getMasterDataName(masterSelectIndex + i + 1);
  //                 if (targetMasterDataName) {
  //                     promiseArray.push(getLocationData(targetMasterDataName, initialValue, props.hostNameUrl).then(res => {
  //                         if (!res.isError) {
  //                             return res.data;
  //                         } else {
  //                             return [];
  //                         }
  //                     }))
  //                 }
  //             }
  //         });

  //         if (promiseArray.length > 0) {
  //             Promise.all(promiseArray).then(data => {
  //                 setMasterSelect((prev) => ({
  //                     ...prev,
  //                     isLoading: false,
  //                     arraySelect: data,
  //                 }));

  //             });
  //         } else {
  //             setMasterSelect((prev) => ({
  //                 ...prev,
  //                 isLoading: false,
  //             }))
  //         }
  //     }
  // }, [masterSelect]);

  useEffect(() => {
    const currentGroupName = props.currentNameArray.join(".");
    let initialGroupValue = getGroupValue(props.fieldTemplateMain);
    const updateInitialGroupValue = (fieldTemplate, initialGroupValue = {}) => {
      let returnValue = structuredClone(initialGroupValue);
      fieldTemplate.fields.forEach((field) => {
        if (!field.fields) {
          if (field.name) {
            returnValue[field.name] = returnValue[field.name] ?? "";
          }
        } else {
          const newReturnValue = updateInitialGroupValue(field, {});
          if (field.typeGroup === "main") {
            returnValue[field.name] = returnValue[field.name] ?? structuredClone(newReturnValue);
          } else if (field.typeGroup === "partial") {
            returnValue = Object.assign(returnValue, newReturnValue);
          } else {
            initialGroupValue[field.name] = initialGroupValue[field.name] ?? [newReturnValue];
          }
        }
      });

      return returnValue;
    };

    initialGroupValue = updateInitialGroupValue(props.fieldTemplateMain, initialGroupValue);
    if (props.currentNameArray.length === 0) {
      props.fieldTemplateMain.fields?.forEach((field) => {
        if (field.name)
          props.formDynamic.setFieldValue(
            field.name,
            props.fieldTemplateMain.invisible ? "" : initialGroupValue[field.name]
          );
      });
    } else {
      props.formDynamic.setFieldValue(currentGroupName, props.fieldTemplateMain.invisible ? {} : initialGroupValue);
    }
  }, [props.fieldTemplateMain.invisible]);

  // const getDataInSelect = (index) => {
  //     if (!masterSelect || index > masterSelect.masterIndex + masterSelect.numOfDependents || index <= masterSelect.masterIndex)
  //         return null;

  //     return masterSelect.arraySelect ? masterSelect.arraySelect[index - masterSelect.masterIndex - 1] : null;
  // }
  // const getMasterDataName = (index) => {
  //     const fieldTemplate = groupFieldTemplate.fields[index];
  //     return fieldTemplate && fieldTemplate.select && fieldTemplate.select.masterdata ? fieldTemplate.select.masterdata : null;
  // }

  // const handleDataInSelect = (index, selectedValue) => {
  //     if (!masterSelect || index > masterSelect.masterIndex + masterSelect.numOfDependents || index < masterSelect.masterIndex || !props.currentNameArray)
  //         return;

  //     const copyDataInSelect = [...masterSelect.arraySelect];

  //     const getInputTemplateName = (index) => {
  //         const fieldTemplate = groupFieldTemplate.fields[index];
  //         return fieldTemplate && fieldTemplate.name ? fieldTemplate.name : null;
  //     }

  //     if (selectedValue) {
  //         const targetMasterDataName = getMasterDataName(index + 1);
  //         if (targetMasterDataName) {

  //             getLocationData(targetMasterDataName, selectedValue, props.hostNameUrl).then(res => {
  //                 if (!res.isError) {
  //                     let isFirst = false;
  //                     const currentGroupName = props.currentNameArray.join('.');
  //                     for (let i = (index - masterSelect.masterIndex); i < masterSelect.numOfDependents; i++) {
  //                         if (!isFirst) {
  //                             copyDataInSelect[i] = [...res.data];
  //                             isFirst = true;
  //                         } else {
  //                             copyDataInSelect[i] = [];

  //                         }

  //                         if ((index + i + 1) <= (masterSelect.masterIndex + masterSelect.numOfDependents)) {
  //                             const inputTemplateName = getInputTemplateName(index + i + 1);
  //                             if (inputTemplateName)
  //                                 props.formDynamic.setFieldValue(props.currentNameArray.length > 0 ? `${currentGroupName}.${inputTemplateName}` : inputTemplateName, '');
  //                         }

  //                     }

  //                     setMasterSelect((prev) => ({
  //                         ...prev,
  //                         arraySelect: copyDataInSelect
  //                     }))
  //                 }
  //             })
  //         }
  //     }
  // }

  const handleCheckBoxMasterIndex = (valueArray) => {
    if (
      !Array.isArray(valueArray) ||
      groupFieldTemplate.checkboxMasterIndex === undefined ||
      groupFieldTemplate.checkboxControlIndex === undefined ||
      !Array.isArray(groupFieldTemplate.checkboxControlIndex)
    ) {
      return;
    }

    const copyGroupFieldTemplate = { ...groupFieldTemplate };

    if (copyGroupFieldTemplate.fields) {
      copyGroupFieldTemplate.fields = [...copyGroupFieldTemplate.fields].map((fieldTemplate, index) => {
        if (!copyGroupFieldTemplate.checkboxControlIndex.includes(index)) {
          return { ...fieldTemplate };
        }
        const valueFind = valueArray.find((e) => e.controlIndex === index);
        fieldTemplate.invisible = !valueFind;
        return structuredClone(fieldTemplate);
      });
    } else {
      copyGroupFieldTemplate.fields = [];
    }

    setGroupFieldTemplate(copyGroupFieldTemplate);
  };

  const disabledInput = props.disabledInput || props.sectionStatus === FormSectionStatus.APPROVED;

  const contentShown = (
    <Grid columns={12}>
      {groupFieldTemplate?.fields?.map((fieldTemplate, index) => {
        if (!fieldTemplate) {
          return <div key={index}></div>;
        }

        // const funcMap = (any, selectedValue) => handleDataInSelect(index, selectedValue);

        const funcMap2 = groupFieldTemplate.checkboxMasterIndex === index ? handleCheckBoxMasterIndex : undefined;
        return (
          <FormDynamicMainInput
            key={index}
            {...props}
            sectionNote={undefined}
            sectionStatus={props.sectionStatus}
            disabledInput={disabledInput}
            fieldTemplateMain={fieldTemplate}
            handleCheckBoxMasterIndex={funcMap2}
            currentNameArray={
              fieldTemplate.name ? [...props.currentNameArray, fieldTemplate.name] : [...props.currentNameArray]
            }
          />
        );
      })}
    </Grid>
  );
  if (!groupFieldTemplate || groupFieldTemplate.invisible) {
    return <></>;
  }

  if (!groupFieldTemplate) {
    return (
      <Stack justify={"center"} align={"center"} style={{ height: "100%" }} spacing={20}>
        <Loader color={props.primaryColor} />
        <Text fw={700} c={props.primaryColor}>
          Đang tải...
        </Text>
      </Stack>
    );
  }

  const sectionNote = props.sectionNote ? (
    <Group
      position={"left"}
      noWrap={true}
      w={"100%"}
      p={8}
      style={{
        color: "red",
        fontWeight: 700,
        fontSize: 20,
        borderRadius: 8,
        background: "rgba(255, 0, 0, 0.1)",
      }}
    >
      {props.sectionNote}
    </Group>
  ) : (
    <></>
  );

  const descriptionJSX = getDescriptionStyleJSX(groupFieldTemplate);

  return !groupFieldTemplate.isBorderless ? (
    <>
      <Stack
        p={24}
        spacing={20}
        style={{
          marginTop: 40,
          borderRadius: 24,
          backgroundColor: groupFieldTemplate.backgroundColor ?? "transparent",
          border: `1px solid ${variables.primaryColor}`,
          position: "relative",
          paddingTop: 40,
        }}
      >
        <Text
          fw={700}
          fz={props.currentWidth > 800 ? 22 : 18}
          style={{
            position: "absolute",
            top: -20,
            zIndex: 3,
            backgroundColor: "white",
            color: variables.primaryColor,
            borderRadius: 20,
            padding: "0px 20px",
          }}
        >
          {groupFieldTemplate.label}
        </Text>

        <Stack spacing={10} mt={-10}>
          {descriptionJSX}
          {contentShown}
        </Stack>
        {sectionNote}
      </Stack>
    </>
  ) : (
    <>
      <Stack spacing={20}>
        <Stack spacing={10} mt={props.sectionNote && -10}>
          {descriptionJSX}
          {contentShown}
        </Stack>
        {sectionNote}
      </Stack>
    </>
  );
}

export default FormDynamicGroupInput;

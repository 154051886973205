import {
  OfferStatusEnum,
  RecruitmentStageEnum,
  ScheduleMethodEnum,
  ScheduleStatusEnum,
  TalentScheduleStatusEnum,
  showFailureNotification,
} from '@/services/constants';
import { getTalentByCodeRefactor } from '@/services/services';
import {
  Anchor,
  Badge,
  Button,
  Center,
  Group,
  Image,
  List,
  Modal,
  Stack,
  Stepper,
  Text,
  TextInput,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineInfoCircle, AiOutlineLink } from 'react-icons/ai';
import { IoLocationOutline } from 'react-icons/io5';
import { LiaStickyNote } from 'react-icons/lia';
import { MdCancel } from 'react-icons/md';
import { Calendar, Clock } from 'tabler-icons-react';
import { useMediaQuery } from 'usehooks-ts';
import variables from '../../../styles/variables.module.scss';
import Card1 from './card1/card1';
import Card2 from './card2/card2';
import localStyles from './research1.module.scss';

function Research1(props) {
  const refTalentCode = useRef('');
  const [currentTalentCode, setCurrentTalentCode] = useState(null);
  const [currentTalentSchedule, setCurrentTalentSchedule] = useState(null);
  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);
  const [modalInfo, setModalInfo] = useState({ content: <></>, title: <></> });

  const isThreshold = useMediaQuery('(max-width: 1200px)');
  const isMobile = useMediaQuery('(max-width:768px)');
  const modalWidthSize = 800;
  const offerText = props.options ? props.options.offerText : null;
  const badgeText = props.options ? props.options.badgeText : null;

  useEffect(() => {
    if (currentTalentCode) {
      getTalentByCodeRefactor(props.url, currentTalentCode.code).then(
        (talentSchedule) => {
          if (!talentSchedule) {
            showFailureNotification('Không có ứng viên với mã tra cứu này');
            setCurrentTalentSchedule(null);
          } else {
            console.log('check tablen', talentSchedule);
            setCurrentTalentSchedule(talentSchedule);
          }
        }
      );
    } else {
      setCurrentTalentSchedule(null);
    }
  }, [currentTalentCode]);

  const badgeTextFromStatus =
    badgeText && currentTalentSchedule
      ? badgeText[currentTalentSchedule.status]
      : null;
  let color = '#F7B811';
  let defaultText = '';
  switch (currentTalentSchedule?.status) {
    case TalentScheduleStatusEnum.REJECTED:
      color = '#ff5959';
      defaultText = 'Không Đạt Ứng Tuyển';
      break;

    case TalentScheduleStatusEnum.ACCEPTED:
      color = '#41ad49';
      defaultText = 'Đạt Ứng Tuyển';
      break;

    case TalentScheduleStatusEnum.IN_PROGRESS:
    default:
      defaultText = 'Đang Ứng Tuyển';
      break;
  }

  const statusTalentBadgeJSX = (
    <Badge
      styles={(theme) => ({
        root: {
          background: color + '1A',
          color: color,
          fontWeight: 400,
          fontSize: 14,
          lineHeight: 24,
          height: 40,
          borderRadius: 8,
        },
      })}
    >
      {badgeTextFromStatus ? parse(badgeTextFromStatus) : defaultText}
    </Badge>
  );

  const generateModalContentJSX = (mappedRecruitmentPlanStage) => {
    const modalTitle = (
      <Text fw={700} fz={20} c={variables.primaryColor}>
        {mappedRecruitmentPlanStage.stage.name} (Chi tiết)
      </Text>
    );

    let modalContent = <></>;
    switch (mappedRecruitmentPlanStage.stage.type) {
      case RecruitmentStageEnum.OFFER:
        switch (currentTalentSchedule.mappedOfferStatus) {
          case OfferStatusEnum.APPROVED:
            modalContent = (
              <Text>
                {offerText && offerText[OfferStatusEnum.APPROVED]
                  ? parse(offerText[OfferStatusEnum.APPROVED])
                  : 'Offer đã được duyệt thành công. Vui lòng kiểm tra email để biết thêm thông tin!'}
              </Text>
            );
            break;

          case OfferStatusEnum.REJECTED:
            modalContent = (
              <Text>
                {offerText && offerText[OfferStatusEnum.REJECTED]
                  ? parse(offerText[OfferStatusEnum.REJECTED])
                  : 'Offer đã bị từ chối. Vui lòng kiểm tra email để biết thêm thông tin!'}
              </Text>
            );
            break;

          case OfferStatusEnum.IN_PROGRESS:
            modalContent = (
              <Text>
                {offerText && offerText[OfferStatusEnum.IN_PROGRESS]
                  ? parse(offerText[OfferStatusEnum.IN_PROGRESS])
                  : 'Offer đang trong quá trình phê duyệt. Vui lòng kiểm tra lại sau!'}
              </Text>
            );
            break;
          default:
            break;
        }
        break;

      case RecruitmentStageEnum.INTERVIEW:
      case RecruitmentStageEnum.EXAMINATION:
        modalContent = (
          <div
            style={{ maxHeight: 800, overflowY: 'auto', overflowX: 'hidden' }}
          >
            <List type={'ordered'}>
              {mappedRecruitmentPlanStage.schedules.map((schedule, i) => {
                let extraText = '';
                let extraTextColor = '';
                switch (schedule.status) {
                  case ScheduleStatusEnum.PENDING:
                    extraText = 'Chưa diễn ra';
                    extraTextColor = 'blue';
                    break;
                  case ScheduleStatusEnum.DONE:
                    extraText = 'Đạt';
                    extraTextColor = 'green';
                    break;

                  case ScheduleStatusEnum.HAPPENING:
                    extraText = 'Đang diễn ra';
                    extraTextColor = 'purple';
                    break;

                  case ScheduleStatusEnum.APPROVAL:
                    extraText = 'Đang chờ duyệt';
                    extraTextColor = 'yellow';
                    break;

                  case ScheduleStatusEnum.CANCELLED:
                    extraText = 'Đã hủy';
                    extraTextColor = 'red';
                    break;

                  default:
                    extraText = 'Không đạt';
                    extraTextColor = 'red';
                    break;
                }

                let dateJSX = <></>;

                const startDate = dayjs(schedule.startTime).format(
                  'DD/MM/YYYY'
                );
                const endDate = dayjs(schedule.endTime).format('DD/MM/YYYY');

                const startTime = dayjs(schedule.startTime).format('HH:mm');
                const endTime = dayjs(schedule.endTime).format('HH:mm');

                switch (mappedRecruitmentPlanStage.stage.type) {
                  case RecruitmentStageEnum.EXAMINATION:
                    dateJSX = (
                      <Stack spacing={2}>
                        <Group spacing={10}>
                          <Calendar
                            size={24}
                            strokeWidth={1.5}
                            color={variables.primaryColor}
                          />
                          <Text>Ngày bắt đầu: {startDate}</Text>
                        </Group>
                        <Group spacing={10}>
                          <Calendar
                            size={24}
                            strokeWidth={1.5}
                            color={variables.primaryColor}
                          />
                          <Text>Ngày kết thúc: {endDate}</Text>
                        </Group>
                      </Stack>
                    );
                    break;
                  case RecruitmentStageEnum.INTERVIEW:
                    dateJSX = (
                      <Stack spacing={2}>
                        <Group spacing={10}>
                          <Calendar
                            size={24}
                            strokeWidth={1.5}
                            color={variables.primaryColor}
                          />
                          <Text>Ngày phỏng vấn: {startDate}</Text>
                        </Group>
                        <Group spacing={10}>
                          <Clock
                            size={24}
                            strokeWidth={1.5}
                            color={variables.primaryColor}
                          />
                          <Text>
                            Thời gian: {startTime} - {endTime}
                          </Text>
                        </Group>
                      </Stack>
                    );
                    break;

                  default:
                    break;
                }

                let locationJSX = <></>;
                switch (schedule.scheduleMethod) {
                  case ScheduleMethodEnum.Online:
                    locationJSX = (
                      <Group spacing={10}>
                        <AiOutlineLink
                          color={variables.primaryColor}
                          size={24}
                          strokeWidth={1.5}
                        />
                        {schedule.schedulesGroup ? (
                          <Anchor
                            href={`https://meet.neoos.app/${schedule.schedulesGroup}`}
                            target={'_blank'}
                          >
                            Link
                          </Anchor>
                        ) : (
                          <Text>Không có Link</Text>
                        )}
                      </Group>
                    );
                    break;

                  case ScheduleMethodEnum.Onsite:
                  case ScheduleMethodEnum.Offline:
                    locationJSX = (
                      <>
                        <Group spacing={10} align={'start'}>
                          <IoLocationOutline
                            color={variables.primaryColor}
                            size={24}
                            strokeWidth={1.5}
                          />

                          <Text style={{ width: '85%' }}>
                            {schedule.mappedLocation}
                          </Text>
                        </Group>
                      </>
                    );
                    break;

                  default:
                    break;
                }
                return (
                  <List.Item key={i}>
                    <Stack spacing={2}>
                      <Group spacing={5}>
                        <Text fw={700} c={variables.primaryColor}>
                          {schedule.title}
                        </Text>
                        <Text fw={500} c={extraTextColor}>
                          ({extraText})
                        </Text>
                      </Group>
                      <Group spacing={10}>
                        <AiOutlineInfoCircle
                          color={variables.primaryColor}
                          size={24}
                          strokeWidth={1.5}
                        />
                        <Text>Hình thức: {schedule.mappedScheduleMethod}</Text>
                      </Group>
                      {dateJSX}
                      {locationJSX}
                      {schedule.information &&
                      schedule.information.length > 0 ? (
                        <Group spacing={10}>
                          <LiaStickyNote
                            color={variables.primaryColor}
                            size={24}
                            strokeWidth={1.5}
                          />

                          <Text>Ghi chú: {schedule.information}</Text>
                        </Group>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </List.Item>
                );
              })}
            </List>
          </div>
        );
        break;

      default:
        break;
    }

    setModalInfo({ content: modalContent, title: modalTitle });
    openModal();
  };
  const resetModal = () => {
    setModalInfo({ content: <></>, title: <></> });
    closeModal();
  };

  const stepperJSX = (
    <Stepper
      iconSize={48}
      active={currentTalentSchedule?.mappedCurrentStage}
      color={variables.primaryColor}
      breakpoint={1100}
    >
      {currentTalentSchedule?.mappedRecruitmentPlanStages.map(
        (mappedRecruitmentPlanStage, i) => {
          const failedCurrentStage =
            i === currentTalentSchedule?.mappedCurrentStage &&
            (currentTalentSchedule.status ===
              TalentScheduleStatusEnum.REJECTED ||
              currentTalentSchedule.mappedOfferStatus ===
                OfferStatusEnum.REJECTED);

          let descriptionJSX = <></>;

          switch (mappedRecruitmentPlanStage.stage.type) {
            case RecruitmentStageEnum.EXAMINATION:
            case RecruitmentStageEnum.INTERVIEW:
              if (
                i <= currentTalentSchedule.mappedCurrentStage &&
                mappedRecruitmentPlanStage.schedules.length > 0
              ) {
                descriptionJSX = (
                  <Text
                    onClick={() =>
                      generateModalContentJSX(mappedRecruitmentPlanStage)
                    }
                    className={localStyles.research1RefactorDetailButton}
                  >
                    Chi tiết
                  </Text>
                );
              }

              break;

            case RecruitmentStageEnum.TALENT_UPDATE_INFO:
              if (
                i === currentTalentSchedule?.mappedCurrentStage &&
                currentTalentCode &&
                currentTalentCode.code &&
                currentTalentSchedule?.status !==
                  TalentScheduleStatusEnum.REJECTED
              ) {
                descriptionJSX = (
                  <Anchor
                    href={`/collect-form/${currentTalentCode.code}`}
                    target={'_blank'}
                  >
                    Link điền
                  </Anchor>
                );
              }
              break;

            case RecruitmentStageEnum.OFFER:
              if (
                i <= currentTalentSchedule?.mappedCurrentStage &&
                currentTalentSchedule?.offer &&
                currentTalentSchedule?.mappedOfferStatus
              ) {
                descriptionJSX = (
                  <Text
                    onClick={() =>
                      generateModalContentJSX(mappedRecruitmentPlanStage)
                    }
                    className={localStyles.research1RefactorDetailButton}
                  >
                    Chi tiết
                  </Text>
                );
              }
              break;
            default:
              break;
          }

          return (
            <Stepper.Step
              key={i}
              label={
                <Text fw={700}>{mappedRecruitmentPlanStage.stage.name}</Text>
              }
              icon={
                failedCurrentStage ? (
                  <MdCancel size={30} color={variables.primaryColor} />
                ) : (
                  i + 1
                )
              }
              description={descriptionJSX}
            ></Stepper.Step>
          );
        }
      )}
    </Stepper>
  );

  let cardJSX = <></>;
  switch (props.options.cardType) {
    case 'card2':
      {
        cardJSX = (
          <Card2
            maxDescriptionCols={props.options?.maxCols}
            bottomJSX={stepperJSX}
            rightSideJSX={statusTalentBadgeJSX}
            objectData={currentTalentSchedule}
            _jsonata={props.options?.jsonata || '$'}
            placeholder={
              props.defaultImage ? props.defaultImage : props.imageLogo
            }
            descriptionArray={props.options?.descriptionArray || []}
          />
        );
      }
      break;

    default:
      {
        cardJSX = (
          <Card1
            bottomJSX={stepperJSX}
            rightSideJSX={statusTalentBadgeJSX}
            title={currentTalentSchedule?.recruitmentPlan?.name}
            talentBasics={currentTalentSchedule?.talent?.basics}
            placeholder={
              props.defaultImage ? props.defaultImage : props.imageLogo
            }
          />
        );
      }
      break;
  }

  return (
    <>
      <Stack
        spacing={isThreshold ? 24 : 48}
        className={localStyles.research1Refactor}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: isThreshold ? 'column-reverse' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: isThreshold ? 0 : 72,
            paddingRight: isThreshold ? 0 : 72,
            marginBottom: isThreshold ? 48 : 0,
          }}
        >
          <Stack
            spacing={isThreshold ? 24 : 48}
            align={isMobile ? 'center' : 'flex-start'}
            maw={isMobile ? '100%' : 640}
            style={{ width: '100%', zIndex: 1 }}
            px={isMobile && 20}
          >
            <Stack spacing={24}>
              <Text
                style={{ textAlign: 'left' }}
                fw={700}
                className={localStyles.research1RefactorTitle}
              >
                {props.options.title}
              </Text>
              {props.options.description && (
                <Text className={localStyles.description}>
                   {props.options.description}
                </Text>
              )}
            </Stack>
            <Group spacing={10} position={'center'} style={{ width: '100%' }}>
              <div
                style={{
                  width: isMobile ? '100%' : 640,
                  marginBottom: isMobile ? 50 : 0,
                }}
              >
                <TextInput
                  placeholder={
                    props.options.placeholder ||
                    'Nhập mã ứng tuyển, VD: ABCD-1234'
                  }
                  className={localStyles.textInput}
                  styles={(theme) => ({
                    wrapper: {
                      display: 'flex',
                      flex: '1 1 0',
                    },
                    rightSection: {
                      width: 160,
                      bottom: isMobile ? -120 : 0,
                      right: isMobile ? `calc(50% - 85px)` : 0,
                    },
                  })}
                  onChange={(e) => (refTalentCode.current = e.target.value)}
                  rightSection={
                    <Button
                      className={localStyles.submitButton}
                      radius='md'
                      bg={variables.primaryColor}
                      w={'auto'}
                      onClick={() => {
                        setCurrentTalentCode({ code: refTalentCode.current });
                      }}
                      sx={{
                        '&:focus': {
                          backgroundColor: variables.focusColor,
                          borderColor: variables.primaryColor,
                        },
                        '&:hover': { backgroundColor: variables.hoverColor },
                      }}
                    >
                      Tìm kiếm
                    </Button>
                  }
                />
              </div>
            </Group>
          </Stack>
          {/* ================================================================= */}
          <Image
            alt=''
            src={props.options.image || `${process.env.BASE_PATH ? `${process.env.BASE_PATH}/images/img_research.svg` : "/images/img_research.svg"}`}
            width={isMobile ? 320 : 460}
            height={isMobile ? 320 : 460}
            fit='fill'
          />
        </div>

        {props.options?.images?.map((image, index) => {
          return (
            <Image
              key={index}
              alt={image.alt || ''}
              src={image.src}
              style={image.style}
            />
          );
        })}

        {/* 
                <Image alt="" src={"/images/cloud.png"} style={{
                    position: 'absolute',
                    width: 450,
                    height: 320,
                    bottom: 0,
                    right: 0,
                    rotate: '180deg',
                }} /> */}
        <Center
          style={!currentTalentSchedule ? { display: 'none' } : { zIndex: 1 }}
          className={localStyles.research1RefactorMainContent}
        >
          {cardJSX}
        </Center>
      </Stack>
      <Modal
        size={modalWidthSize}
        opened={modalOpened}
        onClose={resetModal}
        title={modalInfo.title}
      >
        {modalInfo.content}
      </Modal>
    </>
  );
}

export default Research1;

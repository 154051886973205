import { Flex, Text, Image} from '@mantine/core';
import variables from "../../styles/variables.module.scss";

export default function MCard(props) {
    console.log(props);
    switch(props.display) {
        case "type1":
            // Loại card gồm title và subtitle
            // props: align, spacing
            return <Card1 options={props.options}/>;
        case "type2":
            // Loại card có hình ảnh và title, subtitle ở dưới
            return <Card2 options={props.options}/>;
        default:
            return <></>;
    }
}

function Card1({options}) {
    return (
        <Flex px={{base: 24, md: options.px ?? 0}} py={{base: 16, md: options.py ?? 0}} direction="column" align="center" justify={options.justify ?? "center"}>
            <Text c={variables.primaryColor} fz={{base: 24, md: 48}} fw={700}>{options.title}</Text>
            <Text fz={{base: 16, md: 24}} fw={400}>{options.subtitle}</Text>
        </Flex>
    )
}

function Card2({options}) {
    return (
        <Flex direction="column" align="center" gap={16}>
            <Image src={options.src} alt="" width={options.width ?? 350} height={options.height ?? 350} fit={options.fit} radius={options.radius ?? 8} mx="auto"/>
            <Flex direction="column" gap={8}>
                <Text fz={24} fw={700}>{options.title}</Text>
                <Text fz={16} fw={400} c="gray">{options.subtitle}</Text>
            </Flex>
        </Flex>
    )
}
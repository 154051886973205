import Feature from "@/components/feature";
import { Stack } from "@mantine/core";
import { useState } from "react";
import { useMediaQuery } from "usehooks-ts";

export default function SearchHub2({
  options: { searchComponent, components },
  url,
  windowDimension,
  primaryColor,
  secondaryColor,
  defaultImage,
  noDataText,
  backgroundColor,
}) {
  const [dynamicFilters, setDynamicFilters] = useState([]);
  const [sortOption, setSortOption] = useState(null);
  const isThreshold = useMediaQuery("(max-width: 1440px)");

  let handleSearch = () => {};

  let searchComponentJSX = <></>;
  let componentJSX = <></>;

  if (searchComponent) {
    if (searchComponent.component === "search")
      switch (searchComponent.display) {
        case "search3":
          handleSearch = (filterArray, sortOption) => {
            setDynamicFilters([...filterArray]);
            setSortOption(sortOption);
          };

        default:
          break;
      }
    searchComponentJSX = (
      <div style={!isThreshold ? searchComponent.options?.style : searchComponent.options?.altStyle}>
        <Feature
          url={url}
          display={searchComponent.display}
          component={searchComponent.component}
          options={{ handleSearch: handleSearch, ...searchComponent.options }}
          windowDimension={windowDimension}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          defaultImage={defaultImage}
          noDataText={noDataText}
          backgroundColor={backgroundColor}
        />
      </div>
    );
  }

  if (Array.isArray(components)) {
    componentJSX = components.map((component, index) => {
      return (
        <div
          key={index}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: component.options?.background,
          }}
        >
          <div style={!isThreshold ? searchComponent.options?.style : searchComponent.options?.altStyle}>
            <Feature
              url={url}
              display={component.display}
              component={component.component}
              options={{
                dynamicFilters: component.type === "dynamic" ? dynamicFilters : [],
                ...component.options,
                sortOption: component.type === "dynamic" ? sortOption : null,
              }}
              windowDimension={windowDimension}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              defaultImage={defaultImage}
              noDataText={noDataText}
              backgroundColor={backgroundColor}
            />
          </div>
        </div>
      );
    });
  }

  return (
    <Stack
      spacing={0}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {searchComponentJSX}
      {componentJSX}
    </Stack>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import Research1 from "@/components/research/research1/research1";
export default function Research(props) {
    let eleHTML = <></>;
    switch (props.display) {
        case "research1":
            eleHTML = <Research1 {...props} />
            break;


        default:
            break;
    }
    return eleHTML;
}

import variables from '@/styles/variables.module.scss';
import { Group, Text, TextInput, Textarea } from "@mantine/core";
import { useEffect, useRef, useState } from 'react';
import { Mail } from "tabler-icons-react";
import styles from "../../styles/dynamic-form.module.scss";
import { getDescriptionStyleJSX } from "../nonPrimitiveType/formDynamicInput";

export default function FormDynamicInputTextInput(props) {
    let eleHTML = <></>;
    switch (props.inputTemplate.type) {
        case "text":
        case "email":
        case "phone":
        case "number":
            eleHTML = <FormDynamicInputTextInputHelper {...props} />;
            break;

        case "textarea":
            eleHTML = <FormDynamicInputTextAreaHelper {...props} />;
            break;
        default:
            break;
    }

    return eleHTML;
}

function FormDynamicInputTextInputHelper(props) {
    const inputTemplate = { ...props.inputTemplate };
    const inputProps = { ...props.formInputProps };
    const [oldInvisible, setOldInvisible] = useState(false);
    const textValueRef = useRef(inputProps.value);

    const inputRef = useRef(null);
    const [actualTextValue, setActualTextValue] = useState(inputProps.value || "");

    useEffect(() => {
        if (inputTemplate.invisible) {
            setOldInvisible(true);
            inputProps.onChange(undefined);
        } else if (oldInvisible) {
            setOldInvisible(false);
            inputProps.onChange(props.initialValue);
        }

    }, [inputTemplate.invisible]);

    useEffect(() => {
        if (props.formInputProps && props.formInputProps.onChange) {
            props.formInputProps.onFocus();
            props.formInputProps.onBlur();
            props.formInputProps.onChange(actualTextValue);
        }
    }, [actualTextValue])

    useEffect(() => {
        if (!inputRef || !inputRef.current) {
            return;
        }
        inputRef.current.value = props.formInputProps.value || "";
        textValueRef.current = props.formInputProps.value || ""

        if (props.formInputProps.value === undefined && !inputTemplate.invisible && !props.initialValue) {
            props.formInputProps.onChange("");
        }
    }, [props.formInputProps.value]);

    const descriptionJSX = getDescriptionStyleJSX(inputTemplate);


    return <TextInput className={styles.formDynamicTextDateSelectInput}
        iconWidth={48}
        ref={inputRef}
        icon={<Mail strokeWidth={1.5} size={16} stroke={'white'} />}
        description={descriptionJSX}
        type={inputTemplate.type}
        withAsterisk={props.requiredInput}
        onKeyDown={evt => {
            if (inputTemplate.type === "number") {
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
            }
        }}

        onChange={(e) => {
            textValueRef.current = e.target.value;
        }}
        error={inputProps.error}
        onBlur={() => {
            setActualTextValue(textValueRef.current)
        }}
        placeholder={inputTemplate.placeholder ? inputTemplate.placeholder : inputTemplate.label}
        label={inputTemplate.labelNoWrap ?
            <span
                style={{ textWrap: "nowrap" }}>{inputProps.label || inputTemplate.label}</span> : inputProps.label || inputTemplate.label}
        disabled={props.disabledInput || inputProps.disabled}
    />
}

function FormDynamicInputTextAreaHelper(props) {
    const inputTemplate = props.inputTemplate;
    const inputProps = props.formInputProps;
    const [oldInvisible, setOldInvisible] = useState(false);

    const textValueRef = useRef(inputProps.value);
    const [actualTextValue, setActualTextValue] = useState(inputProps.value);
    const inputRef = useRef(null);


    useEffect(() => {
        if (props.formInputProps && props.formInputProps.onChange) {
            props.formInputProps.onFocus();
            props.formInputProps.onBlur();
            props.formInputProps.onChange(actualTextValue);
        }
    }, [actualTextValue])

    useEffect(() => {
        if (inputTemplate.invisible) {
            setOldInvisible(true);
            inputProps.onChange(undefined);

        } else if (oldInvisible) {
            setOldInvisible(false);
            inputProps.onChange(props.initialValue)
        }
    }, [inputTemplate.invisible]);

    useEffect(() => {
        if (!inputRef) {
            return;
        }
        inputRef.current.value = props.formInputProps.value || "";
        textValueRef.current = props.formInputProps.value || ""

        if (props.formInputProps.value === undefined && !inputTemplate.invisible && !props.initialValue) {
            props.formInputProps.onChange("");
        }
    }, [props.formInputProps.value]);


    return <Textarea
        ref={inputRef}
        className={styles.formDynamicTextDateSelectInput}
        placeholder={inputTemplate.placeholder || inputTemplate.label}
        rows={inputTemplate.autoSize?.minRows || 2}
        label={
            <Group spacing={4}>
                <Text>{inputTemplate.labelNoWrap ?
                    <span style={{ textWrap: "nowrap" }}>{inputTemplate.label}</span> : inputTemplate.label}</Text>
                {/* {props.requiredInput ? <></> : <Text fz={variables.dynamicFormLabelSize}>{"(không bắt buộc)"}</Text>} */}
            </Group>
        }
        radius="lg"
        withAsterisk={props.requiredInput}
        onChange={(e) => {
            textValueRef.current = e.target.value;
        }}
        error={inputProps.error}
        onBlur={() => {
            setActualTextValue(textValueRef.current)
        }}
        disabled={props.disabledInput || inputProps.disabled}
    />
}

import { Group, Image, SimpleGrid, Stack, Text, Tooltip } from '@mantine/core';
import dayjs from "dayjs";
import { Calendar, GenderBigender, Mailbox, Phone } from 'tabler-icons-react';
import variables from '../../../../styles/variables.module.scss';
import ImagePlaceholder from "../../../imagePlaceholder/imagePlaceholder";
import localStyles from './card1.module.scss';

function Card1({ rightSideJSX, bottomJSX, placeholder, talentBasics, title }) {
    return (
        <Stack className={localStyles.research1RefactorCard} spacing={20}>
            <Stack spacing={10}>
                <Stack spacing={0}>
                    <Group position={'right'}>
                        {rightSideJSX}
                    </Group>
                    <Group position={"center"}>
                        <Text c={variables.primaryColor} ta={"center"} fw={700}
                            className={localStyles.research1RefactorCardTitle}>Thông tin ứng viên</Text>

                    </Group>
                </Stack>

                <div className={localStyles.research1RefactorCardInfo}>
                    <Image withPlaceholder
                        alt={"image"}
                        placeholder={<ImagePlaceholder display={"imagePlaceholder1"}
                            image={placeholder} />}
                        width={300}
                        height={260}
                        radius={32}
                        src={talentBasics?.image}
                    />

                    <Stack spacing={32} style={{ justifyContent: "center" }}>
                        <Text fw={700} fz={24} c={variables.primaryColor} lineClamp={1}>
                            {talentBasics?.name}
                        </Text>
                        <SimpleGrid cols={2} spacing={36}
                            breakpoints={[{ maxWidth: 1100, spacing: 10, cols: 1 }]}>
                            <Group spacing={24}>
                                <Calendar
                                    size={24}
                                    strokeWidth={1.5}
                                    color={variables.primaryColor}
                                />
                                <Text>
                                    {dayjs(talentBasics?.dob).format("DD/MM/YYYY")}
                                </Text>
                            </Group>
                            <Group spacing={24}>
                                <GenderBigender
                                    size={24}
                                    strokeWidth={1.5}
                                    color={variables.primaryColor}
                                />
                                <Text>
                                    {["male", "M"].includes(talentBasics?.gender)
                                        ? "Nam"
                                        : ["female", "F"].includes(talentBasics?.gender)
                                            ? "Nữ"
                                            : "Khác"}
                                </Text>
                            </Group>
                            <Group spacing={24} noWrap>
                                <Mailbox
                                    size={24}
                                    strokeWidth={1.5}
                                    color={variables.primaryColor}
                                />
                                <Tooltip label={talentBasics?.email}>
                                    <Text lineClamp={1} style={{ width: "calc(100% - 48px)" }}>{talentBasics?.email}</Text>
                                </Tooltip>
                            </Group>
                            <Group spacing={24}>
                                <Phone
                                    size={24}
                                    strokeWidth={1.5}
                                    color={variables.primaryColor}
                                />
                                <Text>{talentBasics?.phone}</Text>
                            </Group>
                        </SimpleGrid>
                    </Stack>

                </div>
            </Stack>

            <Group position={"left"}>
                <Text c={variables.primaryColor} fw={700}
                    fz={20}>{title}</Text>
            </Group>

            {bottomJSX}
        </Stack>
    )
}

export default Card1
import { getFormQuickApplyTemplateApi } from "@/services/formDynamicApis";
import { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import FormDynamic from "../formDynamic/formDynamic";

export default function ModalContent(props) {
  const [pageCount, setPageCount] = useState(0);

  const alertOnce = useRef(true);
  const onScroll = (e) => {
    const element = document.getElementById("document-end");

    if (element) {
      const { top, bottom } = element.getBoundingClientRect();
      const isVisible = top >= 0 && bottom <= window.innerHeight;
      if (isVisible && alertOnce.current) {
        alertOnce.current = false;
        if (props.setPdfScrolled) {
          props.setPdfScrolled(true);
        }
      }
    }
  };

  useEffect(() => {
    if (props.display === "modal-content2") {
      const element = document.getElementsByClassName("mantine-Modal-body")[0];
      element && (element.onscroll = onScroll);
    }

    return () => {
      if (props.display === "modal-content2") {
        const element = document.getElementsByClassName("mantine-Modal-body")[0];
        element && (element.onscroll = undefined);
      }
    };
  }, []);

  let eleHTML = <></>;
  switch (props.display) {
    case "modal-content1":
      eleHTML = (
        <FormDynamic
          display={"form-dynamic2"}
          windowDimension={{ height: 0, width: props.contentWidthSize }}
          hostNameUrl={props.url}
          useDefaultValue={false}
          channelType={props.channelType}
          getFormTemplateApi={getFormQuickApplyTemplateApi}
          recruitmentPlanId={props.recruitmentPlanId}
          primaryColor={props.primaryColor}
          secondaryColor={props.secondaryColor}
          closeModal={props.closeModal}
        />
      );
      break;

    case "modal-content2":
      {
        const options = {
          useSystemFonts: true,
        };
        pdfjs.GlobalWorkerOptions.workerSrc = new URL(
          "pdfjs-dist/build/pdf.worker.min.mjs",
          import.meta.url
        ).toString();
        const onDocumentLoadSuccess = ({ numPages }) => {
          setPageCount(numPages);
        };

        eleHTML = (
          <>
            <Document file={props.pdfFileUrl} options={options} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(pageCount), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={900}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              ))}
            </Document>
            <div id={"document-end"}></div>
          </>
        );
      }
      break;
    default:
      break;
  }

  return eleHTML;
}

import IconComponent from '@/components/helpers/iconComponent/iconComponent';
import { Group, Stack, Text } from '@mantine/core';
import jobCard3Styles from './jobCard3.module.scss';
function JobCard3({ job: { name, salary, location, date, profession, tags, defaultText } }) {
    const isHotJob = tags?.includes('Hot Job');
    return (
        <div className={jobCard3Styles.jobCard3}>
            <Text lineClamp={3} className={jobCard3Styles.title}>
                {name}
            </Text>

            <Stack spacing={8}>

                <Group spacing={16} align='center'>

                    <Group spacing={4} align='center'>
                        <IconComponent name="dollar" size={16} />
                        <Text>{salary}</Text>
                    </Group>

                    <Group spacing={4} align='center'>
                        <IconComponent name="location-alt" size={16} />
                        <Text>{location}</Text>
                    </Group>
                </Group>

                <Group spacing={16} align='center'>

                    <Group spacing={4} align='center'>
                        <IconComponent name="clock" size={16} />
                        <Text>{date}</Text>
                    </Group>


                    {isHotJob && <Group spacing={4} align='center'>
                        <IconComponent name="flame" color="#EA3223" size={16} />
                        <Text c={"#EA3223"}>Hot Job</Text>
                    </Group>}

                </Group>


                <Group spacing={4} align='center' style={{ width: '100%' }}>
                    <IconComponent name="tags" size={16} color="white" stroke="var(--primaryColor)" />
                    <Text>{profession}</Text>
                </Group>

            </Stack>

        </div>
    )
}


export default JobCard3

/* eslint-disable react-hooks/exhaustive-deps */
import MCard from "@/components/card/card";
import ImagePlaceholder from "@/components/imagePlaceholder/imagePlaceholder";
import CoreValueList from "@/components/list/coreValueList/coreValueList";
import JobList from "@/components/list/jobList/jobList";
import List8R from "@/components/list/list8R";
import ModalContent from "@/components/modal/modalContent";
import useTrans from "@/hooks/useTrans";
import {
  Box,
  Button,
  Card,
  Center,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  Modal,
  ScrollArea,
  Select,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { openModal } from "@mantine/modals";
import dayjs from "dayjs";
import parse from "html-react-parser";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getDataFromCollection, getMasterdata } from "services/services";
import {
  ChevronLeft,
  Clock,
  CurrencyDollar,
  Location,
  User,
} from "tabler-icons-react";
import styles from "../../styles/feature.module.scss";
import Action from "../action/action";
import { List5R } from "./list5";
import variables from "/styles/variables.module.scss";

export default function List(props) {
  const trans = useTrans();
  let eleHTML = <></>;

  switch (props.display) {
    // list1 - dạng stack + filter bên phải
    case "list1":
      eleHTML = (
        <List1
          url={props.url}
          optionData={props.options.data}
          optionQuery={""}
          optionTypeChild={props.options.child}
          optionSelectedIds={
            props.options.selectedIds ||
            props.options.hotJobIds ||
            props.options.hotNewsIds
          }
          optionJsonata={props.options.jsonata}
          title={props.options.title}
          defaultImage={props.defaultImage}
        />
      );
      break;

    //list2 - dạng 1 page, list data bên trái và hiển thị detail bên phải
    case "list2":
      eleHTML = (
        <List2
          url={props.url}
          optionData={props.options.data}
          optionQuery={""}
          optionTypeChild={props.options.child}
          optionSelectedIds={
            props.options.selectedIds ||
            props.options.hotJobIds ||
            props.options.hotNewsIds
          }
          optionJsonata={props.options.jsonata}
          defaultImage={props.defaultImage}
        />
      );
      break;

    //list3 - list dạng stack với image ~ list articles
    case "list3":
      eleHTML = (
        <List3
          title={props.options.title}
          url={props.url}
          optionData={props.options.data}
          optionQuery={""}
          imageLogo={props.image}
          optionSelectedIds={
            props.options.selectedIds ||
            props.options.hotJobIds ||
            props.options.hotNewsIds
          }
          optionTypeChild={props.options.child}
          optionTitle={props.options.title}
          optionJsonata={props.options.jsonata}
          windowDimension={props.windowDimension}
          useDefault={props.options.useDefault}
          defaultImage={props.defaultImage}
        />
      );
      break;

    //list4 - Feature list in /introduction
    case "list4":
      eleHTML = (
        <List4R
          {...props.options}
          background={props.background}
          windowDimension={props.windowDimension}
          defaultImage={props.defaultImage}
        />
      );
      break;

    //List5 - List cho giá trị cốt lõi trong /introduction
    case "list5":
      eleHTML = (
        <List5R
          {...props.options}
          windowDimension={props.windowDimension}
          defaultImage={props.defaultImage}
        />
      );
      break;

    //List6 - list2 version2
    case "list6":
      eleHTML = (
        <List6R
          url={props.url}
          optionData={props.options.data}
          optionQuery={""}
          optionTypeChild={props.options.child}
          optionSelectedIds={
            props.options.selectedIds ||
            props.options.hotJobIds ||
            props.options.hotNewsIds
          }
          optionJsonata={props.options.jsonata}
          windowDimension={props.windowDimension}
          primaryColor={props.primaryColor}
          secondaryColor={props.secondaryColor}
          defaultImage={props.defaultImage}
        />
      );

      break;

    //List7- list cho /news
    case "list7":
      eleHTML = (
        <List7R
          url={props.url}
          optionData={props.options.data}
          optionQuery={""}
          imageLogo={props.image}
          optionSelectedIds={
            props.options.selectedIds ||
            props.options.hotJobIds ||
            props.options.hotNewsIds
          }
          optionTypeChild={props.options.child}
          optionTitle={props.options.title}
          optionJsonata={props.options.jsonata}
          windowDimension={props.windowDimension}
          useDefault={props.options.useDefault}
          defaultImage={props.defaultImage}
        />
      );

      break;

    case "list8":
      eleHTML = (
        <List8R
          url={props.url}
          windowDimension={props.windowDimension}
          options={props.options}
          primaryColor={props.primaryColor}
          secondaryColor={props.secondaryColor}
          imageLogo={props.image}
          defaultImage={props.defaultImage}
        />
      );
      break;

    case "jobList":
      eleHTML = (
        <JobList
          url={props.url}
          options={props.options}
          primaryColor={props.primaryColor}
          secondaryColor={props.secondaryColor}
          imageLogo={props.image}
          defaultImage={props.defaultImage}
          hideManagerSection={props.options.hideManagerSection}
        />
      );
      break;

    case "coreValueList":
      eleHTML = (
        <CoreValueList
          options={props.options}
          primaryColor={props.primaryColor}
        />
      );
      break;
    case "list9":
      // dạng list ngang sử dụng card làm item con
      eleHTML = (
        <List9 options={props.options} primaryColor={props.primaryColor} />
      );
      break;
    case "list10":
      // list ngang gồm 2 phần hình ảnh và text (title, content), có thể dùng reverse để đổi vị trí
      eleHTML = <List10 options={props.options} />;
      break;
    case "list11":
      eleHTML = <List11 options={props.options} />;
      break;
    case "list12":
      eleHTML = (
        <List12 title={props.options.title} data={props.options.data} />
      );
      break;
    default:
      break;
  }
  return eleHTML;
}

function loadData(
  loadOptions,
  setDataFunc,
  setFilterDataFunc,
  setDetailFunc,
  setSiteFunc
) {
  const loadDataFunc = async () =>
    await getDataFromCollection(
      loadOptions.url,
      loadOptions.data,
      loadOptions.query,
      loadOptions.jsonata,
      loadOptions.selectedIds,
      loadOptions.useDefault
    );

  const loadSiteFunc = async () => await getMasterdata(loadOptions.url, "site");

  loadDataFunc().then((res) => {
    if (res) {
      if (Array.isArray(res)) {
        setDataFunc(res);
        setFilterDataFunc(res);
        setDetailFunc(res[0]);
      } else {
        setDataFunc([res]);
        setFilterDataFunc([res]);
        setDetailFunc(res);
      }
    }
  });

  loadSiteFunc().then((sites) => {
    if (sites) {
      if (Array.isArray(sites)) {
        setSiteFunc(sites);
      } else {
        setSiteFunc([sites]);
      }
    }
  });
}

function openFormApply(ele) {
  openModal({
    centered: true,
    title: (
      <Text fz={25} fw={500} c={variables.primaryColor}>
        {trans.applyFor} {ele.name}
      </Text>
    ),
    children: <Action display="apply-short" recruitmentPlan={ele.id} />,
  });
}

function List1(props) {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [site, setSite] = useState(); // data cho filter select theo vị trí
  const router = useRouter();

  useEffect(() => {
    loadData(
      {
        url: props.url,
        data: props.optionData,
        query: props.optionQuery,
        jsonata: props.optionJsonata,
        selectedIds: props.optionSelectedIds,
      },
      setData,
      setFilterData,
      () => {},
      setSite
    );
  }, []);
  return (
    <Box
      className={styles.list1}
      sx={() => ({
        padding: "0px 64px",
      })}
    >
      <Stack mb={32}>
        <Text
          className={styles.title}
        >{`${props.title} (${data.length})`}</Text>
        <div className={styles.boxDivider}>
          <Divider size={1} color={variables.primaryColor} />
        </div>
      </Stack>
      <Grid gutter={32}>
        <Grid.Col pt={0} span={8} className={styles.scroll}>
          <ScrollArea style={{ height: "55vh" }} type="never">
            {filterData.map((ele, index) => (
              <Card
                key={`list-${index}`}
                p={36}
                radius={32}
                shadow="sm"
                withBorder
                mb={32}
              >
                <Group align="center" position="apart">
                  <Stack>
                    <Group>
                      <Clock
                        size={24}
                        strokeWidth={1.5}
                        color={"rgba(37, 37, 37, 0.7)"}
                      />
                      <Text>{ele.expiration_date}</Text>
                    </Group>
                    <Link
                      href={`/${router.query.id}/${ele.id}?type_child=${props.optionTypeChild}`}
                      style={{ cursor: "pointer" }}
                    >
                      <Text className={styles.jobName} lineClamp={1}>
                        {ele.name}
                      </Text>
                    </Link>
                    <Group spacing={32}>
                      <Group>
                        <Location
                          size={24}
                          strokeWidth={1.5}
                          color={"rgba(37, 37, 37, 0.7)"}
                        />
                        <Text>{ele.location}</Text>
                      </Group>
                      <Group>
                        <CurrencyDollar
                          size={24}
                          strokeWidth={1.5}
                          color={"rgba(37, 37, 37, 0.7)"}
                        />
                        <Text>{ele.salary}</Text>
                      </Group>
                    </Group>
                  </Stack>
                  <Button
                    className={styles.btnApply}
                    size="lg"
                    onClick={() => {
                      openFormApply(ele);
                    }}
                  >
                    {trans.apply}
                  </Button>
                </Group>
              </Card>
            ))}
          </ScrollArea>
        </Grid.Col>
        <Grid.Col span={4} style={{ background: "#FFF", borderRadius: 32 }}>
          <Card radius={32} p={36}>
            <Stack mb={16}>
              <Text className={styles.subtitle}>{trans.filterByCriteria}</Text>
              <div className={styles.boxDivider}>
                <Divider size={1} color={variables.primaryColor} />
              </div>
            </Stack>
            <Stack>
              <TextInput
                radius="md"
                placeholder={trans.jobName}
                label={trans.jobName}
                onChange={(e) => {
                  setFilterData(
                    data.filter((ele) =>
                      ele.name
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )
                  );
                }}
              />
              <Select
                radius="md"
                placeholder={trans.jobLocation}
                label={trans.jobLocation}
                data={site ? [{ value: "all", label: trans.all }, ...site] : []}
                onChange={(e) => {
                  if (e == "all") {
                    setFilterData(data);
                  } else {
                    const siteSelect = site.find((site) => site.value == e);
                    // const arLocation = data
                    setFilterData(
                      data.filter((ele) => {
                        ele.location == siteSelect.label;
                        const splitLocation = ele.location.split(",");
                        const findLocation = splitLocation.find(
                          (location) => location.trim() == siteSelect.label
                        );
                        return findLocation ? ele : null;
                      })
                    );
                  }
                }}
              />
            </Stack>
          </Card>
        </Grid.Col>
      </Grid>
    </Box>
  );
}

function List2(props) {
  const [filterData, setFilterData] = useState([]);
  const [detail, setDetail] = useState(); // dùng lưu 1 data khi click dùng tại list2

  useEffect(() => {
    loadData(
      {
        url: props.url,
        data: props.optionData,
        query: props.optionQuery,
        jsonata: props.optionJsonata,
        selectedIds: props.optionSelectedIds,
      },
      () => {},
      setFilterData,
      setDetail,
      () => {}
    );
  }, []);

  return (
    <Box
      className={styles.list2}
      sx={{
        padding: "56px 64px 0 64px",
        // minHeight: "calc(100vh - 250px - 96px)",
      }}
    >
      <Group spacing={64} noWrap align="flex-start">
        <div className={styles.list}>
          {filterData.map((ele, index) => (
            <Card
              shadow="md"
              radius={16}
              p={32}
              mb={32}
              key={`list2-${index}`}
              onClick={() => setDetail(ele)}
            >
              <Stack>
                <Text lineClamp={1} className={styles.title}>
                  {ele.name}
                </Text>
                <Group>
                  <Group>
                    <Clock
                      size={24}
                      strokeWidth={1.5}
                      color={"rgba(37, 37, 37, 0.7)"}
                    />
                    <Text>{ele.expiration_date}</Text>
                  </Group>
                  <Group>
                    <Location
                      size={24}
                      strokeWidth={1.5}
                      color={"rgba(37, 37, 37, 0.7)"}
                    />
                    <Text>{ele.locationLabel}</Text>
                  </Group>
                  <Group>
                    <CurrencyDollar
                      size={24}
                      strokeWidth={1.5}
                      color={"rgba(37, 37, 37, 0.7)"}
                    />
                    <Text>{ele.salary}</Text>
                  </Group>
                </Group>
              </Stack>
            </Card>
          ))}
        </div>
        <div className={styles.detail}>
          <Group
            position="apart"
            style={{
              background: `${variables.primaryColor}33`,
              padding: "32px 64px",
            }}
          >
            <Stack>
              <Text className={styles.title}>{detail?.name}</Text>
              <Group>
                <Group>
                  <Clock
                    size={24}
                    strokeWidth={1.5}
                    color={"rgba(37, 37, 37, 0.7)"}
                  />
                  <Text>{detail?.expiration_date}</Text>
                </Group>
                <Group>
                  <Location
                    size={24}
                    strokeWidth={1.5}
                    color={"rgba(37, 37, 37, 0.7)"}
                  />
                  <Text>{detail?.location}</Text>
                </Group>
                <Group>
                  <CurrencyDollar
                    size={24}
                    strokeWidth={1.5}
                    color={"rgba(37, 37, 37, 0.7)"}
                  />
                  <Text>{detail?.salary}</Text>
                </Group>
              </Group>
            </Stack>
            <Button
              className={styles.btnApply}
              onClick={() => {
                openFormApply(detail);
              }}
            >
              {trans.apply}
            </Button>
          </Group>
          <Stack spacing={24} py={32} px={64} bg="white">
            {detail?.description.map((ele, index) => (
              <div key={`description-${index}`}>
                <Text className={styles.subtitle} fz={20} fw={500} mb={16}>
                  {ele.name}
                </Text>
                <Text lh={"32px"}>{parse(ele.content)}</Text>
              </div>
            ))}
          </Stack>
        </div>
      </Group>
    </Box>
  );
}

function List3(props) {
  const [data, setData] = useState([]);
  useEffect(() => {
    loadData(
      {
        url: props.url,
        data: props.optionData,
        query: props.optionQuery,
        jsonata: props.optionJsonata,
        selectedIds: props.optionSelectedIds,
        useDefault: props.useDefault,
      },
      setData,
      () => {},
      () => {},
      () => {}
    );
  }, []);
  if (data.length === 0) {
    return <></>;
  }
  return (
    <Box className={styles.list3}>
      <Stack mb={32}>
        <Text className={styles.title}>{props.title}</Text>
        <div className={styles.boxDivider}>
          <Divider size={1} color={variables.primaryColor} />
        </div>
      </Stack>
      <Stack spacing={44}>
        {data.map((ele, index) => (
          <Link
            key={`link-card3-${index}`}
            href={`${props.optionData}/${ele.id}?type_child=${props.optionTypeChild}`}
          >
            <Group spacing={24} noWrap align="flex-start">
              <Image
                src={ele.image ?? props.defaultImage}
                alt=""
                width={400}
                height={250}
                radius={8}
              />
              <Stack spacing={24} className={styles.containerContent}>
                <Text lineClamp={2} className={styles.name}>
                  {ele.title}
                </Text>
                <Group spacing={24}>
                  <Group style={{ fontStyle: "italic" }}>
                    <Clock size={24} strokeWidth={1.5} color={"gray"} />
                    <Text c={"gray"}>
                      {dayjs(ele.date_created).format("DD/MM/YYYY")}
                    </Text>
                  </Group>
                  <Group style={{ fontStyle: "italic" }}>
                    <User size={24} strokeWidth={1.5} color={"gray"} />
                    <Text c={"gray"}>{ele.author}</Text>
                  </Group>
                </Group>
                <Text lineClamp={6} className={styles.summary}>
                  {ele.excerpt}
                </Text>
              </Stack>
            </Group>
          </Link>
        ))}
      </Stack>
    </Box>
  );
}

function List4R(props) {
  const currentWidth = props.windowDimension.width;
  const divider = Math.min(currentWidth < 1440 ? 1440 / currentWidth : 1, 2.2);

  const dataList = props.dataList.map((dataSingleton, index) => {
    const textJSX = (
      <Text
        fz={16 / divider}
        fw={400}
        color="dimmed"
        lineClamp={props.descriptionLineBreak ? props.descriptionLineBreak : 3}
      >
        {parse(dataSingleton.description)}
      </Text>
    );

    const contentJSX = props.hasTooltip ? (
      <Tooltip
        label={parse(dataSingleton.description)}
        multiline={true}
        color={variables.primaryColor}
        withArrow={true}
      >
        {textJSX}
      </Tooltip>
    ) : (
      textJSX
    );
    return (
      <Stack
        spacing={16 / divider}
        key={`feature-${index}`}
        style={{ position: "relative" }}
      >
        <Group spacing={8 / divider}>
          <Image
            width={36 / divider}
            alt={`icon-feature-${index}`}
            src={dataSingleton.icon}
          />
          <Text fw={700} fz={20 / divider}>
            {dataSingleton.name}
          </Text>
        </Group>
        {contentJSX}
      </Stack>
    );
  });
  return (
    <Stack
      style={{ width: "100%", backgroundColor: props.background }}
      spacing={44 / divider}
    >
      <div></div>
      <Stack spacing={currentWidth < 750 ? 20 : 64 / divider}>
        <Text align="center" fz={32 / divider} fw={700}>
          {props.title}
        </Text>
        <Box
          sx={{ padding: `0px ${currentWidth < 750 ? 20 : 144 / divider}px` }}
        >
          <SimpleGrid
            cols={currentWidth > 1100 && props.dataList.length >= 5 ? 3 : 2}
            spacing="xl"
            verticalSpacing="md"
          >
            {dataList}
          </SimpleGrid>
        </Box>
      </Stack>
      {currentWidth >= 750 ? <div></div> : <></>}
    </Stack>
  );
}

function List6R(props) {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  const currentWidth = props.windowDimension.width;

  useEffect(() => {
    loadData(
      {
        url: props.url,
        data: props.optionData,
        query: props.optionQuery,
        jsonata: props.optionJsonata,
        selectedIds: props.optionSelectedIds,
      },
      setData,
      () => {},
      setDetail,
      () => {}
    );
  }, []);

  const handleCardClick = (cardDetail, index) => {
    setDetail(cardDetail);
    setSelectedIndex(index);
  };

  const displayNoneStyle = { display: "none" };

  const listJSX = data.map((dataSingleton, index) => {
    return (
      <Stack
        onClick={() => handleCardClick(dataSingleton, index)}
        key={`card-${index}`}
        className={styles.card}
        style={
          selectedIndex === index
            ? {
                border: `1px solid ${variables.primaryColor}`,
                boxShadow: "0px 0px 30px rgba(0, 136, 72, 0.2)",
                backgroundColor: "rgba(0, 136, 72, 0.1)",
              }
            : null
        }
        spacing={8}
      >
        <Stack spacing={8}>
          <Group spacing={16}>
            <Text lineClamp={1} className={styles.textTitle}>
              {dataSingleton && dataSingleton.name
                ? dataSingleton.name
                : "Chưa Có"}
            </Text>
            <Group spacing={8} style={displayNoneStyle}>
              <Image src="/images/tags-filled.svg" width={20} alt={""} />
              <Text fz={16} fw={500} style={{ color: "#007AFF" }}>
                Nhà máy
              </Text>
            </Group>
          </Group>
          <div
            style={{
              background: "#F5222D",
              borderRadius: "8px",
              width: "44px",
            }}
          >
            <Text
              fz={12}
              fw={400}
              ta={"center"}
              style={{ padding: "4px", color: "white" }}
            >
              {trans.hotJob}
            </Text>
          </div>
        </Stack>
        <Group spacing={16}>
          <Group spacing={4}>
            <CurrencyDollar size={18} strokeWidth={1.5} />
            <Text fz={14} fw={400}>
              {dataSingleton && dataSingleton.salary
                ? dataSingleton.salary
                : trans.salary}
            </Text>
          </Group>
          <Group spacing={4}>
            <Image src="/images/location-alt.svg" width={16} alt={""} />
            <Text fz={14} fw={400}>
              {dataSingleton && dataSingleton.location
                ? dataSingleton.location
                : trans.noInfo}
            </Text>
          </Group>
          <Group spacing={4} style={displayNoneStyle}>
            <Image src="/images/buildingIcon.svg" width={16} alt={""} />
            <Text fz={14} fw={400}>
              Tại nhà máy
            </Text>
          </Group>
          <Group spacing={4}>
            <Image src="/images/clockIcon.svg" width={16} alt={""} />
            <Text fz={14} fw={400}>
              {dataSingleton ? dataSingleton.expiration_date : trans.noInfo}
            </Text>
          </Group>
        </Group>
      </Stack>
    );
  });

  let detailJSX = <></>;
  if (detail) {
    detailJSX = (
      <>
        <Modal
          overflow={"inside"}
          size={800}
          opened={modalOpened}
          onClose={closeModal}
          title={
            <Group spacing={5}>
              <Text fw={700} fz={16} c={props.primaryColor}>
                {trans.applyFor}
              </Text>
              <Text fw={700} fz={16} c={props.primaryColor}>
                {detail.name}
              </Text>
            </Group>
          }
        >
          <ModalContent
            display={"modal-content1"}
            contentWidthSize={800}
            url={props.url}
            channelType={detail.channelType}
            recruitmentPlanId={detail.id}
            primaryColor={props.primaryColor}
            secondaryColor={props.secondaryColor}
            closeModal={closeModal}
          />
        </Modal>
        <Stack className={styles.detailHeader} spacing={24}>
          <Stack spacing={16}>
            <Text
              fz={currentWidth >= 750 ? 32 : 24}
              fw={700}
              style={{ color: variables.primaryColor }}
            >
              {detail.name}
            </Text>
            <Group spacing={16}>
              <Group spacing={8}>
                <CurrencyDollar size={22} strokeWidth={1.5} />
                <Text className={styles.textHeader}>{detail.salary}</Text>
              </Group>
              <Group spacing={8}>
                <Image src="/images/location-alt.svg" width={20} alt={""} />
                <Text className={styles.textHeader}>{detail.location}</Text>
              </Group>
              <Group spacing={8} style={displayNoneStyle}>
                <Image src="/images/buildingIcon.svg" width={20} alt={""} />
                <Text className={styles.textHeader}>Tại nhà máy</Text>
              </Group>
              <Group spacing={8} style={displayNoneStyle}>
                <Image src="/images/comment-user.svg" width={20} alt={""} />
                <Text className={styles.textHeader}>200 thành viên</Text>
              </Group>

              <Group spacing={8}>
                <Image src="/images/clockIcon.svg" width={16} alt={""} />
                <Text className={styles.textHeader}>
                  {detail.expiration_date}
                </Text>
              </Group>
            </Group>
          </Stack>

          <Group spacing={32}>
            <Button
              onClick={() => openModal()}
              className={styles.buttonPrimary}
            >
              <Text className={styles.textButtonPrimary}>{trans.apply}</Text>
            </Button>
            <Button
              className={styles.buttonSecondary}
              style={{ display: "none" }}
            >
              <Text className={styles.textButtonSecondary}>
                Secondary Button
              </Text>
            </Button>
          </Group>
        </Stack>
        <Stack className={styles.detailBody} spacing={48}>
          <Stack spacing={32} className={styles.detailBodyHeader}>
            <Text fz={22} fw={700}>
              General Info
            </Text>
            <Stack spacing={16}>
              <Group spacing={4}>
                <Text className={styles.detailBodyHeaderText}>Lương:</Text>
                <Text className={styles.detailBodyHeaderText}>
                  {detail.salary}
                </Text>
              </Group>
              <Group spacing={4}>
                <Text className={styles.detailBodyHeaderText}>Lĩnh Vực:</Text>
                <Text className={styles.detailBodyHeaderText}>Công Nghệ</Text>
              </Group>
              <Group spacing={4}>
                <Text className={styles.detailBodyHeaderText}>Trình độ:</Text>
                <Text className={styles.detailBodyHeaderText}>Phổ thông</Text>
              </Group>
              <Group spacing={4}>
                <Text className={styles.detailBodyHeaderText}>Địa chỉ:</Text>
                <Text className={styles.detailBodyHeaderText}>
                  {detail.location}
                </Text>
              </Group>
              <Group spacing={4}>
                <Text className={styles.detailBodyHeaderText}>
                  Giờ làm việc:
                </Text>
                <Text className={styles.detailBodyHeaderText}>
                  8 a.m - 5 p.m
                </Text>
              </Group>
            </Stack>
          </Stack>

          {detail.description.map((descriptionObject, index) => {
            return (
              <Stack spacing={24} key={`description-${index}`}>
                <Text fz={22} fw={700}>
                  {descriptionObject.name}
                </Text>
                <Text className={styles.detailBodyDescriptionText}>
                  {parse(descriptionObject.content)}
                </Text>
              </Stack>
            );
          })}
        </Stack>
      </>
    );
  }

  let eleHTML = <></>;
  if (currentWidth >= 768)
    eleHTML = (
      <Group className={styles.list6} spacing={32}>
        <Stack className={styles.stackList} spacing={16}>
          <Group position={"apart"}>
            <Text fz={16} fw={400}>
              {data.length} {trans.searchResult}
            </Text>
            <Button className={styles.sortBtn}>
              <Image src="/images/sortButton.svg" width={20} alt={""} />
            </Button>
          </Group>

          <Stack className={styles.listCard} spacing={16}>
            {listJSX}
          </Stack>
        </Stack>
        <Stack className={styles.stackDetail}>{detailJSX}</Stack>
      </Group>
    );
  else {
    eleHTML = (
      <List6RHelperComponent
        listJSX={listJSX}
        detailJSX={detailJSX}
        currentWidth={currentWidth}
        dataLength={data.length}
      />
    );
  }
  return eleHTML;
}

function List6RHelperComponent(props) {
  const [isAtDetailPage, setIsAtDetailPage] = useState(false);

  const handleOnClick = () => {
    setIsAtDetailPage(true);
    document.getElementById("topOfPage").scrollIntoView({ behavior: "smooth" });
  };
  const handleOnBack = () => {
    setIsAtDetailPage(false);
  };

  const listContent = (
    <Stack spacing={10} onClick={handleOnClick}>
      {props.listJSX}
    </Stack>
  );

  const eleHTML = (
    <Stack
      spacing={20}
      style={{ padding: 20 }}
      className={styles.list6}
      id="topOfPage"
    >
      <Group position={"apart"}>
        {isAtDetailPage ? (
          <Button
            style={{ backgroundColor: "transparent" }}
            onClick={handleOnBack}
          >
            <ChevronLeft
              color={"black"}
              size={25}
              strokeWidth={2}
              style={{ marginLeft: -10 / (768 / props.currentWidth) }}
            />
          </Button>
        ) : (
          <div></div>
        )}
        <Group position={"center"}>
          <Text fz={16} fw={400}>
            {props.dataLength} {trans.searchResult}
          </Text>
          <Button className={styles.sortBtn}>
            <Image src="/images/sortButton.svg" width={20} alt={""} />
          </Button>
        </Group>
      </Group>
      <Stack spacing={10}>
        {isAtDetailPage ? props.detailJSX : listContent}
      </Stack>
    </Stack>
  );

  return eleHTML;
}

function List7R(props) {
  const [data, setData] = useState([]);
  const currentWidth = props.windowDimension.width;
  const divider = currentWidth < 1440 ? 1440 / currentWidth : 1;

  useEffect(() => {
    loadData(
      {
        url: props.url,
        data: props.optionData,
        query: props.optionQuery,
        jsonata: props.optionJsonata,
        selectedIds: props.optionSelectedIds,
        useDefault: props.useDefault,
      },
      setData,
      () => {},
      () => {},
      () => {}
    );
  }, []);
  if (data.length === 0) {
    return <></>;
  }

  const cardList = data.map((dataSingleton, index) => (
    <Link
      key={`link-card-${index}`}
      href={`${props.optionData}/${dataSingleton.id}?type_child=${props.optionTypeChild}`}
    >
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Card.Section>
          <Image
            src={dataSingleton.image}
            height={200}
            alt={dataSingleton.image}
            withPlaceholder
            placeholder={
              <ImagePlaceholder
                display="imagePlaceholder1"
                height={200}
                image={
                  props.defaultImage ? props.defaultImage : props.imageLogo
                }
              />
            }
          />
        </Card.Section>

        <Group position="center" mt="md" mb="xs">
          <Text lineClamp={1} weight={700}>
            {dataSingleton.title}
          </Text>
        </Group>

        <Group spacing={16} mb="xs">
          <Group spacing={8} style={{ fontStyle: "italic" }}>
            <Clock
              color="gray"
              size={16 / Math.min(divider, 1.2)}
              strokeWidth={1.5}
            />
            <Text fz={16 / Math.min(divider, 1.2)} fw={400} c={"gray"}>
              {dayjs(dataSingleton.date_created).format("DD/MM/YYYY")}
            </Text>
          </Group>
          <Group spacing={8} style={{ fontStyle: "italic" }}>
            <User
              color="gray"
              size={16 / Math.min(divider, 1.2)}
              strokeWidth={1.5}
            />
            <Text fz={16 / Math.min(divider, 1.2)} fw={400} c={"gray"}>
              {dataSingleton.author ?? "Admin"}
            </Text>
          </Group>
        </Group>

        <Text size="sm" color="dimmed" lineClamp={2} h={40}>
          {parse(dataSingleton.excerpt || "")}
        </Text>
      </Card>
    </Link>
  ));

  let numPerRow = 3;

  if (currentWidth <= 1000) numPerRow = 2;
  if (currentWidth <= 700) numPerRow = 1;

  let optionalTitle = <></>;

  if (currentWidth <= 720)
    optionalTitle = (
      <Stack spacing={10}>
        <Text align={"center"} fz={30} c={variables.primaryColor} fw={700}>
          {props.optionTitle}
        </Text>
        <Center>
          <div style={{ width: "70%" }}>
            <Divider size={1} color={variables.secondColor} />
          </div>
        </Center>
      </Stack>
    );
  else
    optionalTitle = (
      <Stack spacing={10}>
        <Text align={"left"} fz={48} c={variables.primaryColor} fw={700}>
          {props.optionTitle}
        </Text>
        <div style={{ width: "10%" }}>
          <Divider size={1} color={variables.secondColor} />
        </div>
      </Stack>
    );

  const eleHTML = (
    <Box className={styles.list7} style={{ padding: `${44 / divider}px` }}>
      <Stack spacing={30}>
        {optionalTitle}
        <SimpleGrid cols={numPerRow} spacing="md">
          {cardList}
        </SimpleGrid>
      </Stack>
    </Box>
  );
  return eleHTML;
}

function List9(props) {
  return (
    <Flex direction="column" gap={32}>
      {props.options.title && (
        <Text
          fz={48}
          fw={700}
          c={variables.primaryColor}
          ta={props.options.titleAlign ?? "center"}
        >
          {props.options.title}
        </Text>
      )}
      <Flex
        direction={{ base: "column", md: "row" }}
        gap={{ base: 24, md: 72 }}
        align="center"
        wrap={props.options.wrap ?? "wrap"}
        justify={props.options.justify ?? "center"}
        p={{ base: 24, md: 48 }}
      >
        {props.options.data.map((item, index) => (
          <MCard display={props.options.cardType} options={item} key={index} />
        ))}
      </Flex>
    </Flex>
  );
}

function List10({ options }) {
  return (
    <Flex direction="column" gap={32}>
      {options?.title && (
        <Text
          fz={48}
          fw={700}
          c={variables.primaryColor}
          ta={options.titleAlign ?? "center"}
        >
          {options.title}
        </Text>
      )}
      {options?.data.map((item, index) => (
        <Flex
          key={index}
          maw={item.maw ?? "100%"}
          mx="auto"
          direction={item.reverse ? "row-reverse" : "row"}
          wrap="nowrap"
          gap={{ base: 24, md: 72 }}
          align="center"
          justify="center"
          p={{ base: 24, md: 48 }}
        >
          <Image
            src={item.image}
            alt=""
            radius={24}
            height={item.imageSize.height}
            width={item.imageSize.width}
          />
          <Stack justify="center">
            <Text
              fz={32}
              fw={700}
              c={variables.primaryColor}
              ta={item.reverse ? "right" : "left"}
            >
              {item.title}
            </Text>
            <Text
              fz={16}
              fw={400}
              c={"gray"}
              ta={item.reverse ? "right" : "left"}
            >
              {item.description}
            </Text>
          </Stack>
        </Flex>
      ))}
    </Flex>
  );
}

function List11({ options }) {
  return (
    <Flex direction="column" gap={24} py={44} px={48}>
      {options?.title && (
        <Text
          fz={48}
          fw={700}
          c={variables.primaryColor}
          ta={options.titleAlign ?? "center"}
        >
          {options.title}
        </Text>
      )}
      <Box maw={options.descriptionAlignMaw ?? "100%"} mx="auto">
        {options?.description && (
          <Text fz={16} fw={400} ta={options.descriptionAlign ?? "center"}>
            {options.description}
          </Text>
        )}
      </Box>
      <Stack spacing={16}>
        {options?.heading && (
          <Text
            fz={40}
            fw={700}
            c={variables.primaryColor}
            fs={options.headingStyle ?? "italic"}
            ta={options.headingAlign ?? "center"}
          >
            {options.heading}
          </Text>
        )}
        {options?.text && (
          <Text fz={16} fw={400} ta={options.textAlign ?? "center"}>
            {options.text}
          </Text>
        )}
        {/* <Flex direction={{base: "column", md: "row"}} gap={24} justify="space-between" align="center" style={{background: "#8BABFE", borderRadius: 32}} p={32}> */}
        <SimpleGrid
          spacing="md"
          breakpoints={[
            { minWidth: "sm", cols: 2 },
            { minWidth: "md", cols: 3 },
            { minWidth: "lg", cols: options?.data.length },
          ]}
          style={{ background: variables.primaryColor, borderRadius: 32 }}
          p={32}
        >
          {options?.data.map((item, index) => (
            <Stack key={index} justify="center" align="center" spacing={16}>
              <Text fz={48} fw={700} c={"white"}>
                {item.number}
              </Text>
              <Text
                fz={16}
                fw={400}
                ta={item.reverse ? "right" : "left"}
                c={"white"}
              >
                {item.text}
              </Text>
            </Stack>
          ))}
        </SimpleGrid>
        {/* </Flex> */}
      </Stack>
    </Flex>
  );
}

function List12(props) {
  const dataList = props.data;

  const titleStyle = {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "66px",
    letterSpacing: 0,
    textAlign: "left",
    color: variables.primaryColor,
  };

  const nameStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "inherit",
    lineHeight: 1.55,
    textDecoration: "none",
    color: "rgb(57, 67, 179)",
    fontWeight: 800,
  };

  return (
    <Stack p={{ base: 48, md: 64 }} spacing={0}>
      <Text style={titleStyle} mb={10}>
        {props.title}
      </Text>
      <SimpleGrid cols={2}>
        {dataList.map((data, index) => {
          return (
            <Flex
              key={index}
              value={data.name}
              align={"center"}
              justify={"flex-start"}
              gap={20}
              mb={10}
            >
              <Image
                src="/icons/home-location-decathlon.svg"
                width={50}
                alt="home"
                color={variables.primaryColor}
              />
              <div>
                <Text style={nameStyle}>{data.name}</Text>
                <Text>{data.address}</Text>
              </div>
            </Flex>
          );
        })}
      </SimpleGrid>
    </Stack>
  );
}

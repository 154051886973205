import { Pagination } from '@mantine/core'

function _Pagination({ activePage, activeLimit, setActivePage, primaryColor, dataLength }) {
    return (
        <Pagination page={activePage} onChange={setActivePage} color={primaryColor} siblings={1} boundaries={1}
            total={dataLength} radius={"xl"}
            styles={(theme) => ({
                item: {
                    '&[data-active]': {
                        backgroundColor: primaryColor
                    },
                },
            })} />
    )
}

export default _Pagination
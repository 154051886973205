import React from 'react'
import FormDynamic1 from "@/components/formDynamic/formDynamic1";
import FormDynamic2 from "@/components/formDynamic/formDynamic2";

function FormDynamic(props) {
    console.log('props', props);
    let eleHTML = <></>;

    switch (props.display) {
        case "form-dynamic1":
            eleHTML =
                <FormDynamic1 {...props}/>
            break;


        case "form-dynamic2":
            eleHTML = <FormDynamic2 {...props}/>;
            break;

        default:
            break;
    }
    return eleHTML;
}


export default FormDynamic;

import { Group, Image, Stack, Text } from "@mantine/core";
import Link from "next/link";
import { MdOutlineMail } from "react-icons/md";
import { PiGlobeHemisphereWest } from "react-icons/pi";
import { TbHome2, TbPhoneCall } from "react-icons/tb";
import componentStyles from "./footer2.module.scss";
function Footer2({
  primaryColor,
  options: { socialMedias, address, phone, website, email, background },
  logo,
  logoHref,
}) {

  const capitalizeFirstLetter = (str) => {
    if (!str || typeof str !== 'string' || str.length === 0) {
      return ''
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div
      className={componentStyles.footer2}
      noWrap
      style={{ background: background || "white", justifyContent: 'center' }}
    >
      <Link href={logoHref} target="_blank">
        <Image width={256} height={112} alt="logo" src={logo} fit="contain" />
      </Link>
      <div className={componentStyles.verticalDivider} />
      <Stack className={componentStyles.footer2Group} spacing={16}>
        <Group spacing={4} style={{ width: "100%" }} align="start" noWrap>
          <TbHome2 size={16} color={primaryColor} style={{ marginTop: 5 }} />
          <Text className={componentStyles.text} style={{ width: "95%" }}>
            {address}
          </Text>
        </Group>

        <div className={componentStyles.textGroup}>
          <Group
            spacing={4}
            className={componentStyles.textGroupItem}
            align="start"
            noWrap
          >
            <TbPhoneCall
              size={16}
              color={primaryColor}
              style={{ marginTop: 5 }}
            />
            <Text className={componentStyles.text} style={{ width: "95%" }}>
              {phone}
            </Text>
          </Group>
          <Link
            href={logoHref}
            target="_blank"
            className={componentStyles.textGroupItem}
          >
            <Group spacing={4} style={{ width: "100%" }} align="start" noWrap>
              <PiGlobeHemisphereWest
                size={16}
                color={primaryColor}
                style={{ marginTop: 5 }}
              />
              <Text className={componentStyles.text} style={{ width: "95%" }}>
                {website}
              </Text>
            </Group>
          </Link>

          <Link
            href={`mailto:${email}`}
            className={componentStyles.textGroupItem}
          >
            <Group spacing={4}>
              <MdOutlineMail size={16} color={primaryColor} />
              <Text className={componentStyles.text}>{email}</Text>
            </Group>
          </Link>
        </div>
      </Stack>
      <div className={componentStyles.verticalDivider} />
      <div className={componentStyles.socialMediaGroup}>
        {socialMedias.map(({ logo, title, name, link, type }, index) => {
          let imageJSX = <></>;
          switch (type) {
            case "facebook":
              imageJSX = (
                <Image
                  width={16}
                  height={16}
                  alt="logo-social"
                  src={"/images/facebook-icon.png"}
                />
              );
              break;

            case "zalo":
              imageJSX = (
                <Image
                  width={16}
                  height={16}
                  alt="logo-social"
                  src={"/images/zalo-icon.png"}
                />
              );
              break;

            default:
              imageJSX = (
                <Image width={16} height={16} alt="logo-social" src={logo} />
              );
              break;
          }

          return (
            <Link
              href={link}
              target="_blank"
              key={index}
              className={componentStyles.socialMediaItem}
            >
              {imageJSX}
              <Text className={componentStyles.socialMediaText}>
                {capitalizeFirstLetter(title)}
              </Text>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Footer2;

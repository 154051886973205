import { Carousel } from "@mantine/carousel";
import { Card, Group, Image, Stack, Text } from "@mantine/core";
import { loadData } from "components/carousel/carouel";
import NoData from "components/helpers/noData";
import ImagePlaceholder from "components/imagePlaceholder/imagePlaceholder";
import Link from "next/link";
import { useEffect, useState } from 'react';
import styles from "styles/feature.module.scss";
import variables from "styles/variables.module.scss";

function Carousel6(props) {
    const [data, setData] = useState([]);
    const divider = props.windowDimension.width < 1440 ? 1440 / props.windowDimension.width : 1;

    useEffect(() => {
        loadData({
            url: props.url,
            data: props.data,
            query: props.query,
            jsonata: props.jsonata,
            display: props.display,
            selectedIds: props.selectedIds || props.hotJobIds || props.hotNewsIds
        }, setData);
    }, [props.data, props.display, props.jsonata, props.query, props.url]);

    const testData = data;
    const contentJSX = testData.map((ele, index) => {
        const cardJSX = (
            <Link
                href={`/${props.data}/${ele.id}?type_child=${props.child}`}
            >
                <Card
                    sx={{ borderColor: '#FFFFFF' }}
                    radius={32}
                    p={24}
                    m={0}
                    withBorder
                >
                    <Stack spacing={24} style={{ width: 272 }}>
                        <Image
                            radius={32}
                            src={ele.image}
                            withPlaceholder
                            placeholder={<ImagePlaceholder display={"imagePlaceholder1"}
                                image={props.defaultImage ? props.defaultImage : props.imageLogo} />}
                            alt={ele.name}
                            height={240}
                        />

                        <Text
                            className={styles.jobName}
                            align="center"
                            lineClamp={1}
                        >
                            {ele.name}
                        </Text>

                    </Stack>
                </Card>
            </Link>
        );

        if (testData.length < 3)
            return (
                <div key={index}>
                    {cardJSX}
                </div>
            );
        else
            return (
                <Carousel.Slide key={index}>
                    {cardJSX}
                </Carousel.Slide>
            );
    });

    let carouselHTML = <NoData />
    if (contentJSX.length > 0) {
        carouselHTML = contentJSX.length < 3
            ? <Group spacing={25} position={"center"}>
                {contentJSX}
            </Group>
            : <Carousel
                loop={props.hasLoop}
                className={styles.carousel}
                slideSize="25%"
                slideGap={20}
                align="start"
                slidesToScroll={1}
                controlSize={48}
                controlsOffset={0}
                sx={{
                    button: {
                        background: '#FFF',
                        border: "none"
                    },
                    svg: { color: variables.primaryColor, width: "24px", height: "24px" },
                }}
                styles={{ controls: { bottom: -300, right: 0, justifyContent: "center", gap: 50 } }}
            >
                {contentJSX}
            </Carousel>

    }


    const componentCarousel = (
        <Stack style={{ width: '60%' }} spacing={44 / divider}>
            <Text fz={32 / divider} fw={700}>
                {props.title}
            </Text>

            {props.description && props.description.length > 0
                ? <Text color="dimmed" fz={16 / divider}>
                    {props.description}
                </Text>
                : <></>
            }
            {carouselHTML}
        </Stack>
    );

    const componentImage = (
        <>
            <Image width={500 / divider}
                height={500 / divider}
                radius={16 / divider}
                src={props.image}
                alt="photo carousel6"
            />
        </>
    )

    return (
        <Group className={styles.carousel6}
            style={{ width: "100%", padding: `${44 / divider}px ${64 / divider}px` }}
            spacing={70 / divider}
            align="center"
            position={props.isCarouselLeft ? 'left' : 'apart'}
            noWrap>
            {props.isCarouselLeft ? componentCarousel : componentImage}
            {props.isCarouselLeft ? componentImage : componentCarousel}
        </Group>
    )
}

export default Carousel6

import {postTalent} from "services/services";
import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  Select,
  SimpleGrid,
  Slider,
  Stack,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import {DatePicker} from "@mantine/dates";
import {useForm} from "@mantine/form";
import {openContextModal} from "@mantine/modals";
import {showNotification} from "@mantine/notifications";
import "dayjs/locale/vi";
import {useState} from "react";
import {CirclePlus, Minus, SquarePlus, Trash} from "tabler-icons-react";
import styles from "../../styles/feature.module.scss";
import variables from "../../styles/variables.module.scss";

export default function FormPrivate(props) {
  let eleHTML;
  const [visible, setVisible] = useState(false); // biến check loading overlay khi click submit
  const [confirmPolicy, setConfirmPolicy] = useState(false);
  const [checkedPolicy, setCheckedPolicy] = useState(false);

  // các biến opend dùng để toggle các collapse
  const [openedHocvan, setOpenHocVan] = useState([false, false, false]);
  const [openedKNLV, setOpenKNLV] = useState([false, false, false]);
  const [openedChungChi, setOpenChungChi] = useState([false, false, false]);
  const [openedSkills, setOpenSkills] = useState([false, false, false]);

  // các biến handle tắt mở các thông tin thêm, dùng cho form private
  const [visibleHocvan, setVisibleHocvan] = useState(false);
  const [visibleKNLV, setVisibleKNLV] = useState(false);
  const [visibleChungChi, setVisibleChungChi] = useState(false);
  const [visibleSkills, setVisibleSkills] = useState(false);

  // các biến model dùng để thêm hoặc bớt item trong form nest field
  const modelEducation = {
    institution: "",
    url: "",
    area: "",
    studyType: "",
    startDate: "",
    endDate: "",
    score: "",
    courses: [],
  };
  const modelWork = {
    name: "",
    location: "",
    description: "",
    position: "",
    startDate: "",
    endDate: "",
    summary: "",
    highlights: [],
  };
  const modelCertificates = {
    name: "",
    date: "",
  };
  const modelSkills = {
    name: "",
    level: 0,
  };
  // biến form dùng để khởi tạo giá trị model cả talent, hiện đang theo format của jsonresume
  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      basics: {
        name: "",
        dob: null,
        gender: "",
        phone: "",
        label: "",
        image: "", // chưa up dc image
        email: "",
        summary: "",
        location: {
          address: "",
          city: "",
          countryCode: "",
        },
        profiles: [],
      },
      work: [
        {
          name: "",
          location: "",
          description: "",
          position: "",
          startDate: "",
          endDate: "",
          summary: "",
          highlights: [],
        },
      ],
      volunteer: [],
      education: [
        {
          institution: "",
          area: "",
          studyType: "",
          startDate: "",
          endDate: "",
        },
      ],
      awards: [],
      certificates: [
        {
          name: "",
          date: "",
        },
      ],
      publications: [],
      skills: [
        {
          name: "",
          level: 0,
        },
      ],
      languages: [],
      interests: [],
      references: [],
      projects: [],
      meta: {
        source: "portal",
      },
      tags: ["private"],
    },
    validate: {
      basics: {
        name: (value) =>
            value.length < 2 ? "Tên của bạn ít nhất phải 2 kí tự" : null,
        dob: (value) =>
            value == null ? "Không được để trống ngày sinh" : null,
        gender: (value) =>
            value.length < 1 ? "Không được để trống giới tính" : null,
        phone: (value) =>
            /(((\+84)|0[3|5|7|8|9])+([0-9]{9})\b)|((84|0[3|5|7|8|9])+([0-9]{8})\b)|((02)+([0-9]{9})\b)|((02|(\+)84)+([0-9]{10})\b)/.test(
                value
            )
                ? null
                : "Không đúng định dạng",
        email: (value) => {
          if (value == "") {
            return null;
          } else {
            return /^\S+@\S+$/.test(value)
                ? null
                : "Không đúng định dạng email";
          }
        },
      },
    },
  });

  // mảng lặp các nút thêm nâng cao - dùng tại form apply private
  const arrExtendAdd = [
    {
      text: "Thêm trình độ học vấn",
      visible: visibleHocvan,
      function: () => setVisibleHocvan(true),
    },
    {
      text: "Thêm kinh nghiệm làm việc",
      visible: visibleKNLV,
      function: () => setVisibleKNLV(true),
    },
    {
      text: "Thêm chứng chỉ",
      visible: visibleChungChi,
      function: () => setVisibleChungChi(true),
    },
    {
      text: "Thêm kỹ năng",
      visible: visibleSkills,
      function: () => setVisibleSkills(true),
    },
  ];

  // mảng dùng để RENDER các phần thêm nâng cao: học vấn, kinh nhgiệm,...
  const moreInfomations = [
    {
      title: "Trình độ học vấn",
      field: "education",
      model: modelEducation,
      visible: visibleHocvan,
      function: () => {
        setVisibleHocvan(false);
        form.setFieldValue("education", []);
      },
      body: (
          <Stack spacing={8} w={"100%"}>
            {form.values.education.map((item, index) => (
                <div key={`education-${index}`}>
                  <Group
                      mb={18}
                      onClick={() =>
                          setOpenHocVan((o) => {
                            return o.map((e, i) => (i == index ? !e : e));
                          })
                      }
                      className={styles.collapse}
                      p="xs"
                  >
                    <ActionIcon
                        onClick={() => form.removeListItem("education", index)}
                    >
                      <Trash
                          size={30}
                          color={variables.primaryColor}
                          strokeWidth={1.5}
                      />
                    </ActionIcon>
                    <Text>{item.institution ? item.institution : "- - -"}</Text>
                  </Group>
                  <Collapse in={openedHocvan[index]} className={styles.childCol}>
                    <Stack spacing={8} w={"100%"}>
                      <TextInput
                          radius="lg"
                          label={<Text className={styles.label}>Tên trường</Text>}
                          type="text"
                          {...form.getInputProps(`education.${index}.institution`)}
                      />
                      <TextInput
                          radius="lg"
                          label={<Text className={styles.label}>Chuyên nghành</Text>}
                          type="text"
                          {...form.getInputProps(`education.${index}.area`)}
                      />
                      <SimpleGrid cols={2} spacing={32}>
                        <DatePicker
                            radius="lg"
                            placeholder="Chọn thời gian"
                            label={<Text className={styles.label}>Từ</Text>}
                            {...form.getInputProps(`education.${index}.startDate`)}
                        />
                        <DatePicker
                            radius="lg"
                            placeholder="Chọn thời gian"
                            label={<Text className={styles.label}>Đến</Text>}
                            {...form.getInputProps(`education.${index}.endDate`)}
                        />
                      </SimpleGrid>
                    </Stack>
                  </Collapse>
                </div>
            ))}
          </Stack>
      ),
    },
    {
      title: "Kinh nghiệm làm việc",
      field: "work",
      model: modelWork,
      visible: visibleKNLV,
      function: () => {
        setVisibleKNLV(false);
        form.setFieldValue("work", []);
      },
      body: (
          <Stack spacing={8} w={"100%"}>
            {form.values.work.map((item, index) => (
                <div key={`work-${index}`}>
                  <Group
                      mb={18}
                      onClick={() =>
                          setOpenKNLV((o) => {
                            return o.map((e, i) => (i == index ? !e : e));
                          })
                      }
                      className={styles.collapse}
                      position="apart"
                      p="xs"
                  >
                    <Text>{item.name ? item.name : "- - -"}</Text>
                    {/* <Text>{item.majors}</Text> */}
                    <ActionIcon onClick={() => form.removeListItem("work", index)}>
                      <Minus
                          size={30}
                          color={variables.primaryColor}
                          strokeWidth={1.5}
                      />
                    </ActionIcon>
                  </Group>
                  <Collapse in={openedKNLV[index]} className={styles.childCol}>
                    <Stack spacing={8} w={"100%"}>
                      <TextInput
                          radius="lg"
                          label={<Text className={styles.label}>Tên công ty</Text>}
                          type="text"
                          {...form.getInputProps(`work.${index}.name`)}
                      />
                      <TextInput
                          radius="lg"
                          label={<Text className={styles.label}>Chức danh</Text>}
                          type="text"
                          {...form.getInputProps(`work.${index}.position`)}
                      />
                      <Textarea
                          radius="lg"
                          label={
                            <Text className={styles.label}>Mô tả công việc</Text>
                          }
                          {...form.getInputProps(`work.${index}.summary`)}
                      />
                      <SimpleGrid cols={2} spacing={32}>
                        <DatePicker
                            radius="lg"
                            placeholder="Chọn thời gian"
                            label={<Text className={styles.label}>Từ</Text>}
                            {...form.getInputProps(`work.${index}.startDate`)}
                        />
                        <DatePicker
                            radius="lg"
                            placeholder="Chọn thời gian"
                            label={<Text className={styles.label}>Đến</Text>}
                            {...form.getInputProps(`work.${index}.endDate`)}
                        />
                      </SimpleGrid>
                    </Stack>
                  </Collapse>
                </div>
            ))}
          </Stack>
      ),
    },
    {
      title: "Chứng chỉ",
      field: "certificates",
      model: modelCertificates,
      visible: visibleChungChi,
      function: () => {
        setVisibleChungChi(false);
        form.setFieldValue("certificates", []);
      },
      body: (
          <Stack spacing={8} w={"100%"}>
            {form.values.certificates.map((item, index) => (
                <div key={`certificates-${index}`}>
                  <Group
                      mb={18}
                      onClick={() =>
                          setOpenChungChi((o) => {
                            return o.map((e, i) => (i == index ? !e : e));
                          })
                      }
                      className={styles.collapse}
                      position="apart"
                      p="xs"
                  >
                    <Text>{item.name ? item.name : "- - -"}</Text>
                    <ActionIcon
                        onClick={() => form.removeListItem("certificates", index)}
                    >
                      <Minus
                          size={30}
                          color={variables.primaryColor}
                          strokeWidth={1.5}
                      />
                    </ActionIcon>
                  </Group>
                  <Collapse in={openedChungChi[index]} className={styles.childCol}>
                    <Stack spacing={8} w={"100%"}>
                      <TextInput
                          radius="lg"
                          label={<Text className={styles.label}>Tên chứng chỉ</Text>}
                          type="text"
                          {...form.getInputProps(`certificates.${index}.name`)}
                      />
                      <TextInput
                          radius="lg"
                          label={<Text className={styles.label}>Được trao bởi</Text>}
                          type="text"
                          {...form.getInputProps(`certificates.${index}.issuer`)}
                      />
                      <SimpleGrid cols={2} spacing={32}>
                        <DatePicker
                            radius="lg"
                            placeholder="Chọn thời gian"
                            label={
                              <Text className={styles.label}>Thời gian nhận</Text>
                            }
                            {...form.getInputProps(`certificates.${index}.date`)}
                        />
                        <TextInput
                            radius="lg"
                            label={<Text className={styles.label}>Url</Text>}
                            type="url"
                            {...form.getInputProps(`certificates.${index}.url`)}
                        />
                      </SimpleGrid>
                    </Stack>
                  </Collapse>
                </div>
            ))}
          </Stack>
      ),
    },
    {
      title: "Kỹ năng",
      field: "skills",
      model: modelSkills,
      visible: visibleSkills,
      function: () => {
        setVisibleSkills(false);
        form.setFieldValue("skills", []);
      },
      body: (
          <Stack spacing={8} w={"100%"}>
            {form.values.skills.map((item, index) => (
                <div key={`skills-${index}`}>
                  <Group
                      mb={18}
                      onClick={() =>
                          setOpenSkills((o) => {
                            return o.map((e, i) => (i == index ? !e : e));
                          })
                      }
                      className={styles.collapse}
                      position="apart"
                      p="xs"
                  >
                    <Text>{item.name ? item.name : "- - -"}</Text>
                    <ActionIcon
                        onClick={() => form.removeListItem("skills", index)}
                    >
                      <Minus
                          size={30}
                          color={variables.primaryColor}
                          strokeWidth={1.5}
                      />
                    </ActionIcon>
                  </Group>
                  <Collapse in={openedSkills[index]} className={styles.childCol}>
                    <Stack spacing={8} w={"100%"}>
                      <SimpleGrid cols={2} spacing={32}>
                        <TextInput
                            radius="lg"
                            label={<Text className={styles.label}>Tên kỹ năng</Text>}
                            type="text"
                            {...form.getInputProps(`skills.${index}.name`)}
                        />
                        <Flex align="center">
                          <Slider
                              min={0}
                              max={5}
                              style={{width: "100%"}}
                              defaultValue={0}
                              step={1}
                              marks={[
                                {value: 0, label: "0"},
                                {value: 1, label: "1"},
                                {value: 2, label: "2"},
                                {value: 3, label: "3"},
                                {value: 4, label: "4"},
                                {value: 5, label: "5"},
                              ]}
                              onChange={(value) => {
                                form.setFieldValue(`skills.${index}.level`, value);
                              }}
                          />
                        </Flex>
                      </SimpleGrid>
                    </Stack>
                  </Collapse>
                </div>
            ))}
          </Stack>
      ),
    },
  ];

  const onSubmit = async () => {
    setVisible(true);
    const validate = form.validate().hasErrors;
    if (validate == true) {
      showNotification({
        id: "noti-fail",
        loading: false,
        color: "red",
        title: "Thất baị!",
        message: "Có lỗi xảy ra trong quá trình ứng tuyển.",
        autoClose: 3000,
      });
      setVisible(false);
    } else {
      const newTalent = await postTalent(props.url, form.values);
      if (newTalent.status === 200 || newTalent.status === 201) {
        openContextModal({
          modal: "success",
          centered: true,
          innerProps: {
            text: "Nộp đơn thành công!",
            content: `Cảm ơn bạn đã quan tâm đến công ty.`,
            color: variables.primaryColor,
          },
        });
        form.reset();
        setVisible(false);
      } else {
        setVisible(false);
        showNotification({
          id: "noti-fail",
          loading: false,
          color: "red",
          title: "Thất baị!",
          message: "Có lỗi xảy ra trong quá trình ứng tuyển.",
          autoClose: 3000,
        });
      }
    }
  };

  const handleChangeCheckedPolicy = () => {
    setCheckedPolicy(!checkedPolicy);
  };

  switch (props.display) {
    case "form1":
      eleHTML = (
          <div className={styles.form1}>
            <Box
                sx={(theme) => ({
                  background: "#FFFFFF",
                  boxShadow: theme.shadows.md,
                  [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                    padding: 10,
                  },
                  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
                    padding: 30,
                  },
                  borderRadius: 32,
                  position: "relative",
                  width: 1140,
                })}
            >
              <LoadingOverlay visible={visible} overlayBlur={0.5}/>
              <form onSubmit={form.onSubmit(() => onSubmit())}>
                <Stack spacing={32}>
                  <Stack align="center" mb={32}>
                    <Text align="center" className={styles.title}>
                      {props.options.title}
                    </Text>
                    <div className={styles.boxDivider}>
                      <Divider size={1} color={variables.primaryColor}/>
                    </div>
                  </Stack>
                  <Group noWrap spacing={32}>
                    <Stack spacing={8} w={"100%"}>
                      <TextInput
                          radius="lg"
                          label={<Text className={styles.label}>Họ và tên</Text>}
                          placeholder="vd: Nguyễn Anh Tuấn"
                          type="text"
                          {...form.getInputProps("basics.name")}
                      />
                      <SimpleGrid cols={2} spacing={32}>
                        <TextInput
                            radius="lg"
                            label={
                              <Group spacing={8}>
                                <Text className={styles.label}>Email</Text>
                                {/* <Text
                                    fz={12}
                                    c={variables.primaryColor}
                                >{`(không bắt buộc)`}</Text> */}
                              </Group>
                            }
                            placeholder="Vd: abc@gmail.com"
                            type="email"
                            {...form.getInputProps("basics.email")}
                        />
                        <TextInput
                            radius="lg"
                            label={
                              <Text className={styles.label}>Số điện thoại</Text>
                            }
                            placeholder="Vd: 09xxxxxxxx"
                            type="tel"
                            {...form.getInputProps("basics.phone")}
                        />
                        <Select
                            label={<Text className={styles.label}>Giới tính</Text>}
                            radius="lg"
                            data={[
                              {value: "male", label: "Nam"},
                              {value: "female", label: "Nữ"},
                              {value: "order", label: "Khác"},
                            ]}
                            {...form.getInputProps("basics.gender")}
                        />
                        <DatePicker
                            initialLevel="year"
                            allowFreeInput
                            inputFormat="DD/MM/YYYY"
                            labelFormat="DD/MM/YYYY"
                            radius="lg"
                            locale="vi"
                            dateParser={(dateString) =>
                                new Date(Date.parse(dateString))
                            }
                            label={<Text className={styles.label}>Ngày sinh</Text>}
                            {...form.getInputProps("basics.dob")}
                        />
                      </SimpleGrid>
                    </Stack>
                  </Group>
                  <Group spacing={32}>
                    {arrExtendAdd.map((ele, index) => (
                        <Button
                            key={`btnAdd-${index}`}
                            radius="lg"
                            variant="outline"
                            rightIcon={<CirclePlus size={24} strokeWidth={1.5}/>}
                            className={`${styles.btnAdd} ${
                                ele.visible ? styles.btnAddActive : ""
                            }`}
                            onClick={ele.function}
                        >
                          {ele.text}
                        </Button>
                    ))}
                  </Group>
                  {moreInfomations.map((ele, index) => (
                      <Stack
                          display={ele.visible ? "" : "none"}
                          key={`moreInfo-${index}`}
                      >
                        <Stack>
                          <div className={styles.boxBetween}>
                            <Group noWrap>
                              <Trash
                                  size={30}
                                  style={{cursor: "pointer"}}
                                  strokeWidth={1.5}
                                  color={variables.primaryColor}
                                  onClick={ele.function}
                              />
                              <Text className={styles.subtitle}>{ele.title}</Text>
                            </Group>
                            <Group spacing={16} noWrap>
                              <SquarePlus
                                  size={30}
                                  strokeWidth={1.5}
                                  color={variables.primaryColor}
                                  display={
                                    form.values[ele.field].length < 3 ? "" : "none"
                                  }
                                  onClick={() =>
                                      form.insertListItem(ele.field, ele.model)
                                  }
                              />
                            </Group>
                          </div>
                          <div className={styles.boxDivider}>
                            <Divider size={1} color={variables.primaryColor}/>
                          </div>
                        </Stack>
                        {ele.body}
                      </Stack>
                  ))}

                  {props.options.isPolicy ? (
                      <Checkbox
                          onChange={handleChangeCheckedPolicy}
                          label={
                            <Group>
                              <p>
                                {props.options.textPolicy}
                                <span>
                            <a target="_blank" rel="noreferrer noopener"
                               href={props.options.policyUrl}>Xem điều khoản</a>
                          </span>
                              </p>
                            </Group>
                          }
                          className={styles.checkBox}
                      />
                  ) : (
                      <></>
                  )}

                  {/* == */}
                  <Group position="right">
                    {props.options.isPolicy ? (
                        <Button
                            disabled={!checkedPolicy}
                            fw={400}
                            fz={20}
                            radius={40}
                            w={200}
                            type="submit"
                            bg={variables.primaryColor}
                            sx={{
                              "&:focus": {backgroundColor: variables.focusColor},
                              "&:hover": {backgroundColor: variables.hoverColor},
                            }}
                        >
                          Nộp đơn
                        </Button>
                    ) : (
                        <Button
                            fw={400}
                            fz={20}
                            radius={40}
                            w={200}
                            type="submit"
                            bg={variables.primaryColor}
                            sx={{
                              "&:focus": {backgroundColor: variables.focusColor},
                              "&:hover": {backgroundColor: variables.hoverColor},
                            }}
                        >
                          Nộp đơn
                        </Button>
                    )}
                  </Group>
                </Stack>
              </form>
            </Box>
            {/* <Modal
            opened={confirmPolicy}
            onClose={() => setConfirmPolicy(false)}
            title={<strong>BIỂU MẪU THÔNG BÁO VÀ ĐỒNG Ý VỀ VIỆC XỬ LÝ DỮ LIỆU CÁ NHÂN</strong>}
            size="xl"
            withCloseButton={false}
          >
            <div className="box-policy">
            {parse(md.parse(props.markdown))}
            <Checkbox checked onChange={handleChangeCheckedPolicy}
              label="I agree to sell my privacy"
            />
            </div>
          </Modal> */}
          </div>
      );
      break;

    default:
      break;
  }

  return eleHTML;
}

import { Image, Text, Flex } from '@mantine/core';
import variables from '../../../styles/variables.module.scss';

export function Gallery5({options}) {
    return (
        <Flex direction="column" gap={32} p={{base: 24, md: 48}}>
            <Text fz={48} fw={700} ta={options.titleAlign} c={variables.primaryColor}>{options.title}</Text>
            <Image src={options.src} alt="" w={options.width} h={options.height} fit={options.fit} radius={options.radius ?? 8} mx="auto"/>
        </Flex>
    );
}
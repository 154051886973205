import { Carousel } from "@mantine/carousel";
import { Box, Card, Divider, Group, Image, Stack, Text } from "@mantine/core";
import Link from "next/link";
import { useEffect, useState } from "react";
import { ArrowNarrowRight } from "tabler-icons-react";
import styles from "../../../styles/feature.module.scss";
import variables from "../../../styles/variables.module.scss";
import ImagePlaceholder from "../../imagePlaceholder/imagePlaceholder";
import { loadData } from "../carouel";

export function Carousel3(props) {
    const [data, setData] = useState([]);
    useEffect(() => {
        loadData({
            url: props.url,
            data: props.data,
            query: props.query,
            jsonata: props.jsonata,
            display: props.display,
            selectedIds: props.selectedIds || props.hotJobIds || props.hotNewsIds
        }, setData);

    }, []);
    return (
        <Box sx={{ padding: "0px 64px" }} className={`${styles.carousel1} ${styles.carousel3}`}>
            <Stack>
                <Text align="left" className={styles.title}>
                    {props.title}
                </Text>
                <div className={styles.boxDivider}>
                    <Divider size={1} color={variables.primaryColor} />
                </div>
                <Text>{props.subtitle}</Text>
            </Stack>
            <Carousel
                loop={props.hasLoop}
                className={styles.carousel}
                slideSize="33%"
                slideGap={32}
                align="start"
                slidesToScroll={1}
                controlSize={48}
                controlsOffset={0}
                mt={44}
                sx={{
                    button: { background: variables.primaryColor, border: "none" },
                    svg: { color: "#FFFFFF", width: "24px", height: "24px" },
                }}
                styles={{ controls: { top: '-137px', justifyContent: 'normal', left: 'auto' }, control: { marginLeft: 16 } }}
            >
                {data?.map((ele, index) => (
                    <Carousel.Slide key={`carousel2-${index}`}>
                        <Card
                            // sx={{ borderColor: variables.primaryColor }}
                            radius={32}
                            p={24}
                            h={384}
                            withBorder
                            shadow='md'
                        >
                            <Card.Section>
                                <Image
                                    src={ele.image}
                                    withPlaceholder
                                    placeholder={<ImagePlaceholder display={"imagePlaceholder1"}
                                        image={props.defaultImage ? props.defaultImage : props.imageLogo} />}
                                    alt="job 1"
                                    height={192}
                                />
                            </Card.Section>
                            <Stack spacing={16} p={16}>
                                <Link
                                    href={`/${props.data}/${ele.id}?type_child=${props.child}`}
                                >
                                    <Text
                                        className={styles.carousel3Title}
                                        lineClamp={1}
                                    >
                                        {ele.name}
                                    </Text>
                                </Link>
                                <Text className={styles.summary} lineClamp={3}>
                                    It is a long established fact that a reader will be
                                    distracted by the readable content of a page when looking
                                    at its layout. The point of using Lorem Ipsum is that
                                </Text>
                                <Group align="center">
                                    <Text className={styles.readMore}>{"Xem thêm"}</Text>
                                    <ArrowNarrowRight size={24} color={variables.primaryColor} strokeWidth={1.5} />
                                </Group>
                            </Stack>
                        </Card>
                    </Carousel.Slide>
                ))}
            </Carousel>
        </Box>
    );
}

import { Divider, Stack, Text } from "@mantine/core";
import parse from "html-react-parser";
import variables from "../../../styles/variables.module.scss";
import styles from "./list5.module.scss";
export function List5R(props) {
  const currentWidth = props.windowDimension.width;
  const divider = Math.min(currentWidth < 1440 ? 1440 / currentWidth : 1, 1.6);

  const listDataJSX = props.dataList.map((dataSingleton, index) => (
    <Stack spacing={16} key={`core-value-` + index}>
      <Text fz={currentWidth < 750 ? 20 : 32} fw={700}>
        {dataSingleton.title}
      </Text>
      <Text fz={currentWidth < 750 ? 14 : 16} color={"dimmed"} className={styles.description}>
        {parse(dataSingleton.description)}
      </Text>
    </Stack>
  ));

  return (
    <Stack
      spacing={32 / divider}
      style={{
        padding: `${currentWidth < 750 ? 16 : 44 / divider}px ${currentWidth < 750 ? 20 : 64 / divider}px`,
      }}
    >
      {props.title && (
        <>
          <Text
            ta="left"
            color={variables.primaryColor}
            fz={currentWidth < 750 ? 32 : 48}
            fw={700}
            c={variables.primaryColor}
      >
            {props.title}
          </Text>
          <Divider size={1} style={{ width: "100%", display: props.displayDivider ? "block" : "none" }} color={variables.secondColor} />
        </>
      )}
      {listDataJSX}
    </Stack>
  );
}

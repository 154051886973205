import { useScrollTop } from "@/hooks";
import {
  Anchor,
  Center,
  Collapse,
  Group,
  Image,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { slide as Burger } from "react-burger-menu";
import styles from "styles/menu.module.scss";
import variables from "styles/variables.module.scss";
import { ChevronDown } from "tabler-icons-react";
import BookingCalender from "../bookingCalender/bookingCalender";
import menuStyles from "./menu.module.scss";

export default function Menu(props) {
  let eleHTML = <></>;
  switch (props.display) {
    case "basic":
      if (props.windowDimension.width >= 750)
        eleHTML = (
          <BasicMenu {...props} windowDimension={props.windowDimension} />
        );
      else
        eleHTML = (
          <SmallMenu {...props} windowDimension={props.windowDimension} />
        );

      break;
    default:
      break;
  }
  return eleHTML;
}

function BasicMenu(props) {
  // const [href, setHref] = useState("");
  const router = useRouter();
  const { id } = router.query;
  const [isHovered, setIsHovered] = useState([]);
  const scrolled = useScrollTop();
  const divider =
    props.windowDimension.width < 1440 ? 1440 / props.windowDimension.width : 1;
  const padding = 64 / divider;

  // useEffect(() => {
  //   setHref(location.pathname);
  // }, [location.pathname]);

  useEffect(() => {
    if (Array.isArray(props.data)) {
      setIsHovered(new Array(props.data.length).fill(false));
    }
  }, [props.data]);

  return (
    <Group
      spacing={80 / divider}
      noWrap
      style={{
        height: 96 / divider,
        padding: `0 ${padding}px`,
        position: scrolled && props.isHeaderSticky ? "fixed" : "static",
        zIndex: 200,
        width: "100%",
        backgroundColor: "white",
        justifyContent: "space-between",
      }}
      align="center"
      className={styles.menu1}
    >
      <Anchor href={props.logoHref} target={"_blank"}>
        <Image
          src={props.logo}
          width={(props.logoSize?.width || 140) / divider}
          alt="logo demo"
          fit={"contain"}
          height={(props.logoSize?.height || 80) / divider}
        />
      </Anchor>
      <Group spacing={32 / divider} position="center">
        {props.data.map((ele, index) =>
          ele.component === "booking-calendar" ? (
            <BookingCalender
              key={`menu-${index}`}
              options={ele.options}
              textSize={18 / divider}
            />
          ) : (
            <Stack
              spacing={10}
              key={`menu-${index}`}
              style={{ position: "relative", alignItems: "center" }}
              onMouseEnter={() =>
                setIsHovered((prev) => [
                  ...prev.slice(0, index),
                  true,
                  ...prev.slice(index + 1),
                ])
              }
              onMouseLeave={() =>
                setIsHovered((prev) => [
                  ...prev.slice(0, index),
                  false,
                  ...prev.slice(index + 1),
                ])
              }
            >
              <Center className={styles.boxText} key={`menu-${index}`}>
                <Link href={ele.href}>
                  <Group
                    spacing={8}
                    align="center"
                    className={`${styles.text} ${
                      `/${id}` === ele.href ? styles.active : ""
                    }`}
                  >
                    <Text fz={18 / divider}>{ele.title}</Text>
                    {Array.isArray(ele.redirects) &&
                      ele.redirects.length > 0 && <ChevronDown />}
                  </Group>
                </Link>
              </Center>

              {Array.isArray(ele.redirects) && ele.redirects.length > 0 && (
                <Collapse
                  in={isHovered[index]}
                  transitionDuration={300}
                  style={{ position: "absolute", top: 40, maxWidth: "200%" }}
                >
                  <Stack
                    style={{
                      width: "100%",
                      padding: "16px 0 16px 0",
                      gap: 8,
                      background: "white",
                      boxShadow: "0px 0px 30px 0px #3232321A",
                      borderRadius: 16,
                    }}
                  >
                    {ele.redirects.map((eleRedirect, index) => (
                      <Link
                        href={eleRedirect?.href}
                        key={index}
                        target={eleRedirect?.target}
                        className={menuStyles.text}
                      >
                        {eleRedirect?.hasTooltip ? (
                          <Tooltip label={eleRedirect?.title} withArrow>
                            <Text fz={16} className={menuStyles.textWrap}>
                              {eleRedirect?.title}
                            </Text>
                          </Tooltip>
                        ) : (
                          <Text fz={16} className={menuStyles.textWrap}>
                            {eleRedirect?.title}
                          </Text>
                        )}
                      </Link>
                    ))}
                  </Stack>
                </Collapse>
              )}
            </Stack>
          )
        )}
      </Group>
      {/* <Group align='center' spacing={37 / divider}>

                {
                    props.twitter.length > 0
                        ? <a target={"_blank"} rel="noreferrer"
                            href={props.twitter}><Image src="/images/fa-twitter.svg"
                                width={18} alt={''} /></a>
                        : <></>
                }

                {
                    props.facebook.length > 0
                        ? <a target={"_blank"} rel="noreferrer"
                            href={props.facebook}><Image src="/images/fa-facebook-square.svg"
                                width={18}
                                alt={''} /></a>
                        : <></>
                }

                {
                    props.googlePlus.length > 0
                        ? <a target={"_blank"} rel="noreferrer"
                            href={props.googlePlus}><Image src="/images/fa-google-plus.svg"
                                width={18}
                                alt={''} /></a>
                        : <></>
                }

            </Group> */}
    </Group>
  );
}

function SmallMenu(props) {
  const router = useRouter();
  const { id } = router.query;
  const burgerStyles = {
    bmBurgerButton: {
      position: "fixed",
      width: 32,
      height: 32,
      left: 20,
      top: 20,
    },
    bmBurgerBars: {
      background: variables.primaryColor,
    },
    bmBurgerBarsHover: {
      background: variables.hoverColor,
    },
    bmCrossButton: {
      width: 32,
      height: 32,
    },
    bmCross: {
      background: variables.secondColor,
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
      right: "0px",
      top: "0px",
      width: "100%",
    },
    bmMenu: {
      background: "#FFFFFF",

      borderLeft: `1px solid ${variables.primaryColor}`,
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
    },
    bmMorphShape: {},
    bmItemList: {},
    bmItem: {
      marginBottom: 16,
    },
    bmOverlay: {},
  };
  const [burgerOpen, setBurgerOpen] = useState(false);

  return (
    <Group
      noWrap
      style={{
        height: 71,
        background: "#FFF",
        padding: "0px " + 32 + "px",
        position: "fixed",
        top: 0,
      }}
      align="center"
      position={"apart"}
      className={styles.menuSmall}
    >
      <div onClick={() => setBurgerOpen(true)}>
        <Burger isOpen={burgerOpen} styles={burgerStyles}>
          {props.data.map((ele, index) =>
            ele.component === "booking-calendar" ? (
              <BookingCalender
                key={`menu-${index}`}
                options={ele.options}
                textSize={16}
              />
            ) : (
              <Link
                className={styles.boxText}
                href={ele.href}
                onClick={(e) => {
                  e.stopPropagation();
                  setBurgerOpen(false);
                }}
                key={`menu-${index}`}
              >
                <Text
                  fz={16}
                  c={"black"}
                  className={`${styles.text} ${
                    `/${id}` === ele.href ? styles.active : ""
                  }`}
                >
                  {ele.title}
                </Text>
              </Link>
            )
          )}
          {/* <Group spacing={10} style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                        {
                            props.twitter.length > 0
                                ? <a target={"_blank"} rel="noreferrer"
                                    href={props.twitter}><Image src="/images/fa-twitter.svg"
                                        width={16} alt={''} /></a>
                                : <></>
                        }

                        {
                            props.facebook.length > 0
                                ? <a target={"_blank"} rel="noreferrer"
                                    href={props.facebook}><Image src="/images/fa-facebook-square.svg"
                                        width={16}
                                        alt={''} /></a>
                                : <></>
                        }

                        {
                            props.googlePlus.length > 0
                                ? <a target={"_blank"} rel="noreferrer"
                                    href={props.googlePlus}><Image src="/images/fa-google-plus.svg"
                                        width={16}
                                        alt={''} /></a>
                                : <></>
                        }


                    </Group> */}
        </Burger>
      </div>
      <Anchor href={props.logoHref} target={"_blank"}>
        <Image
          src={props.logo}
          width={200}
          height={71}
          alt="logo demo"
          fit={"contain"}
        />
      </Anchor>
    </Group>
  );
}

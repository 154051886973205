import { Text } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import { useEffect, useState } from "react";
import styles from "../../styles/dynamic-form.module.scss";

function FormDynamicInputDate(props) {
  if (props.inputTemplate && props.inputTemplate.type === "date") {
    return <FormDynamicInputDateHelper {...props} />;
  }
  return <></>;
}

function FormDynamicInputDateHelper(props) {
  const [hasDateCustomError, setHasDateCustomError] = useState(false);
  const [oldInvisible, setOldInvisible] = useState(false);

  const inputTemplate = props.inputTemplate;
  let inputProps = { ...props.formInputProps };
  const defaultDate =
    !inputProps.value || new Date(inputProps.value).toString() === "Invalid Date" ? "" : new Date(inputProps.value);

  dayjs.extend(customParseFormat);

  inputProps.value = defaultDate;
  inputProps.onChange = (valueDate) => {
    if (valueDate) {
      const momentObject = moment(valueDate, customDateFormat, "vi");

      switch (customDateFormat) {
        case "MM/YYYY":
          momentObject.date(1);
          break;

        case "YYYY":
          momentObject.dayOfYear(1);
          break;

        default:
          break;
      }
      if (
        momentObject.year() >= (inputTemplate.minYear || 1900) &&
        momentObject.year() <= (inputTemplate.maxYear || 2100)
      ) {
        setHasDateCustomError(false);
        props.setHasCustomError(false);
      }

      props.formInputProps.onChange(momentObject.toDate());
    } else {
      if (valueDate === null) props.formInputProps.onChange("");
      else {
        props.formInputProps.onChange(valueDate);
        setHasDateCustomError(true);
        props.setHasCustomError(true);
      }
    }
  };

  useEffect(() => {
    if (inputTemplate.invisible) {
      setOldInvisible(true);
      props.formInputProps.onChange(undefined);
      setHasDateCustomError(false);
      props.setHasCustomError(false);
    } else if (oldInvisible) {
      setOldInvisible(false);
      props.formInputProps.onChange(props.initialValue);
    }
  }, [inputTemplate.invisible]);
  const customDateFormat = inputTemplate.dateFormat ? inputTemplate.dateFormat : "DD/MM/YYYY";

  useEffect(() => {
    if (props.formInputProps.value === undefined && !inputTemplate.invisible && !props.initialValue) {
      props.formInputProps.onChange("");
    }
  }, [props.formInputProps.value]);

  if (inputTemplate.invisible) {
    return <></>;
  }
  return (
    <>
      <DatePicker
        className={styles.formDynamicTextDateSelectInput}
        allowFreeInput={true}
        locale="vi"
        dateParser={(value) => {
          if (hasDateCustomError) {
            setHasDateCustomError(false);
            props.setHasCustomError(false);
          }
          if (value.length === 0) {
            return null;
          }
          const momentObject = moment(value, customDateFormat, "vi");
          if (momentObject.isValid()) {
            if (
              momentObject.year() < (inputTemplate.minYear || 1900) ||
              momentObject.year() > (inputTemplate.maxYear || 2100)
            ) {
              setHasDateCustomError(true);
              props.setHasCustomError(true);
              return momentObject.toDate();
            }

            return momentObject.toDate();
          } else {
            return undefined;
          }
        }}
        label={
          inputTemplate.labelNoWrap ? (
            <span style={{ textWrap: "nowrap" }}>{inputTemplate.label}</span>
          ) : (
            inputTemplate.label
          )
        }
        dropdownPosition={"bottom-start"}
        withAsterisk={props.requiredInput}
        inputFormat={customDateFormat}
        placeholder={inputTemplate.placeholder ? inputTemplate.placeholder : inputTemplate.label}
        {...inputProps}
        disabled={props.disabledInput || inputProps.disabled}
      />
      {hasDateCustomError ? (
        <Text c={"red"} fz={12}>
          Ngày nhập không đúng định dạng
        </Text>
      ) : (
        <></>
      )}
    </>
  );
}

export default FormDynamicInputDate;

import React, {useState} from 'react'
import Feature from "../feature";
import {Stack} from "@mantine/core";

export default function Switcher1(props) {
    const [componentObject, setComponentObject] = useState(props.options.componentDefault);

    let componentJSX = <></>;
    if (Array.isArray(componentObject)) {
        componentJSX = (
            <Stack spacing={10}>
                {componentObject.map((oneComponent, index) => {
                    return (<Feature
                        url={props.url}
                        key={`feature-in-component-default-${index}`}
                        component={oneComponent.component}
                        display={oneComponent.display}
                        options={oneComponent.options}
                        windowDimension={props.windowDimension}
                        primaryColor={props.primaryColor}
                        secondaryColor={props.secondaryColor}
                        logo={props.imageLogo}
                        defaultImage={props.defaultImage}
                    />);
                })}
            </Stack>
        );
    } else {
        componentJSX = <Feature
            url={props.url}
            component={componentObject.component}
            display={componentObject.display}
            options={componentObject.options}
            windowDimension={props.windowDimension}
            primaryColor={props.primaryColor}
            secondaryColor={props.secondaryColor}
            logo={props.imageLogo}
            defaultImage={props.defaultImage}
        />;

    }


    const handleControl = (data1, data2) => {
        if (data1 !== "" || data2 !== "") {
            setComponentObject(props.options.componentChange);
        } else {
            setComponentObject(props.options.componentDefault)
        }
    }

    return (
        <>
            <ControllerElement handleControl={handleControl}
                               componentController={props.options.componentController}
                               windowDimension={props.windowDimension}
                               primaryColor={props.primaryColor}
                               secondaryColor={props.secondaryColor}
                               defaultImage={props.defaultImage}/>
            {componentJSX}
        </>
    );
}

function ControllerElement(props) {
    let eleHTML = <></>
    switch (props.componentController.component) {
        case 'search':
            eleHTML = <Feature
                display={props.componentController.display}
                component={props.componentController.component}
                options={{handleSearch: props.handleControl}}
                windowDimension={props.windowDimension}
                primaryColor={props.primaryColor}
                secondaryColor={props.secondaryColor}
                defaultImage={props.defaultImage}
            />
            break;
        default:
            break;
    }
    return eleHTML;
}


import { Carousel } from '@mantine/carousel';
import { Group } from '@mantine/core';
import { useEffect, useState } from 'react';
import Carousel8Style from './carousel8.module.scss';

function Carousel8({ options, backgroundColor, windowDimension }) {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (options.useApi) {
            loadData({
                url: url,
                data: options.data,
                query: options.query,
                jsonata: options.jsonata,
                display: display
            }, setData);
        } else {
            setData(options.dataSource || [])
        }

        return () => {
            setData([])
        }
    }, [])

    if (data.length === 0) {
        return <></>
    }


    const maxSlideVisible = (windowDimension.width >= 1400) ? 3 : (windowDimension.width > 768 ? 2 : 1);

    const useCarousel = data.length > (maxSlideVisible)

    const carousel8JSX = options.dataList.map((dataSingle, index) => {
        const cardJSX = <div className={Carousel8Style.card}>
            <div className={Carousel8Style.cardTitle}>{dataSingle.title}</div>
            <div className={Carousel8Style.cardSubtitle}>{dataSingle.subtitle}</div>
            <div className={Carousel8Style.cardDescription}>{dataSingle.description}</div>
        </div>

        if (useCarousel) {
            return <Carousel.Slide key={index}>{cardJSX}</Carousel.Slide>
        } else {
            return <div key={index}>{cardJSX}</div>
        }
    })

    return (
        <div className={Carousel8Style.carousel8}>
            <div className={Carousel8Style.title}>
                {!options.coloredTitleAfter && <div className={Carousel8Style.coloredTitle}>{options.coloredTitle}</div>}
                <div>{options.title}</div>
                {options.coloredTitleAfter && <div className={Carousel8Style.coloredTitle}>{options.coloredTitle}</div>}
                <div className={Carousel8Style.background} style={{ backgroundColor: backgroundColor }} ></div>
            </div>

            <div className={Carousel8Style.carousel}>
                
                {
                    useCarousel ? <Carousel
                        controlSize={0}
                        align="start"
                        slideGap={44}
                        slideSize={`${100 / maxSlideVisible}%`}
                        dragFree
                        hasLoop={options.hasLoop || true}
                        styles={{ controls: { display: "none" } }}>{carousel8JSX}</Carousel>
                        : <Group position='center' spacing={44}>
                            {carousel8JSX}
                        </Group>
                }

            </div>
        </div>
    )
}

export default Carousel8
import FormDynamicCheckbox from "@/components/formDynamic/components/primitiveType/formDynamicCheckbox";
import FormDynamicInputSlider from "@/components/formDynamic/components/primitiveType/formDynamicInputSlider";
import FormDynamicNote from "@/components/formDynamic/components/primitiveType/formDynamicNote";
import { FormSectionStatus } from "@/services/constants";
import { Anchor, Button, Group, Stack, Text } from "@mantine/core";
import CryptoJS from "crypto-js";
import dayjs from "dayjs";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import variables from "styles/variables.module.scss";
import FormDynamicInputDate from "../primitiveType/formDynamicInputDate";
import FormDynamicInputDropzone from "../primitiveType/formDynamicInputDropzone";
import FormDynamicInputFileInput from "../primitiveType/formDynamicInputFileInput";
import FormDynamicInputSelect from "../primitiveType/formDynamicInputSelect";
import FormDynamicInputTextInput from "../primitiveType/formDynamicInputTextInput";

function FormDynamicInput(props) {
  let eleHTMl = <></>;
  const typeInput = props.options.inputTemplate.type;
  const searchParams = useSearchParams();
  const dataOcr = searchParams.get("dataOcr");
  const router = useRouter();

  const handleClick = () => {
    //eKYC for shlv
    // Tạo thời gian hiện tại
    const formattedDate = dayjs().format("DD/MM/YYYY HH:mm:ss");

    const key = "kI8HjVowDcVXJOQVJYBOwOKJBsLls4ccQ835FIOh6Q8=";
    const iv = generateBase64KeyAndIv();

    //tạo data
    const data = {
      CertiTypeNotUse: [],
      Language: "vi-VN",
      RequestTime: formattedDate,
      UrlParams: [
        {
          Key: "CODE",
          Value: searchParams.get("child"),
        },
      ],
    };

    encryptInputData(data, key, iv).then((encryptedData) => {
      console.log("router", searchParams.getAll("child"));
      console.log("ivKey", iv);
      console.log("RequestTime", formattedDate);
      console.log("Encrypted Data:", encryptedData);
      console.log("data", data);

      const encodeData = encodeURIComponent(encryptedData);
      const encodeIvKey = encodeURIComponent(iv);
      console.log(
        "url",
        props.options.inputTemplate.options?.url +
          `?data=${encodeData}&ivkey=${encodeIvKey}&system=ERM`
      );
      console.log(props.options.inputTemplate.options?.url +
          `?data=${encodeData}&ivkey=${encodeIvKey}&system=ERM`)
      router.push(
        props.options.inputTemplate.options?.url +
          `?data=${encodeData}&ivkey=${encodeIvKey}&system=ERM`
      );

    });
  };

  async function encryptInputData(data, key, iv) {
    const text = JSON.stringify(data);
    const encoder = new TextEncoder();
    const textBytes = encoder.encode(text);

    const keyBytes = Uint8Array.from(atob(key), (c) => c.charCodeAt(0));
    const ivBytes = Uint8Array.from(atob(iv), (c) => c.charCodeAt(0));

    const cryptoKey = await crypto.subtle.importKey(
      "raw",
      keyBytes,
      { name: "AES-CBC" },
      false,
      ["encrypt"]
    );

    const encryptedBytes = await crypto.subtle.encrypt(
      { name: "AES-CBC", iv: ivBytes },
      cryptoKey,
      textBytes
    );

    const encryptedBase64 = btoa(
      String.fromCharCode(...new Uint8Array(encryptedBytes))
    );
    return encryptedBase64;
  }

  function generateBase64KeyAndIv() {
    const iv = crypto.getRandomValues(new Uint8Array(16));

    const ivBase64 = btoa(String.fromCharCode(...iv));

    return ivBase64;
  }

  useEffect(() => {
    if (dataOcr) {
      const wordArray = CryptoJS.enc.Base64.parse(dataOcr);

      const decodedString = CryptoJS.enc.Utf8.stringify(wordArray);

      const jsonObject = JSON.parse(decodedString);
    }
    if (
      props.options.inputTemplate.validators?.find(
        (e) => e.type === "disabledSubmit"
      ) &&
      props.options.setDisabledSubmit
    ) {
      props.options.setDisabledSubmit(
        (prev) =>
          !props.options.initialValue || props.options.initialValue.length === 0
      );
    }

    if (
      props.options.inputTemplate.validators?.find(
        (e) => e.type === "disabledForm"
      ) &&
      props.options.setDisabledInput
    ) {
      props.options.setDisabledInput(
        (prev) =>
          !props.options.initialValue || props.options.initialValue.length === 0
      );
    }
  }, [props.options.initialValue, dataOcr]);

  const sectionStatus = props.options.sectionStatus;
  const actualDisabled =
    sectionStatus && sectionStatus === FormSectionStatus.APPROVED;

  const disabledInput =
    props.options.disabledInput ||
    actualDisabled ||
    !props.options.requireUpdateInfo;
  const disabledButton = props.options.disabledButton;
  switch (typeInput) {
    case "drop-zone":
    case "file-picker":
    case "file-picker-many":
      eleHTMl = (
        <FormDynamicInputDropzone
          {...props.options}
          disabledInput={disabledInput}
        />
      );
      break;
    case "text":
    case "textarea":
    case "phone":
    case "email":
    case "number":
      eleHTMl = (
        <FormDynamicInputTextInput
          {...props.options}
          disabledInput={disabledInput}
        />
      );
      break;
    // case "button":
    //   eleHTMl = <FormDynamicButton {...props.options} disabledInput={disabledInput} />;
    //   break;
    case "select":
      eleHTMl = (
        <FormDynamicInputSelect
          {...props.options}
          disabledInput={disabledInput}
        />
      );
      break;

    case "date":
      eleHTMl = (
        <FormDynamicInputDate
          {...props.options}
          disabledInput={disabledInput}
        />
      );
      break;

    case "file-input":
      eleHTMl = (
        <FormDynamicInputFileInput
          {...props.options}
          disabledInput={disabledInput}
        />
      );
      break;

    case "checkbox":
    case "radio":
      eleHTMl = (
        <FormDynamicCheckbox {...props.options} disabledInput={disabledInput} />
      );
      break;
    case "note":
      eleHTMl = (
        <FormDynamicNote {...props.options} disabledInput={disabledInput} />
      );
      break;
    case "slider":
      eleHTMl = (
        <FormDynamicInputSlider
          {...props.options}
          disabledInput={disabledInput}
        />
      );
      break;
    case "button":
      eleHTMl = (
        <Button onClick={handleClick} bg={variables.primaryColor} mt={22} disabled={disabledButton}>
          {props.options.inputTemplate.label} {disabledButton}
        </Button>
      );
      break;
    default:
      break;
  }
  return eleHTMl;
}

export default FormDynamicInput;

export function getDescriptionStyleJSX(inputTemplate) {
  if (inputTemplate.descriptionStyle) {
    const getOneDescriptionJSX = (singleStyleObject, key) => {
      switch (singleStyleObject.type) {
        case "text":
          return (
            <Text fz={12} key={key}>
              {singleStyleObject.text}
            </Text>
          );

        case "link":
          return (
            <Anchor
              target={"_blank"}
              key={key}
              style={{
                color: variables.primaryColor,
              }}
              href={singleStyleObject.href}
            >
              {singleStyleObject.text}
            </Anchor>
          );

        case "html":
          return (
            <div
              key={key}
              dangerouslySetInnerHTML={{ __html: singleStyleObject.html }}
            ></div>
          );

        default:
          return <></>;
      }
    };

    const getDescriptionJSX = (parentStyleObject, key = "") => {
      if (parentStyleObject.children) {
        const updatedKey =
          key + (parentStyleObject.style ? parentStyleObject.style : "");
        switch (parentStyleObject.type) {
          case "row":
            return (
              <Group
                position={parentStyleObject.position || "left"}
                spacing={parentStyleObject.spacing || 5}
                key={updatedKey}
              >
                {parentStyleObject.children.map((child, index) =>
                  getDescriptionJSX(child, updatedKey + `-${index}`)
                )}
              </Group>
            );

          case "column":
            return (
              <Stack spacing={parentStyleObject.spacing || 5} key={updatedKey}>
                {parentStyleObject.children.map((child, index) =>
                  getDescriptionJSX(child, updatedKey + `-${index}`)
                )}
              </Stack>
            );

          default:
            return <></>;
        }
      } else {
        return getOneDescriptionJSX(parentStyleObject, key);
      }
    };
    return getDescriptionJSX(inputTemplate.descriptionStyle);
  }
  return <></>;
}

import {
  Box,
  Center,
  Divider,
  Group,
  Image,
  Loader,
  Stack,
  Text,
} from "@mantine/core";
import convert from "color-convert";
import dayjs from "dayjs";
import parse from "html-react-parser";
import Link from "next/link";
import { useEffect, useState } from "react";
import { ChevronRight, Clock, User } from "tabler-icons-react";
import styles from "../../../styles/feature.module.scss";
import globals from "../../../styles/globals.module.scss";
import variables from "../../../styles/variables.module.scss";
import ImagePlaceholder from "../../imagePlaceholder/imagePlaceholder";
import { loadData } from "../gallery";

export function Gallery2(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("huyvn props", props);
    const setDataFunc = (data) => {
      setData([...data]);
      setIsLoading(false);
    };
    loadData(
      {
        url: props.url,
        data: props.optionData,
        query: props.optionQuery,
        jsonata: props.optionJsonata,
        selectedIds: props.optionSelectedIds,
        useDefault: props.useDefault,
      },
      setDataFunc,
      true
    );
  }, []);

  const slideCardJSX = [];

  if (isLoading) {
    return (
      <Center style={{ width: "100%", height: "100%" }}>
        <Loader />
      </Center>
    );
  }

  if (data.length === 0) {
    return <></>;
  }

  if (data.length > 1) {
    let stackCardJSX = [];
    let isImageSlideCard = props.isFirstImageSlide;
    const originalIsImageSlideCard = isImageSlideCard;
    // console.log('huyvn data', data);
    for (let i = 1; i < data.length && i < 5; i++) {
      // console.log(i, data[i]);
      switch (props.options?.cardType) {
        case "newsCard": {
          stackCardJSX.push(
            <Link
              key={`news-card-${i}`}
              href={`${props.optionData}/${data[i].id}?type_child=${props.optionTypeChild}`}
              target={props.options?.target}
            >
              <Gallery2NewsCard
                image={data[i].image}
                title={data[i].title}
                date_created={data[i].date_created}
                author={data[i].author}
                placeholder={
                  props.defaultImage ? props.defaultImage : props.logo
                }
              />
            </Link>
          );
          break;
        }

        default: {
          stackCardJSX.push(
            <Gallery2SlideComponent
              key={`slide-card-${i}`}
              imageComponentProps={{
                image: data[i].image,
                alt: "",
                placeholder: (
                  <ImagePlaceholder
                    display="imagePlaceholder1"
                    height={320}
                    image={props.defaultImage ? props.defaultImage : props.logo}
                  />
                ),
                width: 320,
                height: 320,
              }}
              textComponentProps={{
                name: data[i].title,
                excerpt: data[i].excerpt,
              }}
              link={`${props.optionData}/${data[i].id}?type_child=${props.optionTypeChild}`}
              isImageSlideCard={false}
              isAbleToSlide={false}
            />
          );
          isImageSlideCard = !isImageSlideCard;

          break;
        }
      }
      if (i % 2 === 0 || i + 1 === data.length || i + 1 === 5) {
        isImageSlideCard = !originalIsImageSlideCard;
        const returnStackCardJSX = [...stackCardJSX];
        stackCardJSX = [];
        slideCardJSX.push(
          <Stack spacing={32} key={`stack-slide-${slideCardJSX.length}`}>
            {returnStackCardJSX}
          </Stack>
        );
      }
    }
  }

  const convertColor = convert.hex.rgb(props.primaryColor);
  return (
    <>
      <Box className={styles.gallery2}>
        <Stack
          spacing={32}
          className={styles.gallery2Main}
          style={{
            background: `rgba(${convertColor[0]}, ${convertColor[1]}, ${convertColor[2]},0.1)`,
          }}
        >
          <Stack spacing={16}>
            <Text className={globals.heading1}>{props.title}</Text>
            <Divider
              size={1}
              color={variables.secondColor}
              style={{ width: "100px" }}
            />
          </Stack>
          <Group spacing={32} className={styles.gallery2Body}>
            <Link
              className={styles.firstCard}
              href={`${props.optionData}/${
                data.length > 0 ? data[0].id : "id-0"
              }?type_child=${props.optionTypeChild}`}
            >
              <Stack spacing={0}>
                <Image
                  radius={"40px 40px 0px 0px"}
                  width={520}
                  height={386}
                  src={data.length > 0 ? data[0].image : ""}
                  alt=""
                  withPlaceholder={true}
                  placeholder={
                    <ImagePlaceholder
                      display="imagePlaceholder1"
                      height={386}
                      radius={40}
                      image={
                        props.defaultImage ? props.defaultImage : props.logo
                      }
                    />
                  }
                />
                <Stack
                  justify="start"
                  spacing={0}
                  style={{ position: "relative" }}
                  className={styles.firstCardBody}
                >
                  <Text
                    fz={22}
                    fw={700}
                    c={variables.primaryColor}
                    lineClamp={2}
                  >
                    {data[0].title}
                  </Text>
                  <Group spacing={16} py={8}>
                    <Group spacing={8} style={{fontStyle: "italic"}}>
                      <Clock color="gray" size={16} strokeWidth={1.5} />
                      <Text fz={16} fw={400} c={"gray"}>
                        {dayjs(data[0].date_created).format("DD/MM/YYYY")}
                      </Text>
                    </Group>
                    <Group spacing={8} style={{fontStyle: "italic"}}>
                      <User color="gray" size={16} strokeWidth={1.5} />
                      <Text fz={16} fw={400} c={"gray"}>
                        {data[0].author ?? "Admin"}
                      </Text>
                    </Group>
                  </Group>
                  <Text fz={16} c={"#232323"} lineClamp={6}>
                    {parse(data[0].excerpt || "")}
                  </Text>
                </Stack>
              </Stack>
            </Link>
            {slideCardJSX}
          </Group>
        </Stack>
      </Box>
    </>
  );
}

function Gallery2SlideComponent(props) {
  const imageComponentProps = props.imageComponentProps;
  const textComponentProps = props.textComponentProps;

  const textComponent = (
    <Stack
      justify={"space-between"}
      style={{ zIndex: 2 }}
      className={styles.gallery2TextComponent}
    >
      <Stack spacing={10}>
        <Text lineClamp={2} fz={22} fw={700} c={variables.primaryColor}>
          {textComponentProps.name}
        </Text>
        <Text fz={16} lineClamp={6} c={"#232323"}>
          {parse(textComponentProps.excerpt || "")}
        </Text>
      </Stack>
      <Group spacing={8} align="center">
        <Text fz={20} c={variables.primaryColor}>
          Xem thêm
        </Text>
        <ChevronRight
          size={24}
          color={variables.primaryColor}
          strokeWidth={2}
        />
      </Group>
    </Stack>
  );
  const imageComponent = (
    <div style={{ position: "relative" }}>
      <Image
        src={imageComponentProps.image}
        width={imageComponentProps.width}
        height={imageComponentProps.height}
        alt={imageComponentProps.alt}
        placeholder={imageComponentProps.placeholder}
        withPlaceholder={true}
        radius={40}
      />
      <Stack
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          backgroundColor: "rgba(21, 20, 57, 0.8)",
          height: 160,
          opacity: 0.8,
          padding: 24,
          zIndex: 1,
          justifyContent: "space-between",
        }}
      >
        <Text fz={18} fw={700} c={"white"} lineClamp={2}>
          {textComponentProps.name}
        </Text>
        <Group spacing={8} align="center">
          <Text fz={20} c={"white"}>
            Xem thêm
          </Text>
          <ChevronRight size={24} color={"white"} strokeWidth={2} />
        </Group>
      </Stack>
    </div>
  );

  return (
    <div className={styles.item}>
      <Link href={props.link}>
        {/* <Group spacing={0}>
          {props.isAbleToSlide ? (
            <>
              {props.isImageSlideCard ? textComponent : imageComponent}
              <div className={styles.slideCard}>
                {props.isImageSlideCard ? imageComponent : textComponent}
              </div>
            </>
          ) : (
            imageComponent
          )}
        </Group> */}

        {imageComponent}
      </Link>
    </div>
  );
}

function Gallery2NewsCard({ title, image, placeholder, date_created, author }) {
  // const currentWidth = props.windowDimension.width;
  // const divider = Math.min((currentWidth < 1440) ? 1440 / currentWidth : 1, 3.2);
  return (
    <Stack className={styles.newsCard} spacing={12} style={{padding: "16px"}}>
      <Image
        alt="image"
        radius={24}
        width={'100%'}
        height={160}
        withPlaceholder={true}
        src={image}
        placeholder={
          <ImagePlaceholder
            display="imagePlaceholder1"
            height={160}
            allRadius={24}
            image={placeholder}
          />
        }
      />

      

      <Text lineClamp={4} className={styles.newsCardTitle}>
        {title}
      </Text>

      <Group spacing={16} mb="xs" style={{display: "flex", width: "100%", flexWrap: "nowrap"}}>
        <Group spacing={5} style={{display: "flex", flexWrap: "nowrap"}}>
          <Clock
            color="gray"
            style={{marginBottom: "3px"}}
            size={16}
            // size={16 / Math.min(divider, 1.2)}
            strokeWidth={1.5}
          /> 
          {/* <Text fz={16 / Math.min(divider, 1.2)} fw={400}> */}
          <Text fw={400} color="gray" style={{fontSize: "16px"}} fs="italic">
            {dayjs(date_created).format("DD/MM/YYYY")}
          </Text>
        </Group>
        <Group spacing={5} style={{display: "flex", flexWrap: "nowrap"}}>
          <User
          style={{marginBottom: "3px"}}
            color="gray"
            size={16}
            // size={16 / Math.min(divider, 1.2)}
            strokeWidth={1.5}
          />
          {/* <Text fz={16 / Math.min(divider, 1.2)} fw={400}> */}
          <Text fw={500} size={16} color="gray" fs="italic" style={{width: "130px", whiteSpace: "nowrap",overflow:"hidden", textOverflow: "ellipsis"}}>
            {author ?? "Admin"}
          </Text>
        </Group>
      </Group>
    </Stack>
  );
}

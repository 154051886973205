import { Carousel } from "@mantine/carousel";
import { Box, Center, Group, Image, Stack, Text, Tooltip } from "@mantine/core";
import Carousel8Style from "../carousel8/carousel8.module.scss";
import carouselClasses from "./carousel.module.scss";

import { LocalStorageKey } from "@/services/constants";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { ArrowRight } from "tabler-icons-react";
import { useMediaQuery } from "usehooks-ts";
import { loadData } from "../carouel";
import variables from "../../../styles/variables.module.scss";

export default function Carousel9({
  options,
  url,
  variables,
  defaultImage,
  display,
}) {
  const isMobile = useMediaQuery(`(max-width: 768px)`);
  const isThreshold = useMediaQuery(
    `(max-width: ${options.threshold || 1000}px)`
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    if (options.useApi) {
      loadData(
        {
          url: url,
          data: options.data,
          query: options.query,
          jsonata: options.jsonata,
          display: display,
        },
        setData
      );
    } else {
      setData(options.dataSource || []);
    }

    return () => {
      setData([]);
    };
  }, []);

  const contentJSX = data.map((dataSingle, index) => {
    let cardJSX = <></>;

    let image = null;

    if (dataSingle.image && !dataSingle.image.includes("null")) {
      image = dataSingle.image;
    }
    switch (options.cardType) {
      case "news":
        {
          cardJSX = (
            <div
              className={carouselClasses.newsCard}
              onClick={() => {
                if (dataSingle.data && dataSingle.data.id) {
                  sessionStorage.removeItem(
                    LocalStorageKey.NEWS_DATA + dataSingle.data.id
                  );
                  sessionStorage.setItem(
                    LocalStorageKey.NEWS_DATA + dataSingle.data.id,
                    JSON.stringify({
                      image,
                      title: dataSingle.title,
                      ...dataSingle.data,
                    })
                  );
                  const a = document.createElement("a");
                  a.target = options.target;
                  a.href =
                    options.data +
                    `/${dataSingle.data.id || "1"}` +
                    `?type_child=${options.child}` +
                    "&useApi=false";

                  a.click();
                }
              }}
              style={{
                width: options.width,
                height: options.height,
                position: "relative",
                cursor: "pointer",
              }}
            >
              <Image
                src={image || defaultImage}
                alt=""
                width={320}
                height={192}
                radius={8}
              />
              <Stack
                spacing={16}
                style={{ flex: "1 1 0", justifyContent: "space-between" }}
              >
                <Tooltip withArrow label={dataSingle.title} multiline>
                  <Text lineClamp={3} className={carouselClasses.newsCardTitle}>
                    {dataSingle.title}
                  </Text>
                </Tooltip>

                <Group position="right">
                  <div className={carouselClasses.newsCardExtra}>
                    Xem thêm
                    <ArrowRight size={20} />
                  </div>
                </Group>
              </Stack>
            </div>
          );
        }
        break;
      default: {
        cardJSX = (
          <div className={carouselClasses.defaultCard}>
            <Center
              bg={dataSingle.background || variables.secondColor}
              h={"100%"}
              style={{ borderRadius: 8 }}
              px={8}
            >
              <Text
                className={Carousel8Style.title9}
                c={dataSingle.color || variables.primaryColor}
              >
                {(index + 1).toString().padStart(2, "0")}
              </Text>
            </Center>
            <Stack
              spacing={16}
              style={{ minHeight: 150, justifyContent: "start" }}
            >
              <Text
                className={Carousel8Style.title9}
                c={dataSingle.color || variables.primaryColor}
              >
                {dataSingle.title}
              </Text>
              <Text
                c={"var(--primary-4)"}
                lineClamp={4}
                className={Carousel8Style.content9}
              >
                {parse(dataSingle.description)}
              </Text>
            </Stack>
          </div>
        );
      }
    }

    return (
      <Carousel.Slide
        key={index}
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        {cardJSX}
      </Carousel.Slide>
    );
  });

  return (
    <div
      className={Carousel8Style.carousel8}
      style={{ background: options.background }}
    >
      <Group position="center">
        <div className={Carousel8Style.title}>
          {/* {!options.coloredTitleAfter && <div className={Carousel8Style.coloredTitle}>{options.coloredTitle}</div>} */}
          {parse(options.title || "")}
          {/* {options.coloredTitleAfter && <div className={Carousel8Style.coloredTitle}>{options.coloredTitle}</div>} */}
          <div
            className={Carousel8Style.background}
            style={{ backgroundColor: variables.secondColor, width: "120%" }}
          ></div>
        </div>
      </Group>

      <Box mt={44} px={isThreshold ? 10 : 44}>
        <Carousel
          slideSize={
            isMobile ? "100%" : isThreshold ? "50%" : options.width || "634px"
          }
          slidesToScroll={1}
          pb={140}
          classNames={carouselClasses}
          align="start"
          slideGap={32}
          loop={options.hasLoop || true}
          draggable={false}
          withIndicators
          previousControlIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.88029 14.0899L4.75029 17.9999C4.84325 18.0936 4.95385 18.168 5.07571 18.2188C5.19757 18.2696 5.32828 18.2957 5.46029 18.2957C5.5923 18.2957 5.72301 18.2696 5.84487 18.2188C5.96673 18.168 6.07733 18.0936 6.17029 17.9999C6.26402 17.9069 6.33841 17.7963 6.38918 17.6745C6.43995 17.5526 6.46609 17.4219 6.46609 17.2899C6.46609 17.1579 6.43995 17.0272 6.38918 16.9053C6.33841 16.7835 6.26402 16.6729 6.17029 16.5799L2.61029 12.9999H23.0003C23.2655 12.9999 23.5199 12.8945 23.7074 12.707C23.8949 12.5195 24.0003 12.2651 24.0003 11.9999C24.0003 11.7347 23.8949 11.4803 23.7074 11.2928C23.5199 11.1053 23.2655 10.9999 23.0003 10.9999H2.55029L6.17029 7.3799C6.34758 7.19392 6.44648 6.94684 6.44648 6.6899C6.44648 6.43296 6.34758 6.18588 6.17029 5.9999C6.07733 5.90617 5.96673 5.83178 5.84487 5.78101C5.72301 5.73024 5.5923 5.7041 5.46029 5.7041C5.32828 5.7041 5.19757 5.73024 5.07571 5.78101C4.95385 5.83178 4.84325 5.90617 4.75029 5.9999L0.88029 9.8499C0.318488 10.4124 0.00292969 11.1749 0.00292969 11.9699C0.00292969 12.7649 0.318488 13.5274 0.88029 14.0899Z"
                fill={variables.primaryColor}
              />
            </svg>
          }
          // nextControlIcon={<Image alt="" src="/images/arrow-right.svg" width={24} height={24} />}
          nextControlIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.12 9.9099L19.25 5.9999C19.157 5.90617 19.0464 5.83178 18.9246 5.78101C18.8027 5.73024 18.672 5.7041 18.54 5.7041C18.408 5.7041 18.2773 5.73024 18.1554 5.78101C18.0336 5.83178 17.923 5.90617 17.83 5.9999C17.6437 6.18726 17.5392 6.44071 17.5392 6.7049C17.5392 6.96909 17.6437 7.22254 17.83 7.4099L21.39 10.9999H1C0.734784 10.9999 0.48043 11.1053 0.292893 11.2928C0.105357 11.4803 0 11.7347 0 11.9999H0C0 12.2651 0.105357 12.5195 0.292893 12.707C0.48043 12.8945 0.734784 12.9999 1 12.9999H21.45L17.83 16.6099C17.7363 16.7029 17.6619 16.8135 17.6111 16.9353C17.5603 17.0572 17.5342 17.1879 17.5342 17.3199C17.5342 17.4519 17.5603 17.5826 17.6111 17.7045C17.6619 17.8263 17.7363 17.9369 17.83 18.0299C17.923 18.1236 18.0336 18.198 18.1554 18.2488C18.2773 18.2996 18.408 18.3257 18.54 18.3257C18.672 18.3257 18.8027 18.2996 18.9246 18.2488C19.0464 18.198 19.157 18.1236 19.25 18.0299L23.12 14.1499C23.6818 13.5874 23.9974 12.8249 23.9974 12.0299C23.9974 11.2349 23.6818 10.4724 23.12 9.9099Z"
                fill={variables.primaryColor}
              />
            </svg>
          }
        >
          {contentJSX}
        </Carousel>
      </Box>

      {/* <div className={Carousel8Style.carousel}>
                123
            </div> */}
    </div>
  );
}

import { FormSectionStatus, FormTemplateFieldTypeBackEnd } from "@/services/constants";
import * as EmailValidator from "email-validator";
import moment from "moment";

export const getCustomValidation = (
  validators = [],
  fieldTemplate = undefined,
  currentRouterId = "",
  hideArray = [],
  currentSectionStatus = null
) => {
  if (!fieldTemplate || !fieldTemplate.type) {
    return undefined;
  }

  if (
    (hideArray.length > 0 && hideArray.includes(currentRouterId)) ||
    (currentSectionStatus && currentSectionStatus === FormSectionStatus.APPROVED)
  ) {
    return undefined;
  }
  const getDynamicValidationFunc = (validatorType, inputLabel, validatorArg = undefined) => {
    switch (validatorType) {
      case "required":
        return (value, formValues, currentName) => {
          if (value === undefined || fieldTemplate.disabled) return null;
          if (!value || (Array.isArray(value) && value.length === 0) || String(value).length === 0)
            return `${inputLabel} không được trống`;
          return null;
        };
      case "phone":
        return (value) => {
          if (value === undefined || !value || String(value).length === 0) return null;

          const phoneRegex = new RegExp("^(0)\\d{9}$");

          if (!phoneRegex.test(value)) {
            return `${inputLabel} không đúng định dạng số điện thoại`;
          }
          return null;
        };
      case "regExp":
        return (value) => {
          if (value === undefined || !value || String(value).length === 0 || !validatorArg) return null;

          const regex = new RegExp(validatorArg);

          if (!regex.test(value)) {
            return `${inputLabel} không đúng định dạng `;
          }
          return null;
        };

      case "maxLength":
      case "minLength":
        return (value) => {
          if (value === undefined || !value || String(value).length === 0 || !validatorArg) return null;
          const length = parseInt(validatorArg);

          const isMax = validatorType === "maxLength";
          const maxBool = String(value).length > length;

          if (!isNaN(length) && ((isMax && maxBool) || (!isMax && !maxBool))) {
            return `${inputLabel} phải ${isMax ? "ngắn" : "dài"} hơn ${length} kí tự`;
          }
          return null;
        };

      case "email":
        return (value) => {
          if (value === undefined || !value || String(value).length === 0) return null;
          if (!EmailValidator.validate(value)) return `${inputLabel} không đúng định dạnh email`;
          return null;
        };

      case "startDate":
      case "endDate":
        return (value, formValues, currentName) => {
          if (!validatorArg) {
            return null;
          }

          const targetNameArray = currentName.replace(fieldTemplate.name, validatorArg).split(".");
          let targetValue = undefined;
          targetNameArray.forEach((name) => {
            if (!targetValue) {
              targetValue = formValues[name];
            } else {
              targetValue = targetValue[name];
            }
          });
          if (targetValue === undefined || !targetValue || String(targetValue).length === 0) return null;

          const momentValue = moment(value);
          const momentTargetValue = moment(targetValue);

          if (!momentValue.isValid() || !momentTargetValue.isValid()) {
            return null;
          }

          if (validatorType === "startDate" && momentValue.isAfter(momentTargetValue)) {
            return "Ngày bắt đầu không được sau ngày kết thúc";
          }

          if (validatorType === "endDate" && momentValue.isBefore(momentTargetValue)) {
            return "Ngày kết thúc không được trước ngày bắt đầu";
          }

          return null;
        };

      case "dob":
        return (value) => {
          if (
            (validatorArg &&
              ((validatorArg.minAge && isNaN(validatorArg.minAge)) ||
                (validatorArg.maxAge && isNsaN(validatorArg.maxAge)))) ||
            value === undefined ||
            String(value).length === 0
          ) {
            return null;
          }

          const momentValue = moment(value);

          if (!momentValue.isValid()) {
            return `${inputLabel} không đúng định dạng`;
          }

          const minAge = validatorArg && validatorArg.minAge ? validatorArg.minAge : 18;
          const maxAge = validatorArg && validatorArg.maxAge ? validatorArg.maxAge : 110;

          const difference = moment().diff(momentValue, "years");

          const minBool = difference < minAge;
          const maxBool = difference >= maxAge;

          const isMin = !!(
            validatorArg &&
            ((validatorArg.minAge && !validatorArg.maxAge) || validatorArg.type === "min")
          );
          const isMax = !validatorArg || (validatorArg.maxAge && !validatorArg.minAge) || validatorArg.type === "max";
          const isBoth = !!(
            validatorArg &&
            ((validatorArg.maxAge && validatorArg.minAge) || validatorArg.type === "both")
          );

          if ((isMin && minBool) || (isMax && maxBool) || (isBoth && (!minBool || !maxBool))) {
            if (validatorArg && validatorArg.errorMessage) {
              return validatorArg.errorMessage;
            }
            return `${inputLabel} không đúng định dạng`;
          }

          return null;
        };
      default:
        return null;
    }
  };
  let dynamicValidationFunctions = [];
  if (validators)
    dynamicValidationFunctions = validators.map((validator) =>
      getDynamicValidationFunc(validator.type, fieldTemplate.label, validator.args)
    );

  if (
    (fieldTemplate.type === "phone" && (!validators || !validators.find((e) => e.type === "phone"))) ||
    fieldTemplate.type === "email"
  )
    dynamicValidationFunctions.push(getDynamicValidationFunc(fieldTemplate.type, fieldTemplate.label));

  let customValidationFunction = undefined;
  if (dynamicValidationFunctions.length > 0) {
    customValidationFunction = (value, formValues, currentName) => {
      let mainErrorMessage = [];
      dynamicValidationFunctions.forEach((func) => {
        if (func) {
          const errorMessage = func(value, formValues, currentName);
          if (errorMessage) {
            mainErrorMessage.push(<p key={`mainErrorMessage - ${mainErrorMessage.length}`}>{errorMessage}</p>);
          }
        }
      });
      return mainErrorMessage.length > 0 ? <div>{mainErrorMessage}</div> : null;
    };
  }
  return customValidationFunction;
};

const handleInitialValueAndCustomValidation = (fieldTemplate, currentRouterId = "", currentSectionStatus = null) => {
  let initialValue = "";
  const customValidation = getCustomValidation(
    fieldTemplate.validators,
    fieldTemplate,
    currentRouterId,
    fieldTemplate.hide || [],
    currentSectionStatus
  );
  if (["file-input", "drop-zone", "file-picker"].includes(fieldTemplate.type) || fieldTemplate.type?.includes("file")) {
    initialValue = null;
  }

  if (["checkbox", "file-picker-many"].includes(fieldTemplate.type)) {
    initialValue = [];
  }

  return { customValidation, initialValue };
};

const setInitialValueAndValidateForField = (fieldTemplateSingle, initialValue, customValidation) => {
  const fieldTemplateName = fieldTemplateSingle.name;
  const initialValueObject = {};
  const validateObject = {};
  if (fieldTemplateName.includes(".")) {
    const fieldTemplateNameSplit = fieldTemplateName.split(".");
    if (!initialValueObject[fieldTemplateNameSplit[0]]) {
      initialValueObject[fieldTemplateNameSplit[0]] = {};
      validateObject[fieldTemplateNameSplit[0]] = {};
    }
    initialValueObject[fieldTemplateNameSplit[0]][fieldTemplateNameSplit[1]] = initialValue;
    validateObject[fieldTemplateNameSplit[0]][fieldTemplateNameSplit[1]] = customValidation;
  } else {
    initialValueObject[fieldTemplateName] = initialValue;
    validateObject[fieldTemplateName] = customValidation;
  }

  return { initialValueObject, validateObject };
};

const basicsKey = "basics";

export function setupDynamicForm(
  fieldTemplateArray,
  sectionStatusArray = [],
  currentRouterId = "",
  currentSectionStatus = undefined,
  initialValueObjectInput = null,
  validateObjectInput = null
) {
  let initialValueObject = {};
  let validateObject = {};

  if (initialValueObjectInput) {
    initialValueObject = initialValueObjectInput;
  } else if (!initialValueObject[basicsKey]) {
    initialValueObject[basicsKey] = {};
  }

  if (validateObjectInput) {
    validateObject = validateObjectInput;
  } else if (!validateObject[basicsKey]) {
    validateObject[basicsKey] = {};
  }

  fieldTemplateArray.forEach((fieldTemplateMain) => {
    // ======= Bắt đầu Xử lý notApplicable validators ======
    fieldTemplateMain.validators = fieldTemplateMain.validators?.filter(e => e.notApplicable === undefined || e.notApplicable !== currentRouterId);
    // ======= Kết thúc Xử lý notApplicable validators ======

    if (fieldTemplateMain.type === "group") {
      let sectionStatus = currentSectionStatus;
      if (sectionStatusArray.length > 0) {
        const sectionStatusSingle = sectionStatusArray.find((e) => {
          return e.sectionCode === fieldTemplateMain.name;
        });
        if (sectionStatusSingle && sectionStatusSingle.sectionStatus) {
          sectionStatus = sectionStatusSingle.sectionStatus;
        }
      }

      const returnObject = setupDynamicForm(fieldTemplateMain.fields, [], currentRouterId, sectionStatus, {}, {});
      if (fieldTemplateMain.name && !initialValueObject[fieldTemplateMain.name]) {
        initialValueObject[fieldTemplateMain.name] = {};
      }
      if (fieldTemplateMain.name && !validateObject[fieldTemplateMain.name]) {
        validateObject[fieldTemplateMain.name] = {};
      }
      if (fieldTemplateMain.typeGroup) {
        if (fieldTemplateMain.typeGroup === "main") {
          Object.keys(returnObject.initialValueObject).forEach((key) => {
            initialValueObject[fieldTemplateMain.name][key] = returnObject.initialValueObject[key];
          });

          Object.keys(returnObject.validateObject).forEach((key) => {
            validateObject[fieldTemplateMain.name][key] = returnObject.validateObject[key];
          });
        } else {
          initialValueObject = Object.assign(initialValueObject, returnObject.initialValueObject);
          validateObject = Object.assign(validateObject, returnObject.validateObject);
        }
      } else {
        initialValueObject[fieldTemplateMain.name] = [returnObject.initialValueObject];
        validateObject[fieldTemplateMain.name] = returnObject.validateObject;
      }
    } else {
      const handleReturnObject = (returnObject) => {
        Object.keys(returnObject.initialValueObject).forEach((fieldTemplateName) => {
          if (fieldTemplateName === basicsKey) {
            if (!initialValueObject[basicsKey]) {
              initialValueObject[basicsKey] = {};
            }
            initialValueObject[basicsKey] = Object.assign(
              initialValueObject[basicsKey],
              returnObject.initialValueObject[basicsKey]
            );
          } else {
            initialValueObject[fieldTemplateName] = returnObject.initialValueObject[fieldTemplateName];
          }
        });
        Object.keys(returnObject.validateObject).forEach((fieldTemplateName) => {
          if (fieldTemplateName === basicsKey) {
            if (!validateObject[basicsKey]) {
              validateObject[basicsKey] = {};
            }
            validateObject[basicsKey] = Object.assign(
              validateObject[basicsKey],
              returnObject.validateObject[basicsKey]
            );
          } else {
            validateObject[fieldTemplateName] = returnObject.validateObject[fieldTemplateName];
          }
        });
      };

      const handleOneFieldTemplate = (fieldTemplate) => {
        const constantValue = handleInitialValueAndCustomValidation(
          fieldTemplate,
          currentRouterId,
          currentSectionStatus
        );
        const returnObject = setInitialValueAndValidateForField(
          fieldTemplate,
          constantValue.initialValue,
          constantValue.customValidation
        );
        handleReturnObject(returnObject);
      };

      handleOneFieldTemplate(fieldTemplateMain);
    }
  });

  return { initialValueObject, validateObject };
}

const findValidatorType = (formTemplateField, type) => {
  if (!formTemplateField.validators) {
    return undefined;
  }
  if (!Array.isArray(formTemplateField.validators)) {
    return undefined;
  }
  return formTemplateField.validators.find((e) => e.type === type);
};

export const mapFormTemplateFieldRefactor = (formTemplateField) => {
  if (["group", "array"].includes(formTemplateField.type)) {
    switch (formTemplateField.type) {
      case "group":
        formTemplateField.typeGroup = "partial";
        if (findValidatorType(formTemplateField, "groupMain")) {
          formTemplateField.typeGroup = "main";
        }

        const checkboxIndex = formTemplateField.fields.findIndex((e) => {
          if (
            e.type === "checkbox" &&
            e.select &&
            e.select.options &&
            e.select.options.length > 0 &&
            e.select.options.find((e) => e.children && e.children.length > 0)
          ) {
            return e;
          }
        });

        if (checkboxIndex >= 0) {
          const checkboxControlIndex = [];
          const selectObject = structuredClone(formTemplateField.fields[checkboxIndex].select);
          if (selectObject && Array.isArray(selectObject.options) && selectObject.options.length > 0) {
            formTemplateField.fields[checkboxIndex].select.options = structuredClone(selectObject.options).map(
              (eOption) => {
                eOption.children = eOption.children.map((eOptionChild) => {
                  const childrenIndex = formTemplateField.fields.findIndex((e) => e.name === eOptionChild.name);
                  if (childrenIndex >= 0) {
                    checkboxControlIndex.push(childrenIndex);
                    eOption.controlIndex = childrenIndex;
                  }
                  return eOptionChild;
                });
                return eOption;
              }
            );
          }

          if (checkboxControlIndex.length > 0) {
            formTemplateField.checkboxMasterIndex = checkboxIndex;
            formTemplateField.checkboxControlIndex = checkboxControlIndex;
          }
        }

        break;
      case "array":
        formTemplateField.type = "group";
        if (formTemplateField.field && formTemplateField.field.fields) {
          formTemplateField.fields = structuredClone(formTemplateField.field.fields);
        }

        break;

      default:
        break;
    }

    formTemplateField.fields = formTemplateField.fields.map(mapFormTemplateFieldRefactor);

    return formTemplateField;
  } else {
    switch (formTemplateField.type) {
      case FormTemplateFieldTypeBackEnd.SELECT:
        if (formTemplateField.masterdataCode) {
          if (!formTemplateField["select"]) {
            formTemplateField["select"] = {
              masterdata: formTemplateField.masterdataCode,
              options: [],
            };
          } else {
            if (Array.isArray(formTemplateField["select"])) {
              formTemplateField["select"] = {
                masterdata: formTemplateField.masterdataCode,
                options: [...formTemplateField["select"]],
                valueField: "value",
                labelField: "label",
              };
            }
          }
        }
        break;

      case FormTemplateFieldTypeBackEnd.DROP_ZONE:
        formTemplateField.type = "drop-zone";
        break;

      case FormTemplateFieldTypeBackEnd.FILE:
        ["file-picker", "file-picker-many", "file-input"].forEach((type) => {
          if (findValidatorType(formTemplateField, type)) {
            formTemplateField.type = type;
          }
        });

        break;

      case FormTemplateFieldTypeBackEnd.DATE_RANGE:
        if (formTemplateField.mode === "date-picker") {
          formTemplateField.type = "date";
        }
        break;

      default:
        break;
    }

    if (findValidatorType(formTemplateField, "labelNoWrap")) {
      formTemplateField.labelNoWrap = true;
    }

    const linkValidator = findValidatorType(formTemplateField, "hasLink");
    if (linkValidator) {
      const linkOptions = linkValidator.args.split("\\");
      if (
        linkOptions.length === 2 &&
        formTemplateField.select &&
        Array.isArray(formTemplateField.select.options) &&
        formTemplateField.select.options.length > 0
      ) {
        formTemplateField.select.options[0] = {
          ...formTemplateField.select.options[0],
          hasLink: true,
          linkLabel: linkOptions[0],
          linkUrl: linkOptions[1],
        };
      }
    }

    return formTemplateField;
  }
};

export const mapSectionToFormTemplateFields = (sectionArray) => {
  const returnArray = [];
  sectionArray.forEach((section) => {
    if (!section.sectionCode) {
      return;
    }
    returnArray.push({
      type: "group",
      label: section.formTemplate?.title,
      typeGroup: "main",
      name: section.sectionCode,
      fields: (section.formTemplate?.fields || []).map(mapFormTemplateFieldRefactor),
    });
  });

  return returnArray;
};

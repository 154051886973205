import ModalContent from "@/components/modal/modalContent";
import { Group, Modal, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Link from "next/link";
import Carousel5Card from "../helpers/carousel5Card";

function List8CardWithModal(props) {
    const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
    const modalWidthSize = 800;
    return (
        <>
            <Link href={`/recruitments/${props.dataSingleton.id}?type_child=${props.typeChild}`}>
                <Carousel5Card
                    {...props.dataSingleton}
                    imageLogo={props.imageLogo}
                    defaultImage={props.defaultImage}
                    handleButtonClick={openModal} />
            </Link>
            <Modal overflow={'inside'} size={modalWidthSize} opened={modalOpened} onClose={closeModal} title={
                <Group spacing={5}>
                    <Text fw={700} fz={16} c={props.primaryColor}>{trans.applyFor} </Text>
                    <Text fw={700} fz={16} c={props.primaryColor}>{props.dataSingleton.name}</Text>
                </Group>
            }>
                <ModalContent display={"modal-content1"}
                    contentWidthSize={modalWidthSize}
                    url={props.url}
                    channelType={props.dataSingleton.channelType}
                    recruitmentPlanId={props.dataSingleton.id}
                    primaryColor={props.primaryColor}
                    secondaryColor={props.secondaryColor}
                    closeModal={closeModal} />
            </Modal>
        </>
    )
}

export default List8CardWithModal

/* eslint-disable react-hooks/exhaustive-deps */
import { Gallery1 } from "@/components/gallery/gallery1/gallery1";
import { Gallery2 } from "@/components/gallery/gallery2/gallery2";
import { Gallery3R } from "@/components/gallery/gallery3/gallery3";
import { Gallery4 } from "@/components/gallery/gallery4/gallery4";
import { Gallery5 } from "@/components/gallery/gallery5/gallery5";
import { getDataFromCollection } from "services/services";

export default function Gallery(props) {
  let eleHTML = <></>;
  switch (props.display) {
    case "gallery1":
      eleHTML = (
        <Gallery1
          url={props.url}
          title={props.options.title}
          logo={props.image}
          optionData={props.options.data}
          optionQuery={""}
          optionJsonata={props.options.jsonata}
          optionTypeChild={props.options.child}
          background={props.background}
          defaultImage={props.defaultImage}
          optionSelectedIds={props.options.selectedIds || props.options.hotJobIds || props.options.hotNewsIds}
        />
      );
      break;

    //Galery cho News
    case "gallery2":
      if (props.windowDimension.width >= (props.options?.width?.threshold || 1450)) {
        eleHTML = (
          <Gallery2
            url={props.url}
            title={props.options.title}
            logo={props.image}
            optionData={props.options.data}
            optionQuery={""}
            optionJsonata={props.options.jsonata}
            optionTypeChild={props.options.child}
            background={props.background}
            primaryColor={props.primaryColor}
            secondaryColor={props.secondaryColor}
            isFirstImageSlide={false}
            useDefault={props.options.useDefault}
            defaultImage={props.defaultImage}
            options={props.options}
            optionSelectedIds={props.options.selectedIds || props.options.hotJobIds || props.options.hotNewsIds}
          />
        );
      }

      break;

    // Gallery thể hiện hình ảnh trong /Introduction
    case "gallery3":
      eleHTML = (
        <Gallery3R
          {...props.options}
          windowDimension={props.windowDimension}
          primaryColor={props.primaryColor}
          secondaryColor={props.secondaryColor}
          defaultImage={props.defaultImage}
        />
      );

      break;

    //Gallery cho /news kiểu 1 vertical card bên trái và 2 horizontal card bên phải
    case "gallery4":
      eleHTML = (
        <Gallery4
          url={props.url}
          title={props.options.title}
          logo={props.image}
          optionData={props.options.data}
          optionQuery={""}
          optionJsonata={props.options.jsonata}
          optionTypeChild={props.options.child}
          defaultImage={props.defaultImage}
          optionSelectedIds={props.options.selectedIds || props.options.hotJobIds || props.options.hotNewsIds}
        />
      );

      break;

    case "gallery5":
      eleHTML = (
        <Gallery5
          options={props.options}
        />
      );
      break;
      default:
      break;
  }
  return eleHTML;
}

export function loadData(loadOptions, setDataFunc, sortByDate = false) {
  const loadDataFunc = async () =>
    await getDataFromCollection(
      loadOptions.url,
      loadOptions.data,
      loadOptions.query,
      loadOptions.jsonata,
      loadOptions.selectedIds,
      loadOptions.useDefault
    );
  loadDataFunc().then((res) => {
    if (res) {
      if (sortByDate) {
        const resSorted = res.sort((a, b) => {
          const date1 = new Date(a.date);
          const date2 = new Date(b.date);
          return date2 - date1;
        });
        setDataFunc(resSorted);
      } else {
        setDataFunc(res);
      }
    } else {
      setDataFunc([]);
    }
  });
}

import { Carousel } from "@mantine/carousel";
import {
  Checkbox,
  Group,
  Image,
  Modal,
  MultiSelect,
  Radio,
  Select,
  Stack,
  Text,
  TextInput,
  Box,
} from "@mantine/core";
import { useDisclosure, useClickOutside } from "@mantine/hooks";
import convert from "color-convert";
import Autoplay from "embla-carousel-autoplay";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { ChevronDown, ChevronRight } from "tabler-icons-react";
import { useMediaQuery } from "usehooks-ts";
import { getMasterdata } from "../../../services/services";
import Search3Styles from "./search3.module.scss";
import useTrans from "@/hooks/useTrans";

export function Search3({
  options: {
    bannerImage,
    searchFields,
    handleSearch,
    extraFields = [],
    delay,
    threshold,
  },
  url,
  primaryColor,
  variables,
}) {
  const trans = useTrans();
  const isThreshold = useMediaQuery(`(max-width:${threshold || 1000}px)`);

  const [dynamicFilters, setDynamicFilters] = useState([]);
  const [masterdataArray, setMasterdataArray] = useState([]);
  const [sortOption, setSortOption] = useState(null);
  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const [transform, setTransform] = useState(dynamicFilters.map(() => false));
  const ref = useClickOutside(() => setTransform(dynamicFilters.map(() => false)));

  const convertColor = convert.hex.hsl(primaryColor);
  const hoverColor = `hsla(${convertColor[0]}, ${convertColor[1]}%, ${convertColor[2]}%, 0.1)`;
  const router = useRouter();
  const query = router.query;

  const [autoplay, setAutoPlay] = useState(null);

  useEffect(() => {
    if (!Array.isArray(searchFields)) {
      return;
    }
    const promiseArray = [];

    searchFields.forEach(({ masterdata }, index) => {
      if (masterdata) {
        const promise = getMasterdata(url, masterdata.key).then((res) => {
          let selectData = res;
          if (masterdata.metadataType)
            selectData = res.filter((e) => {
              const metadataType = e.metadata.find(
                (metadataSingle) =>
                  metadataSingle.type === masterdata.metadataType
              );
              return metadataType !== undefined;
            });

          return {
            data: selectData.map((selectDataSingle) => ({
              label: selectDataSingle.label,
              value: selectDataSingle.label,
              actualValue: selectDataSingle.value,
            })),
            index: index,
            key: masterdata.key,
          };
        });
        promiseArray.push(promise);
      }
    });

    Promise.all(promiseArray).then((resArray) => {
      setMasterdataArray(resArray);
    });

    setDynamicFilters(
      searchFields.map(
        ({ type, key, searchType, masterdata, label, placeholder }) => {
          return {
            type,
            key,
            searchType,
            masterdata,
            label,
            placeholder,
            value: type === "multiple" ? [] : "",
          };
        }
      )
    );
  }, []);

  useEffect(() => {
    if (query.type && query.value && masterdataArray.length > 0) {
      const dynamicFilterTemp = [...dynamicFilters];
      if (query.type === "masterdata" && query.key) {
        const masterdataSingle = masterdataArray.find(({ key }) => {
          return key === query.key;
        });
        if (masterdataSingle) {
          const dataSingle = masterdataSingle.data.find((dataSingle) => {
            return dataSingle.actualValue === query.value;
          });

          if (dataSingle) {
            dynamicFilterTemp[masterdataSingle.index].value =
              searchFields[masterdataSingle.index].type === "multiple"
                ? [dataSingle.value]
                : dataSingle.value;
          }
        }
      }

      if (query.type === "text" && query.index) {
        dynamicFilterTemp[query.index].value = query.value;
      }

      setDynamicFilters(dynamicFilterTemp);
    }
  }, [query, masterdataArray]);

  useEffect(() => {
    if (handleSearch) {
      handleSearch(dynamicFilters, sortOption);
    }
  }, [dynamicFilters, sortOption]);

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      setAutoPlay(Autoplay({ delay: delay > 0 ? delay * 1000 : 5000 }));
    }
  }, [initialized]);

  if (!Array.isArray(searchFields)) {
    return <></>;
  }

  const searchFieldJSX = dynamicFilters.map(
    ({ type, key, searchType, masterdata, label, placeholder }, index) => {
      switch (type) {
        case "text":
          return (
            <Stack
              key={index}
              spacing={16}
              style={{ width: `${100 / searchFields.length}%` }}
              className={Search3Styles.input}
            >
              <Text className={Search3Styles.label}>{label}</Text>
              <TextInput
                value={dynamicFilters[index].value}
                placeholder={placeholder}
                onChange={(e) => {
                  const dynamicFilterTemp = [...dynamicFilters];
                  dynamicFilterTemp[index].value = e.target.value;
                  setDynamicFilters(dynamicFilterTemp);
                }}
              />
            </Stack>
          );

        case "single": {
          const selectData =
            masterdataArray.find((e) => e.index === index)?.data || [];

          return (
            <Stack
              key={index}
              spacing={16}
              style={{ width: `${100 / searchFields.length}%` }}
              className={Search3Styles.input}
            >
              <Text className={Search3Styles.label}>{label}</Text>
              <Select
                style={{ position: "relative" }}
                placeholder={placeholder}
                dropdownPosition="bottom"
                value={dynamicFilters[index].value}
                rightSection={
                  
                    <Box
                      w={24}
                      h={24}
                      style={{
                        transition: "transform 0.3s ease-in-out",
                        transform: transform[index]
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    >
                      <ChevronDown />
                    </Box>
                  
                }
                data={[{ label: trans.all, value: "" }, ...selectData]}
                transitionDuration={80}
                transitionTimingFunction="ease"
                onClick={() => {
                  const transformTemp = [...transform];
                  transformTemp[index] = !transformTemp[index];
                  setTransform(transformTemp);
                }}
                onChange={(value) => {
                  const dynamicFilterTemp = [...dynamicFilters];
                  dynamicFilterTemp[index].value = value;
                  setDynamicFilters(dynamicFilterTemp);
                }}
                styles={(theme) => ({
                  itemsWrapper: {
                    gap: 8,
                    borderRadius: 16,
                    boxShadow: "0px 0px 30px 0px hsla(0, 0%, 20%, 0.05)",
                    padding: "16px 0 16px 0",
                  },
                  item: {
                    padding: "4px 16px 4px 16px",
                    fontSize: 16,
                    textAlign: "left",
                    "&[data-hovered]": {
                      background: hoverColor,
                    },

                    "&[data-selected]": {
                      background: primaryColor,
                    },
                  },
                })}
              ></Select>
            </Stack>
          );
        }

        case "multiple": {
          const selectData =
            masterdataArray.find((e) => e.index === index)?.data || [];
          const value = dynamicFilters[index].value;

          // const valueComponent = ({label, value}, {...others}) => {
          //     return <div></div>
          // }

          return (
            <Stack
              key={index}
              spacing={16}
              style={{ width: `${100 / searchFields.length}%` }}
              className={Search3Styles.inputMultiSelect}
            >
              <Text className={Search3Styles.label}>{label}</Text>
              <MultiSelect
                ref={ref}
                style={{ position: "relative" }}
                dropdownPosition="bottom"
                value={value}
                data={selectData}
                clearable
                onClick={() => {
                  const transformTemp = [...transform];
                  transformTemp[index] = !transformTemp[index];
                  setTransform(transformTemp);
                }}
                onChange={(value) => {
                  const dynamicFilterTemp = [...dynamicFilters];
                  dynamicFilterTemp[index].value = value;
                  setDynamicFilters(dynamicFilterTemp);
                }}
                rightSection={
                  value.length === 0 && (
                    <Box
                      w={24}
                      h={24}
                      style={{
                        transition: "transform 0.3s ease-in-out",
                        transform: transform[index]
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    >
                      <ChevronDown />
                    </Box>
                  )
                }
                styles={(theme) => ({
                  rightSection: {
                    pointerEvents: "none",
                  },
                  input: {
                    border: "none",
                    borderBottom: "1px solid hsla(249, 8%, 92%, 1)",
                    "&:focus-within": {
                      borderBottom: `1px solid ${primaryColor};`,
                    },
                  },
                  itemsWrapper: {
                    gap: 8,
                    borderRadius: 16,
                    boxShadow: "0px 0px 30px 0px hsla(0, 0%, 20%, 0.05)",
                    padding: "16px 0 16px 0",
                  },
                  item: {
                    padding: "4px 16px 4px 16px",
                    fontSize: 16,
                    textAlign: "left",
                    "&[data-hovered]": {
                      background: hoverColor,
                    },
                  },
                  values: {
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    flexWrap: "nowrap",
                    overflowX: "auto",
                  },
                  value: {
                    borderRadius: 1000,
                    padding: 10,
                    fontSize: 12,
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    background: primaryColor,
                    color: "white",
                    svg: {
                      color: "white",
                    },
                  },
                })}
              />
            </Stack>
          );
        }
        default:
          return <div key={index}></div>;
      }
    }
  );

  const extraFieldJSX = extraFields?.map(
    ({ type, key, label, metadata }, index) => {
      switch (type) {
        case "sort": {
          return (
            <Select
              key={index}
              style={{ position: "relative", ...metadata?.style }}
              dropdownPosition="bottom"
              value={sortOption}
              rightSection={
                <Image
                  src={metadata?.icon?.src}
                  alt="icon"
                  width={24}
                  height={24}
                />
              }
              data={[
                { label: metadata?.nullOptionLabel || "Mặc định", value: null },
                ...(metadata?.options || []),
              ]}
              radius={100}
              transitionDuration={80}
              transitionTimingFunction="ease"
              onChange={(newSortOption) => {
                setSortOption(newSortOption);
                handleSearch(dynamicFilters, newSortOption);
              }}
              styles={(theme) => ({
                input: {
                  textAlign: "center",
                  border: `1px solid ${primaryColor}`,
                  color: primaryColor,
                  fontWeight: 600,
                },
                itemsWrapper: {
                  padding: 0,
                },
                item: {
                  color: variables.textDefault,
                  "&[data-hovered]": {
                    background: hoverColor,
                  },
                  "&[data-selected]": {
                    color: primaryColor,
                    fontWeight: 600,
                    background: hoverColor,
                    "&[data-hovered]": {
                      color: metadata?.hoverSelectedOptionColor || "white",
                    },
                  },
                },
              })}
            ></Select>
          );
        }

        case "filter": {
          const copyDynamicFilters = structuredClone(dynamicFilters);
          const selectedDynamicFilterIndex = selectedFilter
            ? metadata?.options?.find((option) => {
                return option.label === selectedFilter;
              })?.index
            : null;

          const selectedDynamicFilter = selectedDynamicFilterIndex
            ? copyDynamicFilters[selectedDynamicFilterIndex]
            : null;
          const selectedMasterdata = selectedDynamicFilterIndex
            ? masterdataArray.find(
                (e) => e.index === selectedDynamicFilterIndex
              )
            : null;

          let dataJSX = (selectedMasterdata?.data || []).map(
            ({ label, value, actualValue }, index) => {
              const styles = {
                root: {
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                },
              };
              if (selectedDynamicFilter?.type === "multiple") {
                return (
                  <Checkbox
                    p={8}
                    key={index}
                    label={label}
                    value={value}
                    styles={(theme) => styles}
                  />
                );
              }

              return (
                <Radio
                  p={8}
                  key={index}
                  label={label}
                  value={value}
                  styles={(theme) => styles}
                />
              );
            }
          );

          if (selectedDynamicFilter?.type !== "multiple") {
            dataJSX = [
              <Radio
                p={8}
                key={"radio-index"}
                label={trans.all}
                value={""}
                styles={(theme) => styles}
              />,
              ...dataJSX,
            ];
          }

          const onClickOk = () => {
            setDynamicFilters(copyDynamicFilters);
            closeModal();
          };

          const styles = {
            root: {
              fontSize: 14,
              "&>div": {
                paddingTop: 0,
                "&>[data-checked]": {
                  background: variables.secondColor,
                  color: variables.primaryColor,
                  borderRadius: 8,
                },
              },
            },
          };

          const groupJSX =
            selectedDynamicFilter?.type === "multiple" ? (
              <Checkbox.Group
                pt={0}
                orientation="vertical"
                spacing={16}
                styles={(theme) => styles}
                defaultValue={selectedDynamicFilter?.value || []}
                onChange={(newValue) => {
                  selectedDynamicFilter.value = newValue;
                }}
              >
                {dataJSX}
              </Checkbox.Group>
            ) : (
              <Radio.Group
                styles={(theme) => styles}
                pt={0}
                orientation="vertical"
                spacing={16}
                defaultValue={selectedDynamicFilter?.value || ""}
                onChange={(newValue) => {
                  selectedDynamicFilter.value = newValue;
                }}
              >
                {dataJSX}
              </Radio.Group>
            );

          const isFilterNotEmpty = copyDynamicFilters.some((filter) => {
            return filter.value && filter.value.length > 0;
          });

          return (
            <Group
              onClick={openModal}
              key={index}
              position="center"
              style={{
                gap: 8,
                cursor: "pointer",
                background: "white",
                borderRadius: 100,
                height: 36,
                border: `1px solid ${variables.primaryColor}`,
                ...metadata?.style,
              }}
            >
              <div style={{ color: variables.primaryColor, fontWeight: 600 }}>
                {label}
              </div>
              {isFilterNotEmpty ? (
                <Image
                  src={metadata?.icon?.alt}
                  alt="icon"
                  width={"100%"}
                  height={"100%"}
                  style={metadata?.icon?.altStyle}
                />
              ) : (
                <Image
                  src={metadata?.icon?.src}
                  alt="icon"
                  width={"100%"}
                  height={"100%"}
                  style={metadata?.icon?.style}
                />
              )}

              <Modal
                radius={16}
                padding={0}
                withCloseButton={false}
                overflow={"inside"}
                size={isThreshold ? "100%" : 640}
                opened={modalOpened}
                onClose={closeModal}
                styles={(theme) => ({
                  title: {
                    width: "100%",
                  },
                })}
                title={
                  <div
                    style={{
                      width: "100%",
                      height: 44,
                      display: "flex",
                      alignItems: "center",
                      padding: "11px 16px",
                      fontWeight: 700,
                      background: metadata?.modal?.background,
                    }}
                  >
                    {metadata?.modal?.title}
                  </div>
                }
              >
                <Group
                  spacing={0}
                  mih={380}
                  style={{ width: "100%", alignItems: "start" }}
                >
                  <Stack spacing={16} px={8} py={16} style={{ width: "50%" }}>
                    {(metadata?.options || []).map(({ label }, indexOption) => {
                      return (
                        <Group
                          key={indexOption}
                          position="apart"
                          onClick={() => {
                            setDynamicFilters(copyDynamicFilters);
                            setSelectedFilter(label);
                          }}
                          p={8}
                          style={{
                            width: "100%",
                            borderRadius: 6,
                            cursor: "pointer",
                            color:
                              selectedFilter === label
                                ? primaryColor
                                : variables.textDefault,
                            background:
                              selectedFilter === label
                                ? variables.secondColor
                                : "transparent",
                          }}
                        >
                          <div>{label}</div>
                          <ChevronRight />
                        </Group>
                      );
                    })}
                  </Stack>
                  {selectedDynamicFilterIndex && (
                    <Stack
                      spacing={16}
                      px={8}
                      py={16}
                      style={{
                        width: "50%",
                        borderLeft: "1px solid #F5F5F6",
                      }}
                    >
                      {groupJSX}
                    </Stack>
                  )}
                </Group>

                <div
                  style={{
                    width: "100%",
                    height: 54,
                    display: "flex",
                    gap: 8,
                    justifyContent: "end",
                    alignItems: "center",
                    padding: "8px 16px",
                    fontWeight: 700,
                    background: metadata?.modal?.background,
                  }}
                >
                  <div
                    onClick={closeModal}
                    style={{
                      width: 128,
                      height: 38,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      color: variables.primaryColor,
                      background: "white",
                      border: `1px solid ${primaryColor}`,
                      borderRadius: 100,
                    }}
                  >
                    {metadata?.modal?.cancelText}
                  </div>
                  <div
                    onClick={onClickOk}
                    className={Search3Styles.modalOkButton}
                    style={{
                      width: 128,
                      height: 38,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      color: "white",
                      background: variables.primaryColor,
                      border: `1px solid ${primaryColor}`,
                      borderRadius: 100,
                    }}
                  >
                    {metadata?.modal?.okText}
                  </div>
                </div>
              </Modal>
            </Group>
          );
        }
      }
    }
  );

  let imageJSX = <></>;
  if (bannerImage) {
    if (Array.isArray(bannerImage)) {
      imageJSX = (
        <Carousel
          className={Search3Styles.bannerImage}
          loop={true}
          slideGap={"md"}
          slidesToScroll={1}
          plugins={autoplay ? [autoplay] : []}
          sx={{
            button: {
              width: "56px",
              height: "56px",
              background: "#FFFFFF",
              border: `2px solid ${variables.primaryColor}`,
              zIndex: 2,
            },
            svg: {
              color: variables.primaryColor,
              width: "24px",
              height: "24px",
            },
          }}
          styles={{
            controls: {
              left: 85,
              right: 85,
              top: "calc(50% - 20px)",
              display: isThreshold && "none",
            },
          }}
        >
          {bannerImage.map((image, index) => {
            return (
              <Carousel.Slide key={index}>
                <Image
                  alt="search-banner-image"
                  src={image}
                  width={"100%"}
                  fit={isThreshold && "fill"}
                  height={!isThreshold && 538}
                  radius={24}
                />
              </Carousel.Slide>
            );
          })}
        </Carousel>
      );
    } else {
      imageJSX = (
        <div className={Search3Styles.bannerImage}>
          <Image
            alt="search-banner-image"
            src={bannerImage}
            width={"100%"}
            height={isThreshold ? 200 : 538}
            radius={24}
          />
        </div>
      );
    }
  }

  return (
    <Stack spacing={16}>
      <div style={{ position: "relative", width: "100%" }}>
        {imageJSX}

        <div
          className={Search3Styles.searchContainer}
          style={{
            marginTop: bannerImage && 520,
          }}
        >
          <div className={Search3Styles.search3}>
            <div className={Search3Styles.searchFields}>{searchFieldJSX}</div>
          </div>
        </div>
      </div>
      {extraFieldJSX.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <div
            style={{ display: "flex", gap: 16 }}
            className={Search3Styles.extraField}
          >
            {extraFieldJSX}
          </div>
        </div>
      )}
    </Stack>
  );
}

import { FileInput, Stack, Text } from "@mantine/core";
import { IMAGE_MIME_TYPE, MS_EXCEL_MIME_TYPE, MS_WORD_MIME_TYPE, PDF_MIME_TYPE } from "@mantine/dropzone";
import { useEffect, useState } from 'react';
import { Upload } from "tabler-icons-react";
import styles from "../../styles/dynamic-form.module.scss";
import { getDescriptionStyleJSX } from "../nonPrimitiveType/formDynamicInput";

export default function FormDynamicInputFileInput(props) {
    if (props.inputTemplate && props.inputTemplate.type === 'file-input') {
        return (
            <div className={styles.formDynamicTextDateSelectInput}>
                <FormDynamicInputFileInputHelper {...props} />
            </div>
        );
    }
    return <></>;
}

function FormDynamicInputFileInputHelper(props) {
    const [isFileTooBig, setIsFileTooBig] = useState(false);
    const [isFileValid, setIsFileValid] = useState(true);
    const [fileName, setFileName] = useState("");
    const [oldInvisible, setOldInvisible] = useState(false);


    const inputTemplate = props.inputTemplate;
    const fileMaxSizeInMB = inputTemplate.maxSizeInMB ? inputTemplate.maxSizeInMB : 3;
    const descriptionJSX = getDescriptionStyleJSX(inputTemplate);
    let acceptStringArray = [...MS_WORD_MIME_TYPE, ...PDF_MIME_TYPE, "image/png", "image/jpeg"]

    const inputProps = { ...props.formInputProps };
    inputProps.onChange = (e) => {
        const fileToUploadArray = props.fileToUploadArray;
        const inputName = props.inputName;
        const index = fileToUploadArray && props.setFileToUploadArray ? fileToUploadArray.findIndex(e => e.inputName === inputName) : null;
        let copyFileToUploadArray = []
        if (index !== null) {
            if (index >= 0) {
                copyFileToUploadArray = [...fileToUploadArray.slice(0, index), ...fileToUploadArray.slice(index + 1)]
            } else {
                copyFileToUploadArray = [...fileToUploadArray];
            }
            
        }

        if (e) {
            const errorType = !acceptStringArray.includes(e.type);
            const errorSize = (e.size / 1000000) >= fileMaxSizeInMB;

            if (errorType || errorSize) {
                setFileName(e.name);
                if (errorType)
                    setIsFileValid(false);
                if (errorSize)
                    setIsFileTooBig(true);
                props.formInputProps.onChange(null);
                return;
            }

            setIsFileValid(true);
            setIsFileTooBig(false);

            copyFileToUploadArray.push({
                inputName: inputName,
                file: e,
                inputTemplate: props.inputTemplate
            })
        }
        if (props.setFileToUploadArray) {
            props.setFileToUploadArray(copyFileToUploadArray);
        }
        props.formInputProps.onChange(e);
    }
    if (inputTemplate.descriptionStyle) {
        switch (inputTemplate.descriptionStyle.id) {
            case "tanlong":
                acceptStringArray = [...acceptStringArray, ...IMAGE_MIME_TYPE];
                break;

            case "hurc":
                acceptStringArray = [...acceptStringArray, ...MS_EXCEL_MIME_TYPE];
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (inputTemplate.invisible) {
            setOldInvisible(true)
            inputProps.onChange(undefined);
        } else if (oldInvisible) {
            setOldInvisible(false);
            inputProps.onChange(props.initialValue);

        }
    }, [inputTemplate.invisible]);
    return !inputTemplate.invisible
        ? (
            <Stack spacing={18}>
                <FileInput
                    className={styles.myFileInput}
                    accept={acceptStringArray.join(',')}
                    label={inputTemplate.labelNoWrap ?
                        <span style={{ textWrap: "nowrap" }}>{inputTemplate.label}</span> : inputTemplate.label}
                    placeholder={inputTemplate.placeholder}
                    icon={<Upload size={14} />}
                    description={descriptionJSX}
                    clearable

                    withAsterisk={props.requiredInput}
                    {...inputProps}
                    disabled={props.disabledInput || inputProps.disabled}
                />
                <Stack spacing={2}>
                    {isFileTooBig
                        ? <Text fz={12} c="red">
                            Kích thước file {fileName} vượt quá {fileMaxSizeInMB}MB
                        </Text>
                        : <></>}
                    {!isFileValid
                        ? <Text fz={12} c="red">
                            {fileName} không đúng định dạng
                        </Text>
                        : <></>}
                </Stack>
            </Stack>

        )
        : <></>;
}

import { Anchor, Image } from "@mantine/core";
import { useEffect, useState } from "react";
import { Fab } from "react-tiny-fab";
import variables from "/styles/variables.module.scss";

export default function FabButton(props) {
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);
  let eleHTML = <></>;
  if (domLoaded)
    switch (props.display) {
      case "fab1":
        eleHTML = (
          <FabButton1
            backgroundColor={props.options.backgroundColor}
            href={props.options.href}
            primaryColor={props.primaryColor}
            icon={props.options.icon}
          />
        );
        break;
      case "survey":
        eleHTML = (
          <Survey text={props.options.text} href={props.options.href} />
        );
        break;
      default:
        break;
    }

  return eleHTML;
}

function FabButton1(props) {
  const [mouseHover, setMouseHover] = useState(false);

  const handleMouseEnter = () => {
    setMouseHover(true);
  };

  const handleMouseLeave = () => {
    setMouseHover(false);
  };

  const defaultSizeButton = 56;
  const defaultSizeIcon = 24;
  const multiplier = 1.1;

  return (
    <Fab
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      mainButtonStyles={{
        backgroundColor: props.backgroundColor || props.primaryColor,
        width: mouseHover ? defaultSizeButton * multiplier : defaultSizeButton,
        height: mouseHover ? defaultSizeButton * multiplier : defaultSizeButton,
        position: "fixed",
        right: "4dvw",
        bottom: 16,
      }}
      icon={
        <Anchor href={props.href} target={"_blank"}>
          <Image
            alt="fab-icon"
            width={mouseHover ? defaultSizeIcon * multiplier : defaultSizeIcon}
            src={props.icon}
          />
        </Anchor>
      }
      event={""}
    ></Fab>
  );
}

function Survey(props) {
  const buttonStyle = {
    position: "fixed",
    bottom: "40%",
    left: "0%",
    margin: "16px",
    padding: "8px 10px",
    border: "none",
    writingMode: "vertical-lr",
    background: variables.primaryColor,
    color: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0,0,0,0.3)",
    cursor: "pointer",
  };
  return (
    <Anchor href={props.href ? props.href : "#"} target="_blank">
      <button style={buttonStyle}>{props.text}</button>
    </Anchor>
  );
}

import IconComponent from "@/components/helpers/iconComponent/iconComponent";
import { Tag } from "@/components/helpers/tag/tag";
import useTrans from "@/hooks/useTrans";
import {
  BackgroundImage,
  Button,
  Group,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import parse from "html-react-parser";
import JobCardDetailStyles from "./jobCardDetail.module.scss";

function JobCardDetail({
  recruitmentPlan,
  descriptionArray,
  backgroundColor,
  headerBackgroundColor,
  headerImage,
  onBtnClick,
  btnLabel,
  primaryColor,
  hideManagerSection = false,
}) {
  const trans = useTrans();
  return (
    <Stack
      className={JobCardDetailStyles.jobCardDetail}
      style={backgroundColor && { background: backgroundColor }}
    >
      <BackgroundImage
        src={headerImage}
        className={JobCardDetailStyles.header}
        style={{ backgroundColor: headerBackgroundColor }}
      >
        <Stack spacing={16}>
          <Tooltip withArrow label={recruitmentPlan.name}>
            <Text className={JobCardDetailStyles.headerTitle} lineClamp={1}>
              {recruitmentPlan.name}
            </Text>
          </Tooltip>
          {Array.isArray(descriptionArray) && (
            <Group className={JobCardDetailStyles.itemList}>
              {descriptionArray.map((description, index) => {
                return (
                  <Group key={index} className={JobCardDetailStyles.item}>
                    {description.icon && (
                      <IconComponent
                        size={20}
                        {...description.icon}
                        color={description.icon.color || "black"}
                      />
                    )}
                    <Text>
                      {recruitmentPlan[description.key] ??
                        trans[description.default]}
                    </Text>
                  </Group>
                );
              })}
            </Group>
          )}
        </Stack>

        {/* Phúc lợi */}
        {/* {recruitmentPlan.benefits.length > 0 && (
          <Group>
            <Text fw={700} fz={16} c={primaryColor}>Phúc lợi: </Text>
            {recruitmentPlan.benefits.map((tag, index) => (
              <Tag key={index} text={tag} color={primaryColor} />
            ))}
          </Group>
        )} */}

        {recruitmentPlan.benefits && recruitmentPlan.benefits.length > 0 && (
          <Group>
            <Text fw={700} fz={16} c={primaryColor}>
              {trans.benefits}:{" "}
            </Text>
            {recruitmentPlan.benefits.map((tag, index) => (
              <Tag key={index} text={tag} color={primaryColor} type="badge" />
              // <Text key={index}>{tag}</Text>
            ))}
          </Group>
        )}

        {/* Người phụ trách */}
        {!hideManagerSection &&
          recruitmentPlan.assignTo &&
          recruitmentPlan.assignTo.length > 0 && (
            <Group>
              <Text fw={700} fz={16} c={primaryColor}>
                {trans.assignTo}:{" "}
              </Text>
              {recruitmentPlan.assignTo && recruitmentPlan.assignTo[0] ? (
                <Text>
                  {recruitmentPlan.assignTo[0].name} - Email:{" "}
                  {recruitmentPlan.assignTo[0].email}
                </Text>
              ) : (
                <Text>{trans.noInfo}</Text>
              )}
            </Group>
          )}

        <Button className={JobCardDetailStyles.btn} onClick={onBtnClick}>
          {btnLabel || trans.apply}
        </Button>
      </BackgroundImage>

      {Array.isArray(recruitmentPlan.description) && (
        <Stack
          className={JobCardDetailStyles.description}
          style={{ backgroundColor: backgroundColor ? "transparent" : "white" }}
        >
          {recruitmentPlan.description.map((singleDescription, index) => {
            return (
              <Stack key={index} spacing={32}>
                <Text className={JobCardDetailStyles.descriptionTitle}>
                  {singleDescription.name}
                </Text>
                <Text lh={"32px"}>{parse(singleDescription.content)}</Text>
              </Stack>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
}

export default JobCardDetail;

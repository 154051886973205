import { Carousel } from "@mantine/carousel";
import { Image, Text, Flex } from "@mantine/core";
import Autoplay from "embla-carousel-autoplay";
import { useRef } from "react";
import { useMediaQuery } from "usehooks-ts";

export default function Carousel10({ options, variables }) {
  const autoplay = useRef(Autoplay({ delay: 5000 }));
  const isThreshold = useMediaQuery("(max-width:1100px)");

  return (
    <Flex direction="column" gap={0} >
      {options.title && (
        <Text
          fz={48}
          fw={700}
          c={variables.primaryColor}
          ta={options.titleAlign ?? "center"}
        >
          {options.title}
        </Text>
      )}
      <Carousel
        loop={true}
        slideGap={"md"}
        slidesToScroll={1}
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        withIndicators={options.withIndicators ?? true}
        withControls={options.withControls ??true}
        p={{ base: 24, md: 48 }}
        sx={{
          button: {
            background: "#FFFFFF",
            border: `2px solid ${variables.primaryColor}`,
            zIndex: 2,
          },
          svg: {
            color: variables.primaryColor,
            width: "24px",
            height: "24px",
          },
        }}
        styles={{
          controls: {
            left: 0,
            right: 0,
            top: "calc(50% - 20px)",
            display: isThreshold && "none",
          },
          control: {
            width: "56px",
            height: "56px",
          },
          indicator: {
            background: variables.primaryColor,
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            "&[data-active]": {
              background: variables.primaryColor,
            },
          },
        }}
      >
        {options.images.map((image, index) => {
          return (
            <Carousel.Slide key={index}>
              <Image
                alt="search-banner-image"
                src={image}
                width={"100%"}
                fit={isThreshold && "fill"}
                height={!isThreshold && 538}
                radius={24}
              />
            </Carousel.Slide>
          );
        })}
      </Carousel>
    </Flex>
  );
}

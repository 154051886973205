import IconComponent from '@/components/helpers/iconComponent/iconComponent';
import { Group, Image, Stack, Text } from '@mantine/core';
import parse from 'html-react-parser';
import Link from 'next/link';
import { MdOutlineMailOutline } from 'react-icons/md';
import { useMediaQuery } from 'usehooks-ts';
import ContactInfoHeroStyles from './contactInfoHero.module.scss';

function ContactInfoHero({ options: { contactInfoArray, title, imageLink }, primaryColor }) {

    const isMobile = useMediaQuery('(max-width: 1000px)');
    return (
        <Stack className={ContactInfoHeroStyles.contactInfoHero}>
            <Text className={ContactInfoHeroStyles.title}>
                {title}
            </Text>

            {contactInfoArray.map(({ title, description, address, phone, email, image }, index) => {
                const textJSX = (
                    <Stack spacing={16} style={{ width: "100%" }}>
                        <Text className={ContactInfoHeroStyles.contactInfoTitle}>{title}</Text>
                        <Text className={ContactInfoHeroStyles.contactInfoDescription}>{parse(description)}</Text>
                        <Stack spacing={8} className={ContactInfoHeroStyles.contactInfoStack}>
                            {address && <Group spacing={8} align='center' noWrap>
                                <IconComponent name="home-location" size={16} color={primaryColor} />
                                <Text style={{ width: "95%" }}>{parse(address)}</Text>
                            </Group>}
                            {phone && <Group spacing={8} align='center'>
                                <IconComponent name="phone-call" size={16} color={primaryColor} />
                                <Text>{parse(phone)}</Text>
                            </Group>}
                            {email && <Group spacing={8} align='center'>
                                <MdOutlineMailOutline size={16} color={primaryColor} />
                                <Text>{parse(email)}</Text>
                            </Group>}
                        </Stack>
                    </Stack>
                )

                const imageJSX = (
                    <Link href={imageLink}>
                        <Image alt={title} src={image} height={256} radius={24} />
                    </Link>
                )

                return (
                    <div key={index} className={ContactInfoHeroStyles.contactInfo}>

                        {image && (isMobile || index % 2 !== 0)
                            ? <div className={ContactInfoHeroStyles.child} style={{ width: 624 }}>
                                {imageJSX}
                            </div>
                            : <></>}

                        <div className={ContactInfoHeroStyles.child} style={image && { display: 'flex', flex: '1 1 0' }}>
                            {textJSX}
                        </div>
                        {image && (!isMobile && !(index % 2 !== 0))
                            ? <div className={ContactInfoHeroStyles.child} style={{ width: 624 }}>
                                {imageJSX}
                            </div>
                            : <></>}
                    </div>

                )
            })}

        </Stack>
    )
}

export default ContactInfoHero
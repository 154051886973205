import FormDynamicMainInput from "@/components/formDynamic/components/nonPrimitiveType/formDynamicMainInput";
import variables from "@/styles/variables.module.scss";
import { Collapse, Divider, Grid, Group, Image, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { getDescriptionStyleJSX } from "./formDynamicInput";

function FormDynamicArrayInput(props) {
  const [arrayFieldTemplate, setArrayFieldTemplate] = useState(null);
  const [currentArrayName, setCurrentArrayName] = useState("");
  const [arrayInputs, setArrayInputs] = useState([]);
  const [arrayInitialValue, setArrayInitialValue] = useState({});
  const [showContent, setShowContent] = useState(true);
  const [oldInvisible, setOldInvisible] = useState(false);

  useEffect(() => {
    const currentName = props.currentNameArray.join(".");
    setCurrentArrayName(currentName);
  }, [props.currentNameArray]);

  useEffect(() => {
    let currentValue = null;
    if (props.fieldTemplateMain) {
      props.currentNameArray.forEach((name) => {
        if (!currentValue) {
          currentValue = props.finalFormConfig.initialValues[name];
        } else {
          currentValue = currentValue[name];
        }
      });
      if (currentValue && Array.isArray(currentValue)) {
        setArrayInputs(currentValue.map(() => ({ ...props.fieldTemplateMain })));
      } else {
        setArrayInputs([]);
      }
      const setupArrayInitialValue = (fields, currentFieldValue) => {
        fields.forEach((field) => {
          if (!field.name && field.typeGroup !== "partial") {
            return;
          }

          if (Array.isArray(field.fields)) {
            const resultObject = setupArrayInitialValue(field.fields, {});
            if (!field.name) {
              currentFieldValue = Object.assign({ ...currentFieldValue }, resultObject);
            } else {
              currentFieldValue[field.name] = resultObject;
            }
          } else {
            let initialValue = "";
            if (["file-input", "drop-zone", "file-picker"].includes(field.type)) {
              initialValue = null;
            }

            if (["checkbox", "file-picker-many"].includes(field.type)) {
              initialValue = [];
            }
            currentFieldValue[field.name] = initialValue;
          }
        });

        return currentFieldValue;
      };

      const arrayInitialValueConst = setupArrayInitialValue(props.fieldTemplateMain.fields || [], {});

      setArrayInitialValue(arrayInitialValueConst);
      setArrayFieldTemplate({ ...props.fieldTemplateMain });
    }
  }, [props.fieldTemplateMain]);

  const handleAdd = () => {
    setArrayInputs([...arrayInputs, structuredClone(props.fieldTemplateMain)]);
    props.formDynamic.insertListItem(currentArrayName, { ...arrayInitialValue });

    if (!showContent) setShowContent(true);
  };

  const handleDelete = (index) => {
    const copyFileToUploadArray = props.fileToUploadArray.filter((e) => {
      return !e.inputName.includes(currentArrayName + `.${index}`);
    });

    props.setFileToUploadArray(copyFileToUploadArray);

    const arrayInputCopy = [...arrayInputs.slice(0, index), ...arrayInputs.slice(index + 1)];
    setArrayInputs(arrayInputCopy);
    props.formDynamic.removeListItem(currentArrayName, index);

    if (arrayInputCopy.length === 0) {
      setShowContent(false);
    }
  };

  useEffect(() => {
    const currentName = props.currentNameArray.join(".");

    if (props.fieldTemplateMain.invisible) {
      const copyFileToUploadArray = props.fileToUploadArray.filter((e) => {
        return !e.inputName.includes(currentName);
      });

      props.setFileToUploadArray(copyFileToUploadArray);
      setShowContent(false);
      props.formDynamic.setFieldValue(currentName, []);
      setArrayInputs([]);

      if (!oldInvisible) {
        setOldInvisible(true);
      }
    } else if (oldInvisible) {
      let currentValue = null;
      props.currentNameArray.forEach((name) => {
        if (!currentValue) {
          currentValue = props.finalFormConfig.initialValues[name];
        } else {
          currentValue = currentValue[name];
        }
      });

      if (currentValue && Array.isArray(currentValue)) {
        setArrayInputs(currentValue.map(() => ({ ...props.fieldTemplateMain })));
        props.formDynamic.setFieldValue(currentName, currentValue);
      }
      if (Array.isArray(currentValue)) {
        setShowContent(currentValue.length > 0);
      }
    }
  }, [props.fieldTemplateMain.invisible]);

  if (!arrayFieldTemplate || arrayFieldTemplate.invisible) {
    return <></>;
  }
  const addIcon = (
    <Image
      onClick={handleAdd}
      alt={""}
      src={`${process.env.BASE_PATH ? `${process.env.BASE_PATH}/images/add-svgrepo-com.svg` : "/images/add-svgrepo-com.svg"}`}
      width={props.currentWidth > 800 ? 24 : 20}
      style={{ cursor: "pointer" }}
    />
  );

  const descriptionJSX = getDescriptionStyleJSX(arrayFieldTemplate);

  const contentShown = arrayInputs.map((arrayInput, key) => {
    const deleteIcon = (
      <Group position={"right"}>
        <Image
          onClick={() => handleDelete(key)}
          alt={""}
          src={`${process.env.BASE_PATH ? `${process.env.BASE_PATH}/images/trash-bin-svgrepo-com.svg` : "/images/trash-bin-svgrepo-com.svg"}`}
          width={30}
          style={{ cursor: "pointer" }}
        />
      </Group>
    );

    return (
      <Stack key={key} spacing={10} mt={20}>
        <Text color={"dimmed"} ta={"right"} fz={12}>
          {arrayInput?.label} - {key + 1}
        </Text>
        <Grid columns={12}>
          {arrayInput.fields.map((fieldTemplate, index) => {
            const currentNameArray = [...props.currentNameArray, key];
            if (fieldTemplate.name) {
              currentNameArray.push(fieldTemplate.name);
            }
            return (
              <FormDynamicMainInput
                key={index}
                {...props}
                fieldTemplateMain={fieldTemplate}
                currentNameArray={currentNameArray}
              />
            );
          })}
        </Grid>
        {(key === 0 && oldInvisible) || props.disabledInput ? <></> : deleteIcon}

        {arrayInputs.length > 1 && key < arrayInputs.length - 1 ? (
          <Divider size={1} color={variables.primaryColor} />
        ) : (
          <></>
        )}
      </Stack>
    );
  });

  return (
    <Stack
      p={24}
      spacing={20}
      style={{
        marginTop: 20,
        borderRadius: 24,
        backgroundColor: arrayFieldTemplate?.backgroundColor ?? props.backgroundColor,
        position: "relative",
      }}
    >
      <Stack spacing={10}>
        <Group position={"apart"}>
          <Group
            style={{ cursor: "pointer" }}
            spacing={10}
            onClick={() => {
              if (arrayInputs.length > 0) {
                setShowContent((value) => !value);
              }
            }}
          >
            <Text fw={700} fz={props.currentWidth > 800 ? 22 : 18}>
              {arrayFieldTemplate?.label}
            </Text>

            {arrayInputs.length !== 0 ? (
              <Image
                alt=""
                src={`${process.env.BASE_PATH ? `${process.env.BASE_PATH}/images/caret-down.svg` : "/images/caret-down.svg"}`}
                width={props.currentWidth > 800 ? 24 : 20}
                style={{
                  transition: "0.5s ease-in-out",
                  rotate: showContent ? null : "-180deg",
                }}
              />
            ) : (
              <></>
            )}
          </Group>
          {!props.disabledInput ? addIcon : <></>}
        </Group>

        {descriptionJSX}
      </Stack>

      <Collapse in={showContent} transitionDuration={300}>
        {contentShown}
      </Collapse>
    </Stack>
  );
}

export default FormDynamicArrayInput;

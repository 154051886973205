import { Badge } from "@mantine/core";
// Hàm để làm nhạt màu
function lightenColor(color, percent) {
  const num = parseInt(color?.replace("#", ""), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt;
  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
      .toUpperCase()
  );
}
export function Tag({ type, locale, text, color }) {
  // const lightenedColor = lightenColor(color, 50);
  switch (type) {
    case "hotJob":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderRadius: 8,
            padding: 4,
            gap: 4,
            fontSize: 14,
            color: "#F5222D",
            background: "#FFEAF2",
          }}
        >
          <div>🔥</div>
          <div>Hot Job</div>
        </div>
      );
    case "badge":
      return (
        <Badge
          w={"fit-content"}
          style={{ borderColor: color, color: color }}
          p={12}
          variant="outline"
        >
          {text}
        </Badge>
      );
    default:
      return (
        <Badge
          w={"fit-content"}
          style={{ borderColor: color, color: color }}
          p={12}
          variant="outline"
        >
          {text}
        </Badge>
      );
  }
}

import { Text, Flex } from "@mantine/core";
import { useState } from "react";
import Image from "next/image";
import bookingStyle from "./bookingCalendar.module.scss";

export default function BookingCalender(props) {
  const [isOpen, setIsOpen] = useState(false);

  const modalWrapper = {
    zIndex: 9999999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center,",
    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.4)",
  };

  const modalContent = {
    margin: "auto",
    backgroundColor: "#fff",
    position: "relative",
    width: "70%",
    height: "85%",
    borderRadius: "16px",
    boxShadow: "0px 0px 30px 0px #9CABFB80",
    overflowY: "hidden",
  };

  const modalHeader = {
    padding: "16px 24px",
    background: "#178CE71A",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  };

  const modalHeaderTitle = {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "100%",
    letterSpacing: 0,
    verticalAlign: "middle",
  };

  const calender = {
    border: 0,
    width: "100%",
    height: "100%",
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
  };

  return (
    <>
      <Flex
        className={bookingStyle.button}
        gap={10}
        align={"center"}
        justify={"flex-start"}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <img
          width={props.textSize}
          height={props.textSize}
          alt=""
          src="/icons/calendar-black.svg"
        />
        <Text compact fz={props.textSize} className={bookingStyle}>
          {props.options.buttonTitle
            ? props.options.buttonTitle
            : "Booking Calender"}
        </Text>
      </Flex>
      {isOpen && (
        <div
          style={modalWrapper}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <div style={modalContent}>
            <div style={modalHeader}>
              <div style={modalHeaderTitle}>{props.options.title}</div>
              <div>
                <div
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  X
                </div>
              </div>
            </div>
            <iframe style={calender} src={props.options.href} />
          </div>
        </div>
      )}
    </>
  );
}

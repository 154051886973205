import {
  Text,
  Stack,
  TextInput,
  SimpleGrid,
  Select,
  FileInput,
  Group,
  Button,
  LoadingOverlay,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import styles from "../../styles/feature.module.scss";
import { useForm } from "@mantine/form";
import { Upload } from "tabler-icons-react";
import { useState } from "react";
import variables from "/styles/variables.module.scss";
import { showNotification } from "@mantine/notifications";
import { postTalent, uploadFile } from "services/services";
import { openContextModal } from "@mantine/modals";
import useTrans from "@/hooks/useTrans";

export default function Action(props) {
  const trans = useTrans();
  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      basics: {
        name: "",
        dob: null,
        gender: "",
        phone: "",
        label: "",
        image: "", // chưa up dc image
        email: "",
        summary: "",
        location: {
          address: "",
          city: "",
          countryCode: "",
        },
        profiles: [],
      },
      meta: {
        source: "portal",
      },
      recruitmentPlan: "",
      cv: [],
      tags: ["public"],
    },
    validate: {
      basics: {
        name: (value) =>
          value.length < 2 ? trans.formValidate.name : null,
        dob: (value) =>
          value == null ? trans.formValidate.dob : null,
        gender: (value) =>
          value.length < 1 ? trans.formValidate.gender : null,
        phone: (value) =>
          /(((\+84)|0[3|5|7|8|9])+([0-9]{9})\b)|((84|0[3|5|7|8|9])+([0-9]{8})\b)|((02)+([0-9]{9})\b)|((02|(\+)84)+([0-9]{10})\b)/.test(
            value
          )
            ? null
            : trans.formValidate.phoneAndEmail,
        email: (value) =>
          value != ""
            ? /^\S+@\S+$/.test(value)
              ? null
              : trans.formValidate.phoneAndEmail
            : null,
      },
    },
  });

  let eleHTML;
  const [cv, setCV] = useState({ base64: null, fileName: null });
  const [visible, setVisible] = useState(false); // biến check loading overlay khi click submit
  const [value, setValue] = useState(null);

  const onChange = async (e) => {
    setValue(e);
    // if (e) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     form.values.cv = {
    //       base64: reader.result.split(",")[1],
    //       fileName: e.name || "",
    //     };
    //   };
    //   reader.readAsDataURL(e);
    // }
  };

  const onSubmit = async () => {
    setVisible(true);
    const validate = form.validate().hasErrors;
    if (validate == true) {
      showNotification({
        id: "noti-fail",
        loading: false,
        color: "red",
        title: trans.applyNow.fail,
        message: trans.applyNow.failBody,
        autoClose: 3000,
      });
      setVisible(false);
    } else {
      form.values.recruitmentPlan = props.recruitmentPlan;
      const newTalent = await postTalent(props.url, form.values, value);
      if (newTalent.status === 200 || newTalent.status === 201) {
        openContextModal({
          modal: "success",
          centered: true,
          innerProps: {
            text: trans.applyNow.success,
            content: trans.applyNow.successBody,
            code: newTalent?.data.code || "",
            color: variables.primaryColor,
          },
        });
        form.reset();
        setCV({ base64: null, fileName: null });
        setValue(null);
        setVisible(false);
      } else {
        setVisible(false);
        showNotification({
          id: "noti-fail",
          loading: false,
          color: "red",
          title: trans.applyNow.fail,
          message: trans.applyNow.failBody,
          autoClose: 3000,
        });
      }
    }
  };

  switch (props.display) {
    case "apply-short":
      eleHTML = (
        <Stack
          spacing={8}
          w={"100%"}
          className={styles.form1}
          style={{ padding: "0 32px" }}
        >
          <LoadingOverlay visible={visible} overlayBlur={0.5} />
          <TextInput
            radius="lg"
            label={<Text className={styles.label}>{trans.formLabel.name}</Text>}
            type="text"
            {...form.getInputProps("basics.name")}
          />
          <SimpleGrid cols={2} spacing={32}>
            <TextInput
              radius="lg"
              label={
                <Group spacing={8}>
                  <Text className={styles.label}>{trans.formLabel.email}</Text>
                  <Text
                    fz={12}
                    c={variables.primaryColor}
                  >{trans.formValidate.notRequire}</Text>
                </Group>
              }
              type="email"
              {...form.getInputProps("basics.email")}
            />
            <TextInput
              radius="lg"
              label={<Text className={styles.label}>{trans.formLabel.phone}</Text>}
              type="tel"
              {...form.getInputProps("basics.phone")}
            />
            <Select
              label={<Text className={styles.label}>{trans.formLabel.gender}</Text>}
              radius="lg"
              data={[
                { value: "male", label: "Nam" },
                { value: "female", label: "Nữ" },
                { value: "order", label: "Khác" },
              ]}
              {...form.getInputProps("basics.gender")}
            />
            <DatePicker
              initialLevel="year"
              allowFreeInput
              inputFormat="DD/MM/YYYY"
              labelFormat="DD/MM/YYYY"
              radius="lg"
              locale="vi"
              placeholder="Chọn thời gian"
              label={<Text className={styles.label}>{trans.formLabel.dob}</Text>}
              {...form.getInputProps("basics.dob")}
            />
          </SimpleGrid>
          <FileInput
            sx={{
              "button:focus": { borderColor: variables.primaryColor },
              button: { height: 45 },
            }}
            accept="application/pdf"
            radius="lg"
            clearable
            label={<Text className={styles.label}>CV</Text>}
            placeholder="Tải lên CV của bạn. Chú ý: hãy sử dụng file pdf"
            value={value}
            icon={
              <Upload
                size={16}
                strokeWidth={1.5}
                color={"rgba(37, 37, 37, 0.7)"}
              />
            }
            onChange={onChange}
          />
          <Group position="right" mt={16}>
            <Button
              type="submit"
              onClick={onSubmit}
              className={styles.actionBtnApply}
            >
              {trans.apply}
            </Button>
          </Group>
        </Stack>
      );
      break;

    default:
      break;
  }

  return eleHTML;
}

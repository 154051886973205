import { Image } from "@mantine/core";
import { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { PortalJsonata } from "../../../../services/jsonata-functions/portal-jsonata";
import IconComponent from "../../../helpers/iconComponent/iconComponent";
import ImagePlaceholder from "../../../imagePlaceholder/imagePlaceholder";
import localStyles from "./card2.module.scss";
function Card2({ descriptionArray, objectData, placeholder, bottomJSX, rightSideJSX, _jsonata, maxDescriptionCols }) {
  const [transformedObjectData, setTransformedObjectData] = useState(null);
  const isThreshold = useMediaQuery("(max-width:1100px)");

  useEffect(() => {
    const portalJsonata = new PortalJsonata();

    const transformFunc = async (object) => {
      return await portalJsonata.transform(object, _jsonata || "$").catch((err) => {
        console.log("null", err);
        return null;
      });
    };

    transformFunc(objectData).then((transformedObject) => {
      setTransformedObjectData(transformedObject);
    });

    // console.log("check objectData", transformedObjectData);
  }, [objectData, _jsonata]);

  if (!transformedObjectData) {
    return <></>;
  }

  return (
    <div className={localStyles.researchCard2}>
      <div className={localStyles.mainInfo}>
        <div className={localStyles.leftSide}>
          <Image
            className={localStyles.image}
            width={228}
            height={228}
            src={transformedObjectData.image}
            withPlaceholder
            placeholder={
              <ImagePlaceholder
                display={"imagePlaceholder1"}
                image={placeholder}
                width={228}
                height={228}
                allRadius={24}
              />
            }
            alt=""
          ></Image>

          <div className={localStyles.mainInfoMiddle}>
            <div className={localStyles.title}>{transformedObjectData.topTitle}</div>

            {objectData && Array.isArray(descriptionArray) && descriptionArray.length > 0 && (
              <div
                className={localStyles.descriptionGrid}
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(${maxDescriptionCols || 12}, minmax(0, 1fr))`,
                  gap: 24,
                }}
              >
                {descriptionArray.map((description, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        gridColumn: `span ${isThreshold ? maxDescriptionCols || 12 : description.cols || 6} / span ${
                          isThreshold ? maxDescriptionCols || 12 : description.cols || 6
                        }`,
                      }}
                    >
                      <div className={localStyles.descriptionItem}>
                        <div className={localStyles.itemTitle} style={description.titleStyle || {}}>
                          {description.icon && <IconComponent size={20} {...description.icon} />}
                          {description.title}
                        </div>
                        {transformedObjectData[description.key] || description.defaultValue || "Chưa có thông tinh"}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className={localStyles.rightSide}>{rightSideJSX}</div>
      </div>
      <div className={localStyles.bottomInfo}>
        <div className={localStyles.cardTitle}>{transformedObjectData.bottomTitle}</div>
        {bottomJSX}
      </div>
    </div>
  );
}

export default Card2;

import { Box, Button, Group, Image, Stack, Text, Tooltip } from "@mantine/core";
import { useMediaQuery } from "usehooks-ts";
import { Tag } from "../../helpers/tag/tag";
import styles from "./card1.module.scss";
import variables from "../../../styles/variables.module.scss";
import useTrans from "@/hooks/useTrans";

export default function Card1({
  title,
  salary,
  location,
  expirationDate,
  tags,
  onBtnClick,
  threshold,
  image,
  defaultImage,
}) {
  const trans = useTrans();
  const isThreshold = useMediaQuery(`(max-width:${threshold || 1200}px`);
  const isHotJob = Array.isArray(tags) && tags.includes("Hot Job");

  const filteredTags = tags.filter((tag) => tag !== "Hot Job");

  return (
    <Box className={styles.cardContainer}>
      <Group noWrap align="center" style={{ justifyContent: "space-between" }}>
        <Group spacing={24} noWrap>
          <Image src={image ?? defaultImage} alt="" width={180} radius={8} />
          <Stack spacing={12} style={{ position: "relative" }} >
            <Tooltip withArrow label={title}>
              <Text
                fz={20}
                fw={700}
                lh={"28px"}
                lineClamp={1}
                c={variables.primaryColor}
              >
                {title}
              </Text>
            </Tooltip>
            <Group spacing={16}>
              {isHotJob && <Tag type="hotJob" />}
              {filteredTags.map((tag, index) => {
                return (
                  <Group spacing={4} key={index} align="center">
                    <Image
                      alt="icon dollar"
                      src="/icons/tags.svg"
                      width={16}
                      height={16}
                    />
                    <Text c={"#0154A4"} fz={14} fw={400} lh={"24px"}>
                      {tag}
                    </Text>
                  </Group>
                );
              })}
              <Group spacing={4}>
                <Image
                  alt="icon dollar"
                  src="/icons/dollar.svg"
                  width={16}
                  height={16}
                />
                <Text fz={14} fw={400} lh={"24px"}>
                  {salary ?? trans.salary}
                </Text>
              </Group>
              <Group spacing={4}>
                <Image
                  alt="icon dollar"
                  src="/icons/location-alt.svg"
                  width={16}
                  height={16}
                />
                <Text fz={14} fw={400} lh={"24px"}>
                  {location}
                </Text>
              </Group>
              <Group spacing={4}>
                <Image
                  alt="icon dollar"
                  src="/icons/clock.svg"
                  width={16}
                  height={16}
                />
                <Text fz={14} fw={400} lh={"24px"}>
                  {expirationDate}
                </Text>
              </Group>
            </Group>
          </Stack>
        </Group>
        {!isThreshold && (
          <Button className={styles.btnApply} radius={100} onClick={onBtnClick}>
            {trans.apply}
          </Button>
        )}
      </Group>
    </Box>
  );
}

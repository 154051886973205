import ImagePlaceholder from "@/components/imagePlaceholder/imagePlaceholder";
import { Center, Image, Stack } from "@mantine/core";
import parse from "html-react-parser";
import { useMediaQuery } from "usehooks-ts";
import heroListStyles from "./heroList.module.scss";

export default function HeroList({ options: { heroList }, variables, imageLogo, defaultImage }) {
  const isMobile = useMediaQuery("(max-width: 1000px)");

  const heroListJSX = heroList.map(({ title, image, description, background }, index) => {
    const textJSX = (
      <Stack spacing={isMobile ? 22 : 44} style={{ width: isMobile ? "100%" : "50%" }}>
        <div className={heroListStyles.title}>{parse(title || "")}</div>

        <div className={heroListStyles.description}>{parse(description || "")}</div>
      </Stack>
    );

    const imageSize = isMobile ? 320 : 460;

    const imageJSX = (
      <Center style={isMobile ? { width: "100%" } : { display: "flex", flex: "1 1 0" }}>
        <Image
          width={imageSize}
          height={imageSize}
          radius={16}
          alt="image"
          placeholder={
            <ImagePlaceholder
              display={"imagePlaceholder1"}
              width={imageSize}
              height={imageSize}
              allRadius={16}
              image={defaultImage || imageLogo}
            />
          }
          src={image}
        ></Image>
      </Center>
    );

    const isImageLeft = !isMobile && index % 2 !== 0;

    return (
      <div key={index} className={heroListStyles.hero} style={{ background: background }}>
        {isImageLeft ? imageJSX : textJSX}
        {isImageLeft ? textJSX : imageJSX}
      </div>
    );
  });

  return <div className={heroListStyles.heroList}>{heroListJSX}</div>;
}

import { Image, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import ImagePlaceholder from '../../imagePlaceholder/imagePlaceholder';
function ImageComponent({ src, placeholder, alt, width, height, radius, className, imageFit }) {
    const [isHovered, setIsHovered] = useState(false)
    const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
    return (
        <div onClick={openModal} style={{ cursor: 'pointer', width: width || '100%', height: height || '100%', position: 'relative' }} className={className} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <Image width={width || "100%"} height={height || "100%"} alt={alt} src={src} radius={radius} fit={imageFit} withPlaceholder placeholder={
                <ImagePlaceholder
                    display={"imagePlaceholder1"}
                    allRadius={radius}
                    height={"100%"}
                    image={placeholder} />
            } />

            {/* <div style={{ width: '100%', height: '100%', background: 'black', opacity: 0.3, position: 'absolute', display: isHovered ? 'block' : 'none', top: 0, borderRadius: radius }}>


            </div>

            <Button className={ImageComponentStyles.btn} style={{ position: 'absolute', top: '46%', left: '41%', zIndex: 1, display: isHovered ? 'block' : 'none' }}>Mở rộng</Button> */}
            <Modal size={'100dvw'} opened={modalOpened} onClose={closeModal} overlayOpacity={0.9} styles={(theme) => ({
                'inner': {
                    width: '100%',
                    height: '100%'
                },
                'modal': {
                    background: 'transparent',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                'close': {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    ['&:hover']: {
                        background: 'transparent',
                        ['svg']: {
                            color: '#f2f2f2',
                        }
                    },
                    ['svg']: {
                        width: 48,
                        height: 48
                    }
                }
            })}>

                <div style={{ height: '100%', display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', background: 'transparent' }}>
                    <img alt={alt} style={{ width: 'fit-content', height: 'fit-content', maxWidth: '100%', maxHeight: 700, borderRadius: 20 }} src={src} />

                </div>
            </Modal>
        </div >
    )
}

export default ImageComponent
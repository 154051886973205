import { Center, Stack, Text } from "@mantine/core";
import parse from 'html-react-parser';
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

function VideoPlayer({ options: { url, height, width, padding, title } }) {
  const [html, setHtml] = useState(<></>);
  useEffect(() => {
    setHtml(
      <ReactPlayer
        controls
        volume={1}
        url={url}
        onError={() => {
          setHtml(null);
        }}
        width={width || "100%"}
        height={height || "100%"}
      />
    );
  }, [url, height, width]);
  if (!url || typeof url != "string") return <></>;


  if (!html) {
    return <></>
  }

  return <Stack spacing={10} p={padding || 0} style={{ width: '100%' }}>
    {title && <Text fz={32} fw={700}>{parse(title)}</Text>}
    <Center>{html}</Center>
  </Stack>
}

export default VideoPlayer;

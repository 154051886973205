import {Box, Button, Input, Stack, Text, TextInput} from "@mantine/core";
import styles from "../../styles/feature.module.scss";
import {Mail} from "tabler-icons-react";
import variables from "styles/variables.module.scss";
import {useForm} from "@mantine/form";
import {showNotification} from "@mantine/notifications";
import {openContextModal} from "@mantine/modals";
import convert from "color-convert";
import * as EmailValidator from "email-validator";

export default function Subscribe(props) {
    let eleHTML = <></>;
    switch (props.display) {
        case "subscribe1":
            eleHTML = <Subscribe1 background={props.background}
                                  title={props.options.title}
                                  placeholder={props.options.placeholder}
                                  btnTitle={props.options.btnTitle}
                                  defaultImage={props.defaultImage}/>;
            break;

        case "subscribe2":
            eleHTML = <Subscribe2R background={props.background}
                                   primaryColor={props.primaryColor}
                                   secondaryColor={props.secondaryColor}
                                   title={props.options.title}
                                   placeholder={props.options.placeholder}
                                   btnTitle={props.options.btnTitle}
                                   windowDimension={props.windowDimension}
                                   defaultImage={props.defaultImage}/>;
            break;
        default:
            break;
    }
    return eleHTML;
}


function Subscribe1(props) {
    return (
        <Box
            className={styles.subscribe1}
            sx={() => ({
                padding: "56px 64px",
            })}
        >
            <Box
                sx={{
                    background: `${props.background}20`,
                    borderRadius: "40px",
                    height: 364,
                }}
            >
                <Stack align="center" justify="center" h={"100%"} spacing={44}>
                    <Text className={styles.title}>{props.title}</Text>
                    <Input
                        iconWidth={48}
                        icon={<Mail strokeWidth={1.5} size={24}/>}
                        radius={40}
                        placeholder={props.placeHolder}
                        rightSectionWidth={260}
                        rightSection={
                            <Button className={styles.btn}>
                                {props.btnTitle}
                            </Button>
                        }
                    />
                </Stack>
            </Box>
        </Box>
    );

}

function Subscribe2R(props) {
    const divider = props.windowDimension.width < 1440 ? 1440 / props.windowDimension.width : 1;
    const form = useForm({
        validateInputOnChange: true,
        initialValues: {
            email: ""
        },
        validate: {
            email: (value) => {
                if (!value || String(value).length === 0) {
                    return "Email không được trống"
                } else {
                    if (!EmailValidator.validate(value)) {
                        return "Email không đúng định dạng"
                    }
                }
                return null;

            }
        },
    });

    const onSubmit = async () => {
        const validateResult = form.validate();
        if (validateResult.hasErrors) {
            showNotification({
                id: "noti-fail",
                loading: false,
                color: "red",
                title: "Thất bại!",
                message: validateResult.errors.email,
                autoClose: 3000,
            });
        } else {
            openContextModal({
                modal: "success",
                centered: true,
                innerProps: {
                    text: "Đăng ký nhận tin thành công!",
                    content: `Cảm ơn bạn đã quan tâm đến công ty.`,
                    color: variables.primaryColor,
                },
            });
        }
    }

    const convertColor = convert.hex.rgb(props.primaryColor);
    const stackCSS = {
        padding: `${44 / divider}px ${64 / divider}px`,
        background: `rgba(${convertColor[0]}, ${convertColor[1]}, ${convertColor[2]}, 0.1`,
        width: 1440 / divider,
        height: 270 / (Math.min(divider, 2.5)),

    }

    const buttonCSS = {
        borderRadius: 100 / divider,
        height: 52 / divider,
        width: 200 / divider,
        right: 8 / divider,
        fontSize: 18 / divider,
    }

    const eleHTML = (
        <Stack align="center" justify="center" style={stackCSS} className={styles.subscribe2}
               spacing={32 / divider}>
            <Text fz={48 / divider} fw={700} c="#232323">{props.title}</Text>
            <TextInput
                iconWidth={60}
                icon={<Mail color={"black"} strokeWidth={1} size={24 / divider}/>}
                radius={100 / divider}
                placeholder={props.placeholder}
                rightSectionWidth={200 / divider}
                type="email"
                rightSection={
                    <Button className={styles.btn} style={buttonCSS} type="submit"
                            onClick={onSubmit}>
                        {props.btnTitle}
                    </Button>
                }
                {...form.getInputProps("email")}
            />
        </Stack>
    );
    return eleHTML;
}

import { FooterBasic } from "@/components/footer/footer-basic/footer-basic";
import { Footer1R } from "@/components/footer/footer1/footer1R";
import Footer2 from "./footer2/footer2";
import Footer3 from "./footer3/footer3";

export default function Footer(props) {
  let eleHTML = <></>;

  switch (props.display) {
    case "basic":
      eleHTML = (
        <FooterBasic
          options={props.options}
          logo={props.logo}
          windowDimension={props.windowDimension}
          logoHref={props.logoHref}
        />
      );
      break;

    case "footer1":
      eleHTML = (
        <Footer1R
          data={props.data}
          logo={props.logo}
          logoHref={props.logoHref}
          windowDimension={props.windowDimension}
        />
      );
      break;
    case "footer2":
      eleHTML = (
        <Footer2
          options={props.options}
          logo={props.logo}
          logoHref={props.logoHref}
          primaryColor={props.primaryColor}
        />
      );
      break;

    case "footer3":
      eleHTML = <Footer3 options={props.options} logo={props.logo} logoHref={props.logoHref} />;
      break;

    default:
      break;
  }

  return eleHTML;
}

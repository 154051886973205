import { Box, Card, Divider, Grid, Group, Image, SimpleGrid, Stack, Text } from "@mantine/core";
import Link from "next/link";
import { useEffect, useState } from "react";
import { ChevronRight } from "tabler-icons-react";
import styles from "../../../styles/feature.module.scss";
import variables from "../../../styles/variables.module.scss";
import ImagePlaceholder from "../../imagePlaceholder/imagePlaceholder";
import { loadData } from "../gallery";

export function Gallery1(props) {
    const [data, setData] = useState([]);
    useEffect(() => {
        loadData({
            url: props.url,
            data: props.optionData,
            query: props.optionQuery,
            jsonata: props.optionJsonata,
            selectedIds: props.optionSelectedIds
        }, setData, true);
    }, []);
    return (
        <Box
            className={styles.gallery1}
            sx={(theme) => ({
                padding: "0px 64px",
            })}
        >
            <div
                className={styles.container}
                style={{ background: `${props.background}66` }}
            >
                <div className={styles.spaceBetween}>
                    <Stack>
                        <Text fz={48} fw={700} c={variables.primaryColor}>
                            {props.title}
                        </Text>
                        <div className={styles.divider}>
                            <Divider size={1} color={variables.primaryColor} />
                        </div>
                    </Stack>
                    <Group>
                        <Text fz={20} c={variables.primaryColor}>
                            Xem tất cả
                        </Text>
                        <ChevronRight
                            size={24}
                            color={variables.primaryColor}
                            strokeWidth={2}
                        />
                    </Group>
                </div>
                <Grid mt={32}>
                    <Grid.Col span={6} pr={16}>
                        <Card radius={40} h={800}>
                            <Card.Section>
                                <Image
                                    src={
                                        data[0] != undefined
                                            ? data[0]?.image
                                            : "/images/no-img.jpeg"
                                    }
                                    placeholder={<ImagePlaceholder display={"imagePlaceholder1"}
                                        image={props.defaultImage ? props.defaultImage : props.logo} />}
                                    withPlaceholder
                                    height={400}
                                    alt={data[0] != undefined ? data[0]?.name : ""}
                                />
                            </Card.Section>
                            <Box className={styles.cardBody}>
                                <Link
                                    href={`${props.optionData}/${data[0] != undefined ? data[0]?.id : "id-0"
                                        }?type_child=${props.optionTypeChild}`}
                                >
                                    <Text className={styles.name} lineClamp={2}>
                                        {data[0] != undefined ? data[0]?.name : ""}
                                    </Text>
                                </Link>
                                <Text lineClamp={3}>
                                    {data[0] != undefined ? data[0]?.excerpt : ""}
                                </Text>
                                <Link
                                    href={`${props.optionData}/${data[0] != undefined ? data[0]?.id : "id-0"
                                        }?type_child=${props.optionTypeChild}`}
                                >
                                    <Group className={styles.positionBottom}>
                                        <Text fz={20} c={variables.primaryColor}>
                                            Xem tất cả
                                        </Text>
                                        <ChevronRight
                                            size={24}
                                            color={variables.primaryColor}
                                            strokeWidth={2}
                                        />
                                    </Group>
                                </Link>
                            </Box>
                        </Card>
                    </Grid.Col>
                    <Grid.Col span={6} pl={16}>
                        <SimpleGrid cols={2} spacing={32}>
                            <div className={styles.item}>
                                <Image
                                    src={
                                        data[1] != undefined
                                            ? data[1]?.image
                                            : "/images/no-img.jpeg"
                                    }
                                    alt={data[1] != undefined ? data[1]?.name : ""}
                                    placeholder={<ImagePlaceholder display={"imagePlaceholder1"}
                                        image={props.defaultImage ? props.defaultImage : props.logo} />}
                                    withPlaceholder
                                    height={384}
                                />
                                <Box
                                    className={`${styles.cardBody} ${styles.itemBody} ${styles.slide}`}
                                >
                                    <Link
                                        href={`${props.optionData}/${data[1] != undefined ? data[1]?.id : "id-1"
                                            }?type_child=${props.optionTypeChild}`}
                                    >
                                        <Text className={styles.name} lineClamp={2}>
                                            {data[1] != undefined ? data[1]?.name : ""}
                                        </Text>
                                    </Link>
                                    <Text lineClamp={4}>
                                        {data[1] != undefined ? data[1]?.excerpt : ""}
                                    </Text>
                                    <Link
                                        href={`${props.optionData}/${data[0] != undefined ? data[1]?.id : "id-0"
                                            }?type_child=${props.optionTypeChild}`}
                                    >
                                        <Group className={styles.positionBottom}>
                                            <Text fz={20} c={variables.primaryColor}>
                                                Xem tất cả
                                            </Text>
                                            <ChevronRight
                                                size={24}
                                                color={variables.primaryColor}
                                                strokeWidth={2}
                                            />
                                        </Group>
                                    </Link>
                                </Box>
                            </div>
                            <div className={styles.item}>
                                <Box className={`${styles.cardBody} ${styles.itemBody}`}>
                                    <Link
                                        href={`${props.optionData}/${data[2] != undefined ? data[2]?.id : "id-2"
                                            }?type_child=${props.optionTypeChild}`}
                                    >
                                        <Text className={styles.name} lineClamp={2}>
                                            {data[2] != undefined ? data[2]?.name : ""}
                                        </Text>
                                    </Link>
                                    <Text lineClamp={4}>
                                        {data[2] != undefined ? data[2]?.excerpt : ""}
                                    </Text>
                                    <Group className={styles.positionBottom}>
                                        <Text fz={20} c={variables.primaryColor}>
                                            Xem tất cả
                                        </Text>
                                        <ChevronRight
                                            size={24}
                                            color={variables.primaryColor}
                                            strokeWidth={2}
                                        />
                                    </Group>
                                </Box>
                                <Link
                                    href={`${props.optionData}/${data[2] != undefined ? data[0]?.id : "id-0"
                                        }?type_child=${props.optionTypeChild}`}
                                >
                                    <Image
                                        src={
                                            data[2] != undefined
                                                ? data[2]?.image
                                                : "/images/no-img.jpeg"
                                        }
                                        alt={data[2] != undefined ? data[2]?.name : ""}
                                        placeholder={<ImagePlaceholder display={"imagePlaceholder1"}
                                            image={props.defaultImage ? props.defaultImage : props.logo} />}
                                        withPlaceholder
                                        height={384}
                                        className={styles.slide}
                                    />
                                </Link>
                            </div>
                            <div className={styles.item}>
                                <Box className={`${styles.cardBody} ${styles.itemBody}`}>
                                    <Link
                                        href={`${props.optionData}/${data[3] != undefined ? data[3]?.id : "id-3"
                                            }?type_child=${props.optionTypeChild}`}
                                    >
                                        <Text className={styles.name} lineClamp={2}>
                                            {data[3] != undefined ? data[3]?.name : ""}
                                        </Text>
                                    </Link>
                                    <Text lineClamp={4}>
                                        {data[3] != undefined ? data[3]?.excerpt : ""}
                                    </Text>
                                    <Group className={styles.positionBottom}>
                                        <Text fz={20} c={variables.primaryColor}>
                                            Xem tất cả
                                        </Text>
                                        <ChevronRight
                                            size={24}
                                            color={variables.primaryColor}
                                            strokeWidth={2}
                                        />
                                    </Group>
                                </Box>
                                <Link
                                    href={`${props.optionData}/${data[3] != undefined ? data[3]?.id : "id-3"
                                        }?type_child=${props.optionTypeChild}`}
                                >
                                    <Image
                                        src={
                                            data[3] != undefined
                                                ? data[3]?.image
                                                : "/images/no-img.jpeg"
                                        }
                                        alt={data[3] != undefined ? data[3]?.name : ""}
                                        placeholder={<ImagePlaceholder display={"imagePlaceholder1"}
                                            image={props.defaultImage ? props.defaultImage : props.logo} />}
                                        withPlaceholder
                                        height={384}
                                        className={styles.slide}
                                    />
                                </Link>
                            </div>
                            <div className={styles.item}>
                                <Image
                                    src={
                                        data[4] != undefined
                                            ? data[4]?.image
                                            : "/images/no-img.jpeg"
                                    }
                                    alt={data[4] != undefined ? data[4]?.name : ""}
                                    placeholder={<ImagePlaceholder display={"imagePlaceholder1"}
                                        image={props.defaultImage ? props.defaultImage : props.logo} />}
                                    withPlaceholder
                                    height={384}
                                />
                                <Box
                                    className={`${styles.cardBody} ${styles.itemBody} ${styles.slide}`}
                                >
                                    <Link
                                        href={`${props.optionData}/${data[4] != undefined ? data[4]?.id : "id-4"
                                            }?type_child=${props.optionTypeChild}`}
                                    >
                                        <Text className={styles.name} lineClamp={2}>
                                            {data[4] != undefined ? data[4]?.name : ""}
                                        </Text>
                                    </Link>
                                    <Text lineClamp={4}>
                                        {data[4] != undefined ? data[4]?.excerpt : ""}
                                    </Text>
                                    <Link
                                        href={`${props.optionData}/${data[4] != undefined ? data[3]?.id : "id-3"
                                            }?type_child=${props.optionTypeChild}`}
                                    >
                                        <Group className={styles.positionBottom}>
                                            <Text fz={20} c={variables.primaryColor}>
                                                xem chi tiết
                                            </Text>
                                            <ChevronRight
                                                size={24}
                                                color={variables.primaryColor}
                                                strokeWidth={2}
                                            />
                                        </Group>
                                    </Link>
                                </Box>
                            </div>
                        </SimpleGrid>
                    </Grid.Col>
                </Grid>
            </div>
        </Box>
    );
}

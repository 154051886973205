import { Anchor, Box, Divider, Group, Image, Stack, Text } from "@mantine/core";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import styles from "./footer-basics.module.scss";

export function FooterBasic({ options = {}, logo, logoHref, windowDimension }) {
  const isThreshold = useMediaQuery(`(max-width:${options.height?.threshold || 768}px)`);
  const isTextThreshold = useMediaQuery(`(max-width:${options.height?.textThreshold || 768}px)`);
  const [href, setHref] = useState("");
  useEffect(() => {
    setHref(location.pathname);
  }, [location.pathname]);
  return (
    <>
      <Box
        sx={(theme) => ({
          padding: isThreshold ? "10px 20px" : "32px 64px",
        })}
        className={styles.footerBasic}
      >
        <div
          style={{
            display: "flex",
            justifyContent: isThreshold ? "space-between" : "center",
            alignItems: "center",
            flexDirection: isThreshold && !isTextThreshold ? "column" : "row",
            gap: isThreshold ? "20px" : "60px",
          }}
        >
          <Anchor href={logoHref} target={"_blank"}>
            <Image src={logo} alt="logo" width={isThreshold ? 80 : 214} fit="contain" height={isThreshold ? 90 : 128} />
          </Anchor>
          {!isThreshold && <div className={styles.divider}></div>}

          {!isTextThreshold && (
            <Stack spacing={32}>
              {Array.isArray(options.data) && options.data.length > 0 && (
                <Group position="apart" align="center">
                  {options.data?.map((ele, index) => (
                    <Link key={`footer-${index}`} href={ele.href}>
                      <Text
                        className={styles.titleText + " " + (href === ele.href ? styles.titleTextActive : "")}
                        ta="center"
                      >
                        {ele.title}
                      </Text>
                    </Link>
                  ))}
                </Group>
              )}
              {options.description && (
                <>
                  <Divider size={1} />
                  <Text fw={20} ta="center" lineClamp={2} w={600}>
                    {options.description}
                  </Text>
                </>
              )}
            </Stack>
          )}

          {Array.isArray(options.socialMedias) && options.socialMedias.length > 0 && (
            <>
              {!isThreshold && <div className={styles.divider}></div>}
              <Group align="center" spacing={16}>
                {options.socialMedias.map((socialMedia, index) => {
                  return (
                    <Link key={`footer-${index}`} href={socialMedia.link} target={socialMedia.target || "_blank"}>
                      <Image src={socialMedia.image} alt="logo" width={16} height={16} />
                    </Link>
                  );
                })}
              </Group>
            </>
          )}
        </div>
      </Box>
    </>
  );
}

import BookingCalender from "@/components/bookingCalender/bookingCalender";
import FabButton from "@/components/fabButton/fabButton";
import FormDynamic from "@/components/formDynamic/formDynamic";
import Switcher from "@/components/switcher/switcher";
import { newTenants } from "@/services/constants";
import { getFormApplyPrivateApi } from "@/services/formDynamicApis";
import MCard from "./card/card";
import _Carousel from "./carousel/carouel";
import { NewDynamicForm } from "./dynamicForm/core/dynamicForm";
import Faq from "./faq/faq";
import Form from "./form/form";
import FormPrivate from "./formPrivate/formPrivate";
import Gallery from "./gallery/gallery";
import VideoPlayer from "./helpers/videoPlayer";
import Hero from "./hero/hero";
import List from "./list/list";
import Research from "./research/research";
import Search from "./search/search";
import Subscribe from "./subscribe/subscribe";

export default function Feature(props) {
  let eleHTML = <></>;
  switch (props.component) {
    case "hero":
      eleHTML = (
        <Hero
          url={props.url}
          display={props.display}
          options={props.options}
          primaryColor={props.primaryColor}
          secondaryColor={props.secondaryColor}
          imageLogo={props.logo}
          windowDimension={props.windowDimension}
          defaultImage={props.defaultImage}
          backgroundColor={props.backgroundColor}
        />
      );
      break;
    case "card":
      eleHTML = <MCard display={props.display} options={props.options} />;
      break;
    case "search":
      eleHTML = (
        <Search
          url={props.url}
          display={props.display}
          windowDimension={props.windowDimension}
          options={props.options}
          primaryColor={props.primaryColor}
          secondaryColor={props.secondaryColor}
          defaultImage={props.defaultImage}
          imageLogo={props.logo}
          noDataText={props.noDataText}
          backgroundColor={props.backgroundColor}
        />
      );
      break;
    case "carousel":
      eleHTML = (
        <_Carousel
          image={props.logo}
          url={props.url}
          options={props.options}
          display={props.display}
          background={props.background}
          windowDimension={props.windowDimension}
          primaryColor={props.primaryColor}
          secondaryColor={props.secondaryColor}
          defaultImage={props.defaultImage}
          noDataText={props.noDataText}
          backgroundColor={props.backgroundColor}
        />
      );
      break;
    case "gallery":
      eleHTML = (
        <Gallery
          image={props.logo}
          url={props.url}
          display={props.display}
          options={props.options}
          background={props.background}
          primaryColor={props.primaryColor}
          secondaryColor={props.secondaryColor}
          windowDimension={props.windowDimension}
          defaultImage={props.defaultImage}
          backgroundColor={props.backgroundColor}
        />
      );
      break;
    case "list":
      eleHTML = (
        <List
          image={props.logo}
          url={props.url}
          display={props.display}
          options={props.options}
          primaryColor={props.primaryColor}
          secondaryColor={props.secondaryColor}
          windowDimension={props.windowDimension}
          defaultImage={props.defaultImage}
          backgroundColor={props.backgroundColor}
        />
      );
      break;
    case "research":
      eleHTML = (
        <Research
          imageLogo={props.logo}
          url={props.url}
          display={props.display}
          options={props.options}
          background={props.background}
          windowDimension={props.windowDimension}
          defaultImage={props.defaultImage}
          backgroundColor={props.backgroundColor}
        />
      );
      break;
    case "form":
      eleHTML = (
        <Form
          display={props.display}
          url={props.url}
          options={props.options}
          defaultImage={props.defaultImage}
          backgroundColor={props.backgroundColor}
        />
      );
      break;
    case "d-form":
      eleHTML = (
        <NewDynamicForm
          label={props.options.title}
          description={props.options.description}
          controls={props.options.dataSource}
          name="form"
          {...props.options}
        />
      );
      break;
    case "form-private":
      if (newTenants.includes(props.tenant)) {
        eleHTML = (
          <FormDynamic
            display={"form-dynamic2"}
            windowDimension={props.windowDimension}
            hostNameUrl={props.url}
            useDefaultValue={false}
            getFormTemplateApi={getFormApplyPrivateApi}
            primaryColor={props.primaryColor ? props.primaryColor : "#fff"}
            secondaryColor={
              props.secondaryColor ? props.secondaryColor : "#fff"
            }
            defaultImage={props.defaultImage}
            backgroundColor={props.backgroundColor}
          />
        );
      } else {
        eleHTML = (
          <FormPrivate
            display={props.display}
            url={props.url}
            options={props.options}
            backgroundColor={props.backgroundColor}
            defaultImage={props.defaultImage}
            markdown={"**Test Markdown** *Đây là nghiêng nek*"}
          />
        );
      }
      break;
    case "subscribe":
      eleHTML = (
        <Subscribe
          display={props.display}
          options={props.options}
          background={props.background}
          primaryColor={props.primaryColor}
          secondaryColor={props.secondaryColor}
          windowDimension={props.windowDimension}
          defaultImage={props.defaultImage}
          backgroundColor={props.backgroundColor}
        />
      );
      break;

    case "switcher":
      eleHTML = (
        <Switcher
          url={props.url}
          display={props.display}
          options={props.options}
          windowDimension={props.windowDimension}
          primaryColor={props.primaryColor}
          secondaryColor={props.secondaryColor}
          imageLogo={props.logo}
          defaultImage={props.defaultImage}
          backgroundColor={props.backgroundColor}
        />
      );
      break;

    case "fab-button":
      eleHTML = (
        <FabButton
          display={props.display}
          options={props.options}
          backgroundColor={props.backgroundColor}
          primaryColor={props.primaryColor}
        />
      );
      break;

    case "booking-calender": {
      eleHTML = <BookingCalender options={props.options} />;
      break;
    }

    case "form-dynamic":
      eleHTML = (
        <FormDynamic
          display={props.display}
          options={props.options}
          windowDimension={props.windowDimension}
          defaultImage={props.defaultImage}
          backgroundColor={props.backgroundColor}
        />
      );
      break;
    case "video":
      eleHTML = <VideoPlayer options={props.options} />;
      break;
    case "faq":
      eleHTML = <Faq display={props.display} options={props.options} />;
      break;
    default:
      break;
  }
  return eleHTML || <></>;
}

export const dynamicFilterData = (dynamicFilters, data, sortOption) => {
  let filteredData = [...data];
  if (Array.isArray(dynamicFilters) && dynamicFilters.length > 0) {
    filteredData = data.filter((dataSingleton) => {
      return dynamicFilters.every(({ key, value, type, searchType }) => {
        let isValueArray = false;
        switch (type) {
          case "single":
          case "text": {
            if (!dataSingleton[key]) {
              return true;
            }

            if (String(value).length === 0) {
              return true;
            }

            break;
          }
          case "multiple": {
            if (!Array.isArray(value) || value.length === 0) {
              return true;
            }

            isValueArray = true;
            break;
          }
        }
        const checkValue = (valueCheckSingle, valueArray = []) => {
          if (Array.isArray(dataSingleton[key])) {
            if (
              dataSingleton[key].length === 0 &&
              valueArray.length === 0 &&
              valueCheckSingle.length === 0
            ) {
              return true;
            }

            return dataSingleton[key].includes(valueCheckSingle);
          }

          switch (searchType) {
            case "contains": {
              return String(dataSingleton[key])
                .toLowerCase()
                .includes(String(valueCheckSingle).toLowerCase());
            }
            case "exact": {
              return (
                String(dataSingleton[key]).toLowerCase() ===
                String(valueCheckSingle).toLowerCase()
              );
            }
            default:
              return true;
          }
        };

        if (isValueArray) {
          return value.some((valueSingle) => {
            return checkValue(valueSingle, value);
          });
        }
        return checkValue(value);
      });
    });
  }

  if (
    sortOption &&
    typeof sortOption === "string" &&
    sortOption.includes("|")
  ) {
    const splitOption = sortOption.split("|");
    if (sortOption.length !== 3) {
      return filteredData;
    }

    const key = splitOption[0];
    const sortType = splitOption[1];
    const sortValue = parseInt(splitOption[2]);

    filteredData = filteredData.sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      if (!aValue && !bValue) {
        return 0;
      }

      if (aValue && !bValue) {
        return 1;
      }
      if (!aValue && bValue) {
        return -1;
      }
      switch (sortType) {
        case "date": {
          const date1 = new Date(aValue);
          const date2 = new Date(bValue);
          return sortValue < 0 ? date2 - date1 : date1 - date2;
        }

        case "text": {
          const aValueLowerCase = aValue.toLowerCase().trim();
          const bValueLowerCase = bValue.toLowerCase().trim();

          if (aValueLowerCase < bValueLowerCase) {
            return -sortValue;
          }

          if (aValueLowerCase > bValueLowerCase) return sortValue;

          return 0;
        }
      }

      return 0;
    });
  }

  return filteredData;
};

import JobCard2 from "@/components/card/jobCard2/jobCard2";
import { dynamicFilterData } from "@/components/feature";
import ModalContent from "@/components/modal/modalContent";
import { getDataFromCollection } from "@/services/services";
import variables from "@/styles/variables.module.scss";
import { Button, Center, Grid, Group, Modal, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import convert from "color-convert";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { ArrowLeft } from "tabler-icons-react";
import { useMediaQuery } from "usehooks-ts";
import Card1 from "../../card/card1/card1";
import JobCardDetail from "../../card/jobCardDetail/jobCardDetail";
import { JobCardRect } from "../../card/jobCardRect/jobCardRect";
import _Pagination from "../../helpers/pagination";
import JobListStyles from "./jobList.module.scss";
import useTrans from "@/hooks/useTrans";

function JobList({
  options,
  url,
  primaryColor,
  secondaryColor,
  defaultImage,
  imageLogo,
  hideManagerSection,
}) {
  const trans = useTrans();
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [activeLimit, setActiveLimit] = useState(options.limit || 15);
  const [detail, setDetail] = useState(null);
  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);
  const modalWidthSize = 800;

  const isMobile = useMediaQuery("(max-width: 768px)");
  const isHybridMobileIpad = useMediaQuery("(max-width: 1100px)");
  const isIpad = useMediaQuery("(max-width: 1366px)");

  useEffect(() => {
    const getDataApi = async () =>
      await getDataFromCollection(
        url,
        options.data,
        options.query || "",
        options.jsonata,
        null,
        null,
        activePage,
        activeLimit
      );

    setActiveLimit(options.limit || 15);
    getDataApi()
      .then((res) => {
        if (res) {
          let resUsed = [res];
          if (Array.isArray(res)) {
            resUsed = res;
          }
          setData([...resUsed]);
          // if (resUsed.length > 0) {
          //     setDetail(resUsed[0]);
          // }
        }
      })
      .catch((err) => console.log(err));

    return () => {
      setData([]);
    };
  }, [options.data, options.query, options.jsonata, url, activePage]);

  const filteredData = dynamicFilterData(
    options.dynamicFilters || [],
    [...data],
    options.sortOption
  );

  // const pagedData = filteredData.slice(
  //   activeLimit * (activePage - 1),
  //   activeLimit * activePage
  // );

  //  const pagedData = filteredData.slice(
  //   activeLimit * (activePage - 1),
  //   activeLimit * activePage
  // );
  useEffect(() => {
    if (activePage !== 1) {
      setActivePage(1);
    }
    if (detail) {
      setDetail(null);
      setActiveLimit(options.limit || 6);
    }
  }, [options.dynamicFilters]);

  useEffect(() => {
    if (options.cardType === "job-card2") {
      if (detail && !modalOpened) {
        openModal();
      }
    }
  }, [detail, modalOpened, options]);

  const useDetail = options.useDetail === undefined || options.useDetail;

  const jobCardJSX = filteredData.map((recruitmentPlan, index) => {
    let cardJSX = <></>;

    switch (options.cardType) {
      case "job-card2":
        {
          cardJSX = (
            <JobCard2
              onBtnClick={() => {
                if (useDetail) {
                  setDetail(recruitmentPlan);
                } else {
                  const a = document.createElement("a");
                  a.target = "_blank";
                  a.href =
                    options.data +
                    `/${recruitmentPlan.id}` +
                    `?type_child=${options.child}`;
                  a.click();
                }
              }}
              title={recruitmentPlan.name}
              image={recruitmentPlan.image || defaultImage || imageLogo}
              salary={recruitmentPlan.salary}
              location={recruitmentPlan.location}
              expirationDate={
                recruitmentPlan.expiration_date ?? recruitmentPlan.date
              }
              tags={recruitmentPlan.tags}
              description={recruitmentPlan.description}
            />
          );
        }

        break;

      case "card1":
        {
          cardJSX = (
            <Card1
              title={recruitmentPlan.name}
              image={recruitmentPlan.image || defaultImage || imageLogo}
              salary={recruitmentPlan.salary}
              location={recruitmentPlan.locationAlt}
              expirationDate={recruitmentPlan.date}
              tags={recruitmentPlan.tags}
              description={recruitmentPlan.description}
              defaultImage={defaultImage}
              onBtnClick={() => {
                const a = document.createElement("a");
                a.target = "_blank";
                a.href =
                  options.data +
                  `/${recruitmentPlan.id}` +
                  `?type_child=${options.child}`;
                a.click();
              }}
            />
          );
        }
        break;

      default:
        {
          cardJSX = (
            <JobCardRect
              inDetailMode={!!detail}
              isSelected={
                recruitmentPlan.id && recruitmentPlan.id === detail?.id
              }
              recruitmentPlan={recruitmentPlan}
              descriptionArray={options.descriptionArray}
            />
          );
        }
        break;
    }

    return (
      <div
        key={index}
        style={{
          cursor: "pointer",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        onClick={() => {
          if (useDetail) {
            setDetail(recruitmentPlan);
          } else {
            const a = document.createElement("a");
            a.target = "_blank";
            a.href =
              options.data +
              `/${recruitmentPlan.id}` +
              `?type_child=${options.child}`;
            a.click();
          }
        }}
      >
        {cardJSX}
      </div>
    );
  });

  if (jobCardJSX.length === 0) {
    return <></>;
  }
  const convertColor = convert.hex.hsl(primaryColor);
  const regularStyle = filteredData.length < data.length && !options.altStyle;
  const altStyle = filteredData.length < data.length && options.altStyle;

  let titleJSX = <></>;

  switch (options.titleType) {
    case "alt1":
      {
        titleJSX = (
          <Group position="center">
            <div className={JobListStyles.titleAlt1}>
              {/* {!options.coloredTitleAfter && <div className={Carousel8Style.coloredTitle}>{options.coloredTitle}</div>} */}
              {parse(options.title || "")}
              {/* {options.coloredTitleAfter && <div className={Carousel8Style.coloredTitle}>{options.coloredTitle}</div>} */}
              <div
                className={JobListStyles.backgroundAlt1}
                style={{ backgroundColor: secondaryColor, width: "120%" }}
              ></div>
            </div>
          </Group>
        );
      }
      break;

    default:
      {
        titleJSX = (
          <Stack spacing={16}>
            <Group spacing={16} align="center">
              <Text className={JobListStyles.startTitle}>
                {options.title || trans.jobListTitle}
              </Text>
              <Text className={JobListStyles.searchCountText}>
                {/* {"(" + filteredData.length + " " + trans.job + ")"} */}
                {`(${filteredData.length} ${trans.job})`}
              </Text>
            </Group>
            <div className={JobListStyles.divider} />
          </Stack>
        );
      }
      break;
  }

  return (
    <>
      {detail && (
        <Modal
          overflow={"inside"}
          size={modalWidthSize}
          opened={modalOpened}
          onClose={() => {
            closeModal();
            setDetail(null);
          }}
          title={
            <Group spacing={5}>
              <Text fw={700} fz={16} c={primaryColor}>
                {trans.applyFor}
              </Text>
              <Text fw={700} fz={16} c={primaryColor}>
                {detail.name}
              </Text>
            </Group>
          }
        >
          <ModalContent
            display={"modal-content1"}
            contentWidthSize={modalWidthSize}
            url={url}
            channelType={detail.channelType}
            recruitmentPlanId={detail.id}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            closeModal={closeModal}
          />
        </Modal>
      )}
      {detail && useDetail ? (
        <Stack className={JobListStyles.jobList}>
          <Group
            position="left"
            style={{ width: "100%", padding: 10, marginBottom: -10 }}
          >
            <Button
              bg={primaryColor}
              style={{ width: "fit-content" }}
              sx={{
                "&:hover": {
                  backgroundColor: variables.hoverColor,
                },
              }}
              onClick={() => {
                setDetail(null);
                setActiveLimit(options.limit || 6);
              }}
            >
              <ArrowLeft />
            </Button>
          </Group>
          {regularStyle &&
            (options.hasTitle === undefined || options.hasTitle) && (
              <Text className={JobListStyles.title}>
                {filteredData.length === data.length
                  ? options.title
                  : options.searchTitle || options.title}
              </Text>
            )}
          <Group className={JobListStyles.listAndDetail} align="start">
            <Stack spacing={24} style={{ width: "40%" }} hidden={isMobile}>
              <Stack className={JobListStyles.list}>
                {altStyle && (
                  <Text className={JobListStyles.searchCountText}>
                    {filteredData.length} {trans.searchResult}
                  </Text>
                )}
                {jobCardJSX}
              </Stack>
              <Group
                position={
                  filteredData.length === data.length || options.altStyle
                    ? "center"
                    : "apart"
                }
                align="center"
              >
                {regularStyle && (
                  <Text
                    className={JobListStyles.searchCountText}
                  >{`${filteredData.length} ${trans.searchResult}`}</Text>
                )}
                <_Pagination
                  activePage={activePage}
                  activeLimit={activeLimit}
                  dataLength={filteredData.length}
                  setActivePage={setActivePage}
                  primaryColor={primaryColor}
                />
              </Group>
            </Stack>

            <div
              style={{
                width: isMobile ? "100%" : "calc(60% - 32px)",
                height: "100%",
                padding: isMobile && 10,
              }}
            >
              {detail && (
                <JobCardDetail
                  backgroundColor={options.detailBackgroundColor}
                  headerImage={options.detailHeaderImage}
                  headerBackgroundColor={
                    !options.detailHeaderImage &&
                    `hsla(${convertColor[0]}, ${convertColor[1]}%, ${convertColor[2]}%, 0.1)`
                  }
                  onBtnClick={openModal}
                  recruitmentPlan={detail}
                  descriptionArray={options.descriptionArray}
                  primaryColor={primaryColor}
                  hideManagerSection={hideManagerSection}
                />
              )}
            </div>
          </Group>
        </Stack>
      ) : (
        <Stack spacing={32} p={isMobile ? 20 : 48} style={{ height: "100%" }}>
          {(options.hasTitle === undefined || options.hasTitle) && titleJSX}

          <div
            style={{
              height: options.height
                ? options.height *
                  (isIpad
                    ? isMobile
                      ? options.multiplier?.height?.mobile || 1.4
                      : options.multiplier?.height?.ipad || 1.1
                    : 1)
                : "100%",
              overflowY: options.overflowY || "hidden",
              overflowX: "hidden",
            }}
          >
            <Grid columns={12}>
              {jobCardJSX.map((card, index) => {
                return (
                  <Grid.Col
                    key={index}
                    span={isIpad ? 12 : options.minSpan || 6}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {card}
                  </Grid.Col>
                );
              })}
            </Grid>
          </div>

          {(options.hasPagination === undefined || options.hasPagination) && (
            <Center>
              <_Pagination
                activePage={activePage}
                activeLimit={activeLimit}
                dataLength={data[0].total}
                setActivePage={setActivePage}
                primaryColor={primaryColor}
              />
            </Center>
          )}
        </Stack>
      )}
    </>
  );
}

export default JobList;

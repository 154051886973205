import { Divider, Group, Image, Stack, Text } from "@mantine/core";
import Link from "next/link";
import { useEffect, useState } from "react";
import { ChevronRight } from "tabler-icons-react";
import styles from "../../../styles/feature.module.scss";
import globals from "../../../styles/globals.module.scss";
import variables from "../../../styles/variables.module.scss";
import { loadData } from "../gallery";

export function Gallery4(props) {
    const [data, setData] = useState([]);
    useEffect(() => {
        loadData({
            url: props.url,
            data: props.optionData,
            query: props.optionQuery,
            jsonata: props.optionJsonata,
            selectedIds: props.optionSelectedIds
        }, setData, true);
    }, []);

    return (
        <Stack spacing={32} className={styles.gallery4}>
            <Stack spacing={16}>
                <Text className={globals.heading1}>{props.title}</Text>
                <Divider size={1} color={variables.secondColor} style={{ width: "100px" }} />
            </Stack>
            <Group spacing={32} className={styles.gallery4Body}>
                <Link className={styles.firstCard}
                    href={`${props.optionData}/${data.length > 0 ? data[0].id : "id-0"
                        }?type_child=${props.optionTypeChild}`}>
                    <Stack>
                        <Image radius={"40px 40px 0px 0px"} width={520} height={385.24}
                            src={data.length > 0 ? data[0].image : (props.defaultImage ? props.defaultImage : props.logo)}
                            alt="" />
                        <Stack justify="space-between" className={styles.firstCardBody}>
                            <Text fz={22} fw={700} c={variables.primaryColor}
                                lineClamp={2}>{data.length > 0 ? data[0].name : 'Loading...'}</Text>
                            <Group spacing={8}>
                                <Text fz={20} c={variables.primaryColor}>
                                    Xem chi tiết
                                </Text>
                                <ChevronRight
                                    size={24}
                                    color={variables.primaryColor}
                                    strokeWidth={2}
                                />
                            </Group>
                        </Stack>
                    </Stack>
                </Link>
                <Stack spacing={32}>
                    <Gallery4HorizontalCard
                        image={data.length > 0 ? data[1].image : (props.defaultImage ? props.defaultImage : props.logo)}
                        title={data.length > 0 ? data[1].name : 'Loading...'}
                        link={data.length > 0 ? `${props.optionData}/${data[1] ? data[1].id : "id-0"
                            }?type_child=${props.optionTypeChild}` : ''}
                        isImageLeft={true} />

                    <Gallery4HorizontalCard
                        image={data.length > 0 ? data[2].image : (props.defaultImage ? props.defaultImage : props.logo)}
                        title={data.length > 0 ? data[2].name : 'Loading...'}
                        link={data.length > 0 ? `${props.optionData}/${data[2] ? data[2].id : "id-0"
                            }?type_child=${props.optionTypeChild}` : ''}
                        isImageLeft={false} />
                </Stack>
            </Group>
        </Stack>
    );
}

function Gallery4HorizontalCard(props) {
    const imageComponent = <Image radius={24} src={props.image} alt="" width={320} height={320} />

    const textComponent = (
        <Stack style={{ padding: "24px", width: "320px" }} spacing={124}>
            <Text lineClamp={4} fz={22} fw={700} c={variables.primaryColor}>{props.title}</Text>
            <Group spacing={8}>
                <Text fz={20} c={variables.primaryColor}>
                    Xem chi tiết
                </Text>
                <ChevronRight
                    size={24}
                    color={variables.primaryColor}
                    strokeWidth={2}
                />
            </Group>
        </Stack>
    );

    const eleHTMl = (
        <>
            <Link href={props.link}>
                <Group spacing={32} style={{ width: "100%", height: "100%" }}>
                    {props.isImageLeft ? imageComponent : textComponent}
                    {props.isImageLeft ? textComponent : imageComponent}
                </Group>

            </Link>
        </>
    );

    return eleHTMl;
}

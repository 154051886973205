import { Carousel } from "@mantine/carousel";
import { Box, Button, Center, Divider, Group, Image, Stack, Text } from "@mantine/core";
import Link from "next/link";
import { useEffect, useState } from "react";
import { AlertCircle, Clock, CurrencyDollar, Location } from "tabler-icons-react";
import styles from "../../../styles/feature.module.scss";
import variables from "../../../styles/variables.module.scss";
import ImagePlaceholder from "../../imagePlaceholder/imagePlaceholder";
import { loadData } from "../carouel";
import useTrans from "@/hooks/useTrans";

export function Carousel1(props) {
    const [data, setData] = useState([]);
    const trans = useTrans();
    useEffect(() => {
        loadData({
            url: props.url,
            data: props.data,
            query: props.query,
            jsonata: props.jsonata,
            display: props.display,
            selectedIds: props.selectedIds || props.hotJobIds || props.hotNewsIds
        }, setData);

    }, []);

    return (
        <Box
            className={styles.carousel1}
            sx={(theme) => ({
                padding: "0px 64px 56px 64px",
                background: "#F2F2F2",
            })}
        >
            <Stack>
                <Text align="center" className={styles.title}>
                    {props.title}
                </Text>
                <Center>
                    <div className={styles.boxDivider}>
                        <Divider size={1} color={variables.primaryColor} />
                    </div>
                </Center>
            </Stack>
            <Carousel
                loop={props.hasLoop}
                className={styles.carousel}
                slideSize="33%"
                slideGap={48}
                align="start"
                slidesToScroll={2}
                controlSize={48}
                mt={48}
            >
                {data?.map((ele, index) => (
                    <Carousel.Slide key={`slide-${index}`}>
                        <Stack className={styles.slide} spacing={0}>
                            <Image
                                src={ele.image}
                                withPlaceholder
                                placeholder={<ImagePlaceholder display={"imagePlaceholder1"}
                                    image={props.defaultImage ? props.defaultImage : props.imageLogo} />}
                                alt="job 1"
                                height={400}
                            />
                            <Stack p={24}>
                                <div className={styles.flexBetween}>
                                    {ele.hot_job
                                        ? <Group>
                                            <AlertCircle
                                                size={16}
                                                strokeWidth={1.5}
                                                color={"#F5222D"}
                                            />
                                            <Text color={"#F5222D"}>{trans.hotJob}</Text>
                                        </Group>
                                        : <div></div>
                                    }

                                    <Group>
                                        <Clock
                                            size={16}
                                            strokeWidth={1.5}
                                            color={"rgba(37, 37, 37, 0.7)"}
                                        />
                                        <Text>{ele.expiration_date}</Text>
                                    </Group>
                                </div>
                                <Link
                                    href={`/${props.data}/${ele.id}?type_child=${props.child}`}
                                >
                                    <Text className={styles.jobName} lineClamp={1}>
                                        {ele.name}
                                    </Text>
                                </Link>
                                <Group>
                                    <Location
                                        size={24}
                                        strokeWidth={1.5}
                                        color={"rgba(37, 37, 37, 0.7)"}
                                    />
                                    <Text>{ele.location}</Text>
                                </Group>
                                <Group>
                                    <CurrencyDollar
                                        size={24}
                                        strokeWidth={1.5}
                                        color={"rgba(37, 37, 37, 0.7)"}
                                    />
                                    <Text>{ele.salary}</Text>
                                </Group>
                                <Button
                                    style={{
                                        background: variables.primaryColor,
                                        borderRadius: 36,
                                    }}
                                >
                                    {trans.apply}
                                </Button>
                            </Stack>
                        </Stack>
                    </Carousel.Slide>
                ))}
            </Carousel>
        </Box>
    );
}

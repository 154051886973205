import ImageComponent from '@/components/helpers/imageComponent/imageComponent';
import ImagePlaceholder from '@/components/imagePlaceholder/imagePlaceholder';
import { Carousel } from '@mantine/carousel';
import { Divider, Image, Text } from '@mantine/core';
import Autoplay from 'embla-carousel-autoplay';
import parse from 'html-react-parser';
import { useRef } from 'react';
import componentStyle from './carouselHero.module.scss';

function CarouselHero({ options: { showPreviewImage = false, images = [], title, data = [], description, delay = 0, imageFit }, imageLogo = undefined, defaultImage = undefined }) {
    const autoplay = useRef(Autoplay({ delay: delay > 0 ? delay * 1000 : 5000 }));


    let carouselJSX = <></>
    if (images.length > 1)
        carouselJSX = <>
            <Carousel
                className={componentStyle.carousel}
                withControls={false}
                loop={true}
                slideGap={"md"}
                plugins={[autoplay.current]}

            >

                {images.map((image, index) => {
                    return (
                        <Carousel.Slide key={index}>

                            {
                                showPreviewImage ? <ImageComponent src={image} width={'100%'} alt="image" className={componentStyle.carouselImage} radius={40} fit={imageFit} placeholder={defaultImage || imageLogo} />
                                    : <Image src={image} radius={40} width={"100%"} alt="image" className={componentStyle.carouselImage} fit={imageFit} placeholder={<ImagePlaceholder display={"imagePlaceholder1"}
                                        image={defaultImage || imageLogo} />} />
                            }

                        </Carousel.Slide>
                    )
                })}
            </Carousel>
        </>

    return (
        <div className={componentStyle.carouselHero}>
            <div className={componentStyle.imageBox} />
            {images.length === 0 && <Image src={defaultImage || imageLogo} alt="image" className={componentStyle.image} fit={imageFit} />}
            {images.length > 0 && images.length === 1 && (
                <>
                    {
                        showPreviewImage ? <ImageComponent src={images[0]} alt="image" radius={40} className={componentStyle.image} fit={imageFit} placeholder={defaultImage || imageLogo} />
                            : <Image radius={40} src={images[0]} alt="image" className={componentStyle.image} fit={imageFit} placeholder={<ImagePlaceholder display={"imagePlaceholder1"}
                                image={defaultImage || imageLogo} />} />
                    }
                </>

            )}
            {images.length > 0 && images.length > 1 && carouselJSX}

            <div className={componentStyle.rightSide} >
                <div className={componentStyle.rightSideTitle}>
                    <div className={componentStyle.title}>{title}</div>
                    <div style={{ width: 100 }}>
                        <Divider size={2} className={componentStyle.divider} />
                    </div>
                    <Text className={componentStyle.description} lineClamp={5}>
                        {parse(description)}
                    </Text>
                </div>


                <div className={componentStyle.rightSideData}>

                    {data.map(({ label, description }, index) => {
                        return <div className={componentStyle.dataGroup} key={index}>
                            <div className={componentStyle.data}>
                                <div className={componentStyle.title}>{label}</div>
                                <div className={componentStyle.description}>{parse(description)}</div>
                            </div>
                            {index + 1 < data.length && <div className={componentStyle.verticalDivider}></div>}
                        </div>

                    })}
                </div>

            </div>

        </div>

    )
}

export default CarouselHero
import { Group, Image, Stack, Text } from "@mantine/core";
import parse from "html-react-parser";
import Link from "next/link";
import styles from "./footer3.module.scss";

export default function Footer3({ options, logo, logoHref }) {
  return (
    <div
      className={styles.footer3}
      style={{ color: options.color || "#031F6D", background: options.background || "#E1EDFB" }}
    >
      <Stack spacing={24} align="center">
        <Link href={logoHref || ""} target="_blank">
          <Image alt="" width={200} src={logo} />
        </Link>
        <Text className={styles.text}>{parse(options?.subLogoText)}</Text>
      </Stack>

      <Stack spacing={16}>
        <Text className={styles.heading}>{options?.title}</Text>
        <Stack spacing={8}>
          {options?.descriptionArray?.map((description, index) => {
            return (
              <Group noWrap key={index}>
                {description.image && (
                  <Image
                    src={description.image.src}
                    alt=""
                    width={description.image.width || 16}
                    height={description.image.height || 16}
                  />
                )}
                <Text className={styles.text}>{description.text}</Text>
              </Group>
            );
          })}
        </Stack>
      </Stack>
      <Stack spacing={32}>
        {options?.contactInfoArray?.map(({ title, infoArray, infoSpacing }, index) => {
          return (
            <Stack spacing={16} key={index}>
              <Text className={styles.text1}>{title}</Text>
              <Group noWrap spacing={infoSpacing || 40}>
                {infoArray?.map(({ href, image, target }, infoIndex) => {
                  return (
                    <Link href={href} key={infoIndex} target={target}>
                      {" "}
                      <Image
                        alt=""
                        fit="fill"
                        src={image?.src}
                        width={image?.width || 36}
                        height={image?.height || 36}
                      />
                    </Link>
                  );
                })}
              </Group>
            </Stack>
          );
        })}
      </Stack>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { getLocationData } from "@/services/formDynamicApis";
import { Autocomplete, Text, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import stringSimilarity from "string-similarity";
import { Mail } from "tabler-icons-react";
import vnStr from "vn-str";
import styles from "../../styles/dynamic-form.module.scss";
import { getDescriptionStyleJSX } from "../nonPrimitiveType/formDynamicInput";

export default function FormDynamicInputSelect(props) {
  if (props.inputTemplate && props.inputTemplate.type === "select") {
    return <FormDynamicInputSelectHelper {...props} />;
  }
  return <></>;
}

function FormDynamicInputSelectHelper(props) {
  const [dataToLoop, setDataToLoop] = useState([]);
  const [oldInvisible, setOldInvisible] = useState(false);
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [hasSelectCustomError, setHasSelectCustomError] = useState(false);
  const [allowType, setAllowType] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const inputTemplate = { ...props.inputTemplate };

  const inputProps = { ...props.formInputProps };
  const disabledInput = props.disabledInput || inputProps.disabled;

  const setupDataToLoop = (inputTemplate, data = []) => {
    let dataToBeLooped = data.length > 0 ? data : inputTemplate.select.options;

    const valueField = inputTemplate.select.valueField || "code";
    const labelField = inputTemplate.select.labelField || "name";
    dataToBeLooped = dataToBeLooped.map((e) => {
      const returnData = {};
      returnData["value"] = e[valueField];
      returnData["label"] = e[labelField];
      return returnData;
    });

    if (inputTemplate.select.masterdata === "nationality") {
      const vietnamOptionIndex = dataToBeLooped.findIndex(
        (e) => e.value === "001"
      );
      if (vietnamOptionIndex >= 0) {
        dataToBeLooped = [
          dataToBeLooped[vietnamOptionIndex],
          ...dataToBeLooped.slice(0, vietnamOptionIndex),
          ...dataToBeLooped.slice(vietnamOptionIndex + 1),
        ];
      }
    }
    if (inputTemplate.specialOptions) {
      if (
        Array.isArray(inputTemplate.specialOptions.add) &&
        inputTemplate.specialOptions.add.length > 0
      ) {
        dataToBeLooped = [
          ...dataToBeLooped,
          ...inputTemplate.specialOptions.add,
        ];
      }

      if (
        Array.isArray(inputTemplate.specialOptions.remove) &&
        inputTemplate.specialOptions.remove.length > 0
      ) {
        dataToBeLooped = dataToBeLooped.filter((e) => {
          return !inputTemplate.specialOptions.remove.find((singleOption) => {
            return (
              singleOption.label === e.label || singleOption.value === e.value
            );
          });
        });
      }
      if (
        Array.isArray(inputTemplate.specialOptions.prefixes) &&
        inputTemplate.specialOptions.prefixes.length > 0
      ) {
        dataToBeLooped = dataToBeLooped.sort((a, b) => {
          const aOptionFind = inputTemplate.specialOptions.prefixes.find(
            (singleOption) => {
              return (
                singleOption.label === a.label || singleOption.value === a.value
              );
            }
          );

          const bOptionFind = inputTemplate.specialOptions.prefixes.find(
            (singleOption) => {
              return (
                singleOption.label === b.label || singleOption.value === b.value
              );
            }
          );

          if (aOptionFind && !bOptionFind) {
            return -1;
          }
          if (bOptionFind && !aOptionFind) {
            return 1;
          }
          return 0;
        });
      }
      if (
        Array.isArray(inputTemplate.specialOptions.suffixes) &&
        inputTemplate.specialOptions.suffixes.length > 0
      ) {
        dataToBeLooped = dataToBeLooped.sort((a, b) => {
          const aOptionFind = inputTemplate.specialOptions.suffixes.find(
            (singleOption) => {
              return (
                singleOption.label === a.label || singleOption.value === a.value
              );
            }
          );
          const bOptionFind = inputTemplate.specialOptions.prefixes.find(
            (singleOption) => {
              return (
                singleOption.label === b.label || singleOption.value === b.value
              );
            }
          );
          if (aOptionFind && !bOptionFind) {
            return 1;
          }
          if (bOptionFind && !aOptionFind) {
            return -1;
          }
          return 0;
        });
      }
    }
    return dataToBeLooped;
  };

  inputProps.onChange = (value) => {
    if (!value) {
      props.formInputProps.onChange(value);
      if (allowType) {
        setAllowType(null);
      }
      return;
    }

    if (props.handleDataInSelect) {
      props.handleDataInSelect(inputTemplate, value);
    }
    const allowTypeConfig =
      inputTemplate.select && inputTemplate.select.allowType
        ? { ...inputTemplate.select.allowType }
        : null;
    if (allowTypeConfig && value === allowTypeConfig.value) {
      if (!allowType) {
        setAllowType({ ...allowTypeConfig, textValue: "" });
        props.formInputProps.onChange(
          value + allowTypeConfig.delimiter || "\\"
        );
      } else {
        props.formInputProps.onChange(
          value + (allowType.delimiter || "\\") + allowType.textValue
        );
      }
    } else {
      setAllowType(null);
      props.formInputProps.onChange(value);
    }
  };

  useEffect(() => {
    if (
      !inputProps.dataRef &&
      inputTemplate.select &&
      inputTemplate.select.options &&
      Array.isArray(inputTemplate.select.options)
    ) {
      const dataToBeLooped = setupDataToLoop(inputTemplate);

      setDataToLoop(dataToBeLooped);
    }
  }, [inputTemplate.select]);

  // useEffect(() => {
  //     if (props.dataInSelect && Array.isArray(props.dataInSelect)) {
  //         if (props.dataInSelect.length === 0 && props.formInputProps.value.length > 0) {
  //             return;
  //         }

  //         const dataToBeLooped = setupDataToLoop(inputTemplate, [...props.dataInSelect]);
  //         setDataToLoop(dataToBeLooped);
  //     }

  // }, [props.dataInSelect]);

  useEffect(() => {
    if (
      inputTemplate.select &&
      inputTemplate.select.masterdata &&
      props.hostNameUrl &&
      inputTemplate._dataRef
    ) {
      if (!inputProps.dataRef || inputProps.dataRef.length === 0) {
        setDataToLoop([]);
        inputProps.onChange("");
        if (!!loaded) {
          setLoaded(true);
        }
        return;
      } else {
        getLocationData(
          inputTemplate.select.masterdata,
          inputProps.dataRef,
          props.hostNameUrl
        ).then((res) => {
          if (!res.isError) {
            const dataToBeLooped = setupDataToLoop({ ...inputTemplate }, [
              ...res.data,
            ]);
            if (!loaded) {
              inputProps.onChange(props.initialValue);
              setLoaded(true);
            } else {
              inputProps.onChange("");
            }
            setDataToLoop(dataToBeLooped);
          }
        });
      }
    }
  }, [inputProps.dataRef]);
  
  useEffect(() => {
    if (props.formInputProps.value) {
      if (props.formInputProps.value === "") {
        setAutoCompleteValue("");
        return;
      }
      let valueToCompare =
        props.inputTemplate.defaultValue ?? props.formInputProps.value;
      console.log(valueToCompare, 'uyntq1')
      const delimiter = allowType ? allowType.delimiter || "\\" : "\\";
      if (props.formInputProps.value.includes(delimiter)) {
        const splitValue = props.formInputProps.value.split(delimiter);
        if (splitValue.length === 2) {
          valueToCompare = splitValue[0];
          if (!allowType || allowType.textValue !== splitValue) {
            setAllowType({
              ...inputTemplate.select.allowType,
              textValue: splitValue[1],
            });
          }
        }
      }

      const findValue = dataToLoop.find((dataSingle) => {
          return dataSingle.value === valueToCompare;
        })
      const findLabel = dataToLoop.find(dataSingle => {
          return dataSingle.label.toLowerCase() === valueToCompare.toLowerCase();
      })
      let actualValue =
        findValue?.label || findLabel?.label || "";

      setAutoCompleteValue(actualValue);
    }
  }, [dataToLoop, props.formInputProps.value]);

  useEffect(() => {
    if (inputTemplate.invisible) {
      setOldInvisible(true);
      inputProps.onChange(undefined);
      setAllowType(null);
    } else if (oldInvisible) {
      setOldInvisible(false);
      inputProps.onChange(props.initialValue);
    }
  }, [inputTemplate.invisible]);

  useEffect(() => {
    if (
      props.formInputProps.value === undefined &&
      !inputTemplate.invisible &&
      !props.initialValue
    ) {
      props.formInputProps.onChange("");
    }
  }, [props.formInputProps.value]);

  const textInputTemplate = allowType?.inputTemplate;

  return (
    <div>
      <Autocomplete
        className={styles.formDynamicTextDateSelectInput}
        label={
          inputTemplate.labelNoWrap ? (
            <span style={{ textWrap: "nowrap" }}>{inputTemplate.label}</span>
          ) : (
            inputTemplate.label
          )
        }
        withAsterisk={props.requiredInput}
        placeholder={inputTemplate.placeholder}
        disabled={disabledInput}
        limit={dataToLoop.length}
        value={autoCompleteValue}
        hoverOnSearchChange={true}
        onChange={(value) => {
          setAutoCompleteValue(value);
          if (value.length === 0) {
            inputProps.onChange("");
          }
          if (hasSelectCustomError) {
            setHasSelectCustomError(false);
            if (props.setHasCustomError) props.setHasCustomError(false);
          }
        }}
        error={inputProps.error}
        onBlur={(event) => {
          const valueLabel = event.target.value;
          const vnStrValueLabel = vnStr.strToTelex(valueLabel);

          const dataMap = dataToLoop.map((dataSingle) => {
            const vnStrData = vnStr.strToTelex(dataSingle.label);
            return {
              stringSimilarity: stringSimilarity.compareTwoStrings(
                vnStrData,
                vnStrValueLabel
              ),
              dataSingle: dataSingle,
            };
          });

          let max = 0;
          let maxIndex = -1;
          dataMap.forEach((dataMapSingle, index) => {
            if (
              dataMapSingle.stringSimilarity >
                (inputTemplate?.stringSimilarityCoefficient || 0.6) &&
              dataMapSingle.stringSimilarity > max
            ) {
              max = dataMapSingle.stringSimilarity;
              maxIndex = index;
            }
          });

          if (maxIndex > -1) {
            if (hasSelectCustomError) {
              setHasSelectCustomError(false);
              props.setHasCustomError(false);
            }
            const dataFind = dataMap[maxIndex].dataSingle;
            setAutoCompleteValue(dataFind.label);
            inputProps.onChange(dataFind.value);
          } else {
            if (props.setHasCustomError && valueLabel.length > 0) {
              setHasSelectCustomError(true);
              props.setHasCustomError(true);
            }
          }
        }}
        dropdownPosition="bottom"
        maxDropdownHeight={250}
        data={dataToLoop}
        onItemSubmit={(item) => {
          const value = item.value;
          const label = item.label;
          setAutoCompleteValue(label);
          inputProps.onChange(value);
        }}
        filter={(searchStr, item) => {
          if (searchStr.length === 0) {
            return true;
          }

          const vnSearchStr = vnStr.strToTelex(searchStr);
          const vnItemStr = vnStr.strToTelex(item.label || "");
          return (
            vnItemStr
              .toLowerCase()
              .includes(vnSearchStr.toLowerCase().trim()) ||
            stringSimilarity.compareTwoStrings(vnItemStr, vnSearchStr) >
              (inputTemplate?.stringSimilarityCoefficient || 0.6)
          );
        }}
      />
      {hasSelectCustomError ? (
        <Text c={"red"} fz={12}>
          Không có lựa chọn đó trong danh sách
        </Text>
      ) : (
        <></>
      )}

      {textInputTemplate && (
        <TextInput
          className={styles.formDynamicTextDateSelectInput}
          iconWidth={48}
          icon={<Mail strokeWidth={1.5} size={16} stroke={"white"} />}
          description={getDescriptionStyleJSX(textInputTemplate)}
          type={textInputTemplate.type}
          onKeyDown={(evt) => {
            if (textInputTemplate.type === "number") {
              ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
            }
          }}
          value={allowType.textValue}
          onChange={(e) => {
            setAllowType({
              ...allowType,
              textValue: e.target.value,
            });
          }}
          onBlur={() => {
            inputProps.onChange(allowType.value);
          }}
          placeholder={
            textInputTemplate.placeholder
              ? textInputTemplate.placeholder
              : textInputTemplate.label
          }
          label={
            textInputTemplate.labelNoWrap ? (
              <span style={{ textWrap: "nowrap" }}>
                {textInputTemplate.label}
              </span>
            ) : (
              textInputTemplate.label
            )
          }
        />
      )}
    </div>
  );
}

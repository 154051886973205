import Feature from "@/components/feature";
import { Stack } from "@mantine/core";
import { useState } from 'react';

function SearchHub(props) {
    const [filterTagStringArray, setFilterTagStringArray] = useState(null);
    const [textSearch, setTextSearch] = useState(null);

    const [dynamicFilters, setDynamicFilters] = useState([])

    let handleSearch = () => {
    };

    const searchComponentObject = props.options.searchComponent;
    const staticComponentArray = props.options.staticComponents
    const dynamicComponentArray = props.options.dynamicComponents;

    let searchComponentJSX = <></>;
    let staticComponentJSX = <></>;
    let dynamicComponentJSX = <></>;

    if (searchComponentObject) {
        if (searchComponentObject.component === "search")
            switch (searchComponentObject.display) {
                case "search2":
                    handleSearch = (data1, data2, textSearchValue) => {
                        if (data1 !== "" || data2 !== "") {
                            const tempFilterTagString1 = data1 !== "" ? data1 : null;
                            const tempFilterTagString2 = data2 !== "" ? data2 : null;

                            setFilterTagStringArray([tempFilterTagString1, tempFilterTagString2]);
                        } else {
                            setFilterTagStringArray(null);
                        }
                        if (textSearchValue && textSearchValue !== "") {
                            setTextSearch(textSearchValue);
                        } else {
                            setTextSearch(null);
                        }
                    }
                    break;

                case "search3":
                    handleSearch = (filterArray) => {
                        setDynamicFilters([...filterArray])
                    }

                default:
                    break;
            }
        searchComponentJSX = <Feature
            url={props.url}
            display={searchComponentObject.display}
            component={searchComponentObject.component}
            options={{ handleSearch: handleSearch, ...searchComponentObject.options }}
            windowDimension={props.windowDimension}
            primaryColor={props.primaryColor}
            secondaryColor={props.secondaryColor}
            defaultImage={props.defaultImage}
            noDataText={props.noDataText}
            backgroundColor={props.backgroundColor}
        />
    }

    if (staticComponentArray && Array.isArray(staticComponentArray)) {
        staticComponentJSX = (
            <Stack spacing={10}>
                {
                    staticComponentArray.map((staticComponent, index) => {
                        return (<Feature
                            url={props.url}
                            key={`feature-in-component-default-${index}`}
                            component={staticComponent.component}
                            display={staticComponent.display}
                            options={staticComponent.options}
                            windowDimension={props.windowDimension}
                            primaryColor={props.primaryColor}
                            secondaryColor={props.secondaryColor}
                            logo={props.imageLogo}
                            defaultImage={props.defaultImage}
                            noDataText={props.noDataText}
                            backgroundColor={props.backgroundColor}
                        />);
                    })
                }
            </Stack>
        );
    }

    if (dynamicComponentArray && Array.isArray(dynamicComponentArray)) {
        dynamicComponentJSX = (
            <Stack spacing={10}>
                {
                    dynamicComponentArray.map((dynamicComponent, index) => {
                        return (<Feature
                            url={props.url}
                            key={`feature-in-component-default-${index}`}
                            component={dynamicComponent.component}
                            display={dynamicComponent.display}
                            options={{
                                filterTagStringArray: filterTagStringArray,
                                textSearch: textSearch, ...dynamicComponent.options
                            }}
                            windowDimension={props.windowDimension}
                            primaryColor={props.primaryColor}
                            secondaryColor={props.secondaryColor}
                            logo={props.imageLogo}
                            defaultImage={props.defaultImage}
                            noDataText={props.noDataText}
                            backgroundColor={props.backgroundColor}
                        />);
                    })
                }
            </Stack>
        );
    }

    return (
        <>
            {searchComponentJSX}
            {staticComponentJSX}
            {dynamicComponentJSX}
        </>
    )
}

export default SearchHub

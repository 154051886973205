import IconComponent from "@/components/helpers/iconComponent/iconComponent";
import { Group, Stack, Text, Tooltip } from "@mantine/core";
import { Tag } from "tabler-icons-react";
import { useMediaQuery } from "usehooks-ts";
import JobCardRectStyles from "./jobCardRect.module.scss";

export function JobCardRect({ recruitmentPlan, isSelected, inDetailMode, descriptionArray }) {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const checkDetailOrMobile = isMobile || inDetailMode;
  return (
    <Stack className={isSelected ? JobCardRectStyles.jobCardRectSelected : JobCardRectStyles.jobCardRect}>
      <Stack className={JobCardRectStyles.title} style={{ width: "100%" }}>
        <Tooltip label={recruitmentPlan.name} withArrow>
          <Text style={{ width: "100%" }} lineClamp={1} className={JobCardRectStyles.jobName}>
            {recruitmentPlan.name}
          </Text>
        </Tooltip>
        <Group style={{ width: "100%", minHeight: 22, alignItems: "center" }} noWrap>
          {[...recruitmentPlan.tags].map((eTag, index) => {
            return (
              <Group key={index} className={JobCardRectStyles.tag} style={{ position: "relative" }}>
                <Tag size={11} />
                <Tooltip withArrow label={eTag}>
                  <Text style={{ flex: "1 1 0" }} lineClamp={1}>
                    {eTag}
                  </Text>
                </Tooltip>
              </Group>
            );
          })}
        </Group>
      </Stack>

      {descriptionArray && (
        <Group className={JobCardRectStyles.description}>
          {descriptionArray.map((description, index) => {
            return (
              <Group key={index} className={JobCardRectStyles.item}>
                <IconComponent size={16} {...description.icon} color={description.icon.color || "black"} />
                <Text>{recruitmentPlan[description.key]}</Text>
              </Group>
            );
          })}
        </Group>
      )}
    </Stack>
  );
}

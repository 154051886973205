import { Button, Flex, Stack, Text } from "@mantine/core";
import { openConfirmModal, openContextModal } from "@mantine/modals";
import axios from "axios";
import { useState } from "react";
import variables from "../../../styles/variables.module.scss";
import { InputTextProps } from "../components/inputText";
import { FormGroup } from "./formGroup";

export function NewDynamicForm(props: {
  label: string;
  description: string;
  controls: {
    label: string;
    description: string;
    controls: InputTextProps[];
    cols: number;
    name: string;
  };
  submit?: {
    label: string;
    uri: string;
    method: string;
  };
  afterSubmit?: {
    title: string;
  };
  confirmSubmit?: {
    title: string;
    description: string;
    labelConfirm: string;
    labelCancel: string;
  };
}) {
  const [value, setValue] = useState<{ [k: string]: any }>({});
  const submit = async () => {
    if (props.confirmSubmit) {
      openConfirmModal({
        title: props.confirmSubmit?.title,
        children: <Text size="sm">{props.confirmSubmit?.description}</Text>,
        labels: {
          confirm: props.confirmSubmit?.labelConfirm,
          cancel: props.confirmSubmit?.labelCancel,
        },
        onConfirm: realSubmit,
        onCancel: () => {},
      });
    } else {
      await realSubmit();
    }
  };

  const realSubmit = async () => {
    const hostNameUrl = window.location.hostname;
    const actualHostName =
      hostNameUrl !== undefined &&
      hostNameUrl !== "" &&
      !hostNameUrl.includes("localhost")
        ? `https://${hostNameUrl}/api`
        : process.env.NEXT_PUBLIC_API_URL;
    if (props.submit?.method === "POST") {
      const res = await axios.post(
        `${actualHostName}/${props.submit?.uri}`,
        value
      );
      if (res.status === 200 || res.status === 201) {
        openContextModal({
          modal: "success",
          centered: true,
          innerProps: {
            text: props.afterSubmit?.title,
            color: variables.primaryColor,
          },
        });
        setValue({});
      }
    }
  };
  return (
    <Stack
      spacing="md"
      style={{
        backgroundImage: "url(/images/subscribe-mail.png)",
        backgroundSize: "cover",
        margin: 40,
        padding: 48,
        borderRadius: 48,
      }}
    >
      <div
        style={{
          backgroundColor: "#FFFFFF7A",
          padding: 40,
          borderRadius: 24,
          border: "2px solid #ffffff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 16,
        }}
      >
        <div>
          <Flex align="center" justify="center">
            <Text style={{ fontSize: 32 }} fw={700}>
              {props.label}
            </Text>
          </Flex>
          <Flex align="center" justify="center">
            <Text>{props.description}</Text>
          </Flex>
        </div>
        <div style={{ width: "100%" }}>
          <FormGroup
            label={props.controls.label}
            description={props.controls.description}
            controls={props.controls.controls}
            cols={props.controls.cols}
            name={props.controls.name}
            value={value}
            onChange={(v) => {
              setValue(v);
            }}
          ></FormGroup>
        </div>
        <Button style={{ width: 160 }} radius={32} onClick={submit}>
          {props.submit?.label}
        </Button>
      </div>
    </Stack>
  );
}

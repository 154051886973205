import { Text } from "@mantine/core";

function FormDynamicNote(props) {
    const {inputTemplate} = props;
    return (
        <Text size="sm">
             {inputTemplate.value} </Text>
    )
}

export default FormDynamicNote

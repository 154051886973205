import { Container, Flex, SimpleGrid, Stack, Text } from "@mantine/core";
import { InputText, InputTextProps } from "../components/inputText";
export function FormGroup(props: {
  label: string;
  description: string;
  controls: InputTextProps[];
  cols: number;
  name: string;
  value: { [k: string]: any };
  onChange: (value: { [k: string]: any }) => void;
}) {
  return (
    <Container size={600}>
      <Stack spacing="lg">
        <div>
          <Flex align="center" justify="center">
            <Text style={{ fontSize: 32 }} fw={700}>
              {props.label}
            </Text>
          </Flex>
          <Flex align="center" justify="center">
            <Text>{props.description}</Text>
          </Flex>
        </div>
        <SimpleGrid cols={props.cols ?? 1}>
          {props.controls.map((control, index) => {
            return (
              <InputText
                key={control.name}
                name={control.name}
                label={control.label}
                placeholder={control.placeholder}
                value={props.value[control.name] ?? control.value}
                icon={control.icon}
                onChange={(v) => {
                  props.onChange({ ...props.value, [control.name]: v });
                }}
              />
            );
          })}
        </SimpleGrid>
      </Stack>
    </Container>
  );
}

import IconComponent from "@/components/helpers/iconComponent/iconComponent";
import ImagePlaceholder from "@/components/imagePlaceholder/imagePlaceholder";
import {
  BackgroundImage,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  Image,
  Modal,
  Stack,
  Text,
} from "@mantine/core";
import parse from "html-react-parser";
import Link from "next/link";
import { useState } from "react";
import ImageComponent from "../helpers/imageComponent/imageComponent";
import CarouselHero from "./carouselHero/carouselHero";
import ContactInfoHero from "./contactInfoHero/contactInfoHero";
import HeroList from "./heroList/heroList";
import styles from "/styles/feature.module.scss";
import globals from "/styles/globals.module.scss";
import variables from "/styles/variables.module.scss";
export default function Hero(props) {
  let eleHTML = <></>;

  switch (props.display) {
    case "hero1":
      eleHTML = <Hero1 {...props.options} defaultImage={props.defaultImage} />;
      break;

    case "hero2":
      eleHTML = <Hero2 {...props.options} defaultImage={props.defaultImage} />;
      break;

    case "hero3":
      eleHTML = <Hero3 {...props.options} defaultImage={props.defaultImage} />;
      break;
    case "hero4":
      eleHTML = (
        <Hero4
          {...props.options}
          imageLogo={props.imageLogo}
          defaultImage={props.defaultImage}
        />
      );
      break;
    case "hero5":
      eleHTML = (
        <Hero5R
          {...props.options}
          windowDimension={props.windowDimension}
          imageLogo={props.imageLogo}
          defaultImage={props.defaultImage}
        />
      );
      break;

    case "hero6":
      eleHTML = (
        <Hero6R
          {...props.options}
          windowDimension={props.windowDimension}
          defaultImage={props.defaultImage}
        />
      );
      break;
    case "hero7":
      eleHTML = (
        <Hero7
          {...props.options}
          windowDimension={props.windowDimension}
          defaultImage={props.defaultImage}
        />
      );
      break;
    case "contactInfoHero":
      eleHTML = (
        <ContactInfoHero
          options={props.options}
          primaryColor={props.primaryColor}
        />
      );
      break;

    case "heroList":
      eleHTML = (
        <HeroList
          options={props.options}
          variables={variables}
          imageLogo={props.imageLogo}
          defaultImage={props.defaultImage}
        />
      );
      break;

    case "carouselHero":
      eleHTML = (
        <CarouselHero
          options={{ ...props.options }}
          defaultImage={props.defaultImage}
          imageLogo={props.imageLogo}
        />
      );
      break;
    case "decathlonPurpose":
      eleHTML = (<DecathlonPurpose options={{ ...props.options }} />);
      break;
    default:
      break;
  }

  return eleHTML;
}

function DecathlonPurpose(props) {
  const options = props.options;
  return (
    <Flex direction="column" gap={24} py={44} px={48}>
      {options?.title && (
        <Text
          fz={48}
          fw={700}
          c={variables.primaryColor}
          ta={options.titleAlign ?? "center"}
        >
          {options.title}
        </Text>
      )}
      <Box maw={"100%"} mx="auto">
        {options?.subtitle && (
          <Text fz={16} fw={400} ta={"center"}>
            {options.subtitle}
          </Text>
        )}
      </Box>
      <Flex gap={40}>
        <Stack style={{ width: "50%", justifyContent: "center", gap: 48 }}>
          {options.data.map((item, index) => {
            return (
              <Flex key={index} gap={16} align="center">
                <div style={{ color: "var(--primaryColor)" }}>
                  <IconComponent
                    name={item.icon}
                    size={48}
                    color={"none"}
                  ></IconComponent>
                </div>
                <Stack gap={8}>
                  <Text size={18} fw="bold">
                    {" "}
                    {item.text}
                  </Text>
                  <Text> {item.subText}</Text>
                </Stack>
              </Flex>
            );
          })}
        </Stack>
        <Image src={options.image} style={{ width: "50%" }}></Image>
      </Flex>
    </Flex>
  );
}
function Hero1(props) {
  return (
    <Box
      className={styles.hero1}
      sx={(theme) => ({
        height: 804,
        background: `radial-gradient(50.3% 50.3% at 50% 47.92%, ${variables.primaryColor}66 0%, ${variables.secondColor}66 29.69%, rgba(190, 214, 228, 0.2) 61.98%, rgba(255, 255, 255, 0) 100%)`,
        display: "flex",
        justifyContent: "center",
      })}
    >
      <Group
        style={{ width: 1399 }}
        spacing={205}
        align="center"
        position="center"
        noWrap
      >
        <div className={styles.boxImg}>
          <Image
            radius={40}
            height={576}
            src={props.image}
            alt="photo hero"
            className={styles.effectImg}
          />
        </div>
        <Stack style={{ width: 608 }}>
          <Text className={globals.heading1}>{props.title}</Text>
          <div className={styles.boxDivider}>
            <Divider size={1} color={variables.primaryColor} />
          </div>
          <Text style={{ lineHeight: "32px" }} lineClamp={3}>
            {props.description}
          </Text>
          <Group noWrap mt={56} spacing={28} align="center" position="center">
            <Stack spacing={8} className={styles.stackText}>
              <Text className={styles.dataNumber}>{props.data1.number}</Text>
              <Text>{props.data1.text}</Text>
            </Stack>
            <Divider
              orientation="vertical"
              color={variables.primaryColor}
              className={styles.divider}
            />
            <Stack spacing={8} className={styles.stackText}>
              <Text className={styles.dataNumber}>{props.data2.number}</Text>
              <Text>{props.data2.text}</Text>
            </Stack>
            <Divider
              orientation="vertical"
              color={variables.primaryColor}
              className={styles.divider}
            />
            <Stack spacing={8} className={styles.stackText}>
              <Text className={styles.dataNumber}>{props.data3.number}</Text>
              <Text>{props.data3.text}</Text>
            </Stack>
          </Group>
          {/* <Button
                radius={40}
                mt={34}
                style={{
                  height: "auto",
                  background: variables.primaryColor,
                  fontFamily: "Avenir",
                  fontWeight: 500,
                  fontSize: 22,
                  padding: "19px 0px",
                }}
              >
                Get started
              </Button> */}
        </Stack>
      </Group>
    </Box>
  );
}

function Hero2(props) {
  return (
    <Box className={styles.hero2}>
      <Stack align="center" spacing={24} w={750}>
        <Text className={styles.title} align="center">
          {props.title}
        </Text>
        <Text className={styles.description} align="center" lineClamp={4}>
          {props.description}
        </Text>
        <Center>
          <Button
            bg={variables.primaryColor}
            radius={36}
            fz={24}
            h={66}
            w={"100%"}
          >
            {props.btnTitle}
          </Button>
        </Center>
      </Stack>
    </Box>
  );
}

function Hero3(props) {
  return (
    <Box className={styles.hero3}>
      <Box className={styles.list}>
        {props.data.map((ele, index) => (
          <Stack key={`hero3-${index}`} w={300} px={32}>
            <Text className={styles.title}>{ele.title}</Text>
            <Text lineClamp={2}>{ele.text}</Text>
          </Stack>
        ))}
      </Box>
      <Image
        src={props.image ? props.image : "/images/no-img.jpeg"}
        radius={24}
        alt="hero 3"
        height={500}
        className={styles.image}
      />
    </Box>
  );
}

function Hero4(props) {
  return (
    <Box className={styles.hero4}>
      <Group position="center" spacing={64} align="center" h={"100%"} noWrap>
        <Stack spacing={24}>
          <Text lineClamp={2} className={styles.title}>
            {props.title}
          </Text>
          <Text className={styles.subtitle}>{props.subtitle}</Text>
        </Stack>
        <Image
          width={793}
          height={530}
          src={props.image}
          alt=""
          withPlaceholder
          placeholder={
            <ImagePlaceholder
              display={"imagePlaceholder1"}
              image={props.defaultImage ? props.defaultImage : props.imageLogo}
            />
          }
        />
      </Group>
    </Box>
  );
}

function Hero5R(props) {
  const divider =
    props.windowDimension.width < 1440 ? 1440 / props.windowDimension.width : 1;
  let eleHTML = <></>;

  const imageBoxCSS = {
    width: 496 / divider,
    height: 552 / divider,
    border: `${12 / divider}px solid ${variables.primaryColor}`,
    borderRadius: 40 / divider,
    marginLeft: 32 / divider,
    position: "relative",
  };
  const effectImageCSS = {
    left: -32 / divider,
    top: -32 / divider,
    position: "absolute",
  };

  const dividerCSS = {
    height: 88 / divider,
    position: "relative",
    top: 11 / divider,
  };

  eleHTML = (
    <BackgroundImage
      src={props.backgroundImage}
      radius="sm"
      style={{ marginBottom: -44, padding: 64 / divider }}
    >
      <Group spacing={100 / divider} style={{ height: 680 / divider }}>
        <div style={imageBoxCSS}>
          <Image
            radius={40 / divider}
            width={464 / divider}
            height={520 / divider}
            src={props.image}
            alt="photo hero"
            style={effectImageCSS}
          />
        </div>
        <Stack
          style={{ width: 640 / divider, height: "auto" }}
          spacing={60 / divider}
        >
          <Stack spacing={16 / divider}>
            <Text fz={48 / divider} fw={700} c={variables.primaryColor}>
              {props.title}
            </Text>
            <div style={{ width: 100 / divider }}>
              <Divider size={1} color={variables.primaryColor} />
            </div>
            <Text style={{ lineHeight: 2 }} fz={18 / divider}>
              {parse(props.description)}
            </Text>
          </Stack>
          {props.data1 &&
          props.data1.number &&
          props.data2 &&
          props.data2.number &&
          props.data3 &&
          props.data3.number ? (
            <Group
              noWrap
              spacing={28 / divider}
              align="center"
              position="apart"
            >
              <Stack
                spacing={8 / divider}
                style={{ width: 176 / divider, height: 110 / divider }}
              >
                <Text fz={48 / divider} fw={700} c={variables.primaryColor}>
                  {props.data1.number}
                </Text>
                <Text fz={15 / divider}>
                  {props.data1 ? props.data1.text : ""}
                </Text>
              </Stack>
              <Divider
                orientation="vertical"
                color={variables.primaryColor}
                style={dividerCSS}
              />
              <Stack
                spacing={8 / divider}
                style={{ width: 176 / divider, height: 110 / divider }}
              >
                <Text fz={48 / divider} fw={700} c={variables.primaryColor}>
                  {props.data2.number}
                </Text>
                <Text fz={15 / divider}>
                  {props.data2 ? props.data2.text : ""}
                </Text>
              </Stack>
              <Divider
                orientation="vertical"
                color={variables.primaryColor}
                style={dividerCSS}
              />
              <Stack
                spacing={8 / divider}
                style={{ width: 176 / divider, height: 110 / divider }}
              >
                <Text fz={48 / divider} fw={700} c={variables.primaryColor}>
                  {props.data3.number}
                </Text>
                <Text fz={15 / divider}>
                  {props.data3 ? props.data3.text : ""}
                </Text>
              </Stack>
            </Group>
          ) : (
            <></>
          )}
        </Stack>
      </Group>
    </BackgroundImage>
  );
  return eleHTML;
}

function Hero6R(props) {
  const isMobile = props.windowDimension.width <= 1024;

  const buttonCSS = {
    backgroundColor: variables.primaryColor,
    borderRadius: 36,
    fontSize: 18,
    fontWeight: 700,
    height: 52,
    width: 280,
  };

  let query = "";
  if (props.button?.query) {
    query = Object.keys(props.button?.query)
      .map((key) => `${key}=${props.button?.query[key]}`)
      .join("&");
  }

  const componentText = (
    <Stack
      style={{
        width: isMobile ? "100%" : "calc(100% - 500px)",
        alignItems: !isMobile ? "start" : "center",
      }}
      spacing={44}
    >
      {props.title && (
        <Text fz={32} fw={700}>
          {parse(props.title)}
        </Text>
      )}

      {props.description && props.description.length > 0 ? (
        <Text color="dimmed" fz={16}>
          {parse(props.description)}
        </Text>
      ) : (
        <></>
      )}

      {!props.buttonInvisible && (
        <Link href={props.button ?? "#"}>
          {/* {(props.button?.href || "/recruitments") + (query.length > 0 ? ('?' + query) : "")} */}
          <Button style={buttonCSS} className={styles.btn}>
            {props.button?.label || "Xem thêm"}
          </Button>
        </Link>
      )}
    </Stack>
  );

  const componentPic = (
    <>
      {props.showPreviewImage ? (
        <ImageComponent
          width={!isMobile ? 500 : "100%"}
          src={props.image}
          alt="photo hero"
          height={500}
          radius={16}
        />
      ) : (
        <Image
          width={!isMobile ? 500 : "100%"}
          height={500}
          radius={16}
          src={props.image}
          alt="photo hero"
        />
      )}
    </>
  );

  return !isMobile ? (
    <>
      <Group
        className={styles.hero6}
        style={{ width: "100%", padding: `44px 64px` }}
        spacing={100}
        align="center"
        position={props.isImageLeft ? "left" : "apart"}
        noWrap
      >
        {props.isImageLeft ? componentPic : componentText}
        {props.isImageLeft ? componentText : componentPic}
      </Group>
    </>
  ) : (
    <>
      <Stack
        lassName={styles.hero6}
        style={{ width: "100%", padding: `10px 20px` }}
        spacing={50}
        align="center"
        position={props.isImageLeft ? "left" : "apart"}
      >
        {componentPic}
        {componentText}
      </Stack>
    </>
  );
}

function Hero7(props) {
  const isMobile = props.windowDimension.width <= 1024;
  const [opened, setOpened] = useState(false);

  const buttonCSS = {
    backgroundColor: variables.primaryColor,
    borderRadius: 36,
    fontSize: 18,
    fontWeight: 700,
    height: 52,
    width: 280,
  };

  let query = "";
  if (props.button?.query) {
    query = Object.keys(props.button?.query)
      .map((key) => `${key}=${props.button?.query[key]}`)
      .join("&");
  }

  const buttonTypeLink = (
    <Link href={props.button ?? "#"}>
      <Button style={buttonCSS} className={styles.btn}>
        {props.button?.label || "Xem tin tuyển dụng"}
      </Button>
    </Link>
  );

  const buttonTypeButton = (
    <Button
      style={buttonCSS}
      className={styles.btn}
      onClick={() => setOpened(true)}
    >
      {props.button?.label || "Xem tin tuyển dụng"}
    </Button>
  );

  const modal = (
    <Modal
      opened={opened}
      size={!isMobile ? "60%" : "100%"}
      onClose={() => {
        setOpened(false);
      }}
      title={props.title}
    >
      <Center>
        {props.detailedDescription && (
          <div style={{ width: "100%" }}>
            {parse(props.detailedDescription)}
          </div>
        )}
      </Center>
    </Modal>
  );

  const componentText = (
    <Stack
      style={{
        width: isMobile ? "100%" : "calc(100% - 500px)",
        alignItems: !isMobile ? "start" : "center",
      }}
      spacing={44}
    >
      {props.title && (
        <Text fz={32} fw={700}>
          {parse(props.title)}
        </Text>
      )}

      {props.description && props.description.length > 0 ? (
        <Text color="dimmed" fz={16}>
          {parse(props.description)}
        </Text>
      ) : (
        <></>
      )}

      {!props.buttonInvisible &&
        (props.button?.type === "link" ? buttonTypeLink : buttonTypeButton)}
      {modal}
    </Stack>
  );

  const componentPic = (
    <>
      {props.showPreviewImage ? (
        <ImageComponent
          width={!isMobile ? 500 : "100%"}
          src={props.image}
          alt="photo hero"
          height={500}
          radius={16}
        />
      ) : (
        <Image
          width={!isMobile ? 500 : "100%"}
          height={500}
          radius={16}
          src={props.image}
          alt="photo hero"
        />
      )}
    </>
  );

  return !isMobile ? (
    <>
      <Group
        className={styles.hero6}
        style={{ width: "100%", padding: `44px 64px` }}
        spacing={100}
        align="center"
        position={props.isImageLeft ? "left" : "apart"}
        noWrap
      >
        {props.isImageLeft ? componentPic : componentText}
        {props.isImageLeft ? componentText : componentPic}
      </Group>
    </>
  ) : (
    <>
      <Stack
        lassName={styles.hero6}
        style={{ width: "100%", padding: `10px 20px` }}
        spacing={50}
        align="center"
        position={props.isImageLeft ? "left" : "apart"}
      >
        {componentPic}
        {componentText}
      </Stack>
    </>
  );
}
